const path = 'AdminModulesModule';
const apiPath = '/admin-panal/notifications';

const AdminNotificationsActions = {
    GET_NOTIFICATIONS : `${path}/getNotifications`,
    SET_NOTIFICATIONS_BY_PAGE : `${path}/setNotificationsByPage`,
}
const AdminNotificationsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {AdminNotificationsAPIsConstants, AdminNotificationsActions };
