const path = 'LeaveRequestReportModule';
const apiPath = 'hr/attendance/report';

const LeaveRequestReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  GET_MY_REPORT: `${path}/getMyReport`,
  // GENERATE_DETAILED_AttendanceDeparture_REPORT : `${path}/generateDetailedLeaveRequestReport`,
}

const LeaveRequestReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {LeaveRequestReportAPIsConstants, LeaveRequestReportActions };
