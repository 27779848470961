import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'settings',
      name: 'pos-general-settings',
      meta: {
         permission: 'pos_setting',
         title: 'Pos Settings',
      },
      component: () => import('@/views/modules/pos/views/settings/Setting.vue'),
   },
   {
      path: 'delivery-zones',
      name: 'pos-general-delivery-zones',
      meta: {
         permission: 'pos_delivery_zones',
         title: 'Delivery Zones',
      },
      component: () => import('@/views/modules/pos/views/crud/delivery-zones/List.vue'),
   },
   {
      path: 'branches',
      name: 'pos-general-branches',
      meta: {
         permission: 'branches',
         title: 'Branches',
      },
      component: () => import('@/views/modules/settings/views/settings/general-settings/branches/List.vue'),
   },
   {
      path: 'sections',
      name: 'pos-general-sections',
      meta: {
         permission: 'pos_sections',
         title: 'Sections',
      },
      component: () => import('@/views/modules/pos/views/crud/sections/List.vue'),
   },
   {
      path: 'pos',
      name: 'pos-general-pos',
      meta: {
         permission: 'pos_point_sales',
         title: 'Points Of Sales',
      },
      component: () => import('@/views/modules/pos/views/crud/pos/List.vue'),
   },
   {
      path: 'offers',
      name: 'pos-general-offers',
      meta: {
         permission: 'pos_offers',
         title: 'Offers',
      },
      component: () => import('@/views/modules/pos/views/crud/offers/List.vue'),
   },

   {
      path: 'clients',
      name: 'pos-general-clients',
      meta: {
         permission: 'clients',
         title: 'Clients',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client/List.vue'),
   },
   {
      path: 'unit',
      name: 'pos-general-unit',
      meta: {
         permission: 'units',
         title: 'Units',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/unit/List.vue'),
   },
   {
      path: 'taxes',
      name: 'pos-general-taxes',
      meta: {
         permission: 'taxes',
         title: 'Taxes',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/taxes/List.vue'),
   },
   {
      path: 'coupons',
      name: 'pos-general-coupons',
      meta: {
         permission: 'pos_coupons',
         title: 'Coupons',
      },
      component: () => import('@/views/modules/pos/views/crud/coupons/List.vue'),
   },
   {
      path: 'expenses',
      name: 'pos-general-expenses',
      meta: {
         permission: 'pos_expenses',
         title: 'Expenses',
      },
      component: () => import('@/views/modules/pos/views/crud/expenses/List.vue'),
   },
   {
      path: 'delivery-employees',
      name: 'pos-general-delivery-employees',
      meta: {
         permission: 'pos_delivery_men',
         title: 'Delivery Employees',
      },
      component: () => import('@/views/modules/pos/views/crud/delivery-employees/List.vue'),
   },
];

export default settingRouter;
