import { RouteRecordRaw } from 'vue-router';

const invoiceRouter: Array<RouteRecordRaw> = [
   {
      path: 'sales-invoices/edit/:modelId/:invoiceId',
      name: 'warehouse-management-edit-sales-invoice',
      meta: {
         permission: 'sales_daily_movements',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/:type/:modelId/:invoiceId',
      name: 'warehouse-management-view-sales-invoice',
      meta: {
         permission: 'sales_daily_movements',
         title: 'sales daily transactions',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/add/:modelId/:noticeIds?',
      name: 'warehouse-management-add-sales-invoice',
      meta: {
         permission: 'sales_daily_movements',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/add/:modelId/duplicate/:duplicationId',
      name: 'warehouse-management-add-sales-duplicate-invoice',
      meta: {
         permission: 'sales_daily_movements',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/list/:modelId',
      name: 'warehouse-management-sales-invoices',
      meta: {
         permission: 'sales_daily_movements',
         title: 'sales daily transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/sales/List.vue'),
   },
   {
      path: 'purchase-invoices/edit/:modelId/:invoiceId',
      name: 'edit-purchase-invoice',
      meta: {
         permission: 'purchase_daily_movements',
         title: 'purchase daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoices/:type/:modelId/:invoiceId',
      name: 'view-purchase-invoice',
      meta: {
         permission: 'purchase_daily_movements',
         title: 'purchase daily transactions',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoices/add/:modelId/:noticeIds?',
      name: 'add-purchase-invoice',
      meta: {
         permission: 'purchase_daily_movements',
         title: 'purchase daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoices/add/:modelId/duplicate/:duplicationId',
      name: 'add-purchase-duplicate-invoice',
      meta: {
         permission: 'purchase_daily_movements',
         title: 'purchase daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoice/list/:modelId',
      name: 'warehouse-management-purchase-invoices',
      meta: {
         permission: 'purchase_daily_movements',
         title: 'purchase daily transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/invoices/purchase/List.vue'),
   },

   {
      path: 'purchase-financial-transactions',
      name: 'w-purchase-financial-transactions',
      meta: {
         permission: 'purchase_invoice_financial_transactions',
         title: 'purchase invoice financial transactions',
         type: 'read',
      },
      component: () =>
         import(
            '@/views/modules/invoicing/views/settings/financial-transactions/purchase/List.vue'
         ),
   },
   {
      path: 'add-purchase-financial-transactions',
      name: 'w-add-purchase-financial-transactions',
      meta: {
         permission: 'purchase_invoice_financial_transactions',
         title: 'purchase invoice financial transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/purchase/Add.vue'),
   },
   {
      path: 'update-purchase-financial-transactions/:id',
      name: 'w-update-purchase-financial-transactions',
      meta: {
         permission: 'purchase_invoice_financial_transactions',
         title: 'purchase invoice financial transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/purchase/Add.vue'),
   },
   {
      path: 'view-purchase-financial-transactions/:id/:view',
      name: 'w-view-purchase-financial-transactions',
      meta: {
         permission: 'purchase_invoice_financial_transactions',
         title: 'purchase invoice financial transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/purchase/Add.vue'),
   },

   {
      path: 'sales-financial-transactions',
      name: 'warehouse-management-sales-financial-transactions',
      meta: {
         permission: 'sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/sales/List.vue'),
   },
   {
      path: 'add-sales-financial-transactions',
      name: 'warehouse-management-add-sales-financial-transactions',
      meta: {
         permission: 'sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'update-sales-financial-transactions/:id',
      name: 'warehouse-management-update-sales-financial-transactions',
      meta: {
         permission: 'sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'view-sales-financial-transactions/:id/:type',
      name: 'warehouse-management-view-sales-financial-transactions',
      meta: {
         permission: 'sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'commission-policy',
      name: 'warehouse-management-commission-policy',
      meta: {
         permission: 'commission_policies',
         title: 'Commission Policy',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/commission-policy/Settings.vue'),
   },
];

export default invoiceRouter;
