import axios from 'axios';
import { StocktakingAPIsConstants } from '../enums/StocktakingEnums';
import DownloadService from '../../../../../core/services/DownloadService';

const StocktakingModule = {
   namespaced: true,
   mutations: {
      SET_DATA_TABLE(state, payload) {
         state.DataTable = payload.DataTable;
      },
      SET_DATA_Name(state, payload) {
         state.dataName = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         let arr = [];

         if (payload.itemEditable.item.translations) {
            payload.itemEditable.item.translations.map((lang) => {
               arr[lang.locale] = lang;
            });
            payload.itemEditable.item['translations'] = arr;
         }

         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      RESET_ALL(state) {
         state.DataTable = [];
         state.dataName = '';
         state.loading = false;
         state.itemEditable = {};
         state.tableFilters = {};
         state.isPagination = false;
         state.pagination = {
            page: 1,
            total: 0,
            rowsPerPage: 10,
         };
      },
   },
   actions: {
      generateStocktakingNumber({ commit, dispatch }, { apiPath, id }) {
         // return console.log('testset');
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/get-init-data`)
               .then((response) => {
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      findItemById({ commit, state }, { id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${StocktakingAPIsConstants.API_PATH}/${id}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      exportItemById({ commit, dispatch }, { id, credentials }) {
         commit('SET_LOADING', true);

         DownloadService.ddd(`${StocktakingAPIsConstants.API_PATH}/export/${id}`, {
            excel: 1,
         }).then(() => commit('SET_LOADING', false));
         return;
      },
      importItemById({ commit, state }, { id, values }) {
         commit('SET_LOADING_ITEM', true);
         var formData = new FormData();
         formData.append('file', values['file']);

         return new Promise((resolve, reject) => {
            axios
               .post(`${StocktakingAPIsConstants.API_PATH}/import/${id}`, formData)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  if (res.data.result?.item)
                     commit('SET_ITEM_EDITABLE', {
                        itemEditable: res.data.result,
                     });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      editItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}/save-stocktaking/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      approveItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}/approve-stocktaking/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      approveStocktaking({ commit }, { id, values }) {
         commit('SET_CRUD_LOADING', true);

         return axios
            .post(`${StocktakingAPIsConstants.API_PATH}/approve-stocktaking/${id}`, values)
            .finally(() => {
               commit('SET_CRUD_LOADING', false);
            });
      },
   },
};
export default StocktakingModule;
