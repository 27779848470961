import axios from 'axios';

const apiPath = '/hr/salaries/hr-link-with-accounting/migrate-to-accounting';
const path = 'MigrationToAccounting';

export const MigrationToAccountingApiConstants = {
   API_PATH: apiPath,
};

export const MigrationToAccountingActions = {
   MIGRATE: `${path}/migrate`,
};

const MigrationToAccounting = {
   namespaced: true,
   state: {
      crudLoading: false,
   },
   mutations: {
      SET_CRUD_LOADING(state, val) {
         state.crudLoading = val;
      },
   },
   actions: {
      migrate({ commit }, data) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(MigrationToAccountingApiConstants.API_PATH, data.values).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
   },
};

export default MigrationToAccounting;
