const apiPath = '/hr/hr-employees';
const path = 'HrEmployeeModule';

const EmployeesAPIsConstants = {
   API_PATH: `${apiPath}`,
   EMPLOYEE_DOCUMENT: (employeeId, documentId) =>
      `${apiPath}/get-employee-document/${employeeId}/${documentId}`,
};

const EmployeesModelActions = {
   GET_EMPLOYEE_INFORMATION: `${path}/getEmployeeInformation`,
   GET_EMPLOYEE_DOCUMENT: `${path}/getEmployeeDocument`,
};

export { EmployeesAPIsConstants, EmployeesModelActions };
