const path = 'BrokerDealsReportModule';
const apiPath = 'customs/report';

const BrokerDealsReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_BrokerDeals_REPORT : `${path}/generateDetailedBrokerDealsReport`,
}

const BrokerDealsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {BrokerDealsReportAPIsConstants, BrokerDealsReportActions };
