import AuthService from '@/core/services/AuthService';
import { MODULES_IDS } from '@/views/modules/shared/constants/ModulesNames';

export const ModulesNames = {
   ACCOUNTING: 'accounting',
   INVOICE: 'invoice',
   WAREHOUSE: 'warehouse',
   TRANSPORT: 'transport',
   CUSTOMS: 'customs',
   ASSET: 'asset',
   POS: 'pos',
};

export const financialYear = (moduleName) => ({
   computed: {
      settings() {
         const companySetting = this.$store.state.CompanyModule.companySetting;

         if ([ModulesNames.INVOICE, ModulesNames.WAREHOUSE].includes(moduleName)) {
            if (AuthService.hasModule(MODULES_IDS.WAREHOUSE_MANAGEMENT)) {
               return companySetting?.warehouse_settings;
            } else {
               return companySetting?.invoice_settings;
            }
         }

         return companySetting?.[`${moduleName}_settings`];
      },
      hasChangeYearPermission() {
         if ([ModulesNames.INVOICE, ModulesNames.WAREHOUSE].includes(moduleName)) {
            if (AuthService.hasModule(MODULES_IDS.WAREHOUSE_MANAGEMENT)) {
               return AuthService.hasPermission(`read_warehouse_change_financial_years`);
            } else {
               return AuthService.hasPermission(`read_invoicing_change_financial_years`);
            }
         }

         return AuthService.hasPermission(`read_${moduleName}_change_financial_years`);
      },
      hasOneFinancialYear() {
         return (name) => {
            let financialYears = this.$store.getters['ListModule/getListByName'](name);

            return financialYears?.length === 1 && this.settings?.financial_year_id;
         };
      },
   },
   watch: {
      settings: {
         handler() {
            this.setFinancialYear();
         },
         deep: true,
      },
   },
   mounted() {
      this.setFinancialYear();
   },
   methods: {
      setFinancialYear() {
         if (this.settings?.financial_year_id && !this.isEdit) {
            if (this.$refs.financial_year_id)
               this.$refs.financial_year_id.data = this.settings?.financial_year_id;
         }
      },
      resetFinancialYear() {
         if (this.$refs.financial_year_id)
            this.$refs.financial_year_id.data = this.settings?.financial_year_id;
      },
   },
});
