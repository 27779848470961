import axios from 'axios';

const apiPath = 'asset/asset-acquire-movements';
const path = 'AcquireAssets';

export const AcquireAssetsApiConstants = {
   API_PATH: apiPath,
};

export const AcquireAssetsActions = {
   ACQUIRE_ASSET: `${path}/addAcquireAsset`,
};

const AcquireAssetsModule = {
   namespaced: true,
   state: {
      loading: false,
   },
   mutations: {
      SET_LOADING(state, val) {
         state.loading = val;
      },
   },
   actions: {
      acquireAsset({ commit }, data) {
         commit('SET_LOADING', true);

         return axios.post(AcquireAssetsApiConstants.API_PATH, data).finally(() => {
            commit('SET_LOADING', false);
         });
      },
   },
};

export default AcquireAssetsModule;
