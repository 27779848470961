import { crudActions, mergeCrudStore } from '@/core/stores/curd';

const apiPath = 'asset/asset-attachments';
const path = 'AssetsAttachmentsModule';

export const AssetsAttachmentsApiConstants = {
   API_PATH: apiPath,
};

export const AssetsAttachmentsActions = {
   ...crudActions(path),
};

const AssetsAttachmentsModule = mergeCrudStore({ namespaced: true });

export default AssetsAttachmentsModule;
