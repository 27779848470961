import DownloadService from '@/core/services/DownloadService';
import axios from 'axios';
import { MuqeemOperationLogReportAPIsConstants } from '../../enums/reports/muqeem/MuqeemOperationLogReport';
import {EmployeesCareerInfoAPIsConstants} from "@/views/modules/hr/store/enums/reports/EmployeesCareerInfoEnums";

export default {
   namespaced: true,
   state: {
      loading: false,
      itemEditable: {},
      editableId: null,
   },
   mutations: {
      RESET_EDITABLE(state) {
         state.loading = false;
         state.itemEditable = {};
         state.editableId = null;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
   },
   actions: {
      resetEditable({ commit }) {
         commit('RESET_EDITABLE');
      },
      // generateReport({ commit }, credentials) {
      //    commit('SET_LOADING', true);
      //
      //    return DownloadService.downloadPDF(`${MuqeemOperationLogReportAPIsConstants.API_PATH}`, {
      //       method: 'get',
      //       params: {
      //          ...credentials,
      //          filters: { ...credentials.filters, print: 1 },
      //       },
      //    }).finally(() => commit('SET_LOADING', false));
      // },

      generateReport({ commit }, credentials) {
         commit("SET_LOADING", true);

         if (credentials.pdf == 1) {
            DownloadService.ddd(
                `${MuqeemOperationLogReportAPIsConstants.API_PATH}`,
                credentials
            ).then(() => commit("SET_LOADING", false));
            return;
         }

         return new Promise((resolve, reject) => {
            axios
                .get(`${MuqeemOperationLogReportAPIsConstants.API_PATH}`, {
                   params: credentials,
                })
                .then((response) => {
                   commit("SET_ITEM_EDITABLE", {
                      itemEditable: response.data.result,
                   });
                   commit("SET_LOADING", false);
                   resolve(response);
                })
                .catch((error) => {
                   commit("SET_LOADING", false);
                   reject(error);
                });
         });
      }
   },
};
