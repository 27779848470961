import axios from 'axios';
import {crudActions, mergeCrudStore} from "@/core/stores/curd";

const path = 'DisposeAssetModule';
const apiPath = '/asset/asset-disposes';

export const DisposeAssetActions = {
   ...crudActions(path),
   GET_INIT_DATA: `${path}/getInitData`,
};

export const DisposeAssetAPIsConstants = {
   API_PATH: apiPath,
};

const DisposeAssetModule = mergeCrudStore({
   namespaced: true,
   actions: {
      findItemById({ commit, state }, { apiPath, id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/${id}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: JSON.parse(JSON.stringify(res.data.result)),
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });

      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      getInitData({ commit, state }) {
         // commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/init-data`)
               .then((response) => {
                  // commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  // commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
});
export default DisposeAssetModule;
