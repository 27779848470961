import { RouteRecordRaw } from 'vue-router';

const reportsRouter: Array<RouteRecordRaw> = [
   {
      path: 'attendance-departure-report',
      name: 'attendance-departure-report',
      meta: {
         title: 'Attendance and departure',
         permission: 'attendance_reports',
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/attendance-departure/base.vue'),
   },
   {
      path: 'my-employee-attendance-departure-report',
      name: 'my-employee-attendance-departure-report',
      meta: {
         title: 'Employee Attendance and departure',
         permission: 'hr_employee_manger',
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/attendance-departure/my_employees.vue'),
   },
   {
      path: 'my-attendance-departure-report',
      name: 'my-attendance-departure-report',
      meta: {
         title: 'My Attendance and departure',
         // permission:"attendance_reports",
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/attendance-departure/employee.vue'),
   },
   {
      path: 'my-late-time-report',
      name: 'my-late-time-report',
      meta: {
         title: 'My Late Time',
         // permission:"attendance_reports",
      },
      component: () => import('@/views/modules/attendance/views/reports/late-time/employee.vue'),
   },
   {
      path: 'late-time-report',
      name: 'late-time-report',
      meta: {
         title: 'Late Time',
      },
      component: () => import('@/views/modules/attendance/views/reports/late-time/base.vue'),
   },
   {
      path: 'my-employee-late-time-report',
      name: 'my-employee-late-time-report',
      meta: {
         title: 'Employee Late Time',
         permission: 'hr_employee_manger',
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/late-time/my_employees.vue'),
   },
   {
      path: 'my-extra-hours-report',
      name: 'my-extra-hours-report',
      meta: {
         title: 'My Extra Hours',
         // permission:"attendance_reports",
      },
      component: () => import('@/views/modules/attendance/views/reports/extra-hours/employee.vue'),
   },
   {
      path: 'extra-hours-report',
      name: 'extra-hours-report',
      meta: {
         title: 'Extra Hours',
         permission: 'attendance_reports',
      },
      component: () => import('@/views/modules/attendance/views/reports/extra-hours/base.vue'),
   },
   {
      path: 'delay-request-report',
      name: 'delay-request-report',
      meta: {
         title: 'Employees Delay Permissions',
         permission: 'attendance_reports',
      },
      component: () => import('@/views/modules/attendance/views/reports/delay-request/base.vue'),
   },
   {
      path: 'my-delay-request-report',
      name: 'my-delay-request-report',
      meta: {
         title: 'My Employees Delay Permissions',
         // permission:"attendance_reports",
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/delay-request/employee.vue'),
   },
   {
      path: 'leave-request-report',
      name: 'leave-request-report',
      meta: {
         title: 'Employees Dismiss Permissions',
         permission: 'attendance_reports',
      },
      component: () => import('@/views/modules/attendance/views/reports/leave-request/base.vue'),
   },
   {
      path: 'my-leave-request-report',
      name: 'my-leave-request-report',
      meta: {
         title: 'My Employees Dismiss Permissions',
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/leave-request/employee.vue'),
   },
   {
      path: '/attendance-management/employees-yearly-vacations',
      name: 'employees-yearly-vacations',
      meta: {
         permission: 'attendance_reports',
         title: 'yearlyVacations',
      },
      component: () =>
         import('@/views/modules/attendance/views/reports/employees-personal-info/base.vue'),
   },
   {
      path: '/attendance-management/absence',
      name: 'absence',
      meta: {
         permission: 'hr_employee_manger',
         title: 'Employees Absence',
      },
      component: () => import('@/views/modules/attendance/views/reports/absence/base.vue'),
   },
   {
      path: '/attendance-management/my-absence',
      name: 'my-absence',
      meta: {
         title: 'My Absence',
      },
      component: () => import('@/views/modules/attendance/views/reports/absence/employee.vue'),
   },
   {
      path: '/attendance-management/my-vacations',
      name: 'my-vacations',
      meta: {
         title: 'My Vacations',
      },
      component: () => import('@/views/modules/attendance/views/reports/my-vacations/base.vue'),
   },
];

export default reportsRouter;
