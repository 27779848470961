const apiPath = '/hr/attendance/hr-leave-requests-as-employee';
const path = 'LeaveRequestAsEmployeeModule';

const LeaveRequestAsEmployeeAPIsConstants = {
  API_PATH: `${apiPath}`,
  GET_INIT_DATA: `${apiPath}/init-data`,
};

const LeaveRequestAsEmployeeActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  APPROVE_ITEM: `${path}/approveItem`,
  UNAPPROVE_ITEM: `${path}/unapproveItem`,
};

export { LeaveRequestAsEmployeeAPIsConstants, LeaveRequestAsEmployeeActions };
