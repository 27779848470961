import ApiService from '@/core/services/ApiService';
import JwtService, { USER_TYPE } from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { CookieService } from '@/core/services/CookieService';
import AlertService from '../../core/services/AlertService';
import axios from 'axios';
import UrlService from '@/core/services/UrlService';
import LanguageService from '@/core/services/LanguageService';
import AdminLanguageService from '@/core/services/admin/AdminLanguageService';

export interface User {
   name: string;
   surname: string;
   email: string;
   password: string;
   api_token: string;
}

export interface UserAuthInfo {
   errors: unknown;
   user: any;
   isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
   errors = {};
   errorsObj = {};
   companies = [];
   current_company: any = JwtService.getCurrentCompany();
   user = JwtService.getUser();
   adminData = {};
   InitFCMToken = window.localStorage.getItem('FCMToken');
   FCMToken = null;
   isAuthenticated = CookieService.getCookie('shredDomains')?.token;
   request = null;
   verifyAuthRequestsCount = 0;

   /**
    * Get current user object
    * @returns User
    */
   get currentUser(): any {
      return this.user;
   }

   get getAdminData(): any {
      return this.adminData;
   }

   get currentCompany(): any {
      return this.current_company;
   }

   get userModulesOptions(): any {
      const modules = [...(this.currentCompany.modules || [])];
      modules.sort((a, b) => (a.id > b.id ? 1 : -1));

      return modules?.filter((el) => el.subscribable === 1) ?? [];
   }

   /**
    * Verify user authentication
    * @returns boolean
    */
   get isUserAuthenticated(): boolean {
      return this.isAuthenticated;
   }

   /**
    * Get authentification errors
    * @returns array
    */
   get getErrors() {
      return this.errors;
   }

   get getErrorsObj() {
      return this.errorsObj;
   }

   get getCompanies() {
      return this.companies;
   }

   get isNewUser() {
      return !this.current_company?.modules?.length && this.user?.companies?.length == 1;
   }

   @Mutation
   [Mutations.SET_ERROR](error) {
      this.errors = error;
   }

   @Mutation
   [Mutations.SET_OBJ_ERROR](error) {
      this.errorsObj = error;
   }

   @Mutation
   [Mutations.SET_AUTH](data) {
      this.isAuthenticated = true;
      this.user = data.result.user;
      this.companies = data.result.companies ?? data.result.user.companies;
      JwtService.saveUser(data.result.user);
      JwtService.setUserType(USER_TYPE.USER);
      this.errors = '';
   }

   @Mutation
   [Mutations.SET_ADMIN_AUTH](data) {
      this.isAuthenticated = true;
      this.user = data.result.admin;
      this.adminData = data.result;
      JwtService.saveUser(this.user);
      JwtService.setUserType(USER_TYPE.ADMIN);
      this.errors = '';
   }

   @Mutation
   [Mutations.SET_COMPANY](company) {
      CookieService.setCookie('X-Tenant-Id', company?.tenants?.id);
      this.current_company = company;
      JwtService.saveCurrentCompany(company);
   }

   @Mutation
   [Mutations.SET_TOKEN](token) {
      JwtService.saveToken(token);
   }

   @Mutation
   [Mutations.SET_USER](user) {
      this.user = user;
   }
   @Mutation
   [Mutations.UPDATE_USER](user) {
      this.user = { ...(this.user || {}), ...(user || {}) };
   }

   @Mutation
   [Mutations.SET_PASSWORD](password) {}

   @Mutation
   [Mutations.SET_FCM_TOKEN](token) {
      window.localStorage.setItem('FCMToken', token);
      this.FCMToken = token;
   }

   @Mutation
   [Mutations.SET_REQUEST](request) {
      this.request = request;
   }

   @Mutation
   [Mutations.PURGE_AUTH](params) {
      JwtService.destroyToken({ redirectUrl: params?.redirectUrl });
      this.isAuthenticated = false;
      this.user = {} as User;
      JwtService.removeUser();
      this.errors = '';
   }

   @Mutation
   [Mutations.INCREASE_VERIFY_AUTH_REQUESTS_COUNT]() {
      this.verifyAuthRequestsCount++;
   }

   @Mutation
   [Mutations.RESET_VERIFY_AUTH_REQUESTS_COUNT]() {
      this.verifyAuthRequestsCount = 0;
   }

   @Mutation
   [Mutations.INCREASE_NOTIFICATIONS_COUNT]() {
      if (!this.current_company) return;

      this.current_company.notificationUnReadCount++;
   }

   @Mutation
   [Mutations.RESET_NOTIFICATIONS_COUNT]() {
      if (!this.current_company) return;

      this.current_company.notificationUnReadCount = 0;
   }

   @Action
   [Actions.ADMIN_PANEL_LOGIN](credentials) {
      return ApiService.post('admin-panal/auth/login', credentials)
         .then(({ data }) => {
            this.context.commit(Mutations.SET_ADMIN_AUTH, data);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);

            UrlService.redirectWithoutSubdomain('/#/admin-panel');

            AdminLanguageService.setLangWithReload(data?.result?.admin?.locale);
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
         });
   }

   @Action
   [Actions.SET_COMPANY](company) {
      this.context.commit(Mutations.SET_COMPANY, company);
   }

   @Action
   [Actions.LOGIN](credentials) {
      return ApiService.post('/company/auth/login', credentials)
         .then(({ data }) => {
            if (data?.code === 301) {
               UrlService.redirectWithoutSubdomain(
                  '/#/create-company-without-login?token=' + data?.result?.token,
               );
               return;
            }

            this.context.commit(Mutations.SET_AUTH, data);
            LanguageService.setLangWithoutReload(data?.result?.user?.locale);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);

            this.context.commit(Mutations.SET_COMPANY, data.result.companies[0]);

            const companies = data.result.companies;

            if (companies.length > 1) {
               UrlService.redirectWithSubdomain('/#/multi-companies-select');
            } else {
               UrlService.redirectWithSubdomain();
            }
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
            this.context.commit(Mutations.SET_OBJ_ERROR, response.data);
         });
   }

   @Action
   [Actions.TRY_DOSO](credentials) {
      return ApiService.post('/company/auth/try-doso', credentials)
         .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            LanguageService.setLangWithoutReload(data?.result?.user?.locale);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);

            this.context.commit(Mutations.SET_COMPANY, data.result.companies[0]);

            const companies = data.result.companies;

            if (companies.length > 1) {
               UrlService.redirectWithSubdomain('/#/multi-companies-select');
            } else {
               UrlService.redirectWithSubdomain();
            }
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
            this.context.commit(Mutations.SET_OBJ_ERROR, response.data);
         });
   }

   @Action
   [Actions.SEND_OTP](credentials) {
      return new Promise((resolve, reject) => {
         axios
            .post('/company/auth/send-otp', credentials)
            .then((data) => {
               resolve(data);
            })
            .catch((response) => {
               console.log('error', response);
               reject(response);
               AlertService.error(response);
            });
      });
   }
   @Action
   [Actions.GET_PHONE_VERIFICATION](params) {
      return new Promise((resolve, reject) => {
         axios
            .get('/company/auth/getPhoneVerification', {
               params: {
                  ...params,
               },
            })
            .then((data) => {
               resolve(data);
            })
            .catch((response) => {
               console.log('error', response);
               reject(response);
               AlertService.error(response);
            });
      });
   }

   @Action
   [Actions.FIRE_BASE_LOGIN](credentials) {
      // console.log("FIRE_BASE_LOGIN");
      return ApiService.post('/company/auth/firebaseLogin', credentials)
         .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);

            this.context.commit(Mutations.SET_COMPANY, data.result.companies[0]);

            const companies = data.result.companies;

            if (companies.length > 1) {
               UrlService.redirectWithSubdomain('/#/multi-companies-select');
            } else {
               UrlService.redirectWithSubdomain();
            }
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
         });
   }

   @Action
   [Actions.LOGOUT](params) {
      this.context.commit(Mutations.PURGE_AUTH, { redirectUrl: params?.redirectUrl });
   }

   @Action
   [Actions.CREATE_COMPANY](values) {
      return new Promise((resolve, reject) => {
         ApiService.post('/company/auth/create-company', values)
            .then((response) => {
               const { data } = response;

               JwtService.setSharedDomainsCookie({ token: data.result.token });
               this.context.commit(Mutations.SET_TOKEN, data.result.token);

               UrlService.redirectWithSubdomain();
               resolve(response);
            })
            .catch((error) => {
               reject(error);
               AlertService.error(error);
            });
      });
   }

   @Action
   [Actions.CREATE_COMPANY_WITHOUT_LOGIN](values) {
      return new Promise((resolve, reject) => {
         ApiService.post('/company/auth/create-company-without-login', values)
            .then((response) => {
               const { data } = response;

               JwtService.setSharedDomainsCookie({ token: data.result.token });
               this.context.commit(Mutations.SET_TOKEN, data.result.token);

               UrlService.redirectWithSubdomain();
               resolve(response);
            })
            .catch((error) => {
               AlertService.error(error);
               reject(error);
            });
      });
   }

   @Action
   [Actions.SET_USER](user) {
      this.context.commit(Mutations.SET_USER, user);
   }
   @Action
   [Actions.UPDATE_USER](user) {
      this.context.commit(Mutations.UPDATE_USER, user);
   }

   @Action
   [Actions.REGISTER](credentials) {
      return ApiService.post('/company/auth/register', credentials).catch(({ response }) => {
         this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
   }
   @Action
   [Actions.REGISTER_VISITOR](credentials) {
      return ApiService.post('/guest/registered-visitor', credentials).catch(({ response }) => {
         this.context.commit(Mutations.SET_ERROR, response.data.message);
      });
   }
   @Action
   [Actions.VERIFY_EMAIL](credentials) {
      return ApiService.post('/user/auth/verfiy-email', credentials)
         .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);
            this.context.commit(Mutations.SET_COMPANY, data.result.companies[0]);

            AlertService.success('Account has been confirmed successfully').then(() => {
               UrlService.redirectWithSubdomain();
            });
         })
         .catch(AlertService.error);
   }

   @Action
   [Actions.RESEND_VERIFY_EMAIL](credentials) {
      return ApiService.post('/user/auth/resend-verify-email', credentials)
         .then(({ data }) => {
            AlertService.success('Verification link sent successfully please check your email!');
         })
         .catch(AlertService.error);
   }

   @Action
   [Actions.RESEND_INVITATION](credentials) {
      return ApiService.post('/user/users/resend-invitation/' + credentials?.id, {})
         .then(({ data }) => {
            AlertService.success("Verification link re-sent successfully to user's email!");
         })
         .catch(AlertService.error);
   }
   // FOR USER
   @Action
   [Actions.FORGOT_PASSWORD](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/user/auth/forgotPassword', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }
   @Action
   [Actions.RESET_PASSWORD](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/user/auth/reset-password', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }
   @Action
   [Actions.CHECK_RESET_TOKEN](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/user/auth/check-reset-token', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }
   // FRO ADMIN
   @Action
   [Actions.ADMIN_FORGOT_PASSWORD](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/admin-panal/auth/forgotPassword', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }
   @Action
   [Actions.ADMIN_RESET_PASSWORD](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/admin-panal/auth/reset-password', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }
   @Action
   [Actions.ADMIN_CHECK_RESET_TOKEN](payload) {
      return new Promise((resolve, reject) => {
         axios
            .post('/admin-panal/auth/check-reset-token', payload)
            .then((res) => {
               resolve(res);
            })
            .catch(({ response }) => {
               AlertService.errorText(response.data.message);
               reject(response);
            });
      });
   }

   @Action
   [Actions.REQUEST_VERIFY_AUTH]() {
      JwtService.saveRenewSession(new Date().getTime());
      this.context.commit(Mutations.INCREASE_VERIFY_AUTH_REQUESTS_COUNT);

      return this.request;
   }

   @Action
   [Actions.VERIFY_AUTH]() {
      this.context.commit(Mutations.RESET_VERIFY_AUTH_REQUESTS_COUNT);

      if (CookieService.getCookie('shredDomains')) {
         JwtService.saveToken(CookieService.getCookie('shredDomains')?.token);
      }
      if (JwtService.getToken()) {
         ApiService.setHeader();
         if (JwtService.isAdminDomain()) {
            // if admin login
            ApiService.query('/admin-panal/auth/me', {
               params: {
                  fcm_token: this.FCMToken ?? this.InitFCMToken,
               },
            })
               .then(({ data }) => {
                  this.context.commit(Mutations.SET_ADMIN_AUTH, data);
               })
               .catch(({ response }) => {
                  this.context.commit(Mutations.SET_ERROR, response.data.errors);
                  this.context.commit(Mutations.PURGE_AUTH);
               });
         } else if (CookieService.getCookie('X-Tenant-Id')) {
            const request = ApiService.query('/user/auth/me', {
               params: {
                  fcm_token: this.FCMToken ?? this.InitFCMToken,
               },
            })
               .then(({ data }) => {
                  this.context.commit(Mutations.SET_AUTH, data);
                  this.context.commit(Mutations.SET_COMPANY, data.result.current_company);
               })
               .catch(({ response }) => {
                  this.context.commit(Mutations.SET_ERROR, response.data.errors);
                  this.context.commit(Mutations.PURGE_AUTH);
               });

            this.context.commit(Mutations.SET_REQUEST, request);
         }
      } else {
         this.context.commit(Mutations.PURGE_AUTH);
      }
   }

   @Action
   async [Actions.GET_CURRENT_COMPANY]() {
      if (!this.request) return this.current_company;

      await this.request;

      return this.current_company;
   }

   @Action
   [Actions.USER_COMPLETE_PROFILE](credentials) {
      return ApiService.post('/user/auth/user-complete-profile', credentials)
         .then(({ data }) => {
            if (data.code == 200) {
               this.context.commit(Mutations.SET_COMPANY, data.result.companies[0]);
               JwtService.setSharedDomainsCookie({ token: data.result.token });
               this.context.commit(Mutations.SET_TOKEN, data.result.token);
               UrlService.redirectWithSubdomain();
            }
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.message);
         });
   }

   @Action
   async [Actions.SWITCH_COMAPNY](payload) {
      return ApiService.get('/company/auth/switchCompany/' + payload.company_id, {
         headers: {
            'X-Tenant-Id': payload.XTenantId,
         },
      })
         .then(({ data }) => {
            this.context.commit(
               Mutations.SET_COMPANY,
               data.result.companies.find((el) => el.id == payload.company_id),
            );
            this.context.commit(Mutations.SET_AUTH, data);
            JwtService.setSharedDomainsCookie({ token: data.result.token });
            this.context.commit(Mutations.SET_TOKEN, data.result.token);

            UrlService.redirectWithSubdomain('/');
         })
         .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
         });
   }

   @Action
   async [Actions.HANDLE_NOTIFICATION]({ payload }) {
      this.context.commit(Mutations.INCREASE_NOTIFICATIONS_COUNT);
   }
}
