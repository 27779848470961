const path = "TrialBalanceReportModule";
const apiPath = "/accounting/report";

const TrialBalanceModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_TRIAL_BALANCE_REPORT: `${path}/trialBalanceReport`,
};

const TrialBalanceAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

export { TrialBalanceAPIsConstants, TrialBalanceModelActions };
