import axios from 'axios';

const RecycleTheFiscalYearModule = {
    namespaced: true,
    state: {
        itemEditable: {},
        initData: {},
        dataName: '',
        loading: false,
        crudLoading: false,
        loadingItem: false,
        editableId: null,
        isPagination: true,
        isCloseModal: false,
    },
    mutations: {
        SET_DATA_Name(state, payload) {
            state.dataName = payload;
        },
        SET_ITEM_EDITABLE(state, payload) {
            let arr = [];

            if (payload.itemEditable.item.translations) {
                payload.itemEditable.item.translations.map((lang) => {
                    arr[lang.locale] = lang;
                });
                payload.itemEditable.item['translations'] = arr;
            }

            state.itemEditable = payload.itemEditable;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_CRUD_LOADING(state, payload) {
            state.crudLoading = payload;
        },
        SET_LOADING_ITEM(state, payload) {
            state.loadingItem = payload;
        },
        RESET_ALL(state) {
            state.dataName = '';
            state.loading = false;
            state.itemEditable = {};
        },
        RESET_EDITABLE(state) {
            state.loading = false;
            state.itemEditable = {};
            state.editableId = null;
        },
        SET_EDITABLE_ID(state, id) {
            state.editableId = id;
        },
        SET_IS_CLOSE_MODAL(state, payload) {
            state.isCloseModal = payload;
        },
        SET_FILTERS(state, payload) {
            state.tableFilters = payload.filters;
        },
    },
    actions: {
        setDataName({ commit }, name) {
            commit('SET_DATA_Name', name);
        },

        closeFinancialYear({ commit, state }, {values}) {
            console.log(values);
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`/accounting/financial-year-management/close-financial-year`, values)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },
        checkApprovedAllVouchers({ commit, state }) {
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`/accounting/financial-year-management/check-approved-all-vouchers`)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },
        checkMigratedAllJornalEntries({ commit, state }) {
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`/accounting/financial-year-management/check-migrated-all-jornal-entries`)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },
        databaseBackup({ commit, state }) {
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`/accounting/financial-year-management/database-backup`)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },


    },
    getters: {
        findItemById: (state) => (id) => {
            return state.DataTable.find((x) => x.id === id);
        },
    },
};
export default RecycleTheFiscalYearModule;
