import axios from 'axios';

const apiPath = 'hr/attendance/hr-pre-processed-attendance';
const path = 'OvertimeApprovals';

export const OvertimeApprovalsApiConstants = {
   API_PATH: apiPath,
   APPROVE: (id) => `${apiPath}/${id}`,
};

export const OvertimeApprovalsActions = {
   APPROVE_OVERTIME: `${path}/approveOvertime`,
};

const OvertimeApprovalsModule = {
   namespaced: true,
   state: {
      loading: false,
   },
   mutations: {
      SET_LOADING(state, val) {
         state.loading = val;
      },
   },
   actions: {
      approveOvertime({ commit }, { id, credentials }) {
         commit('SET_LOADING', true);

         return axios
            .put(OvertimeApprovalsApiConstants.APPROVE(id), { ...credentials, overtime_approve: 1 })
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
   },
};

export default OvertimeApprovalsModule;
