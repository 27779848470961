const path = 'CargoPurchaseReportModule';
const apiPath = '/transport/report';

const PurchaseReportActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_PURCHASE_REPORT : `${path}/generatePurchaseReport`,
  GENERATE_PRODUCT_PURCHASE_REPORT : `${path}/generateProductPurchaseReport`,
  GENERATE_PURCHASE_BUSINESS_MOVEMENT_REPORT : `${path}/generatePurchaseBusinessMovementReport`,
  GENERATE_VENDOR_GROUP_DETAILED_PURCHASE_REPORT : `${path}/generateVendorGroupDetailedPurchaseReport`,
  GENERATE_SALESMAN_DETAILED_PURCHASE_REPORT : `${path}/generateSalesmanDetailedPurchaseReport`,
  GENERATE_SALESMAN_TOTAL_PURCHASE_REPORT : `${path}/generateSalesmanTotalPurchaseReport`,
  GENERATE_VENDOR_ACCOUNT_STATEMENT_REPORT : `${path}/generateVendorAccountStatementReport`,

  GENERATE_VENDOR_DETAILED_PURCHASE_REPORT : `${path}/generateVendorDetailedPurchaseReport`,
  GENERATE_VENDOR_TOTAL_PURCHASE_REPORT : `${path}/generateVendorTotalPurchaseReport`,
}

const PurchaseReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {PurchaseReportAPIsConstants, PurchaseReportActions };
