import POSInvoice from './modules/Invoice';
import POS from './modules/POS';
import PosCoupon from './modules/Coupon';
import CasherVoucherModule from './modules/CasherVoucherModule';
import PosInvoiceSearchModule from './modules/InvoiceSearch';

const casherStore = {
   POSInvoice,
   POS,
   PosCoupon,
   CasherVoucherModule,
   PosInvoiceSearchModule,
};

export default casherStore;
