import axios from 'axios';
import { WageItemsReportAPIsConstants } from '../../enums/reports/WageItemsReportModuleEnums';
import DownloadService from '../../../../../../core/services/DownloadService';

const WageItemsReportModule = {
  namespaced: true,
  state: {
    itemEditable: {},
    loading: false,
  },
  mutations: {
    SET_ITEM_EDITABLE(state, payload) {
      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    getReport({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${WageItemsReportAPIsConstants.API_PATH}/wage-item-report`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${WageItemsReportAPIsConstants.API_PATH}/wage-item-report`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
};
export default WageItemsReportModule;
