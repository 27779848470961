const path = 'IncomesReportModule';
const apiPath = 'customs/report';

const IncomesReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_Incomes_REPORT : `${path}/generateDetailedIncomesReport`,
}

const IncomesReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {IncomesReportAPIsConstants, IncomesReportActions };
