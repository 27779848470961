import axios from 'axios';
const EmployeeAttendanceModule = {
    namespaced: true,
    actions: {
        company({ commit }, { apiPath, credentials }) {
            return new Promise((resolve, reject) => {
                axios
                .post(`${apiPath}/by-employees`, credentials)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
            });
        },
    }
}
export default EmployeeAttendanceModule;