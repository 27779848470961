import axios from "axios";
import { DownloadsTransactionsAPIsConstants } from "../../enums/daily-movements/DownloadsTransactionsEnums";

const DownloadsTransactionsModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    dataName: '',
    loading: false,
    crudLoading: false,
    loadingItem: false,
    editableId: null,
    isPagination: false,
    isCloseModal: false,
    bindingItem: {},
    bindProductValidation: {},
    pagination: {
      page: 1,
      total: 0,
      rowsPerPage: 10,
    },
    invoiceModel: {},
  },
  mutations: {
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },

    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },

    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item["translations"] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },

    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
      RESET_EDITABLE(state) {
          state.loading = false;
          state.itemEditable = {};
          state.editableId = null;
      },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = "";
      state.loading = false;
      state.itemEditable = {};
      state.tableFilters = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
  },

  actions: {
      resetEditable({ commit }) {
          commit('RESET_EDITABLE');
      },
    findItemById({ commit, state }, { id, params }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
            .get(`${DownloadsTransactionsAPIsConstants.API_PATH}/${id}`, { params })
            .then((res) => {
              commit("SET_LOADING_ITEM", false);
              commit("SET_ITEM_EDITABLE", {
                itemEditable: res.data.result,
              });
              resolve(res);
            })
            .catch((error) => {
              commit("SET_LOADING_ITEM", false);
              reject(error);
            });
      });
    },
    getInitData({ commit, dispatch, state }, { apiPath, credentials }) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
            .get(`${apiPath}/init-data`)
            .then((response) => {
              commit('SET_LOADING', false);

              state.initData = response.data.result;
              resolve(response);
            })
            .catch((error) => {
              commit('SET_LOADING', false);
              reject(error);
            });
      });
    },
    addItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
            .post(`${apiPath}`, credentials)
            .then((response) => {
              commit("SET_CRUD_LOADING", false);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_CRUD_LOADING", false);
              reject(error);
            });
      });
    },
    getMovementDetails({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
            .post(`${apiPath}/get-movement-details`, credentials)
            .then((response) => {
              commit("SET_CRUD_LOADING", false);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_CRUD_LOADING", false);
              reject(error);
            });
      });
    },

    editItem({ commit, state }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
            .put(`${apiPath}/${credentials.id}`, credentials)
            .then((response) => {
              commit("SET_CRUD_LOADING", false);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_CRUD_LOADING", false);
              reject(error);
            });
      });
    },

    updateItem({ commit, state }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
            .put(`${apiPath}/${credentials.id}`, credentials)
            .then((response) => {
              commit("SET_CRUD_LOADING", false);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_CRUD_LOADING", false);
              reject(error);
            });
      });
    },

    calculateAmount({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
            .post(`${apiPath}/calculate-amount`, credentials )
            .then((response) => {
              commit("SET_CRUD_LOADING", false);
              resolve(response);
            })
            .catch((error) => {
              commit("SET_CRUD_LOADING", false);
              reject(error);
            });
      });
    },
      getDownloadMovementsDetails({ commit }, { apiPath, credentials }) {
          return new Promise((resolve, reject) => {
              axios
                  .post(`${apiPath}/get-download-movements-details`, credentials)
                  .then((response) => {
                      resolve(response);
                  })
                  .catch((error) => {
                      reject(error);
                  });
          });
      },
  },
};

export default DownloadsTransactionsModule;

