import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'electronic-approval-dashboard',
      name: 'electronic-approval-dashboard',
      meta: {
         title: 'ElectronicApprovalsModule',
      },
      component: () => import('@/views/modules/electronic-approvals/views/dashboard/Index.vue'),
   },
   {
      path: 'settings',
      name: 'electronic-approval-settings',
      meta: {
         permission: "e_approval_setting",
         title: "Electronic Approval Settings",
      },
      component: () => import('@/views/modules/electronic-approvals/views/settings/index.vue'),
   },
   {
      path: "transactions-settings",
      name: "electronic-approval-transaction",
      meta: {
         permission: "e_approval_movements",
         title: "Transactions Settings",
      },
      component: () =>
          import("@/views/modules/electronic-approvals/views/settings/MovementsSettings/index.vue"),
   },
];

export default settingRouter;
