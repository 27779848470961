const path = "EmployeesCustodyInfoModule";
const apiPath = "/hr/report/employee/employee-custody-info-report";

const EmployeesCustodyInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesCustodyInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesCustodyInfoAPIsConstants, EmployeesCustodyInfoActions };
