import axios from 'axios';
import { DelayRequestReportAPIsConstants } from '../../enums/reports/DelayRequestReportEnums';
import DownloadService from '../../../../../../core/services/DownloadService';

const DelayRequestReportModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    loading: false,
  },
  mutations: {
    SET_ITEM_EDITABLE(state, payload) {
      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {

    getMyReport({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${DelayRequestReportAPIsConstants.API_PATH}/delay-request-employee-report`, credentials).then(() =>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${DelayRequestReportAPIsConstants.API_PATH}/delay-request-employee-report`, {
            params: credentials,
          })
          .then((response) => {
            commit('SET_ITEM_EDITABLE', {
              itemEditable: response.data.result,
            });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getReport({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${DelayRequestReportAPIsConstants.API_PATH}/delay-request-report`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${DelayRequestReportAPIsConstants.API_PATH}/delay-request-report`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
};
export default DelayRequestReportModule;
