import axios from 'axios';
import { TotalSummarySalariesReportAPIsConstants } from '../../enums/reports/TotalSummarySalariesReportModuleEnums';
import DownloadService from '../../../../../../core/services/DownloadService';

const TotalSummarySalariesReportModule = {
  namespaced: true,
  state: {
    itemEditable: {},
    loading: false,
  },
  mutations: {
    SET_ITEM_EDITABLE(state, payload) {
      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    getReportDepartment({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/department`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/department`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getReportLocation({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/location`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/location`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getReportEmployer({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/employer`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/employer`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getReportCompany({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/company`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${TotalSummarySalariesReportAPIsConstants.API_PATH}/total-summary-salaries/company`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default TotalSummarySalariesReportModule;
