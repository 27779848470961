const apiPath = "/transport/transport-download-movements";
const path = "DownloadsTransactionsModule";

const DownloadsTransactionsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const DownloadsTransactionsActions = {
  GET_INIT_DATA : `${path}/getInitData`,
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  CALCULATE_AMOUNT: `${path}/calculateAmount`,
  RESET_EDITABLE : `${path}/resetEditable`,
  GET_MOVEMENT_DETAILS : `${path}/getMovementDetails`,
  GET_DOWNLOAD_MOVEMENTS_DETAILS : `${path}/getDownloadMovementsDetails`,
};

export { DownloadsTransactionsAPIsConstants, DownloadsTransactionsActions };
