import { RouteRecordRaw } from 'vue-router';

const dailyMovementRouter: Array<RouteRecordRaw> = [
   //begin::employment-requests
   {
      path: 'employees',
      name: 'hr-employees',
      meta: {
         permission: 'hr_employees',
         title: 'employees',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/employees/List.vue'),
   },
   {
      path: 'employees/add',
      name: 'hr-employees-add',
      meta: {
         permission: 'hr_employees',
         title: 'employees',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'employees/edit/:id',
      name: 'hr-employees-edit',
      meta: {
         permission: 'hr_employees',
         title: 'employees',
      },
      props: true,
      component: () => import('@/views/modules/hr/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'employees/:id/:view',
      name: 'hr-employees-view',
      meta: {
         permission: 'hr_employees',
         title: 'employees',
      },
      props: true,
      component: () => import('@/views/modules/hr/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'daily-transactions/employment-requests',
      name: 'hr-management-employment-requests',
      meta: {
         permission: 'hr_employment_requests',
         title: 'Employment Requests',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employment-requests/List.vue'),
   },
   {
      path: 'daily-transactions/employment-requests/add',
      name: 'hr-management-employment-requests-add',
      meta: {
         permission: 'hr_employment_requests',
         title: 'Employment Requests',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employment-requests/Add.vue'),
   },
   {
      path: 'daily-transactions/employment-requests/edit/:id',
      name: 'hr-management-employment-requests-edit',
      meta: {
         permission: 'hr_employment_requests',
         title: 'Employment Requests',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employment-requests/Add.vue'),
   },
   {
      path: 'daily-transactions/employment-requests/:view/:id',
      name: 'hr-management-employment-requests-view',
      meta: {
         permission: 'hr_employment_requests',
         title: 'Employment Requests',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employment-requests/Add.vue'),
   },
   //end::employment-requests

   //begin::Contract Update
   {
      path: 'daily-transactions/contract-update',
      name: 'hr-management-contract-update',
      meta: {
         permission: 'hr_update_contract_movements',
         title: 'Contract Update',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/updated-contracts/List.vue'),
   },
   {
      path: 'daily-transactions/contract-update/add',
      name: 'hr-management-contract-update-add',
      meta: {
         permission: 'hr_update_contract_movements',
         title: 'Contract Update',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-contracts/Add.vue'),
   },
   {
      path: 'daily-transactions/contract-update/edit/:id',
      name: 'hr-management-contract-update-edit',
      meta: {
         permission: 'hr_update_contract_movements',
         title: 'Contract Update',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-contracts/Add.vue'),
   },
   {
      path: 'daily-transactions/contract-update/:view/:id',
      name: 'hr-management-contract-update-view',
      meta: {
         permission: 'hr_update_contract_movements',
         title: 'Contract Update',
         type: 'read',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-contracts/Add.vue'),
   },
   //end::Contract Update

   //begin::Document Update
   {
      path: 'daily-transactions/document-update',
      name: 'hr-management-document-update',
      meta: {
         permission: 'hr_document_movements',
         title: 'Document Update Transactions',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/updated-documents/List.vue'),
   },
   {
      path: 'daily-transactions/document-update/add',
      name: 'hr-management-document-update-add',
      meta: {
         permission: 'hr_document_movements',
         title: 'Document Update Transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-documents/Add.vue'),
   },
   {
      path: 'daily-transactions/document-update/edit/:id',
      name: 'hr-management-document-update-edit',
      meta: {
         permission: 'hr_document_movements',
         title: 'Document Update Transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-documents/Add.vue'),
   },
   {
      path: 'daily-transactions/document-update/:view/:id',
      name: 'hr-management-document-update-view',
      meta: {
         permission: 'hr_document_movements',
         title: 'Document Update Transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/updated-documents/Add.vue'),
   },
   //end::Document Update

   //begin::Employees Evaluations
   {
      path: 'daily-transactions/employees-evaluations',
      name: 'hr-management-employees-evaluations',
      meta: {
         permission: 'hr_employee_rate_movements',
         title: 'Employees Evaluations',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employees-evaluations/List.vue'),
   },
   {
      path: 'daily-transactions/employees-evaluations/add',
      name: 'hr-management-employees-evaluations-add',
      meta: {
         permission: 'hr_employee_rate_movements',
         title: 'Employees Evaluations',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employees-evaluations/Add.vue'),
   },
   {
      path: 'daily-transactions/employees-evaluations/edit/:id',
      name: 'hr-management-employees-evaluations-edit',
      meta: {
         permission: 'hr_document_movements',
         title: 'Employees Evaluations',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employees-evaluations/Add.vue'),
   },
   {
      path: 'daily-transactions/employees-evaluations/:view/:id',
      name: 'hr-management-employees-evaluations-view',
      meta: {
         permission: 'hr_employee_rate_movements',
         title: 'Employees Evaluations',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/employees-evaluations/Add.vue'),
   },
   //end::Employees Evaluations

   {
      path: 'daily-transactions/trainings-transactions',
      name: 'hr-trainings-transactions',
      meta: {
         permission: 'hr_training_movements',
         title: 'trainings',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/trainings-transactions/List.vue'),
   },
   {
      path: 'daily-transactions/trainings-transactions/:id/:view',
      name: 'hr-trainings-transactions-view',
      meta: {
         permission: 'hr_training_movements',
         title: 'trainings',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/trainings-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/trainings-transactions/add',
      name: 'hr-trainings-transactions-add',
      meta: {
         permission: 'hr_training_movements',
         title: 'trainings',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/trainings-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/trainings-transactions/edit/:id',
      name: 'hr-trainings-transactions-edit',
      meta: {
         permission: 'hr_training_movements',
         title: 'trainings',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/trainings-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/exit-reenter',
      name: 'exit-reenter-list',
      meta: {
         title: 'Exit Reenter',
         permission: 'hr_muqeem_exit_reenter_operations',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/exit-reenter/List.vue'),
   },
   {
      path: 'daily-transactions/exit-reenter/add',
      name: 'add-exit-reenter',
      meta: {
         title: 'Exit Reenter',
         permission: 'hr_muqeem_exit_reenter_operations',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/exit-reenter/Add.vue'),
   },
   {
      path: 'daily-transactions/exit-reenter/view/:id',
      name: 'view-exit-reenter',
      meta: {
         title: 'Exit Reenter',
         permission: 'hr_muqeem_exit_reenter_operations',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/exit-reenter/Add.vue'),
   },

   {
      path: 'daily-transactions/final-exit',
      name: 'final-exit-list',
      meta: {
         permission: 'hr_muqeem_final_exit_operations',
         title: 'Final Exit',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/final-exit/List.vue'),
   },
   {
      path: 'daily-transactions/final-exit/add',
      name: 'add-final-exit',
      meta: {
         permission: 'hr_muqeem_final_exit_operations',
         type: 'write',
         title: 'Final Exit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/muqeem/final-exit/Add.vue'),
   },
   {
      path: 'daily-transactions/final-exit/view/:id',
      name: 'view-final-exit',
      meta: {
         permission: 'hr_muqeem_final_exit_operations',
         title: 'Final Exit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/muqeem/final-exit/Add.vue'),
   },
   {
      path: 'daily-transactions/renew-passport',
      name: 'renew-passport-list',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         title: 'Renew Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-passport/List.vue'),
   },
   {
      path: 'daily-transactions/renew-passport/add',
      name: 'add-renew-passport',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         type: 'write',
         title: 'Renew Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-passport/Add.vue'),
   },
   {
      path: 'daily-transactions/renew-passport/view/:id',
      name: 'view-renew-passport',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         title: 'Renew Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-passport/Add.vue'),
   },
   {
      path: 'daily-transactions/extend-passport',
      name: 'extend-passport-list',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         title: 'Extend Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/extend-passport/List.vue'),
   },
   {
      path: 'daily-transactions/extend-passport/add',
      name: 'add-extend-passport',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         type: 'write',
         title: 'Extend Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/extend-passport/Add.vue'),
   },
   {
      path: 'daily-transactions/extend-passport/view/:id',
      name: 'view-extend-passport',
      meta: {
         permission: 'hr_muqeem_passport_operations',
         title: 'Extend Passport',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/extend-passport/Add.vue'),
   },
   {
      path: 'daily-transactions/update-employee-job',
      name: 'update-employee-job-list',
      meta: {
         permission: 'hr_muqeem_occupation_operations',
         title: 'Update Employee Job',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/update-employee-job/List.vue'),
   },
   {
      path: 'daily-transactions/update-employee-job/add',
      name: 'add-update-employee-job',
      meta: {
         permission: 'hr_muqeem_occupation_operations',
         type: 'write',
         title: 'Update Employee Job',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/update-employee-job/Add.vue'),
   },
   {
      path: 'daily-transactions/update-employee-job/view/:id',
      name: 'view-update-employee-job',
      meta: {
         permission: 'hr_muqeem_occupation_operations',
         title: 'Update Employee Job',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/update-employee-job/Add.vue'),
   },
   {
      path: 'daily-transactions/issue-iqama',
      name: 'issue-iqama-list',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Issue Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/issue-iqama/List.vue'),
   },
   {
      path: 'daily-transactions/issue-iqama/add',
      name: 'add-issue-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         type: 'write',
         title: 'Issue Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/issue-iqama/Add.vue'),
   },
   {
      path: 'daily-transactions/issue-iqama/view/:id',
      name: 'view-issue-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Issue Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/issue-iqama/Add.vue'),
   },

   {
      path: 'daily-transactions/renew-iqama',
      name: 'renew-iqama-list',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Extend Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-iqama/List.vue'),
   },
   {
      path: 'daily-transactions/renew-iqama/add',
      name: 'add-renew-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         type: 'write',
         title: 'Extend Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-iqama/Add.vue'),
   },
   {
      path: 'daily-transactions/renew-iqama/view/:id',
      name: 'view-renew-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Extend Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/renew-iqama/Add.vue'),
   },

   {
      path: 'daily-transactions/transfer-iqama',
      name: 'transfer-iqama-list',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Transfer Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/transfer-iqama/List.vue'),
   },
   {
      path: 'daily-transactions/transfer-iqama/add',
      name: 'add-transfer-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         type: 'write',
         title: 'Transfer Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/transfer-iqama/Add.vue'),
   },
   {
      path: 'daily-transactions/transfer-iqama/view/:id',
      name: 'view-transfer-iqama',
      meta: {
         permission: 'hr_muqeem_iqama_operations',
         title: 'Transfer Iqama',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/muqeem/transfer-iqama/Add.vue'),
   },
   {
      path: 'daily-transactions/penalties',
      name: 'penalties',
      meta: {
         permission: 'hr_sanction_movements',
         title: 'Penalties',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/penalties/List.vue'),
   },
   {
      path: 'daily-transactions/add-penalties',
      name: 'add-penalties',
      meta: {
         permission: 'hr_sanction_movements',
         title: 'Penalties',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/penalties/Add.vue'),
   },
   {
      path: 'daily-transactions/update-penalties/:id/:type',
      name: 'update-penalties',
      meta: {
         permission: 'hr_sanction_movements',
         title: 'Penalties',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/penalties/Add.vue'),
   },
   {
      path: 'daily-transactions/transfer-transactions',
      name: 'transfer-transactions',
      meta: {
         permission: 'hr_transfer_movements',
         title: 'Transfer Transactions',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/transfer-movements/List.vue'),
   },
   {
      path: 'daily-transactions/add-transfer-transactions',
      name: 'add-transfer-transactions',
      meta: {
         permission: 'hr_transfer_movements',
         title: 'Transfer Transactions',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/transfer-movements/Add.vue'),
   },
   {
      path: 'daily-transactions/update-transfer-movements/:id',
      name: 'update-transfer-transactions',
      meta: {
         permission: 'hr_transfer_movements',
         title: 'Transfer Transactions',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/transfer-movements/Add.vue'),
   },
   {
      path: 'daily-transactions/transfer-movements/:id/:view',
      name: 'transfer-transactions-view',
      meta: {
         permission: 'hr_transfer_movements',
         title: 'Transfer Transactions',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/transfer-movements/Add.vue'),
   },
   {
      path: 'daily-transactions/leave-settlement',
      name: 'leave-settlement',
      meta: {
         permission: 'hr_leave_settlements',
         title: 'leaveSettlement',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/leave-settlement/List.vue'),
   },
   {
      path: 'daily-transactions/add-leave-settlement',
      name: 'add-leave-settlement',
      meta: {
         permission: 'hr_leave_settlements',
         title: 'Leave Settlement',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/leave-settlement/Add.vue'),
   },
   {
      path: 'daily-transactions/update-leave-settlement/:id/:type',
      name: 'update-leave-settlement',
      meta: {
         permission: 'hr_leave_settlements',
         title: 'Leave Settlement',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/leave-settlement/Add.vue'),
   },
   {
      path: 'daily-transactions/vacation',
      name: 'hr-management-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/List.vue'),
   },
   {
      path: 'daily-transactions/add-vacation',
      name: 'hr-management-add-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/Add.vue'),
   },
   {
      path: 'daily-transactions/update-vacation/:id/:type',
      name: 'hr-management-update-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/Add.vue'),
   },
   {
      path: 'daily-transactions/termination',
      name: 'employees-termination-list',
      meta: {
         title: 'endOfService',
         permissions: 'hr_end_of_services',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/termination/List.vue'),
   },
   {
      path: 'daily-transactions/termination/add',
      name: 'employees-termination-add',
      meta: {
         title: 'endOfService',
         permissions: 'hr_end_of_services',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/termination/Add.vue'),
   },
   {
      path: 'daily-transactions/termination/edit/:id/:type',
      name: 'employees-termination-edit',
      meta: {
         title: 'endOfService',
         permissions: 'hr_end_of_services',
         type: 'edit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/termination/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions',
      name: 'hr-management-employees-code-transactions-list',
      meta: {
         title: 'Update Employee Id',
         permissions: 'hr_employee_number_movements',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/code-transactions/List.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/add',
      name: 'hr-management-employees-code-transactions-add',
      meta: {
         title: 'Update Employee Id',
         permissions: 'hr_employee_number_movements',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/edit/:id',
      name: 'hr-management-employees-code-transactions-edit',
      meta: {
         title: 'Update Employee Id',
         permissions: 'hr_employee_number_movements',
         type: 'edit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/:id/:view',
      name: 'hr-management-employees-code-transactions-view',
      meta: {
         title: 'Update Employee Id',
         permissions: 'hr_employee_number_movements',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/recruitments',
      name: 'employees-recruitments-list',
      meta: {
         permissions: 'hr_recruitment_requests',
         title: 'Recruitment',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/recruitments/List.vue'),
   },
   {
      path: 'daily-transactions/recruitments/add',
      name: 'employees-recruitments-add',
      meta: {
         title: 'Recruitment',
         permissions: 'hr_recruitment_requests',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/recruitments/Add.vue'),
   },
   {
      path: 'daily-transactions/recruitments/edit/:id',
      name: 'employees-recruitments-edit',
      meta: {
         title: 'Recruitment',
         permissions: 'hr_recruitment_requests',
         type: 'edit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/recruitments/Add.vue'),
   },
   {
      path: 'daily-transactions/recruitments/:id/:view',
      name: 'employees-recruitments-view',
      meta: {
         permission: 'hr_recruitment_requests',
         title: 'Recruitment',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/recruitments/Add.vue'),
   },
];

export default dailyMovementRouter;
