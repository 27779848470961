const apiPath = "/customs/customs-invoice-items";
const path = "CustomsInvoiceItemsModule";

const CustomsInvoiceItemsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const CustomsInvoiceItemsActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  GET_INIT_DATA: `${path}/getInitData`,
  BULK_UPDATE: `${path}/bulkUpdate`,
};

export { CustomsInvoiceItemsAPIsConstants, CustomsInvoiceItemsActions };
