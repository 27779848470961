const apiPath = '/hr/hr-leave-settlements';
const path = 'LeaveSettlementModule';

const LeaveSettlementAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const LeaveSettlementModelActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  CALCULATE_AMOUNT: `${path}/calculateAmount`,
  // IMPORT_ITEM_BY_ID: `${path}/importItemById`,
}
export { LeaveSettlementAPIsConstants, LeaveSettlementModelActions };
