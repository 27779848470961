const path = 'CargoReportModule';
const apiPath = '/cargo/report/cargo';

const CargoReportActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_DETAILED_PRODUCT_STOCK_REPORT : `${path}/generateDetailedProductStockReport`,
  GENERATE_TOTAL_PRODUCT_STOCK_REPORT : `${path}/generateTotalProductStockReport`,
  GENERATE_DETAILED_PRODUCT_PROFITS_REPORT : `${path}/generateDetailedProductProfitsReport`,
  GENERATE_TOTAL_PRODUCT_PROFITS_REPORT: `${path}/generateTotalProductProfitsReport`,
  GENERATE_DETAILED_PRODUCT_TRANSACTION_REPORT : `${path}/generateDetailedProductTransactionReport`,
  GENERATE_TOTAL_PRODUCT_TRANSACTION_REPORT: `${path}/generateTotalProductTransactionReport`,
  GENERATE_PRODUCT_DATA_REPORT: `${path}/generateProductDataReport`,
}


const CargoReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {CargoReportAPIsConstants, CargoReportActions };
