const path = 'WageItemsReportModule';
const apiPath = 'hr/salaries/report';

const WageItemsReportModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_WageItems_REPORT : `${path}/generateDetailedWageItemsReport`,
}

const WageItemsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {WageItemsReportAPIsConstants, WageItemsReportModelActions };
