import axios from 'axios';

const path = 'EApprovalMovementSettingsModule';
const apiPath = '/e-approval/e-approval-movements';

export const ElectronicApprovalMovementsActions = {
   GET_SETTINGS: `${path}/getSetting`,
   UPDATE_SETTINGS: `${path}/updateSetting`,
   RESET_SETTING: `${path}/resetSetting`,
   GET_E_APPROVAL_MOVEMENTS_LIST: `${path}/getEApprovalMovementsList`,
   UPDATE_ITEM_E_APPROVAL_MOVEMENTS_LIST: `${path}/updateItemEApprovalMovementsList`,
   GET_E_APPROVAL_MOVEMENTS: `${path}/getEApprovalMovement`,
   UPDATE_E_APPROVAL_MOVEMENTS: `${path}/updateEApprovalMovement`,
   ADD_USER: `${path}/addUser`,
   REMOVE_USER: `${path}/removeUser`,
};

export const ElectronicApprovalMovementsAPIsConstants = {
   API_PATH: apiPath,
};

const EApprovalMovementSettingsModule = {
   namespaced: true,
   state: {
      loading: false,
      crudLoading: false,
      eApprovalMovementsLoading: false,
      eApprovalMovementsList: [],
      lockEApprovalMovementsList: false,
      itemEditable: {},
      eApprovalMovementUser: [],
      helper: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_E_APPROVAL_MOVEMENTS_LOADING(state, payload) {
         state.eApprovalMovementsLoading = payload;
      },
      SET_SETTING(state, payload) {
         const data = payload.data;
         state.itemEditable = data.item;
         state.eApprovalMovementUser = data.item?.eApprovalMovementUser ?? [];
      },
      RESET_SETTING(state, payload) {
         state.itemEditable = {};
         state.eApprovalMovementUser = [];
      },
      SET_HELPER(state, payload) {
         state.helper = payload.data;
      },
      SET_E_APPROVAL_MOVEMENTS_LIST(state, payload) {
         state.eApprovalMovementsList = payload.data;
      },
      ADD_USER(state, payload){
         state.eApprovalMovementUser.push(payload)
      },
      REMOVE_USER(state, payload){
         state.eApprovalMovementUser = payload.data;
         state.eApprovalMovementUser.splice(payload.index, 1);
      },
      UPDATE_ITEM_E_APPROVAL_MOVEMENTS_LIST(state, payload){
         if(state.eApprovalMovementsList.length===0) return;
         const index = state.eApprovalMovementsList.findIndex(el=>el.id===payload.id);
         if(payload.waiting_time && state.eApprovalMovementsList[index]['waiting_time'] !== payload.waiting_time){
            state.eApprovalMovementsList[index]['waiting_time'] = payload.waiting_time;
         }
         if(payload.active !== undefined){
            state.eApprovalMovementsList[index]['active'] = payload.active;
         }

         state.lockEApprovalMovementsList = true;
         setTimeout(()=>{
            state.lockEApprovalMovementsList = false;
         },3000);
      },
   },
   actions: {
      updateItemEApprovalMovementsList({ commit }, payload){
         commit('UPDATE_ITEM_E_APPROVAL_MOVEMENTS_LIST',payload);
      },
      removeUser({ commit }, payload){
         commit('REMOVE_USER',payload);
      },
      addUser({ commit }, payload){
         commit('ADD_USER',payload);
      },
      resetSetting({ commit }){
         commit('RESET_SETTING');
      },
      getSetting({ commit },movementId) {
         commit('SET_LOADING', true);

         return axios
            .get(`${ElectronicApprovalMovementsAPIsConstants.API_PATH}/${movementId}`)
            .then((res) => {
               commit('SET_SETTING', { data: res.data.result });
               return res;
            })
            .finally(() => commit('SET_LOADING', false));
      },
      updateSetting({ commit }, data) {
         commit('SET_CRUD_LOADING', true);

         return axios
            .put(`${ElectronicApprovalMovementsAPIsConstants.API_PATH}/${data.movementId}`, data.credentials)
            .finally(() => commit('SET_CRUD_LOADING', false));
      },
      getEApprovalMovementsList({ commit }, filters) {
         commit('SET_E_APPROVAL_MOVEMENTS_LOADING', true);

         return axios
            .get(`${apiPath}`, {
               params:{
                  filters:{...filters},
                  pageSize:1000
               }
            })
             .then((res) => {
                commit('SET_E_APPROVAL_MOVEMENTS_LIST', { data: res.data.result?.e_approval_movements?.data });
             })
            .finally(() => commit('SET_E_APPROVAL_MOVEMENTS_LOADING', false));
      },
      getEApprovalMovement({ commit }, movementId) {
         commit('SET_E_APPROVAL_MOVEMENTS_LOADING', true);

         return axios
            .get(`${apiPath}/${movementId}`)
             .then((res) => {
                commit('SET_E_APPROVAL_MOVEMENTS_LIST', { data: res.data.result?.e_approval_movements?.data });
             })
            .finally(() => commit('SET_E_APPROVAL_MOVEMENTS_LOADING', false));
      },
      updateEApprovalMovement({ commit }, filters) {
         commit('SET_E_APPROVAL_MOVEMENTS_LOADING', true);

         return axios
            .put(`${apiPath}`, {
               params:{
                  filters:{...filters}
               }
            })
             .then((res) => {
                commit('SET_E_APPROVAL_MOVEMENTS_LIST', { data: res.data.result?.e_approval_movements?.data });
             })
            .finally(() => commit('SET_E_APPROVAL_MOVEMENTS_LOADING', false));
      },
   },
};

export default EApprovalMovementSettingsModule;
