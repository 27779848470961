const apiPath = "/customs/customs-document";
const path = "CustomsDocumentsModule";

const CustomsDocumentsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const CustomsDocumentsActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  GET_INIT_DATA : `${path}/getInitData`,
};

export { CustomsDocumentsAPIsConstants, CustomsDocumentsActions };
