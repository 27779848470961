import axios from 'axios';

const path = 'ClientModule';

export const ClientActions = {
   IMPORT_EXCEL: `${path}/importExcel`,
};

export const ClientModule = {
   namespaced: true,
   actions: {
      importExcel(_, { values }) {
         return axios.post(`core/clients/import`, values).then(({ data }) => data);
      },
   },
};
