import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'settings',
      name: 'assets-general-settings',
      meta: {
         permission: 'asset_setting',
         title: 'Assets Settings',
      },
      component: () => import('@/views/modules/assets/views/settings/index.vue'),
   },
   {
      path: 'assets-categories',
      name: 'assets-general-categories',
      meta: {
         permission: 'asset_categories',
         title: 'Assets Categories',
      },
      component: () => import('@/views/modules/assets/views/crud/assets-categories/List.vue'),
   },

   {
      path: 'assets',
      name: 'settings-assets-assets',
      meta: {
         permission: 'products',
         title: 'Assets',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/List.vue'),
   },
   {
      path: 'add-asset',
      name: 'settings-assets-add-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },
   {
      path: 'update-asset/:id',
      name: 'settings-assets-update-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },
   {
      path: 'view-asset/:id',
      name: 'settings-assets-view-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },

   {
      path: 'assets-cases',
      name: 'assets-general-cases',
      meta: {
         permission: 'asset_cases',
         title: 'Assets Cases',
      },
      component: () => import('@/views/modules/assets/views/crud/assets-cases/List.vue'),
   },
   {
      path: 'work-locations',
      name: 'assets-general-work-locations',
      meta: {
         permission: 'asset_locations',
         title: 'Work Locations',
      },
      component: () => import('@/views/modules/hr/views/crud/work-locations/List.vue'),
   },
   {
      path: 'acquisition-methods',
      name: 'assets-general-acquisition-methods',
      meta: {
         permission: 'asset_acquisition_methods',
         title: 'Acquisition Methods',
      },
      component: () => import('@/views/modules/assets/views/crud/acquisition-methods/List.vue'),
   },
];

export default settingRouter;
