import { RouteRecordRaw } from "vue-router";

const reportsRouter: Array<RouteRecordRaw> = [

    {
        path: "employee-installments-report",
        name: "employee-installments-report",
        meta: {
            title: 'Employee Installments',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/employee-installments/base.vue"
            ),
    },
    {
        path: "employee-pay-slip-report",
        name: "employee-pay-slip-report",
        meta: {
            title: 'Employee Pay Slip',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/employee-pay-slip/base.vue"
            ),
    },
    {
        path: "wage-item-report",
        name: "wage-item-report",
        meta: {
            title: 'Wage Item',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/wage-items/base.vue"
            ),
    },
    {
        path: "total-summary-salaries-report/location",
        name: "total-summary-salaries-report-location",
        meta: {
            title: 'Total Location Summary Salaries',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/total-summary-salaries/base.vue"
            ),
    },
    {
        path: "total-summary-salaries-report/department",
        name: "total-summary-salaries-report-department",
        meta: {
            title: 'Total Department Summary Salaries',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/total-summary-salaries/department.vue"
            ),
    },
    {
        path: "total-summary-salaries-report/employer",
        name: "total-summary-salaries-report-employer",
        meta: {
            title: 'Total Employer Summary Salaries',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/total-summary-salaries/employer.vue"
            ),
    },
    {
        path: "total-summary-salaries-report/company",
        name: "total-summary-salaries-report-company",
        meta: {
            title: 'Total Company Summary Salaries',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/total-summary-salaries/company.vue"
            ),
    },
    {
        path: "employee-salaries",
        name: "employee-salaries",
        meta: {
            title: 'Employee Salaries',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/employees-salaries-distributed/base.vue"
            ),
    },
    {
        path: "employee-salaries-in-duration",
        name: "employee-salaries-in-duration",
        meta: {
            title: 'Employee Salaries In Duration',
            permission:"hr_salaries_reports",
        },
        component: () =>
            import(
                "@/views/modules/salaries/views/reports/employee-salaries-in-duration/base.vue"
            ),
    },
];

export default reportsRouter;
