const path = 'Bank';
const apiPath = '/core/banks';

const BankActions = {
  // action types
  GET_DATA : `${path}/getBank`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
}

const BankMutations = {
  // mutation types
  SET_ERROR : `${path}/setError`,
  RESET_DATA : `${path}/resetData`,
  SET_DATA : `${path}/setBank`,
  SET_LIST_DATA : `${path}/setListData`,
}

const BankAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { BankActions, BankMutations,BankAPIsConstants };
