import axios from "axios";
import { DashboardModelAPIsConstants } from "@/views/modules/accounting/store/enums/DashboardModelEnum.ts";

const AccountingDashboardModule = {
  namespaced: true,

  state: {
    loading: false,
    dashboardValues: {},
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_DASHBOARD_MODEL_DATA(state, payload) {
      state.dashboardValues = payload.item;
    },

    RESET_ALL(state) {
      state.loading = false;
      state.dashboardValues = {};
    },
  },

  actions: {
    getStatisticData({ commit }) {
      commit("SET_LOADING", true);

      return new Promise((resolve, reject) => {
        axios
          .get(`${DashboardModelAPIsConstants.API_PATH}/get-statistic-data`)
          .then((response) => {
            commit("SET_DASHBOARD_MODEL_DATA", {
              item: response.data.result,
            });
            commit("SET_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            reject(error);
          });
      });
    },
  },
};

export default AccountingDashboardModule;
