import { createApp, Plugin } from 'vue';
import App from './App.vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import * as Sentry from '@sentry/vue';
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

//imports for app initialization
import '@/core/services/firebase';
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';

import '@/core/plugins/prismjs';
import 'bootstrap';

import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';

import VueHtmlToPaper from './core/plugins//VueHtmlToPaper';
import axios from 'axios';
import listMixins from '@/core/mixins/global/listMixins';
import {
   dateFormatter,
   timeFormatter,
   dateTimeFormatter,
   dateFormat,
   timeFormat,
   dateTimeFormat,
} from '@/core/plugins/dataTimeFormatter';
import { BackPlugin } from './core/plugins/back';
import { AmazonLinkPlugin } from './core/plugins/AmazonLink';
import { DemoCompanyPlugin } from './core/plugins/DemoCompany';
import { RedirectPlugin } from './core/plugins/Redirect';

const options = {
   name: '_blank',
   specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
   styles: ['./css/print.css'],
   timeout: 1000,
   autoClose: true,
   windowTitle: window.document.title,
};

const app = createApp(App);

if (process.env.VUE_APP_APP_ENV == 'production')
   Sentry.init({
      app,
      dsn: 'https://dba2b928740fb2da4c0871ca28a6dbb7@o4505997148356608.ingest.sentry.io/4505997150584832',
      integrations: [
         new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/dosoerp\.com/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
         }),
         new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
   });
app.use(store);
app.use(router);
app.use(ElementPlus as Plugin);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(BackPlugin);
app.use(AmazonLinkPlugin);
app.use(DemoCompanyPlugin);
app.use(VueTelInput);
app.use(VueHtmlToPaper, options);
app.use(VueGoogleMaps, {
   load: {
      key: 'AIzaSyAExvmJmNmDycmmUeDLMOH5rbcfYcqzNVI',
      libraries: 'places',
   },
});

app.config.errorHandler = (err: any, instance, info) => {
   console.error(err);
   const errors = {
      url: window.location.href,
      errors: {
         err: err.message,
         info: info,
      },
   };

   if (process.env.NODE_ENV !== 'development')
      axios.post(`company/frontend-service/report-error`, errors);
};
//Mixins
app.mixin(listMixins);
app.provide('formatter', { dateFormatter, timeFormatter, dateTimeFormatter });
app.use(dateFormat);
app.use(timeFormat);
app.use(dateTimeFormat);
app.use(RedirectPlugin);

app.mount('#app');
