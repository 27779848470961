import axios from 'axios';
import {ModulesAPIsConstants} from "../enums/ApplicationListEnums";
import {CompaniesAPIsConstants} from "../enums/CompaniesEnums";

const AdminCustomerModule = {
  namespaced: true,
  state: {
    data: [],
    loading: false,
    notifications: [],
    loadingItem: false,
    itemEditable: {},
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      // let arr = [];
      // state.itemEditable = payload.itemEditable;
      var val = payload.itemEditable;
      // console.log(val,'val')
      let arr = [];
      if (val.translations) {
        val.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        val['translations'] = arr;
        // console.log(arr,'arr')
      }
      let array = [];
      if(val.contact.address){
        if (val.contact.address.translations) {
          val.contact.address.translations.map((lang) => {
            array[lang.locale] = lang;
          });
          val['contact']['address']['translations'] = array;
          // console.log(val['contact']['address']['translations'],'qqqqqq');
        }
      }
      // console.log(val,'zz');
      state.itemEditable = val;
    },
  },
  actions: {
    getData({ commit }) {
      commit('SET_LOADING', true);
      axios
        .get(`${ModulesAPIsConstants.API_PATH}`)
        .then((res) => {
          commit('SET_DATA', res.data.result.modules);
          commit('SET_LOADING', false);
        })
        .catch((error) => {
          commit('SET_LOADING', false);
        //   console.log(error);
        });
    },
    getNotifications({ commit, state }, { apiPath }) {
      commit('SET_LOADING', true);
      
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}`)
          .then((response) => {
            state.notifications = response.data.result;
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    updateProfile({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${CompaniesAPIsConstants.API_PATH}/update-profile`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },
    getProfile({ commit, state }, {  id }) {
        commit('SET_LOADING_ITEM', true);
        return new Promise((resolve, reject) => {
        axios
          .get(`${CompaniesAPIsConstants.API_PATH}/${id}`)
          .then((res) => {
            commit('SET_LOADING_ITEM', false);
            commit('SET_ITEM_EDITABLE', {
                itemEditable: res.data.result?.company,
            });
            // commit('SET_LOADING', false);
            // console.log('dataaa',res.data.result?.item);
            resolve(res);
          })
          .catch((error) => {
            commit('SET_LOADING_ITEM', false);
            // console.log(error);
            reject(error);
            // commit('SET_LOADING', false);
          });
        });
    },
    activeCompanny({ commit }, { id }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
            .post('admin-panal/companies/toggle-active/'+id,{active: 1})
            .then((response) => {
              commit('SET_CRUD_LOADING', false);
              resolve(response);
            })
            .catch((error) => {
              commit('SET_CRUD_LOADING', false);
              reject(error);
            });
      });
    },
    deactivateCompany({ commit }, { id }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
            .post('admin-panal/companies/toggle-active/'+id,{active: 0})
            .then((response) => {
              commit('SET_CRUD_LOADING', false);
              resolve(response);
            })
            .catch((error) => {
              commit('SET_CRUD_LOADING', false);
              reject(error);
            });
      });
    },
  },
  getters: {

  },
};
export default AdminCustomerModule;
