import { RouteRecordRaw } from 'vue-router';

const movementRouter: Array<RouteRecordRaw> = [
   {
      path: 'transport-transactions/edit/:transportId',
      name: 'transport-management-edit-transport-transactions',
      meta: {
         permission: 'transport_transport_movements',
         title: 'Transport Transactions',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/transport-movement/Add.vue'),
   },
   {
      path: 'transport-transactions/:type/:transportId',
      name: 'transport-management-view-transport-transactions',
      meta: {
         permission: 'transport_transport_movements',
         title: 'Transport Transactions',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/transport-movement/Add.vue'),
   },
   {
      path: 'transport-transactions/add',
      name: 'transport-management-transport-transactions-add',
      meta: {
         permission: 'transport_transport_movements',
         title: 'Transport Transactions',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/transport-movement/Add.vue'),
   },
   {
      path: 'transport-transactions/list',
      name: 'transport-management-list-transport-transactions',
      meta: {
         permission: 'transport_transport_movements',
         title: 'Transport Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/transport-movement/List.vue'),
   },
   {
      path: 'return-transactions/edit/:transportId',
      name: 'transport-management-edit-return-transactions',
      meta: {
         permission: 'transport_return_movements',
         title: 'Return Transactions',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/return-movement/Add.vue'),
   },
   {
      path: 'return-transactions/:type/:transportId',
      name: 'transport-management-view-return-transactions',
      meta: {
         permission: 'transport_return_movements',
         title: 'Return Transactions',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/return-movement/Add.vue'),
   },
   {
      path: 'return-transactions/add',
      name: 'transport-management-return-transactions-add',
      meta: {
         permission: 'transport_return_movements',
         title: 'Return Transactions',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/return-movement/Add.vue'),
   },
   {
      path: 'return-transactions/list',
      name: 'transport-management-list-return-transactions',
      meta: {
         permission: 'transport_return_movements',
         title: 'Return Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/return-movement/List.vue'),
   },
   {
      path: 'transport-transaction-expenses/edit/:transportId/:type',
      name: 'transport-management-edit-transport-transaction-expenses',
      meta: {
         permission: 'transport_movement_expenses',
         title: 'Transport Transaction Expenses',
         type: 'write',
      },
      component: () =>
         import(
            '@/views/modules/cargo/views/daily-movements/transport-transaction-expenses/Add.vue'
         ),
   },
   {
      path: 'transport-transaction-expenses/:transportId/:type',
      name: 'transport-management-view-transport-transaction-expenses',
      meta: {
         permission: 'transport_movement_expenses',
         title: 'Transport Transaction Expenses',
      },
      component: () =>
         import(
            '@/views/modules/cargo/views/daily-movements/transport-transaction-expenses/Add.vue'
         ),
   },
   {
      path: 'transport-transaction-expenses/add',
      name: 'transport-management-transport-transaction-expenses-transactions-add',
      meta: {
         permission: 'transport_movement_expenses',
         title: 'Transport Transaction Expenses',
         type: 'write',
      },
      component: () =>
         import(
            '@/views/modules/cargo/views/daily-movements/transport-transaction-expenses/Add.vue'
         ),
   },
   {
      path: 'transport-transaction-expenses/list',
      name: 'transport-management-list-transport-transaction-expenses',
      meta: {
         permission: 'transport_movement_expenses',
         title: 'Transport Transaction Expenses',
         type: 'read',
      },
      component: () =>
         import(
            '@/views/modules/cargo/views/daily-movements/transport-transaction-expenses/List.vue'
         ),
   },
   {
      path: 'daily-transactions/downloads-transactions',
      name: 'transport-management-downloads-transactions-list',
      meta: {
         title: 'Downloads transactions',
         permissions: 'transport_download_movements',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/downloads-transactions/List.vue'),
   },
   {
      path: 'daily-transactions/downloads-transactions/add',
      name: 'transport-management-downloads-transactions-add',
      meta: {
         title: 'Downloads transactions',
         permissions: 'transport_download_movements',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/downloads-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/downloads-transactions/edit/:id',
      name: 'transport-management-downloads-transactions-edit',
      meta: {
         title: 'Downloads transactions',
         permissions: 'transport_download_movements',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/downloads-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/downloads-transactions/:type/:id',
      name: 'transport-management-downloads-transactions-view',
      meta: {
         title: 'Downloads transactions',
         permissions: 'transport_download_movements',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/downloads-transactions/Add.vue'),
   },
   {
      path: 'journal-posting',
      name: 'transportation-journal-posting',
      meta: {
         title: 'Post to Accounting',
         permission: 'transport_postings',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/journal-posting/base.vue'),
   },
   {
      path: 'transferring-log',
      name: 'transportation-transferring-log',
      meta: {
         title: 'transferringLog',
         permission: 'transport_postings',
      },
      component: () =>
         import('@/views/modules/cargo/views/daily-movements/journal-posting/List.vue'),
   },
];

export default movementRouter;
