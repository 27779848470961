export default function (filtersObject = {}) {
  if (typeof filtersObject !== "object") {
    throw TypeError(
      `setParams first argument "filtersObject" is not of type Object, got ${filtersObject}`
    );
  }

  let getPairs = (obj, keys = []) =>
    Object.entries(obj).reduce((pairs, [key, value]) => {
      if (typeof value === "object")
        pairs.push(...getPairs(value, [...keys, key]));
      else pairs.push([[...keys, key], value]);
      return pairs;
    }, []);

  return getPairs(filtersObject)
    .map(
      ([[key0, ...keysRest], value]) =>
        `${key0}${keysRest.map((a) => `[${a}]`).join("")}=${value}`
    )
    .join("&");
}
