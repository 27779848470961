const apiPath = '/hr/salaries/hr-migrate/calculate-salaries';
const path = 'CalculateSalariesModule';

const CalculateSalariesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const CalculateSalariesModelActions = {
  ADD_ITEM: `${path}/addItem`,
}
export { CalculateSalariesAPIsConstants, CalculateSalariesModelActions };
