import { App } from 'vue';

export function amazonLink(link: string) {
   return process.env.VUE_APP_AMAZON_LINK + link;
}

export const AmazonLinkPlugin = {
   install: (app: App<Element>) => {
      app.config.globalProperties.$amazonLink = amazonLink;
   },
};
