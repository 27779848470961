import AlertService from '@/core/services/AlertService';
import axios from 'axios';

const apiPath = '/core/zatca-devices';
const path = 'ZatcaDevicesModule';

export const ZatcaDevicesAPIsConstants = {
   API_PATH: `${apiPath}`,
   ACTIVATE: (id) => `${apiPath}/${id}/activate`,
   DEACTIVATE: (id) => `${apiPath}/${id}/deactivate`,
};

export const ZatcaDevicesActions = {
   ACTIVATE_DEVICE: `${path}/activateDevice`,
   DEACTIVATE_DEVICE: `${path}/deactivateDevice`,
};

export const ZatcaDevicesModule = {
   namespaced: true,
   state() {
      return {
         loading: false,
      };
   },
   mutations: {
      SET_LOADING(state, value) {
         state.loading = value;
      },
   },
   actions: {
      activateDevice({ commit }, id) {
         commit('SET_LOADING', true);

         return axios
            .put(ZatcaDevicesAPIsConstants.ACTIVATE(id))
            .catch((e) => AlertService.error(e))
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
      deactivateDevice({ commit }, id) {
         commit('SET_LOADING', true);

         return axios
            .put(ZatcaDevicesAPIsConstants.DEACTIVATE(id))
            .catch((e) => AlertService.error(e))
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
   },
};
