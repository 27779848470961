const path = 'JournalEntriesModule';
const apiPath = '/core/JournalEntriess';

const JournalEntriesActions = {
   // action types
   GET_DATA: `${path}/getJournalEntries`,
   UPDATE_ITEM: `${path}/updateJournalEntries`,
   ADD_ITEM: `${path}/addJournalEntries`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   GET_ACCOUNT_INIT_INFO: `${path}/getAccountInitInfo`,
   DELETE_ITEM: `${path}/deleteItem`,
   EXPORT_AS_EXCEL: `${path}/exportAsExcel`,

   SET_EDITABLE_ID: `${path}/setEditableId`,
   RESET_EDITABLE: `${path}/resetEditable`,
   SET_ROLE_NAME: `${path}/setRoleName`,
   SEED_TREE: `${path}/seedTree`,
   DELETE_TREE: `${path}/deleteTree`,
   SET_FILTERS: `${path}/setFilters`,
   RESET_FILTERS: `${path}/resetFilters`,
   // RESET : `${path}/reset`,
   // SUBSCRIBE : `${path}/subscribe`,
   // GET_LIST_DATA : `${path}/getListData`,
};

const JournalEntriesMutations = {
   // mutation types
   SET_ERROR: `${path}/setError`,
   RESET_DATA: `${path}/resetData`,
   SET_DATA: `${path}/setJournalEntries`,
   SET_LIST_DATA: `${path}/setListData`,
   SET_ITEM_EDITABLE: `${path}/SET_ITEM_EDITABLE`,
};

const JournalEntriesAPIsConstants = {
   // api's
   API_PATH: `${apiPath}`,
   DELETE_TREE: `/accounting/accounts/delete-account-tree`,
   SHOW_ENTRY: `/accounting/accounts/show-entry`,
};

export { JournalEntriesActions, JournalEntriesMutations, JournalEntriesAPIsConstants };
