import AssetsSettings from './modules/Settings';
import AcquireAssets from './modules/daily-movements/AcquireAssets';
import DeprecationAssets from './modules/daily-movements/Deprecation';
import TransferringAssetModule from './modules/daily-movements/TransferringAsset';
import DisposeAssetModule from './modules/daily-movements/DisposeAsset';
import MaintenancePlansModule from './modules/crud/MaintenancePlans';
import AssetsAttachmentsModule from './modules/crud/AssetsAttachments';
import AssetsDashboardModule from './modules/Dashboard';

const assetsStore = {
   AssetsSettings,
   AcquireAssets,
   DeprecationAssets,
   TransferringAssetModule,
   DisposeAssetModule,
   MaintenancePlansModule,
   AssetsDashboardModule,
   AssetsAttachmentsModule,
};

export default assetsStore;
