const path = 'EmployeePaySlipReportModule';
const apiPath = 'hr/salaries/report';

const EmployeePaySlipReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_EmployeePaySlip_REPORT : `${path}/generateDetailedEmployeePaySlipReport`,
}

const EmployeePaySlipReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {EmployeePaySlipReportAPIsConstants, EmployeePaySlipReportModelActions };
