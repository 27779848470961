const path = "TransferringLogModule";
const apiPath = "/invoicing/invoiceing-warehouse-posting";

const TransferringLogActions = {
  // action types
  GET_DATA: `${path}/getData`,
  SET_DATA_NAME: `${path}/setDataName`,
  SET_EDITABLE_ID: `${path}/setEditableId`,
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  DELETE_ITEM: `${path}/deleteItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  RESET_EDITABLE: `${path}/resetEditable`,
  RESET: `${path}/reset`,
  SET_ROWS_PER_PAGE: `${path}/setRowsPerPage`,
  SET_TOTAL_NUM: `${path}/setTotalNum`,
  SET_CURRENT_PAGE: `${path}/setCurrentPage`,
  SET_IS_PAGINATION: `${path}/setIsPagination`,
  SET_IS_CLOSE_MODAL: `${path}/setIsCloseModal`,
  GET_INIT_DATA: `${path}/getInitData`,
};

const TransferringLogAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

const TRANSFERRING_STATUS = [
  { id: 1, name: "PENDING" },
  { id: 2, name: "SUCCESS" },
  { id: 3, name: "FAIL" },
];

export {
  TransferringLogAPIsConstants,
  TransferringLogActions,
  TRANSFERRING_STATUS,
};
