const path = 'ClientDealsReportModule';
const apiPath = 'customs/report';

const ClientDealsReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_ClientDeals_REPORT : `${path}/generateDetailedClientDealsReport`,
}

const ClientDealsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {ClientDealsReportAPIsConstants, ClientDealsReportActions };
