import axios from 'axios';
import { ModalActions } from '../enums/ModalEnum';
import { mergeCrudStore } from '@/core/stores/curd';
import router from '@/router';
import ObjectService from '@/core/services/Object';

const TableModule = mergeCrudStore({
   namespaced: true,
   actions: {
      getData({ commit, state, dispatch }, apiPath) {
         commit('SET_LOADING', true);

         const { page, rowsPerPage } = state.pagination || {};
         const currentRoute = router.currentRoute.value;
         const query = state.isPagination ? { pageSize: rowsPerPage, page } : {};

         if (state.isPagination) {
            const currentParams = { page, rowsPerPage };

            router.replace({
               query: { ...(currentRoute.query || {}), ...currentParams },
            });
         }

         if (state?.sort_column) state.tableFilters['sort_column'] = state.sort_column;
         if (state?.sort_direction) state.tableFilters['sort_direction'] = state.sort_direction;

         let filters = currentRoute.query?.filters || null;
         if (filters) filters = JSON.parse(filters);
         const baseFilters = ObjectService.merge(state.tableFilters || {}, filters || {});

         return axios
            .get(apiPath, { params: { ...baseFilters, ...query } })
            .then((res) => {
               if (state.isPagination)
                  commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
               commit('SET_DATA_TABLE', {
                  DataTable: state.dataName
                     ? res.data.result[state.dataName].data
                     : res.data.result,
               });
               commit('SET_EXTRA_DATA', res.data.result?.extra_data);
               commit('SET_HELPER', res.data.result?.helper);
               commit('SET_LOADING', false);

               state.getDataResponse = res.data;

               if (state.isCloseModal) {
                  dispatch(ModalActions.CLOSE_MODEL, '', { root: true });
                  commit('SET_IS_CLOSE_MODAL', false);
               }

               return res.data;
            })
            .catch((error) => {
               console.log(error);
               commit('SET_LOADING', false);
               if (state.isCloseModal) {
                  dispatch(ModalActions.CLOSE_MODEL, '', { root: true });
                  commit('SET_IS_CLOSE_MODAL', false);
               }
            });
      },

      findItemById({ commit, state }, data) {
         if (!state.editableId) {
            return;
         }
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${data.apiPath}/${state.editableId}`, { params: data.params })
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  console.log(error);
                  reject(error);
               });
         });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  commit('SET_INVOICE_DATA', response.data?.result?.sales_invoice || null);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${state.editableId}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      getInitData(_, { apiPath, params }) {
         return axios.get(`${apiPath}`, { params });
      },
   },
});
export default TableModule;
