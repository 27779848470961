const apiPath = '/hr/attendance/hr-work-time-temporary-change-movements';
const path = 'ChangingTemporaryWorkingHoursModule';
const ChangingTemporaryWorkingHoursAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const ChangingTemporaryWorkingHoursActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
};

export { ChangingTemporaryWorkingHoursAPIsConstants, ChangingTemporaryWorkingHoursActions };
