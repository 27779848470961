import axios from "axios";
import { WorkTimesSchedulesAPIsConstants } from "@/views/modules/attendance/store/enums/settings/WorkTimesSchedulesEnums.ts";

const WorkTimesSchedulesModule = {
  namespaced: true,

  state: {
    itemEditable: {},
    initData: {},
    dataName: "",
    loading: false,
    crudLoading: false,
    loadingItem: false,
    isPagination: true,
  },

  mutations: {
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },

    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },

    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item["translations"] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },

    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },

    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = "";
      state.loading = false;
      state.itemEditable = {};
      state.tableFilters = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
  },

  actions: {
    findItemById({ commit }, { id }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${WorkTimesSchedulesAPIsConstants.API_PATH}/${id}`)
          .then((res) => {
            commit("SET_LOADING_ITEM", false);
            commit("SET_ITEM_EDITABLE", {
              itemEditable: res.data.result,
            });
            resolve(res);
          })
          .catch((error) => {
            commit("SET_LOADING_ITEM", false);
            reject(error);
          });
      });
    },

    addItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    editItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    updateItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    getInitData({ commit, state }, { apiPath }) {
      commit("SET_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/init-data`)
          .then((response) => {
            commit("SET_LOADING", false);

            state.initData = response.data.result;
            resolve(response);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            reject(error);
          });
      });
    },
  },
};

export default WorkTimesSchedulesModule;
