import { RouteRecordRaw } from 'vue-router';

const dailyMovementsRouter: Array<RouteRecordRaw> = [
   {
      path: 'approvals',
      name: 'electronic-approval-transactions',
      meta: {
         title: 'Approvals',
      },
      component: () =>
         import('@/views/modules/electronic-approvals/views/daily-movements/approvals/List.vue'),
   },
];

export default dailyMovementsRouter;

