import EmployeeAttendanceModule from './modules/EmployeeAttendanceModule.js';
import HREmployeeAttendanceModule from './modules/HREmployeeAttendanceModule.js';
import WorkTimesSchedulesModule from '@/views/modules/attendance/store/modules/settings/WorkTimesSchedulesModule.js';
import EmployeesYearlyVacationsModule from '@/views/modules/attendance/store/modules/reports/EmployeesYearlyVacationsModule.js';
import AttendanceDepartureModule from './modules/AttendanceDepartureModule.js';
import MigrateToSalariesModule from './modules/MigrateToSalariesModule.js';
import AttendanceDepartureReportModule from './modules/reports/AttendanceDepartureReportModule.js';
import ExtraHoursReportModule from './modules/reports/ExtraHoursReportModule.js';
import LeaveRequestReportModule from './modules/reports/LeaveRequestReportModule.js';
import DelayRequestReportModule from './modules/reports/DelayRequestReportModule.js';
import CloseMonthModule from './modules/daily-movements/CloseMonthModule.js';
import LeaveRequestModule from './modules/LeaveRequestModule.js';
import LeaveRequestAsEmployeeModule from './modules/daily-movements/LeaveRequestAsEmployeeModule.js';
import DelayRequestModule from './modules/DelayRequestModule.js';
import ChangingTemporaryWorkingHoursModule from './modules/daily-movements/ChangingTemporaryWorkingHoursModule.js';
import DelayRequestAsEmployeeModule from './modules/daily-movements/DelayRequestAsEmployeeModule.js';
import AbsenceReport from './modules/reports/AbsenceReport.js';
import MyVacations from './modules/reports/MyVacations';
import OvertimeApprovals from './modules/daily-movements/OvertimeApprovals';

const attendanceStore = {
   EmployeeAttendanceModule,
   WorkTimesSchedulesModule,
   ExtraHoursReportModule,
   LeaveRequestReportModule,
   DelayRequestReportModule,
   AttendanceDepartureReportModule,
   MigrateToSalariesModule,
   LeaveRequestModule,
   AttendanceDepartureModule,
   EmployeesYearlyVacationsModule,
   CloseMonthModule,
   HREmployeeAttendanceModule,
   LeaveRequestAsEmployeeModule,
   ChangingTemporaryWorkingHoursModule,
   DelayRequestModule,
   DelayRequestAsEmployeeModule,
   AbsenceReport,
   MyVacations,
   OvertimeApprovals,
};

export default attendanceStore;
