const path = 'ProductModule';
const apiPath = '/product/products';

const ProductActions = {
   // action types
   GET_DATA: `${path}/getData`,
   SET_DATA_NAME: `${path}/setDataName`,
   SET_EDITABLE_ID: `${path}/setEditableId`,
   ADD_ITEM: `${path}/addItem`,
   UPDATE_ITEM: `${path}/updateItem`,
   DELETE_ITEM: `${path}/deleteItem`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   RESET_EDITABLE: `${path}/resetEditable`,
   RESET: `${path}/reset`,
   SET_ROWS_PER_PAGE: `${path}/setRowsPerPage`,
   SET_TOTAL_NUM: `${path}/setTotalNum`,
   SET_CURRENT_PAGE: `${path}/setCurrentPage`,
   SET_IS_PAGINATION: `${path}/setIsPagination`,
   SET_IS_CLOSE_MODAL: `${path}/setIsCloseModal`,
   GET_PRODUCT_UNIT_WAREHOUSE_INFO: `${path}/getProductUnitWarehouseInfo`,
   GET_PRODUCT_UNIT_WAREHOUSE_INFO_LIST: `${path}/getProductUnitWarehouseInfoList`,
   SERIAL_NUMBERS_VALIDATION: `${path}/serialNumbersValidation`,
   GET_GREATER_THAN_UNITS: `${path}/getGreaterThanUnits`,
   IMPORT_EXCEL: `${path}/importExcel`,
};

const ProductsAPIsConstants = {
   // api's
   API_PATH: `${apiPath}`,
   GET_PRODUCT_UNIT_WAREHOUSE_INFO_PATH: `${apiPath}/get-product-unit-warehouse-info`,
   GET_PRODUCT_UNIT_WAREHOUSE_INFO_LIST: `${apiPath}/get-products-unit-warehouse-info-list`,
};

export { ProductsAPIsConstants, ProductActions };
