const path = "EmployeeDetailsModule";
const apiPath = "/hr/report/movements/employee-details-report";

const EmployeeDetailsActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeeDetailsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeeDetailsAPIsConstants, EmployeeDetailsActions };
