const path = 'PojectsGroupReportModule';
const apiPath = '/accounting/report';

const PojectsGorupReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_PROJECTS_GROUPS_REPORT : `${path}/projectGroupReport`,
}

const PojectsGorupReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {PojectsGorupReportAPIsConstants, PojectsGorupReportModelActions };
