import axios from "axios";

const CloseMonthModule = {
  namespaced: true,

  state: () => ({
    crudLoading: false,
  }),

  mutations: {
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
  },

  actions: {
    addItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);

      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },
  },
};
export default CloseMonthModule;
