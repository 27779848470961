const TransportMovementMenuConfig = [
    {
      heading: 'Daily Transactions', 
      route: '#',
      name: 'daily_movementsss',
      pages: [
        {
          heading: 'Transport Transactions',
          route: '/external-client/transport-management',
        },
        {
          heading: 'Downloads transactions',
          route: '/external-client/transport-management/downloads',
        },
        {
          heading: 'Sales',
          route: '/external-client/transport-management/sales',
        },
      ],
    },
  ];
  export default TransportMovementMenuConfig;
  