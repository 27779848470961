const path = 'AdminCompanyModule';
const apiPath = '/admin-panal/companies';
const togglePath = '/admin-panal/companies/toggle-active';

const CompaniesActions = {
  // action types
  GET_DATA : `${path}/getData`,
  SET_DATA_NAME : `${path}/setDataName`,
  SET_EDITABLE_ID : `${path}/setEditableId`,
  ADD_ITEM : `${path}/addItem`,
  UPDATE_ITEM : `${path}/updateItem`,
  DELETE_ITEM : `${path}/deleteItem`,
  FIND_ITEM_BY_ID : `${path}/findItemById`,
  RESET_EDITABLE : `${path}/resetEditable`,
  RESET : `${path}/reset`,
  SET_ROWS_PER_PAGE : `${path}/setRowsPerPage`,
  SET_TOTAL_NUM : `${path}/setTotalNum`,
  SET_CURRENT_PAGE : `${path}/setCurrentPage`,
  SET_IS_PAGINATION : `${path}/setIsPagination`,
  SET_IS_CLOSE_MODAL : `${path}/setIsCloseModal`,
  SET_FILTERS : `${path}/setFilters`,
  GET_PROFILE : `${path}/getProfile`,
  ACTIVE_COMPANY : `${path}/activeCompanny`,
  DEACTIVATE_COMPANY : `${path}/deactivateCompany`,
}

const CompaniesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  ACTIVE_COMPANY : `${togglePath}/activeCompanny`,
  DEACTIVATE_COMPANY : `${togglePath}/deactivateCompany`,
}

export { CompaniesAPIsConstants, CompaniesActions };
