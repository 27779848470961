import {useI18n} from "vue-i18n";
import {CookieService} from "@/core/services/CookieService";

class LanguageService {

  public static setLang(lang) {
    console.log("lang1",lang);
    const i18n = useI18n();
    // localStorage.setItem('lang', lang);
    document.documentElement.setAttribute('lang', lang);
    i18n.locale.value = lang;
    console.log("lang2",lang);
    CookieService.setCookie('lang', lang);
  }

  public static setLangWithReload(lang) {
    CookieService.setCookie('lang', lang);
    window.location.reload();
  }

  public static setLangCookie(lang) {
    CookieService.setCookie('lang', lang);
  }

  public static setLangWithoutReload(lang) {
    CookieService.setCookie('lang', lang);
  }

  public static getLang() {
    return CookieService.getCookie('lang');
  }

}

export default LanguageService;
