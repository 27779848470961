import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'assets-dashboard',
      name: 'assets-dashboard',
      meta: {
         title: 'Assets Module',
      },
      component: () => import('@/views/modules/assets/views/dashboard/Index.vue'),
   },
   {
      path: 'settings',
      name: 'assets-settings',
      meta: {
         permission: 'asset_setting',
         title: 'Assets Settings',
      },
      component: () => import('@/views/modules/assets/views/settings/index.vue'),
   },
   {
      path: 'assets-categories',
      name: 'assets-categories',
      meta: {
         permission: 'asset_categories',
         title: 'Assets Categories',
      },
      component: () => import('@/views/modules/assets/views/crud/assets-categories/List.vue'),
   },
   {
      path: 'assets',
      name: 'assets-management-assets-assets',
      meta: {
         permission: 'products',
         title: 'Assets',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/List.vue'),
   },
   {
      path: 'add-asset',
      name: 'assets-management-assets-add-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },
   {
      path: 'update-asset/:id',
      name: 'assets-management-assets-update-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },
   {
      path: 'view-asset/:id',
      name: 'assets-management-assets-view-asset',
      meta: {
         permission: 'products',
         title: 'Assets',
         type: 'write',
      },
      component: () => import('@/views/modules/assets/views/crud/assets/Add.vue'),
   },

   {
      path: 'assets-cases',
      name: 'assets-cases',
      meta: {
         permission: 'asset_cases',
         title: 'Assets Cases',
      },
      component: () => import('@/views/modules/assets/views/crud/assets-cases/List.vue'),
   },

   {
      path: 'work-locations',
      name: 'assets-work-locations',
      meta: {
         permission: 'asset_locations',
         title: 'Work Locations',
      },
      component: () => import('@/views/modules/hr/views/crud/work-locations/List.vue'),
   },

   {
      path: 'acquisition-methods',
      name: 'assets-acquisition-methods',
      meta: {
         permission: 'asset_acquisition_methods',
         title: 'Acquisition Methods',
      },
      component: () => import('@/views/modules/assets/views/crud/acquisition-methods/List.vue'),
   },

   {
      path: 'warehouse',
      name: 'assets-warehouse',
      meta: {
         permission: 'warehouses',
         title: 'Warehouses',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/warehouse/List.vue'),
   },
   {
      path: 'unit',
      name: 'assets-unit',
      meta: {
         permission: 'units',
         title: 'units',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/unit/List.vue'),
   },
   {
      path: 'taxes',
      name: 'assets-taxes',
      meta: {
         permission: 'taxes',
         title: 'Taxes',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/taxes/List.vue'),
   },
   {
      path: 'client-group',
      name: 'assets-client-group',
      meta: {
         permission: 'client_groups',
         title: 'Clients Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client-group/List.vue'),
   },
   {
      path: 'clients',
      name: 'assets-clients',
      meta: {
         permission: 'clients',
         title: 'Clients',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client/List.vue'),
   },
   {
      path: 'vendor-group',
      name: 'assets-vendor-group',
      meta: {
         permission: 'vendor_groups',
         title: 'Vendor Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue'),
   },
   {
      path: 'vendor',
      name: 'assets-vendor',
      meta: {
         permission: 'vendors',
         title: 'Vendors',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/vendor/List.vue'),
   },
];

export default settingRouter;
