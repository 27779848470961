const apiPath = '/hr/attendance';
const path = 'AttendanceDepartureModule';

const AttendanceDepartureAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const AttendanceDepartureModelActions = {
  IMPORT: `${path}/import`,
}
export { AttendanceDepartureAPIsConstants, AttendanceDepartureModelActions };
