const path = 'CargoDashboardModule';
const apiPath = 'transport/dashboard';

const CargoDashboardModelActions = {
    //action type
    GET_CARGO_STATISTIC_DATA : `${path}/getCargoStatisticData`,
    GET_SALES_STATISTIC_DATA : `${path}/GetSalesStatisticData`,
    GET_PURCHASE_STATISTIC_DATA : `${path}/GetPurchaseStatisticData`,
};

const CargoDashboardModelAPIsConstants = {
    API_PATH : `${apiPath}`
}

export {CargoDashboardModelActions, CargoDashboardModelAPIsConstants};
