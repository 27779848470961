import axios from 'axios';
import { IqamaAPIsConstants } from '../enums/daily-movements/Iqama';
import { TerminationAPIsConstants } from '@/views/modules/hr/store/enums/daily-movements/TerminationEnums';

export default {
   namespaced: true,
   state: {
      crudLoading: false,
      itemLoading: false,
      itemEditable: {},
   },
   mutations: {
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_ITEM_LOADING(state, payload) {
         state.itemLoading = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
   },
   actions: {
      addItem({ commit }, { data }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(IqamaAPIsConstants.ISSUE, data).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
      findItemById({ commit }, { id, params }) {
         commit('SET_ITEM_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${IqamaAPIsConstants.API_PATH}/${id}`, { params })
               .then((res) => {

                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               })
               .finally(() => {
                  setTimeout(() => {
                     commit('SET_ITEM_LOADING', false);
                  }, 500);
               });
         });
      },
      transfer({ commit }, { data }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(IqamaAPIsConstants.TRANSFER, data).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
      renew({ commit }, { data }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(IqamaAPIsConstants.RENEW, data).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
   },
};
