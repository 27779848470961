const path = 'InvoicingJournalEntryPostingsModule';
const apiPath = '/invoicing/invoice-accounting';

const InvoicingJournalEntryPostingsModelActions = {
  // action types
  MAKE_ITEM: `${path}/createPosting`,

}

const InvoicingJournalEntryPostingsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { InvoicingJournalEntryPostingsAPIsConstants, InvoicingJournalEntryPostingsModelActions };
