const apiPath = '/hr/hr-sanctions';
const path = 'SanctionsModule';

const SanctionsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}
const SanctionsModelActions = {
  // api's
  FIND_ITEM_BY_ID: `${path}/findItemById`,
}

export { SanctionsAPIsConstants, SanctionsModelActions };
