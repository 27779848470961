
import { defineComponent, onMounted, onBeforeMount, nextTick, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { initializeComponents } from '@/core/plugins/keenthemes';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { useI18n } from 'vue-i18n';
import * as Yup from 'yup';
import { ListActions } from '@/store/enums/ListEnum';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import JwtService from '@/core/services/JwtService';
import axios from 'axios';
import { CookieService } from './core/services/CookieService';
import ContactUsPopup from './components/contact-us/ContactUsPopup.vue';
import AlertService from '@/core/services/AlertService';
import Swal from 'sweetalert2';
import { UTMSource } from './core/services/UTMSource';
import { useRoute, useRouter } from 'vue-router';
import { SubscriptionInfo } from './core/services/SubscriptionInfo';

export default defineComponent({
   name: 'app',
   data() {
      return {
         VUE_APP_APP_ENV: process.env.VUE_APP_APP_ENV,
         logoutTime: 183,
         waitingTime: 180,
         renewTime: 10,
         lockSignOut: false,
         isRenewSessionOpen: false,
      };
   },
   mounted() {
      this.renewSession();
      setInterval((): void => {
         this.renewSession();
      }, 60000);
   },
   methods: {
      signOut() {
         this.$store.dispatch(Actions.LOGOUT).then(() => {
            if (JwtService.isAdminDomain()) this.$router.push({ name: 'admin-sign-in' });
            else this.$router.push({ name: 'sign-in' });
         });
      },
      async renewSession() {
         if (!this.$store.getters.isUserAuthenticated) return;

         let time = new Date().getTime();
         let sessionTime = parseInt(JwtService.getRenewSession());

         // minutes passed since last token refresh
         let minutes = Math.floor((time - sessionTime) / 1000 / 60);

         if (minutes >= this.renewTime) {
            if (this.$store.state.AuthModule.verifyAuthRequestsCount > 0)
               this.$store.dispatch(Actions.VERIFY_AUTH);
         }

         if (minutes >= this.logoutTime) {
            AlertService.close();
            this.signOut();
         } else if (minutes >= this.waitingTime) {
            if (this.isRenewSessionOpen) return;
            this.isRenewSessionOpen = true;

            const confirmed = await AlertService.warning(
               this.$t('The session is about to expire. Do you want to renew the session?'),
               this.$t('Renew'),
               this.$t('Sign Out'),
            );
            this.isRenewSessionOpen = false;

            if (confirmed.isConfirmed || confirmed.dismiss == Swal.DismissReason.backdrop) {
               JwtService.saveRenewSession(new Date().getTime());
               this.$store.dispatch(Actions.VERIFY_AUTH);
            } else {
               this.signOut();
            }
         }
      },
   },
   setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { t } = useI18n();
      const isFirstLoad = ref(true);
      Yup.setLocale({
         mixed: {
            required: t('Required Field'),
            notType: (params) => t('must be') + ' ' + t(params.type),
         },
         number: {
            integer: t('must be integer'),
            min: (params) => t('Must be grater than or equal to') + ' ' + params.min,
            max: (params) => t('Must be less than  or equal to') + ' ' + params.max,
         },
         date: {
            min: (params) =>
               t('must be grater than') + ' ' + new Date(params.min).toJSON().slice(0, 10),
            max: (params) =>
               t('Must be less than  or equal to') +
               ' ' +
               new Date(params.max).toJSON().slice(0, 10),
         },
         array: {
            min: (params) => t('must have at least') + ' ' + params.min + ' ' + t('items'),
            max: (params) => t('must have at most') + ' ' + params.max + ' ' + t('items'),
         },
         string: {
            email: t('must be a valid email'),
            min: (params) => t('must be at least') + ' ' + params.min + ' ' + t('character'),
            max: (params) => t('must be at most') + ' ' + params.max + ' ' + t('character'),
            length: (params) => t('must be') + ' ' + params.length + ' ' + t('character'),
         },
      });
      onBeforeMount(() => {
         axios.interceptors.request.use(function (config) {
            if (CookieService.getCookie('shredDomains')) {
               config.headers.Authorization = `Bearer ${
                  CookieService.getCookie('shredDomains')?.token
               }`;
            } else if (CookieService.getCookie('X-Tenant-Id')) {
               config.headers.Authorization = null;
            }

            if (CookieService.getCookie('X-Tenant-Id'))
               config.headers['X-Tenant-Id'] = CookieService.getCookie('X-Tenant-Id') || null;
            return config;
         });
         axios.interceptors.response.use(
            (response) => {
               if (response.config.method != 'get') {
                  store.dispatch(ListActions.CLEAR_CACHE);
               }
               return response;
            },
            (error) => {
               try {
                  const errors = {
                     url: window.location.href,
                     type: 'axios',
                     status: error.response.status,
                     response: error.response,
                     cookies: document.cookie,
                  };
                  if (process.env.NODE_ENV !== 'development')
                     axios.post(`company/frontend-service/report-error`, errors);
               } catch (err) {}
               return Promise.reject(error);
            },
         );

         store.dispatch(Actions.VERIFY_AUTH);
      });
      const currentUser = computed(() => {
         return store.getters.currentUser;
      });
      watch(currentUser, () => {
         if (currentUser.value && isFirstLoad.value) {
            //load one time in whole system
            isFirstLoad.value = false;
            if (!JwtService.isAdminDomain() && CookieService.getCookie('X-Tenant-Id')) {
               store.dispatch(ListActions.GET_LIST_BY_NAME, 'getConstList');
            }
         }
      });
      watch(route, () => {
         const { utm_source, subscription_info } = route.query;

         if (utm_source) UTMSource.set(utm_source as string);
         if (subscription_info) {
            const oldSubscriptionInfo = SubscriptionInfo.get();
            SubscriptionInfo.set(subscription_info as string);
            if (oldSubscriptionInfo && store.getters.isUserAuthenticated) {
               router.push('/subscription-plan');
            }
         }
      });
      onMounted(() => {
         const messaging = getMessaging();

         onMessage(messaging, (payload) => {
            store.dispatch(Actions.HANDLE_NOTIFICATION, { payload });
         });
         getToken(messaging, {
            vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
         })
            .then((currentToken) => {
               if (currentToken) {
                  store.commit(Mutations.SET_FCM_TOKEN, currentToken);
               } else {
                  console.log(
                     'No registration token available. Request permission to generate one.',
                  );
               }
            })
            .catch((err) => {
               console.log('An error occurred while retrieving token. ', err);
            });
         /**
          * this is to override the layout config using saved data from localStorage
          * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
          */
         store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
         nextTick(() => {
            initializeComponents();
         });
      });
   },
   components: { ContactUsPopup },
});
