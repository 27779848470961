const apiPath = '/hr/salaries/hr-entitlement-deduction-movement';
const path = 'EmployeesEarningsDeductionsModule';

const EmployeesEarningsDeductionsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const EmployeesEarningsDeductionsModelActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  IMPORT: `${path}/import`,
}
export { EmployeesEarningsDeductionsAPIsConstants, EmployeesEarningsDeductionsModelActions };
