const RestaurantsMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 40,
      pages: [
         {
            heading: 'Restaurants Settings',
            route: '/restaurant-management/setting',
         },
         {
            heading: 'Unit Of Measure',
            route: '/restaurant-management/unit',
            permission: 'units',
         },
         {
            heading: 'Taxes',
            route: '/restaurant-management/taxes',
            permission: 'taxes',
         },
         {
            heading: 'salesmans',
            route: '/restaurant-management/salesman',
            permission: 'salesmen',
         },
         {
            heading: 'Clients Groups',
            route: '/restaurant-management/client-group',
            permission: 'client_groups',
         },
         {
            heading: 'Clients',
            route: '/restaurant-management/clients',
            permission: 'clients',
         },
         {
            heading: 'Branches',
            route: '/restaurant-management/branches',
            permission: 'branches',
         },
         {
            heading: 'Meal Categories',
            route: '/restaurant-management/meal-categories',
            permission: 'restaurant_meal_categories',
         },
         {
            heading: 'Meal Modifiers Groups',
            route: '/restaurant-management/meal-modifiers-groups',
            permission: 'restaurant_meal_modifiers',
         },
         {
            heading: 'Meal Modifiers',
            route: '/restaurant-management/meal-modifiers',
            permission: 'restaurant_meal_modifier_options',
         },
         {
            heading: 'Meals',
            route: '/restaurant-management/meals',
            permission: 'restaurant_meals',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '#',
      module_id: 40,
      pages: [],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [],
   },
];
export default RestaurantsMenuConfig;
