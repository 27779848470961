import axios from "axios";
import getFiltersString from "@/utils/getFiltersString.js";

export default function (api = "", extend = {}) {
  if (typeof api !== "string") {
    throw TypeError(
      `StoreModule first argument "api" is not of type String, got ${api}`
    );
  }

  if (typeof extend !== "object") {
    throw TypeError(
      `StoreModule second argument "extend" is not of type Object, got ${extend}`
    );
  }

  const state = {
    data: {},
    page: 1,
    pagination: NaN,
    filters: {},
    loaded: false,
    loading: false,
    error: false,
    ...extend?.state,
  };

  const mutations = {
    SET_DATA(state, payload) {
      state.data = payload;
    },

    SET_PAGE(state, payload) {
      state.page = payload;
    },

    INCREMENT_PAGE(state) {
      state.page += 1;
    },

    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    RESET_PAGINATION(state) {
      state.page = 1;
      state.pagination = NaN;
    },

    SET_FILTERS(state, payload) {
      state.filters = payload;
    },

    RESET_FILTERS(state) {
      state.filters = {};
    },

    SET_LOADED(state, payload) {
      state.loaded = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_ERROR(state, payload) {
      state.error = payload;
    },

    ...extend?.mutations,
  };

  const actions = {
    async SET_DATA({ commit, state }) {
      commit("RESET_PAGINATION");
      commit("SET_LOADING", true);
      commit("SET_ERROR", false);

      try {
        const filtersString = getFiltersString(state?.filters);
        const promise = await axios.get(`${api}?${filtersString}`);
        const { data } = promise;

        commit("SET_DATA", data);
        commit("SET_LOADED", true);
      } catch (error) {
        commit("SET_ERROR", true);
        commit("SET_LOADED", false);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    async SET_DATA_BY_PAGINATION({ commit, state }) {
      commit("INCREMENT_PAGE");
      commit("SET_LOADING", true);
      commit("SET_ERROR", false);

      try {
        const filtersString = getFiltersString({
          ...state?.filters,
          page: state?.page,
        });

        const promise = await axios.get(`${api}?${filtersString}`);
        const { data } = promise;

        commit("SET_DATA", data);
        commit("SET_PAGINATION", data?.result?.total);
        commit("SET_LOADED", true);
      } catch (error) {
        commit("SET_ERROR", true);
        commit("SET_LOADED", false);
      } finally {
        commit("SET_LOADING", false);
      }
    },

    ...extend?.actions,
  };

  const getters = {
    data(state) {
      return state?.data;
    },

    loading(state) {
      return state?.loading;
    },

    loaded(state) {
      return state?.loaded;
    },

    error(state) {
      return state?.error;
    },

    ...extend?.getters,
  };

  return {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
}
