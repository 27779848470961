import axios from "axios";

const path = 'AssetsSettings';
const apiPath = '/asset/setting';

export const AssetsSettingsActions = {
   GET_SETTINGS: `${path}/getSetting`,
   UPDATE_SETTINGS: `${path}/updateSetting`,
};

export const AssetsSettingsAPIsConstants = {
   API_PATH: apiPath,
};

const AssetsSettings = {
   namespaced: true,
   state: {
      loading: false,
      crudLoading: false,
      itemEditable: {},
      settings: {},
      helper: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_SETTING(state, payload) {
         const data = payload.data;
         state.itemEditable = data.item;
         state.settings = data.item;
      },
      SET_HELPER(state, payload) {
         state.helper = payload.data;
      },
   },
   actions: {
      getSetting({ commit }) {
         commit('SET_LOADING', true);

         return axios
            .get(`${AssetsSettingsAPIsConstants.API_PATH}/get-setting`)
            .then((res) => {
               commit('SET_SETTING', { data: res.data.result });
               commit('SET_HELPER', { data: res.data.result?.helper });
               return res;
            })
            .finally(() => commit('SET_LOADING', false));
      },
      updateSetting({ commit }, data) {
         commit('SET_CRUD_LOADING', true);

         return axios
            .post(`${AssetsSettingsAPIsConstants.API_PATH}/update-setting`, data)
            .finally(() => commit('SET_CRUD_LOADING', false));
      },
   },
};

export default AssetsSettings;
