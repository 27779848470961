const path = 'ProjectsReportModule';
const apiPath = '/accounting/report';

const ProjectsReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_DETAILED_PROJECTS_REPORT : `${path}/generateDetailedProjectsReport`,
  GENERATE_TOTAL_PROJECTS_REPORT : `${path}/generateTotalProjectsReport`,
  GENERATE_ANALYZE_PROJECTS_REPORT : `${path}/generateAnalyzeProjectsReport`,
}

const ProjectsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {ProjectsReportAPIsConstants, ProjectsReportModelActions };
