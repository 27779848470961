const path = 'CostCenterReportModule';
const apiPath = '/accounting/report';

const CostCenterReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_DETAILED_COST_CENTER_REPORT : `${path}/generateDetailedCostCenterReport`,
  GENERATE_TOTAL_COST_CENTER_REPORT : `${path}/generateTotalCostCenterReport`,
  GENERATE_ANALYZE_COST_CENTER_REPORT : `${path}/generateAnalyzeCostCenterReport`,
  GENERATE_ANALYZE_COST_CENTER_GROUP_REPORT : `${path}/generateAnalyzeCostCenterGroupReport`,
}

const CostCenterReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {CostCenterReportAPIsConstants, CostCenterReportModelActions };
