interface Product {
   id: number;
   product_unit_warehouse_id: number;
   quantity: string;
   serial_numbers: string[];
}

interface ProductStock {
   id: number;
   warehouse_invoice_id: number;
   product_id: number;
   quantity: string;
   smallest_unit_quantity: string;
   expiry_date: string;
   serial_numbers: string[];
   lot_number: string;
}

export class BindingInvoiceService {
   static getProducts(
      products: Product[],
      remainingProducts: Record<number, { quantity: number }>,
      productsStock: ProductStock[] = [],
   ) {
      const serialNumbers = productsStock.reduce((res, stock) => {
         if (stock.serial_numbers) res.push(...stock.serial_numbers);

         return res;
      }, []);

      return products
         .map((p) => ({
            ...p,
            quantity: Math.min(
               parseFloat(p.quantity.replace(/,/g, '')),
               remainingProducts[p.product_unit_warehouse_id]?.quantity,
            ),
            max_quantity: remainingProducts[p.product_unit_warehouse_id]?.quantity,
            serial_numbers: p.serial_numbers
               ? p.serial_numbers.filter((serialNumber) => serialNumbers.includes(serialNumber))
               : undefined,
         }))
         .filter((p) => p.quantity > 0);
   }
}
