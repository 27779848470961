const path = 'AccountsGroupReportModule';
const apiPath = '/accounting/report';

const AccountsGroupReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_DETAILED_GENERAL_JOURNAL_REPORT : `${path}/generateDetailedAccountsGroupReport`,
}

const AccountsGroupReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {AccountsGroupReportAPIsConstants, AccountsGroupReportModelActions };
