const apiPath = '/hr/muqeem/final-exit';
const path = 'FinalExitModule';

const FinalExitAPIsConstants = {
   API_PATH: `${apiPath}`,
   EXTEND: `${apiPath}/extend`,
   CANCEL: `${apiPath}/cancel`,
   PRINT: `${apiPath}/reprint`,
};

const FinalExitActions = {
   ADD_ITEM: `${path}/addItem`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   EXTEND: `${path}/extend`,
   CANCEL: `${path}/cancel`,
   PRINT: `${path}/print`,
};
export { FinalExitAPIsConstants, FinalExitActions };
