import { App } from 'vue';

export const redirectToLandingPage = (path: string) => {
   window.location.href = `${process.env.VUE_APP_LANDING_PAGE_URL}${path}`;
};
export const landingPageUrl = (path: string) => {
   return `${process.env.VUE_APP_LANDING_PAGE_URL}${path}`;
};

export const RedirectPlugin = {
   install: (app: App<Element>) => {
      app.config.globalProperties.$redirectToLandingPage = redirectToLandingPage;
      app.config.globalProperties.$landingPageUrl = landingPageUrl;
   },
};
