
import CargoSettingModule from './modules/CargoSettingModule';
import CargoPurchaseReportModule from './modules/CargoPurchaseReportModule';
import CargoDashboardModule from './modules/CargoDashboardModule';
import CargoReportModule from './modules/CargoReportModule.js';
import TransportReportModule from './modules/TransportReportModule.js';
import CargoJournalEntryPostingsModule from './modules/CargoJournalEntryPostingsModule.js';
import CargoInvoiceModelModule from './modules/CargoInvoiceModelModule';
import CargoPurchaseInvoiceModule from './modules/CargoPurchaseInvoiceModule';
import CargoSalesInvoiceModule from './modules/CargoSalesInvoiceModule';
import CargoTransportMovementModule from './modules/CargoTransportMovementModule';
import DownloadsTransactionsModule from './modules/daily-movements/DownloadsTransactionsModule';
import TransportTransactionExpensesModule from './modules/daily-movements/TransportTransactionExpensesModule';
import CargoTransferringLogModule from './modules/daily-movements/CargoTransferringLogModule';
import CargoReturnMovementModule from './modules/CargoReturnMovementModule';
import CargoSalesReportModule from './modules/CargoSalesReportModule';
import CargoSalesFinancialTransactionModule from './modules/CargoSalesFinancialTransactionModule';
import CargoPurchaseFinancialTransactionModule from './modules/CargoPurchaseFinancialTransactionModule';
import ExternalClientTransportMovementModule from './modules/ExternalClientTransportMovementModule'
import ExternalClientDownloadMovementModule from './modules/ExternalClientDownloadMovementModule'
import ExternalVendorDownloadMovementModule from './modules/external-links/ExternalVendorDownloadMovementModule'
import ExternalVendorTransportMovementModule from './modules/external-links/ExternalVendorTransportMovementModule'

const cargoStore = {
    CargoSettingModule,
    CargoPurchaseReportModule,
    CargoReportModule,
    TransportReportModule,
    CargoDashboardModule,
    CargoJournalEntryPostingsModule,
    CargoInvoiceModelModule,
    CargoPurchaseInvoiceModule,
    CargoSalesInvoiceModule,
    DownloadsTransactionsModule,
    TransportTransactionExpensesModule,
    CargoTransportMovementModule,
    CargoReturnMovementModule,
    CargoSalesReportModule,
    CargoPurchaseFinancialTransactionModule,
    CargoSalesFinancialTransactionModule,
    ExternalClientTransportMovementModule,
    ExternalClientDownloadMovementModule,
    ExternalVendorDownloadMovementModule,
    ExternalVendorTransportMovementModule,
    CargoTransferringLogModule
};

export default cargoStore;
