const path = 'MuqeemOperationLogReport';
const apiPath = '/hr/report/muqeem/doso-operations-report';

const MuqeemOperationLogReportActions = {
   RESET_EDITABLE: `${path}/resetEditable`,
   GENERATE_REPORT: `${path}/generateReport`,
};

const MuqeemOperationLogReportAPIsConstants = {
   API_PATH: `${apiPath}`,
};

export { MuqeemOperationLogReportAPIsConstants, MuqeemOperationLogReportActions };
