const path = 'ExpiryCustomsBrokerReportModule';
const apiPath = 'customs/report';

const ExpiryCustomsBrokerReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_ExpiryCustomsBroker_REPORT : `${path}/generateDetailedExpiryCustomsBrokerReport`,
};

const ExpiryCustomsBrokerReportAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

export {
  ExpiryCustomsBrokerReportAPIsConstants,
  ExpiryCustomsBrokerReportActions,
};
