const path = 'DelayRequestReportModule';
const apiPath = 'hr/attendance/report';

const DelayRequestReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  GET_MY_REPORT: `${path}/getMyReport`,
  // GENERATE_DETAILED_AttendanceDeparture_REPORT : `${path}/generateDetailedDelayRequestReport`,
}

const DelayRequestReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {DelayRequestReportAPIsConstants, DelayRequestReportActions };
