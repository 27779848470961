import DownloadService from '@/core/services/DownloadService';
import axios from 'axios';
import { JournalEntriesAPIsConstants } from '../enums/JournalEntriesEnums';

const JournalEntriesModule = {
   namespaced: true,
   state: {
      dataTable: [],
      helper: {},
      extraData: {},
      roleName: '',
      loading: false,
      loadingItem: false,
      itemEditable: {},
      crudLoading: false,
      crudFilters: {},
   },
   mutations: {
      SET_DATA_TABLE(state, payload) {
         state.dataTable = payload.data;
      },
      SET_HELPER(state, payload) {
         state.helper = payload;
      },
      SET_EXTRA_DATA(state, payload) {
         state.extraData = payload;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_EDITABLE_ID(state, id) {
         state.editableId = id;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         let arr = [];
         const itemEditable = payload.itemEditable.entry;

         if (itemEditable.translations) {
            itemEditable.translations.map((lang) => {
               arr[lang.locale] = lang;
            });
            itemEditable['translations'] = arr;
         }

         let lvl = 0;
         arr = [];

         function iter(el) {
            if (el.tree.length == 0) {
               el['lvl'] = lvl + 1;
               el['children'] = el.tree;
            } else {
               lvl++;
               el['lvl'] = lvl;
               el['children'] = el.tree;
               el.tree.map((child) => iter(child));
               lvl--;
            }
         }

         itemEditable.tree.map((row) => {
            lvl = 0;
            iter(row);
         });
         itemEditable['lvl'] = 0;
         itemEditable['children'] = itemEditable.tree;

         state.itemEditable = payload.itemEditable;
      },
      RESET_EDITABLE(state) {
         state.loading = false;
         state.itemEditable = {};
         state.editableId = null;
      },
      SET_ROLE_NAME(state, payload) {
         state.roleName = payload;
      },
      SET_FILTERS(state, payload) {
         state.crudFilters = payload;
      },
      RESET_FILTERS(state, payload) {
         state.crudFilters = {};
      },
   },
   actions: {
      setFilters({ commit }, payload) {
         commit('SET_FILTERS', payload);
      },
      resetFilters({ commit }, payload) {
         commit('RESET_FILTERS', payload);
      },
      setRoleName({ commit }, payload) {
         commit('SET_ROLE_NAME', payload);
      },
      setEditableId({ commit }, id) {
         commit('SET_EDITABLE_ID', id);
      },
      resetEditable({ commit }) {
         commit('RESET_EDITABLE');
      },
      getJournalEntries({ commit }, data) {
         commit('SET_LOADING', true);

         const filters = data?.filters ? data.filters : {};
         commit('SET_FILTERS', filters);

         axios
            .get(`/accounting/accounts/tree-list`, {
               params: {
                  ...filters,
               },
            })
            .then((res) => {
               console.log('getJournalEntries', res);
               commit('SET_DATA_TABLE', { data: res.data.result.account });
               commit('SET_HELPER', res.data.result?.helper);
               commit('SET_EXTRA_DATA', res.data.result?.extra_data);
               commit('SET_LOADING', false);
            })
            .catch((error) => {
               console.log(error);
               commit('SET_LOADING', false);
            });
      },
      exportAsExcel({ commit }) {
         commit('SET_LOADING', true);

         return DownloadService.ddd('/accounting/accounts/tree-list', {
            exportAsExcel: 1,
            excel: 1,
         }).finally(() => {
            commit('SET_LOADING', false);
         });
      },
      deleteTree({ commit, dispatch }) {
         commit('SET_LOADING', true);

         return axios
            .get(JournalEntriesAPIsConstants.DELETE_TREE)
            .then(() => {
               dispatch('getJournalEntries');
            })
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
      seedTree({ commit }, data) {
         commit('SET_LOADING', true);
         const filters = data?.filters ? data.filters : {};

         return new Promise((resolve, reject) => {
            axios
               .get(`/accounting/accounts/seed-account-tree`, { params: { ...filters } })
               .then((res) => {
                  commit('SET_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      findItemById({ commit, state }, data) {
         if (!state.editableId) {
            return;
         }
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${JournalEntriesAPIsConstants.SHOW_ENTRY}/${state.editableId}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);

                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      getAccountInitInfo({ commit, state }, params) {
         return new Promise((resolve, reject) => {
            axios
               .get(`/accounting/accounts/get-account-init-info`, {
                  params: {
                     parent_id: params.id,
                  },
               })
               .then((response) => {
                  resolve(response.data.result);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      addJournalEntries({ commit }, { credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`/accounting/accounts/add-entry`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateJournalEntries({ commit, state }, { credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`/accounting/accounts/update-entry/${state.editableId}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit }, { id }) {
         commit('SET_LOADING', true);

         return axios.delete(`/accounting/accounts/delete-entry/${id}`).finally(() => {
            commit('SET_LOADING', false);
         });
      },
   },
};
export default JournalEntriesModule;
