import { RouteRecordRaw } from 'vue-router';

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      children: [
         {
            path: 'assets-additions',
            name: 'assets-additions-reports',
            meta: {
               title: 'Assets Additions Voucher',
               permission: 'asset_reports',
            },
            component: () =>
               import('@/views/modules/assets/views/reports/assets-additions/base.vue'),
         },
         {
            path: 'assets-disposes',
            name: 'assets-disposes-reports',
            meta: {
               title: 'Disposes',
               permission: 'asset_reports',
            },
            component: () => import('@/views/modules/assets/views/reports/assets-dispose/base.vue'),
         },
         {
            path: 'assets-value-changes',
            name: 'assets-value-changes-reports',
            meta: {
               title: 'Assets Value changes',
               permission: 'asset_reports',
            },
            component: () =>
               import('@/views/modules/assets/views/reports/assets-value-changes/base.vue'),
         },
         {
            path: 'maintenance',
            name: 'maintenance-report',
            meta: {
               title: 'Maintenance',
               permission: 'asset_reports',
            },
            component: () =>
               import('@/views/modules/assets/views/reports/maintenance-table/base.vue'),
         },
         {
            path: 'reevaluation',
            name: 'reevaluation-report',
            meta: {
               title: 'Assets Reevaluation',
               permission: 'asset_reports',
            },
            component: () => import('@/views/modules/assets/views/reports/reevaluations/base.vue'),
         },
         {
            path: 'asset-info',
            name: 'asset-info',
            meta: {
               title: 'Asset Info',
               permission: 'asset_reports',
            },
            component: () => import('@/views/modules/assets/views/reports/asset-info/base.vue'),
         },
         {
            path: 'asset-value',
            name: 'asset-value',
            meta: {
               title: 'Asset Value',
               permission: 'asset_reports',
            },
            component: () => import('@/views/modules/assets/views/reports/asset-value/base.vue'),
         },
      ],
   },
];

export default reportRouter;
