import HrSettingModule from './modules/HrSettingModule.js';
import HrDashboardModule from './modules/HrDashboardModule.js';
import HrEmploymentRequestModule from './modules/HrEmploymentRequestModule.js';
import HrContractMovementsModule from './modules/HrContractMovementsModule.js';
import HrDocumentMovementsModule from './modules/HrDocumentMovementsModule.js';
import LeaveSettlementModule from './modules/LeaveSettlementModule';
import PenaltiesModule from './modules/PenaltiesModule';
import MovementsModule from './modules/MovementsModule';
import VacationModule from './modules/VacationModule';
import TerminationModule from './modules/TerminationModule';
import CodeTransactionsModule from './modules/CodeTransactionsModule';
import HrEmployeesEvaluationsMovementsModule from './modules/HrEmployeesEvaluationsMovementsModule';
import EmployeesCustodyInfoModule from './modules/EmployeesCustodyInfoModule';
import EmployeesPersonalInfoModule from './modules/EmployeesPersonalInfoModule';
import EmployeesInfoModule from './modules/EmployeesInfoModule';
import EmployeeDetailsModule from './modules/EmployeeDetailsModule';
import EmployeesPenaltyInfoModule from './modules/EmployeesPenaltyInfoModule';
import EmployeesLeavesInfoModule from './modules/EmployeesLeavesInfoModule';
import EmployeesMovementsInfoModule from './modules/EmployeesMovementsInfoModule';
import EmployeesDocumentsInfoModule from './modules/EmployeesDocumentsInfoModule';
import EmployeesEvaluationInfoModule from './modules/EmployeesEvaluationInfoModule';
import EmployeesTerminationInfoModule from './modules/EmployeesTerminationInfoModule';
import EmployeesLeaveSettlmentInfoModule from './modules/EmployeesLeaveSettlmentInfoModule';
import EmployeesCareerInfoModule from './modules/EmployeesCareerInfoModule';
import HrEmployeeModule from './modules/HrEmployeeModule';
import SanctionsModule from './modules/crud/SanctionsModule';
import MuqeemModule from './modules/MuqeemModule';
import ExitReenterModule from './modules/ExitReenter.js';
import FinalExitModule from './modules/dailyMovements/FinalExit';
import MuqeemEmployeesInfo from './modules/muqeem-reports/EmployeesInfo';
import MuqeemOperationLogReport from './modules/muqeem-reports/OperationLogReport';
import OperationLogFromMuqeemModule from './modules/muqeem-reports/OperationLogFromMuqeemModule';
import Passport from './modules/Passport';
import UpdateEmployeeJob from './modules/UpdateEmployeeJob';
import Iqama from './modules/Iqama';

const hrStore = {
   HrSettingModule,
   HrDashboardModule,
   HrEmployeeModule,
   SanctionsModule,
   HrEmploymentRequestModule,
   MovementsModule,
   PenaltiesModule,
   LeaveSettlementModule,
   VacationModule,
   HrContractMovementsModule,
   HrDocumentMovementsModule,
   TerminationModule,
   CodeTransactionsModule,
   HrEmployeesEvaluationsMovementsModule,
   EmployeesCustodyInfoModule,
   EmployeesPersonalInfoModule,
   EmployeesInfoModule,
   EmployeeDetailsModule,
   EmployeesPenaltyInfoModule,
   EmployeesLeavesInfoModule,
   EmployeesMovementsInfoModule,
   EmployeesDocumentsInfoModule,
   EmployeesEvaluationInfoModule,
   EmployeesTerminationInfoModule,
   EmployeesLeaveSettlmentInfoModule,
   EmployeesCareerInfoModule,
   MuqeemModule,
   ExitReenterModule,
   FinalExitModule,
   MuqeemEmployeesInfo,
   MuqeemOperationLogReport,
   OperationLogFromMuqeemModule,
   Passport,
   UpdateEmployeeJob,
   Iqama,
};

export default hrStore;
