import { RouteRecordRaw } from 'vue-router';
import settingRouter from './settingRouter';
import reportRouter from './reportRouter';

const restaurantsRouters: Array<RouteRecordRaw> = [
  ...settingRouter,
  ...reportRouter,
];

export default restaurantsRouters;
