const path = "AnalyzeTrialBalanceByTotalReportModule";
const apiPath = "/accounting/report";

const AnalyzeTrialBalanceByTotalReportModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/analyzeTrialBalanceByTotalReport`,
};

const AnalyzeTrialBalanceByTotalReportAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

export {
  AnalyzeTrialBalanceByTotalReportAPIsConstants,
  AnalyzeTrialBalanceByTotalReportModelActions,
};
