const PATH = '/assets-management';

const AssetsMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 50,
      pages: [
         {
            heading: 'Assets Settings',
            route: `${PATH}/settings`,
            permission: 'asset_setting',
         },
         {
            heading: 'Assets Categories',
            route: `${PATH}/assets-categories`,
            permission: 'asset_categories',
         },
         {
            heading: 'Assets',
            route: `${PATH}/assets`,
            permission: 'products',
         },
         {
            heading: 'Work Locations',
            route: `${PATH}/work-locations`,
            permission: 'asset_locations',
         },
         {
            heading: 'Acquisition Methods',
            route: `${PATH}/acquisition-methods`,
            permission: 'asset_acquisition_methods',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '/daily-transactions',
      module_id: 50,
      name: 'daily_movements',
      pages: [
         {
            heading: 'Opening Assets',
            route: `${PATH}/opening-assets`,
            permission: 'asset_openings',
         },
         {
            heading: 'Acquire Assets',
            route: `${PATH}/acquire-assets`,
            permission: 'asset_acquire_movements',
         },
         {
            heading: 'Deprecation',
            route: `${PATH}/deprecations`,
            permission: 'asset_maintenances',
         },
         {
            heading: 'Transferring Asset Voucher',
            route: `${PATH}/transferring-asset`,
            permission: 'asset_transfers',
         },
         {
            heading: 'Maintenance',
            route: `${PATH}/maintenance`,
            permission: 'asset_maintenances',
         },
         {
            heading: 'Assets Additions Voucher',
            route: `${PATH}/additions`,
            permission: 'asset_addition_movements',
         },
         {
            heading: 'Dispose Asset Document',
            route: `${PATH}/dispose-asset`,
            permission: 'asset_disposes',
         },
         {
            heading: 'Asset Reevaluation Document',
            route: `${PATH}/assets-reevaluations`,
            permission: 'asset_re_evaluations',
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Assets Additions',
            route: `${PATH}/reports/assets-additions`,
            permission: 'asset_reports',
         },
         {
            heading: 'Disposes',
            route: `${PATH}/reports/assets-disposes`,
            permission: 'asset_reports',
         },
         {
            heading: 'Assets Value changes',
            route: `${PATH}/reports/assets-value-changes`,
            permission: 'asset_reports',
         },
         {
            heading: 'Maintenance',
            route: `${PATH}/reports/maintenance`,
            permission: 'asset_reports',
         },
         {
            heading: 'Assets Reevaluation',
            route: `${PATH}/reports/reevaluation`,
            permission: 'asset_reports',
         },
         {
            heading: 'Asset Info',
            route: `${PATH}/reports/asset-info`,
            permission: 'asset_reports',
         },
         {
            heading: 'Asset Value',
            route: `${PATH}/reports/asset-value`,
            permission: 'asset_reports',
         },
      ],
   },
];
export default AssetsMenuConfig;
