const path = 'LedgerReportModule';
const apiPath = '/accounting/report';

const LedgerReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_LEDGER_REPORT : `${path}/generateLedgerReport`,
  GENERATE_DETAILED_LEDGER_REPORT : `${path}/generateDetailedLedgerReport`,
}

const LedgerReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {LedgerReportAPIsConstants, LedgerReportModelActions };
