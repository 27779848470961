import axios from 'axios';
import JwtService from '@/core/services/JwtService';

const TableModule = {
   namespaced: true,

   state: {
      list: {},
      cacheLists: {},
   },

   mutations: {
      SET_LIST(state, payload) {
         state.list[payload.listName] = payload.list;
         state.cacheLists[payload.listName + JSON.stringify(payload.filters)] = payload.list;
      },
      RESET_LIST(state, payload) {
         state.list[payload.listName] = [];
      },
   },

   actions: {
      resetList({ commit }, options) {
         let listName = typeof options === 'object' ? options.name : options;
         commit('RESET_LIST', { listName: listName });
      },

      getListByName({ commit, state }, options) {
         let listName = typeof options === 'object' ? options.name : options;
         var path = '';

         if (JwtService.isAdminDomain()) {
            path = 'admin-panal/common/list/';
         } else {
            path = 'core/common/list/';
         }

         if (listName === 'getConstList') path = 'core/common/';

         if (
            !options.disableCache &&
            listName + JSON.stringify(options.filters ?? {}) in state.cacheLists
         ) {
            state.list[listName] =
               state.cacheLists[listName + JSON.stringify(options.filters ?? {})];

            return {
               data: {
                  result: {
                     list: state.cacheLists[listName + JSON.stringify(options.filters ?? {})],
                  },
               },
            };
         }

         return new Promise((resolve, reject) => {
            axios
               .get(path + listName, {
                  params: {
                     filters: options.filters ?? {},
                     columns: options.columns ?? {},
                     id: options.id ?? null,
                     ...(options.params || {}),
                  },
               })
               .then((res) => {
                  commit('SET_LIST', {
                     listName,
                     list:
                        listName === 'getConstList' ? res.data.result.lists : res.data.result.list,
                     filters: options.filters ?? {},
                  });
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      getBulkListByName({ commit, state }, { lists = [], filters = {}, params }) {
         let path = 'core/common/list/bulklists?';

         return new Promise((resolve, reject) => {
            axios
               .get(path + lists.map((n, index) => `lists[${index}]=${n}`).join('&'), { params })
               .then((res) => {
                  Object.entries(res.data.result.lists).map(([listName, listValue]) => {
                     commit('SET_LIST', {
                        listName,
                        list: listValue,
                        filters: filters,
                     });
                  });
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      getExternalListByName({ commit, state }, options) {
         let listName = typeof options === 'object' ? options.name : options;
         var path = '';

         path = 'core/common/external-list/';

         if (listName === 'external-get-const-list') path = 'core/common/';

         return new Promise((resolve, reject) => {
            axios
               .get(path + listName, {
                  params: {
                     filters: options.filters ?? {},
                     columns: options.columns ?? {},
                     id: options.id ?? null,
                  },
               })
               .then((res) => {
                  commit('SET_LIST', {
                     listName: listName === 'external-get-const-list' ? 'getConstList' : listName,
                     list:
                        listName === 'external-get-const-list'
                           ? res.data.result.lists
                           : res.data.result.list,
                     filters: options.filters ?? {},
                  });
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      getBulkExternalListByName({ commit, state }, { lists = [], filters = {} }) {
         let path = 'core/common/external-list/bulklists?';

         return new Promise((resolve, reject) => {
            axios
               .get(path + lists.map((n, index) => `lists[${index}]=${n}`).join('&'), {})
               .then((res) => {
                  Object.entries(res.data.result.lists).map(([listName, listValue]) => {
                     commit('SET_LIST', {
                        listName,
                        list: listValue,
                        filters: filters,
                     });
                  });
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      clearCache({ commit, state }) {
         state.cacheLists = {};
      },
   },

   getters: {
      getListByName: (state) => (name) => {
         return state.list?.[name] ?? [];
      },
   },
};
export default TableModule;
