
import WarehouseSettingModule from './modules/WarehouseSettingModule';
import InventoryNoticeModule from './modules/InventoryNoticeModule';
import PurchaseReportModule from './modules/PurchaseReportModule';
import StocktakingReportModule from './modules/StocktakingReportModule';
import WarehouseDashboardModule from './modules/WarehouseDashboardModule';
import WarehouseReportModule from './modules/WarehouseReportModule.js';
import WarehouseJournalEntryPostingsModule from './modules/WarehouseJournalEntryPostingsModule.js';

const warehouseStore = {
    InventoryNoticeModule,
    WarehouseSettingModule,
    StocktakingReportModule,
    PurchaseReportModule,
    WarehouseReportModule,
    WarehouseDashboardModule,
    WarehouseJournalEntryPostingsModule,
};

export default warehouseStore;
