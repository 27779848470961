import { RouteRecordRaw } from "vue-router";
import settingRouter from "./settingRouter";
import reportsRouter from "./reportsRouter";
import dailyMovementRouter from "./dailyMovementsRouter";

const AttendanceRouters: Array<RouteRecordRaw> = [
  ...settingRouter,
  ...reportsRouter,
  ...dailyMovementRouter,
];

export default AttendanceRouters;
