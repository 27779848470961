const apiPath = '/hr/attendance/hr-delay-requests';
const path = 'DelayRequestModule';
const DelayRequestAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const DelayRequestActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  APPROVE_ITEM: `${path}/approveItem`,
  UNAPPROVE_ITEM: `${path}/unapproveItem`,
};

export { DelayRequestAPIsConstants, DelayRequestActions };
