import { RouteRecordRaw } from 'vue-router';

const reportsRouter: Array<RouteRecordRaw> = [
   {
      path: '/hr-management/documents-need-renew',
      name: 'documents-need-renew-list',
      meta: {
         permissions: 'hr_documents',
         title: 'documentsNeedRenew',
      },
      component: () => import('@/views/modules/hr/views/reports/documents-need-renew/List.vue'),
   },
   {
      path: '/hr-management/employees-custody-info',
      name: 'employees-custody-info',
      meta: {
         permissions: 'hr_custodies',
         title: 'Custodies',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-custody-info/base.vue'),
   },
   {
      path: '/hr-management/employees-personal-info',
      name: 'employees-personal-info',
      meta: {
         permissions: 'hr_reports',
         title: 'Personal Employee Info',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-personal-info/base.vue'),
   },
   {
      path: '/hr-management/employees-info',
      name: 'employees-info',
      meta: {
         permissions: 'hr_reports',
         title: 'employeesInfo',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-info/base.vue'),
   },
   {
      path: '/hr-management/employee-details',
      name: 'employee-details',
      meta: {
         permissions: 'hr_reports',
         title: 'Employee Detailed Info',
      },
      component: () => import('@/views/modules/hr/views/reports/employee-details/base.vue'),
   },
   {
      path: '/hr-management/employees-penalty-info',
      name: 'employees-penalty-info',
      meta: {
         permissions: 'hr_reports',
         title: 'penalties',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-penalty-info/base.vue'),
   },
   {
      path: '/hr-management/employees-leaves-info',
      name: 'employees-leaves-info',
      meta: {
         permissions: 'hr_reports',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-leaves-info/base.vue'),
   },
   {
      path: '/hr-management/employees-transactions-info',
      name: 'employees-transactions-info',
      meta: {
         permissions: 'hr_reports',
         title: 'Transfer Transactions',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-movements-info/base.vue'),
   },
   {
      path: '/hr-management/employees-documents-info',
      name: 'employees-documents-info',
      meta: {
         permissions: 'hr_reports',
         title: 'Documents',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-documents-info/base.vue'),
   },
   {
      path: '/hr-management/employees-evaluation-info',
      name: 'employees-evaluation-info',
      meta: {
         permissions: 'hr_reports',
         title: 'employeesEvaluation',
      },
      component: () =>
         import('@/views/modules/hr/views/reports/employees-evaluation-info/base.vue'),
   },
   {
      path: '/hr-management/employees-termination-info',
      name: 'employees-termination-info',
      meta: {
         permissions: 'hr_reports',
         title: 'employeesTermination',
      },
      component: () =>
         import('@/views/modules/hr/views/reports/employees-termination-info/base.vue'),
   },
   {
      path: '/hr-management/employees-info-muqeem',
      name: 'employees-info-muqeem',
      meta: {
         permissions: 'hr_reports',
         title: 'Employees Info (Muqeem)',
      },
      component: () => import('@/views/modules/hr/views/reports/muqeem/employees-info/Base.vue'),
   },
   {
      path: '/hr-management/muqeem-operations-log',
      name: 'muqeem-operations-log',
      meta: {
         permissions: 'hr_reports',
         title: 'Muqeem Operations Log',
      },
      component: () => import('@/views/modules/hr/views/reports/muqeem/doso-operations-report/base.vue'),
   },
   {
      path: '/hr-management/operations-log-from-muqeem',
      name: 'operations-log-from-muqeem',
      meta: {
         permissions: 'hr_reports',
         title: 'Operations Log From Muqeem',
      },
      component: () => import('@/views/modules/hr/views/reports/muqeem/operations-log-from-muqeem-report/base.vue'),
   },
   {
      path: '/hr-management/employees-leave-settlment-info',
      name: 'employees-leave-settlment-info',
      meta: {
         permissions: 'hr_reports',
         title: 'leaveSettlement',
      },
      component: () =>
         import('@/views/modules/hr/views/reports/employees-leave-settlment-info/base.vue'),
   },
   {
      path: '/hr-management/employees-career-info',
      name: 'employees-career-info',
      meta: {
         permissions: 'hr_reports',
         title: 'employeesCareerInfo',
      },
      component: () => import('@/views/modules/hr/views/reports/employees-career-info/base.vue'),
   },
];

export default reportsRouter;
