import { RouteRecordRaw } from 'vue-router';

const dailyMovementsRouter: Array<RouteRecordRaw> = [
   {
      path: 'fund',
      children: [
         {
            path: '',
            name: 'pos-fund',
            meta: {
               permission: 'pos_fund_movements',
               title: 'Start End Shift',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/fund/List.vue'),
         },
         {
            path: 'open',
            name: 'open-pos-fund',
            meta: {
               permission: 'pos_fund_movements',
               title: 'Start Shift',
               type: 'write',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/fund/Add.vue'),
         },
         {
            path: 'close/:id',
            name: 'close-fund',
            meta: {
               permission: 'pos_fund_movements',
               title: 'End Shift',
               type: 'write',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/fund/Close.vue'),
         },
         {
            path: ':id/:type',
            name: 'pos-view-fund',
            meta: {
               permission: 'pos_fund_movements',
               title: 'Start End Shift',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/fund/Add.vue'),
         },
      ],
   },
   {
      path: 'drafts',
      children: [
         {
            path: '',
            name: 'pos-drafts',
            meta: {
               permission: 'pos_invoice_sales_drafts',
               title: 'Drafts',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/draft/List.vue'),
         },
         {
            path: ':id',
            name: 'pos-edit-draft',
            meta: {
               permission: 'pos_invoice_sales_drafts',
               title: 'Drafts',
            },
            component: () => import('@/views/modules/pos/views/daily-movements/draft/Edit.vue'),
         },
      ],
   },
   {
      path: 'payment-voucher',
      name: 'pos-payment-voucher',
      meta: {
         permission: 'pos_expenses',
         title: 'Expenses',
      },
      component: () =>
         import('@/views/modules/pos/views/daily-movements/payment-voucher/List.vue'),
   },
   {
      path: 'payment-voucher/:id/:view',
      name: 'pos-payment-voucher-view',
      meta: {
         permission: 'pos_expenses',
         title: 'Expenses',
      },
      component: () =>
         import('@/views/modules/casher/views/crud/payment-voucher/Add.vue'),
   },
];

export default dailyMovementsRouter;
