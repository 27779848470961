import { RouteRecordRaw } from 'vue-router';

const inventoryRouter: Array<RouteRecordRaw> = [
   //Inventory Notice
   {
      path: 'inventory/add-notice',
      name: 'inventory-add-notice-list',
      meta: {
         permission: 'add_notices',
         title: 'Stock Receipt Voucher',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/add-notice/List.vue'),
   },
   {
      path: 'inventory/add-notice/add',
      name: 'inventory-add-notice-add',
      meta: {
         permission: 'add_notices',
         title: 'Stock Receipt Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/add-notice/Add.vue'),
   },
   {
      path: 'inventory/add-notice/edit/:id',
      name: 'inventory-add-notice-edit',
      meta: {
         permission: 'add_notices',
         title: 'Stock Receipt Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/add-notice/Add.vue'),
   },
   {
      path: 'inventory/add-notice/:view/:id',
      name: 'inventory-add-notice-view',
      meta: {
         permission: 'add_notices',
         title: 'Stock Receipt Voucher',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/add-notice/Add.vue'),
   },
   //return-add-notice
   {
      path: 'inventory/return-add-notice',
      name: 'inventory-return-add-notice-list',
      meta: {
         permission: 'return_add_notices',
         title: 'Return receipt voucher',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-add-notice/List.vue'),
   },
   {
      path: 'inventory/return-add-notice/add',
      name: 'inventory-return-add-notice-add',
      meta: {
         permission: 'return_add_notices',
         title: 'Return receipt voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-add-notice/Add.vue'),
   },
   {
      path: 'inventory/return-add-notice/edit/:id',
      name: 'inventory-return-add-notice-edit',
      meta: {
         permission: 'return_add_notices',
         title: 'Return receipt voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-add-notice/Add.vue'),
   },

   {
      path: 'inventory/return-add-notice/:type/:id',
      name: 'inventory-return-add-notice-view',
      meta: {
         permission: 'return_add_notices',
         title: 'Return receipt voucher',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-add-notice/Add.vue'),
   },

   //dismissal-notice
   {
      path: 'inventory/dismissal-notice',
      name: 'inventory-dismissal-notice-list',
      meta: {
         permission: 'dismissal_notices',
         title: 'Stock Issue Voucher',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/dismissal-notice/List.vue'),
   },
   {
      path: 'inventory/dismissal-notice/add',
      name: 'inventory-dismissal-notice-add',
      meta: {
         permission: 'dismissal_notices',
         title: 'Stock Issue Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/dismissal-notice/Add.vue'),
   },
   {
      path: 'inventory/dismissal-notice/edit/:id',
      name: 'inventory-dismissal-notice-edit',
      meta: {
         permission: 'dismissal_notices',
         title: 'Stock Issue Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/dismissal-notice/Add.vue'),
   },
   {
      path: 'inventory/dismissal-notice/:view/:id',
      name: 'inventory-dismissal-notice-view',
      meta: {
         permission: 'dismissal_notices',
         title: 'Stock Issue Voucher',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/dismissal-notice/Add.vue'),
   },

   //return-dismissal-notice
   {
      path: 'inventory/return-dismissal-notice',
      name: 'inventory-return-dismissal-notice-list',
      meta: {
         permission: 'return_dismissal_notices',
         title: 'Return Issue Voucher',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-dismissal-notice/List.vue'),
   },
   {
      path: 'inventory/return-dismissal-notice/add',
      name: 'inventory-return-dismissal-notice-add',
      meta: {
         permission: 'return_dismissal_notices',
         title: 'Return Issue Voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-dismissal-notice/Add.vue'),
   },
   {
      path: 'inventory/return-dismissal-notice/edit/:id',
      name: 'inventory-return-dismissal-notice-edit',
      meta: {
         permission: 'return_dismissal_notices',
         title: 'Return Issue Voucher',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-dismissal-notice/Add.vue'),
   },
   {
      path: 'inventory/return-dismissal-notice/:type/:id',
      name: 'inventory-return-dismissal-notice-view',
      meta: {
         permission: 'return_dismissal_notices',
         title: 'Return Issue Voucher',
      },
      component: () =>
         import('@/views/modules/warehouse/views/inventory/return-dismissal-notice/Add.vue'),
   },
   //transfer-notice
   {
      path: 'inventory/transfer-notice',
      name: 'inventory-transfer-notice-list',
      meta: {
         permission: 'transfer_notices',
         title: 'Stock Transfer Voucher',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/transfer-notice/List.vue'),
   },
   {
      path: 'inventory/transfer-notice/add',
      name: 'inventory-transfer-notice-add',
      meta: {
         permission: 'transfer_notices',
         title: 'Stock Transfer Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/transfer-notice/Add.vue'),
   },
   {
      path: 'inventory/transfer-notice/edit/:id',
      name: 'inventory-transfer-notice-edit',
      meta: {
         permission: 'transfer_notices',
         title: 'Stock Transfer Voucher',
         type: 'write',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/transfer-notice/Add.vue'),
   },
   {
      path: 'inventory/transfer-notice/:type/:id',
      name: 'inventory-transfer-notice-view',
      meta: {
         permission: 'transfer_notices',
         title: 'Stock Transfer Voucher',
      },
      component: () => import('@/views/modules/warehouse/views/inventory/transfer-notice/Add.vue'),
   },
];

export default inventoryRouter;
