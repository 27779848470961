import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: "settings-electronic",
      name: "electronic-approval-general-settings",
      meta: {
         permission: "e_approval_setting",
         title: "Electronic Approval Settings",
      },
      component: () =>
          import(
              "@/views/modules/electronic-approvals/views/settings/index.vue"
              ),
   },
   {
      path: "transactions-settings",
      name: "electronic-approval-transaction-general-settings",
      meta: {
         permission: "e_approval_movements",
         title: "Transactions Settings",
      },
      component: () =>
          import("@/views/modules/electronic-approvals/views/settings/MovementsSettings/index.vue"),
   },
];

export default settingRouter;
