import SalariesSettingModule from './modules/SalariesSettingModule.js';
import EmployeesEarningsDeductionsModule from './modules/EmployeesEarningsDeductionsModule.js';
import TransferSalaryiesModule from './modules/TransferSalaryiesModule.js';
import TransferModule from './modules/TransferModule.js';
import CalculateSalariesModule from './modules/CalculateSalariesModule.js';
import CreateNewMonthModule from './modules/CreateNewMonthModule.js';
import InstallmentsModule from './modules/InstallmentsModule.js';
import EmployeeInstallmentsReportModule from './modules/reports/EmployeeInstallmentsReportModule.js';
import WageItemsReportModule from './modules/reports/WageItemsReportModule.js';
import EmployeePaySlipReportModule from './modules/reports/EmployeePaySlipReportModule.js';
import TotalSummarySalariesReportModule from './modules/reports/TotalSummarySalariesReportModule.js';
import EmployeesSalariesDistributedReportModule from './modules/reports/EmployeesSalariesDistributedReportModule.js';
import PostParolesModule from './modules/PostParolesModule.js';
import MigrationToAccounting from './modules/MigrationToAccounting';

const salariesStore = {
   PostParolesModule,
   EmployeesSalariesDistributedReportModule,
   TotalSummarySalariesReportModule,
   EmployeePaySlipReportModule,
   WageItemsReportModule,
   EmployeeInstallmentsReportModule,
   InstallmentsModule,
   CreateNewMonthModule,
   CalculateSalariesModule,
   TransferModule,
   TransferSalaryiesModule,
   EmployeesEarningsDeductionsModule,
   SalariesSettingModule,
   MigrationToAccounting,
};

export default salariesStore;
