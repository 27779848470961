import axios from 'axios';

const path = 'RestaurantsSettings';
const apiPath = '/restaurant/setting';

export const RestaurantsSettingActions = {
   GET_SETTINGS: `${path}/getSetting`,
   UPDATE_SETTINGS: `${path}/updateSetting`,
};

export const RestaurantsSettingAPIsConstants = {
   API_PATH: apiPath,
};

const RestaurantsSettings = {
   namespaced: true,
   state: {
      loading: false,
      crudLoading: false,
      itemEditable: {},
      settings: {},
      helper: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_SETTING(state, payload) {
         const data = payload.data;
         state.itemEditable = data.item;
         state.settings = data.item;
         state.has_purchase_notice = data.has_purchase_notice;
         state.has_sales_notice = data.has_sales_notice;
         state.company_setting = data.company_setting;
      },
      SET_HELPER(state, payload) {
         state.helper = payload.data;
      },
   },
   actions: {
      getSetting({ commit }) {
         commit('SET_LOADING', true);

         return axios
            .get(`${RestaurantsSettingAPIsConstants.API_PATH}/get-setting`)
            .then((res) => {
               commit('SET_SETTING', { data: res.data.result });
               commit('SET_HELPER', { data: res.data.result?.helper });
               return res;
            })
            .finally(() => commit('SET_LOADING', false));
      },
      updateSetting({ commit }, data) {
         commit('SET_CRUD_LOADING', true);

         return axios
            .post(`${RestaurantsSettingAPIsConstants.API_PATH}/update-setting`, data)
            .finally(() => commit('SET_CRUD_LOADING', false));
      },
   },
};

export default RestaurantsSettings;
