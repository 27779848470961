
import CustomsInvoiceModelModule from './modules/CustomsInvoiceModelModule';
import CustomsInvoiceModule from './modules/CustomsInvoiceModule';
import CustomsSettingsModule from './modules/CustomsSettingsModule';
import CustomsReportModule from './modules/CustomsReportModule';
import DashboardsModule from './modules/DashboardsModule'
import SalesFinancialTransactionModule from './modules/SalesFinancialTransactionModule';
import InvoicingJournalEntryPostingsModule from './modules/InvoicingJournalEntryPostingsModule';
import CustomsTransactionsModule from './modules/daily-movements/CustomsTransactionsModule.js';
import CustomsBrokersModule from './modules/daily-movements/CustomsBrokersModule.js';
import CustomsContainersModule from './modules/daily-movements/CustomsContainersModule.js';
import CustomsDocumentsModule from './modules/daily-movements/CustomsDocumentsModule.js';
import ClientDealsReportModule from './modules/reports/ClientDealsReportModule.js';
import DealsReportModule from './modules/reports/DealsReportModule.js';
import BrokerDealsReportModule from './modules/reports/BrokerDealsReportModule.js';
import ExpensesReportModule from './modules/reports/ExpensesReportModule.js';
import IncomesReportModule from './modules/reports/IncomesReportModule.js';
import CustomsInvoiceItemsModule from './modules/daily-movements/CustomsInvoiceItemsModule.js';
import CustomsExpensesIncomesModule from './modules/daily-movements/CustomsExpensesIncomesModule.js';
import CustomsDealsModule from './modules/daily-movements/CustomsDealsModule.js';
import ExternalCustomsDealsModule from './modules/daily-movements/ExternalCustomsDealsModule.js';
import ExternalCustomsBrokerDealsModule from './modules/daily-movements/ExternalCustomsBrokerDealsModule.js';
import ProceduresDealsModule from './modules/daily-movements/ProceduresDealsModule.js';
import DealProcedureModule from './modules/daily-movements/DealProcedureModule.js';
import CustomsTransferringLogModule from './modules/daily-movements/CustomsTransferringLogModule.js';

import ReceiptModule from "./modules/ReceiptModule.js";
import CustomsVoucherModule from "./modules/CustomsVoucherModule.js";
import ExpiryCustomsBrokerReportModule from './modules/reports/ExpiryCustomsBrokerReportModule';

const customsStore = {
    ReceiptModule,
    CustomsVoucherModule,
    CustomsInvoiceModelModule,
    CustomsSettingsModule,
    CustomsInvoiceModule,
    CustomsReportModule,
    DashboardsModule,
    SalesFinancialTransactionModule,
    InvoicingJournalEntryPostingsModule,
    CustomsTransactionsModule,
    CustomsBrokersModule,
    CustomsContainersModule,
    IncomesReportModule,
    ExpiryCustomsBrokerReportModule,
    ExpensesReportModule,
    BrokerDealsReportModule,
    DealsReportModule,
    ClientDealsReportModule,
    CustomsDocumentsModule,
    CustomsInvoiceItemsModule,
    CustomsExpensesIncomesModule,
    CustomsDealsModule,
    ExternalCustomsDealsModule,
    ExternalCustomsBrokerDealsModule,
    ProceduresDealsModule,
    DealProcedureModule,
    CustomsTransferringLogModule,
};

export default customsStore;
