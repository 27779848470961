const path = 'MuqeemModule';
const apiPath = '/hr/setting';

const MuqueemActions = {
   GET_DATA: `${path}/getData`,
   UPDATE_SETTINGS: `${path}/updateSetting`,
   GET_CITIES: `${path}/getCities`,
   GET_COUNTRIES: `${path}/getCountries`,
   GET_MARITAL_STATUSES: `${path}/getMaritalStatuses`,
};

const MuqueemAPIsConstants = {
   GET_SETTINGS: `${apiPath}/get-muqeem-setting`,
   UPDATE_SETTINGS: `${apiPath}/update-muqeem-setting`,
   GET_CITIES: `/hr/muqeem/lookups/cities`,
   GET_COUNTRIES: `/hr/muqeem/lookups/countries`,
   GET_MARITAL_STATUSES: `/hr/muqeem/lookups/marital-statuses`,
};

export { MuqueemAPIsConstants, MuqueemActions };
