import axios from 'axios';
import { EmployeesAPIsConstants } from '@/views/modules/hr/store/enums/crud/EmployeesEnums';

const HrEmployeeModule = {
   namespaced: true,
   state: {},
   mutations: {},
   actions: {
      getEmployeeInformation({ commit, state }, { id, transfer_type }) {
         return new Promise((resolve, reject) => {
            axios
               .get(`${EmployeesAPIsConstants.API_PATH}/get-employee-information/${id}`, {
                  params: {
                     transfer_type,
                  },
               })
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getEmployeeDocument(_, { employeeId, documentId }) {
         return axios
            .get(`${EmployeesAPIsConstants.EMPLOYEE_DOCUMENT(employeeId, documentId)}`)
            .then(({ data }) => data.result);
      },
   },
   getters: {},
};
export default HrEmployeeModule;
