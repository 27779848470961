import {ServicesEnumAPIsConstants} from "@/store/enums/ServicesEnum";
import axios from "axios";

const ServicesModule = {
    namespaced: true,
    state: {
        number: {},
    },
    mutations: {
        SET_NUMBER(state, payload) {
            state.number = payload;
        },
        RESET_NUMBER(state, payload) {
            state.number = {};
        },
    },
    actions: {
        resetNumber({commit}) {
            commit('RESET_NUMBER');
        },
        getNumberAsText({commit}, number) {
            return new Promise((resolve, reject) => {
                axios
                    .get(ServicesEnumAPIsConstants.GET_NUMBER_AS_TEXT, {params: {number}})
                    .then((response) => {
                        commit('SET_NUMBER', response?.data?.result?.number);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
};
export default ServicesModule;
