import axios from 'axios';
import {JournalEntryDailyAPIsConstants} from "@/views/modules/accounting/store/enums/daily-movements/JournalEntryEnums";
import {compileScript} from "@vue/compiler-sfc";

const JournalEntryTemplateModule = {
    namespaced: true,
    state: {
        itemEditable: {},
        initData: {},
        detailsTable: [],
        detailsTableLoading: false,
        dataName: '',
        loading: false,
        crudLoading: false,
        loadingItem: false,
        editableId: null,
        isPagination: true,
        isCloseModal: false,
    },
    mutations: {
        SET_DATA_Name(state, payload) {
            state.dataName = payload;
        },
        SET_ITEM_EDITABLE(state, payload) {
            let arr = [];

            if (payload.itemEditable?.item?.translations) {
                payload.itemEditable.item.translations.map((lang) => {
                    arr[lang.locale] = lang;
                });
                payload.itemEditable.item['translations'] = arr;
            }

            state.itemEditable = payload.itemEditable;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_CRUD_LOADING(state, payload) {
            state.crudLoading = payload;
        },
        SET_LOADING_ITEM(state, payload) {
            state.loadingItem = payload;
        },
        RESET_ALL(state) {
            state.dataName = '';
            state.loading = false;
            state.itemEditable = {};
        },
        RESET_EDITABLE(state) {
            state.loading = false;
            state.itemEditable = {};
            state.editableId = null;
        },
        SET_EDITABLE_ID(state, id) {
            state.editableId = id;
        },
        SET_IS_CLOSE_MODAL(state, payload) {
            state.isCloseModal = payload;
        },
        SET_FILTERS(state, payload) {
            state.tableFilters = payload.filters;
        },
        SET_DETAILS_TABLE(state, payload) {
            state.detailsTable = payload ?? [];
        },
        SET_DETAILS_TABLE_LOADING(state, payload) {
            state.detailsTableLoading = payload;
        },
    },
    actions: {
        setDetailsTable({ commit }, payload) {
            commit('SET_DETAILS_TABLE', payload);
        },
        setDetailsTableLoading({ commit }, payload) {
            commit('SET_DETAILS_TABLE_LOADING', payload);
        },
        setDataName({ commit }, name) {
            commit('SET_DATA_Name', name);
        },
        setIsCloseModal({ commit }, payload) {
            commit('SET_IS_CLOSE_MODAL', payload);
        },
        setEditableId({ commit }, id) {
            commit('SET_EDITABLE_ID', id);
        },
        reset({ commit }) {
            commit('RESET_ALL');
        },
        resetEditable({ commit }) {
            commit('RESET_EDITABLE');
        },

        findItemById({ commit, state }, { apiPath, id }) {
            commit('SET_LOADING_ITEM', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${apiPath}/${id}`)
                    .then((res) => {
                        commit('SET_LOADING_ITEM', false);
                        commit('SET_ITEM_EDITABLE', {
                            itemEditable: res.data.result,
                        });
                        resolve(res);
                    })
                    .catch((error) => {
                        commit('SET_LOADING_ITEM', false);
                        reject(error);
                    });
            });
        },

        getReceiptDetails({ commit, state }, { transaction_type, transaction_id }) {
            commit('SET_DETAILS_TABLE_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${JournalEntryDailyAPIsConstants.API_PATH}/get-receipt-details`,{
                            transaction_type: transaction_type,
                            transaction_id: transaction_id
                    })
                    .then((res) => {
                        commit('SET_DETAILS_TABLE_LOADING', false);
                        commit('SET_DETAILS_TABLE', res.data.result?.details);
                        resolve(res);
                    })
                    .catch((error) => {
                        commit('SET_DETAILS_TABLE_LOADING', false);
                        reject(error);
                    });
            });
        },

        getReverseJournalEntry({ commit, state }, journalId) {
            commit('SET_DETAILS_TABLE_LOADING', true);
            commit('SET_LOADING_ITEM', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${JournalEntryDailyAPIsConstants.API_PATH}/get-reverse-journal-entry/${journalId}`)
                    .then((res) => {
                        console.log("res - getReceiptDetails",res.data.result?.journal_entry?.details);
                        commit('SET_DETAILS_TABLE_LOADING', false);
                        commit('SET_LOADING_ITEM', false);
                        // commit('SET_DETAILS_TABLE', res.data.result?.journal_entry?.details);
                        commit('SET_ITEM_EDITABLE', {
                            itemEditable: res.data.result,
                        });
                        resolve(res);
                    })
                    .catch((error) => {
                        commit('SET_DETAILS_TABLE_LOADING', false);
                        commit('SET_LOADING_ITEM', false);
                        reject(error);
                    });
            });
        },
        getInitData({ commit, dispatch,state }, { apiPath, credentials }) {
            commit('SET_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${apiPath}/get-init-data`, {
                        params: credentials,
                    })
                    .then((response) => {
                        commit('SET_LOADING', false);


                        state.initData = response.data.result;
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_LOADING', false);
                        reject(error);
                    });
            });
        },
        addItem({ commit }, { apiPath, credentials }) {
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${apiPath}`, credentials)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },

        updateItem({ commit, state }, { apiPath, credentials }) {
            commit('SET_CRUD_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .put(`${apiPath}/${credentials.id}`, credentials)
                    .then((response) => {
                        commit('SET_CRUD_LOADING', false);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_CRUD_LOADING', false);
                        reject(error);
                    });
            });
        },

        deleteItem({ commit, dispatch }, { apiPath, credentials }) {
            commit('SET_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${apiPath}/${credentials}`)
                    .then((response) => {
                        dispatch('getData', apiPath);
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_LOADING', false);
                        reject(error);
                    });
            });
        },

        getApprovedReceipt({ commit, dispatch,state }, receiptType) {
            commit('SET_LOADING', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${JournalEntryDailyAPIsConstants.API_PATH}/get-approved-receipt?receipt_type=${receiptType}`)
                    .then((response) => {
                        commit('SET_LOADING', false);
                        // state.initData = response.data.result;
                        resolve(response);
                    })
                    .catch((error) => {
                        commit('SET_LOADING', false);
                        reject(error);
                    });
            });
        },



    },
    getters: {
        findItemById: (state) => (id) => {
            return state.DataTable.find((x) => x.id === id);
        },
    },
};
export default JournalEntryTemplateModule;
