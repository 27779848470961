import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'restaurants-dashboard',
      name: 'restaurants-dashboard',
      meta: {
         title: 'RestaurantsModule',
      },
      component: () => import('@/views/modules/restaurants/views/dashboard/Index.vue'),
   },
   {
      path: 'setting',
      name: 'settings-restaurants',
      meta: {
         permission: 'restaurants_setting',
         title: 'Restaurants Settings',
      },
      component: () => import('@/views/modules/restaurants/views/settings/Setting.vue'),
   },
   {
      path: 'unit',
      name: 'restaurants-unit',
      meta: {
         permission: 'units',
         title: 'Units',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/unit/List.vue'),
   },
   {
      path: 'taxes',
      name: 'restaurants-taxes',
      meta: {
         permission: 'taxes',
         title: 'Taxes',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/taxes/List.vue'),
   },
   {
      path: 'salesman',
      name: 'restaurants-salesman',
      meta: {
         permission: 'salesmen',
         title: 'salesmans',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/salesman/List.vue'),
   },
   {
      path: 'client-group',
      name: 'restaurants-client-group',
      meta: {
         permission: 'client_groups',
         title: 'Clients Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client-group/List.vue'),
   },
   {
      path: 'clients',
      name: 'restaurants-clients',
      meta: {
         permission: 'clients',
         title: 'Clients',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client/List.vue'),
   },
   {
      path: 'branches',
      name: 'restaurants-branches',
      meta: {
         permission: 'branches',
         title: 'Branches',
      },
      component: () => import('@/views/modules/restaurants/views/crud/branches/List.vue'),
   },
   {
      path: 'meal-categories',
      name: 'restaurants-meal-categories',
      meta: {
         permission: 'restaurant_meal_categories',
         title: 'Meal Categories',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meal-categories/List.vue'),
   },
   {
      path: 'meal-modifiers-groups',
      name: 'restaurants-meal-modifiers-groups',
      meta: {
         permission: 'restaurant_meal_modifiers',
         title: 'Meal Modifiers Groups',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meal-modifiers-groups/List.vue'),
   },
   {
      path: 'meal-modifiers',
      name: 'restaurants-meal-modifiers',
      meta: {
         permission: 'restaurant_meal_modifier_options',
         title: 'Meal Modifiers',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meal-modifiers/List.vue'),
   },
   {
      path: 'meals',
      name: 'restaurants-meals',
      meta: {
         permission: 'restaurant_meals',
         title: 'Meals',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meals/List.vue'),
   },
   {
      path: 'meals/add',
      name: 'add-restaurants-meals',
      meta: {
         permission: 'restaurant_meals',
         title: 'Meals',
         type: 'write',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meals/Add.vue'),
   },
   {
      path: 'meals/:id/:type',
      name: 'restaurants-meal',
      meta: {
         permission: 'restaurant_meals',
         title: 'Meals',
      },
      component: () => import('@/views/modules/restaurants/views/crud/meals/Add.vue'),
   },
];

export default settingRouter;
