const path = "EmployeesPersonalInfoModule";
const apiPath = "/hr/report/employee/employee-personal-info-report";

const EmployeesPersonalInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesPersonalInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesPersonalInfoAPIsConstants, EmployeesPersonalInfoActions };
