const path = "TrialBalanceByTotalReportModule";
const apiPath = "/accounting/report";

const TrialBalanceByTotalModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_TRIAL_BALANCE_BY_TOTAL_REPORT: `${path}/trialBalanceByTotalReport`,
};

const TrialBalanceByAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

export { TrialBalanceByAPIsConstants, TrialBalanceByTotalModelActions };
