const path = 'StocktakingReportModule';
const apiPath = '/warehouse/report/stocktaking';

const StocktakingReportActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_STOCKTAKING_REPORT : `${path}/generateStocktakingReport`,
  GENERATE_ADDITION_SETTLEMENT_REPORT : `${path}/generateAdditionSettlementReport`,
  GENERATE_EXPENSE_SETTLEMENT_REPORT: `${path}/generateExpenseSettlementReport`,
  // GENERATE_PURCHASE_BUSINESS_MOVEMENT_REPORT : `${path}/generatePurchaseBusinessMovementReport`,
  // GENERATE_VENDOR_GROUP_DETAILED_PURCHASE_REPORT : `${path}/generateVendorGroupDetailedStocktakingReport`,
  // GENERATE_SALESMAN_DETAILED_PURCHASE_REPORT : `${path}/generateSalesmanDetailedStocktakingReport`,
  // GENERATE_SALESMAN_TOTAL_PURCHASE_REPORT : `${path}/generateSalesmanTotalStocktakingReport`,
  // GENERATE_VENDOR_ACCOUNT_STATEMENT_REPORT : `${path}/generateVendorAccountStatementReport`,

  // GENERATE_VENDOR_DETAILED_PURCHASE_REPORT : `${path}/generateVendorDetailedStocktakingReport`,
  // GENERATE_VENDOR_TOTAL_PURCHASE_REPORT : `${path}/generateVendorTotalStocktakingReport`,
}

const StocktakingReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {StocktakingReportAPIsConstants, StocktakingReportActions };
