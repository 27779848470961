const path = "EmployeesEvaluationInfoModule";
const apiPath = "/hr/report/movements/evaluation-info-report";

const EmployeesEvaluationInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesEvaluationInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesEvaluationInfoAPIsConstants, EmployeesEvaluationInfoActions };
