const path = 'CloseFiscalYearModule';
const apiPath = '/accounting/financial-year-management';

const CloseFiscalYearModelActions = {
  // action types
  CHECK_MIGRATED_ALL_JOURNAL_ENTRIES: `${path}/checkMigratedAllJournalEntries`,
  CHECK_UNBALANCED_ALL_JOURNAL_ENTRIES: `${path}/checkUnBalancedAllJournalEntries`,
  SHOW_ENTRIES_CLOSE_FINANCIAL_YEAR: `${path}/showEntriesCloseFinancialYear`,
  CLOSE_FINANCIAL_YEAR: `${path}/closeFinancialYear`,
  CHECK_APPROVED_ALL_VOUCHERS: `${path}/checkApprovedAllVouchers`,
}

const CloseFiscalYearAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { CloseFiscalYearAPIsConstants, CloseFiscalYearModelActions };
