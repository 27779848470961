import axios from 'axios';
import JwtService from "@/core/services/JwtService";

const apiPath = '/pos/info';
const path = 'POS';

export const PosAPIsConstants = {
   API_PATH: apiPath,
};

export const PosActions = {
   GET_DATA: `${path}/getData`,
   GET_DATA_LIST: `${path}/getDataList`,
   SET_CURRENT_POS_ID: `${path}/setCurrentPosId`,
};

export default {
   namespaced: true,
   state: {
      posData: null,
      currentPosId: JwtService.getPosId() ?? null,
   },
   mutations: {
      SET_POS_DATA(state, data) {
         state.posData = data;
      },
      SET_INVOICE_DATA(state, { key, val }) {
         state.invoiceData[key] = val;
      },
      SET_CURRENT_POS_ID(state, id) {
         state.currentPosId = id;
         JwtService.savePosId(id);
      },
   },
   actions: {
      /**
       * Get selected pos according to currentPosId
       * @param commit
       * @param state
       */
      getData({ commit, state }) {
         return new Promise((resolve, reject)=>{
            axios.get(PosAPIsConstants.API_PATH).then(({ data }) => {
               commit('SET_POS_DATA', data?.result?.item?.find(el=>el.id == state.currentPosId));
               resolve(data);
            }).catch((error) => {
               reject(error);
            });
         });
      },
      getDataList({ commit }) {
         return new Promise((resolve, reject)=>{
            axios.get(PosAPIsConstants.API_PATH).then(({ data }) => {
               resolve(data);
            }).catch((error) => {
               reject(error);
            });
         });
      },
      setCurrentPosId({commit}, id){
         commit('SET_CURRENT_POS_ID', id);
      }
   },
};
