const path = "EmployeesPenaltyInfoModule";
const apiPath = "/hr/report/movements/sanction-info-report";

const EmployeesPenaltyInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesPenaltyInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesPenaltyInfoAPIsConstants, EmployeesPenaltyInfoActions };
