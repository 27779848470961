import axios from 'axios';
import DownloadService from '@/core/services/DownloadService';

const GlobalReportModule = {
   namespaced: true,
   state: {
      itemEditable: {},
      loading: false,
   },
   mutations: {
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      RESET_ALL(state) {
         state.loading = false;
         state.itemEditable = {};
      },
   },
   actions: {
      reset({ commit }) {
         commit('RESET_ALL');
      },
      getReport({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);

         if (credentials.pdf == 1) {
            DownloadService.ddd(`${apiPath}`, credentials).then(() => commit('SET_LOADING', false));
            return;
         }

         return axios
            .get(`${apiPath}`, { params: credentials })
            .then((response) => {
               if (!credentials.excel)
                  commit('SET_ITEM_EDITABLE', { itemEditable: response.data.result });
               return response;
            })
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
   },
   getters: {},
};
export default GlobalReportModule;
