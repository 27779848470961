import axios from "axios";
import { EmployeesLeavesInfoAPIsConstants } from "@/views/modules/hr/store/enums/reports/EmployeesLeavesInfoEnums.ts";
import DownloadService from "@/core/services/DownloadService";

const EmployeesLeavesInfoModule = {
  namespaced: true,
  state: {
    loading: false,
    itemEditable: {},
    editableId: null,
  },

  mutations: {
    RESET_EDITABLE(state) {
      state.loading = false;
      state.itemEditable = {};
      state.editableId = null;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_ITEM_EDITABLE(state, payload) {
      state.itemEditable = payload.itemEditable;
    },
  },

  actions: {
    resetEditable({ commit }) {
      commit("RESET_EDITABLE");
    },

    generateReport({ commit }, credentials) {
      commit("SET_LOADING", true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(
          `${EmployeesLeavesInfoAPIsConstants.API_PATH}`,
          credentials
        ).then(() => commit("SET_LOADING", false));
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${EmployeesLeavesInfoAPIsConstants.API_PATH}`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit("SET_ITEM_EDITABLE", {
                itemEditable: response.data.result,
              });
            commit("SET_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_LOADING", false);
            reject(error);
          });
      });
    },
  },
};

export default EmployeesLeavesInfoModule;
