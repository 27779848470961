import axios from 'axios';

const apiPath = '/pos/sales-invoice';
const path = 'POSInvoice';

export const POSInvoiceAPIsConstants = {
   API_PATH: apiPath,
   API_DRAFTS: `${apiPath}/drafts`,
};

export const POSDraftInvoiceAPIsConstants = {
   API_PATH: `${apiPath}/drafts`,
};

export const POSInvoiceActions = {
   INSERT_PRODUCT: `${path}/insertProduct`,
   CLEAR_PRODUCT: `${path}/clearProduct`,
   SET_INVOICE_DATA: `${path}/setInvoiceData`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   UPDATE_ITEM: `${path}/updateItem`,

   SET_DRAFT_INVOICE_DATA: `${path}/setDraftInvoiceData`,
   APPEND_DRAFT_INVOICE_DATA: `${path}/appendDraftInvoiceData`,
   RESET_DRAFT_INVOICE_DATA: `${path}/resetDraftInvoiceData`,

   SHOW_COUPON_DIALOG: `${path}/showCouponDialog`,
};

export default {
   namespaced: true,
   state: {
      currentProduct: null,
      invoiceData: {},
      draftInvoiceData: {},
      itemEditable: {},
      loadingItem: false,
      couponDialogVisible: false,
   },
   mutations: {
      SET_CURRENT_PRODUCT(state, product) {
         state.currentProduct = product;
      },
      SET_INVOICE_DATA(state, { key, val }) {
         state.invoiceData[key] = val;
      },
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },

      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },

      SET_DRAFT_INVOICE_DATA(state, { values }) {
         state.draftInvoiceData = values;
      },
      APPEND_DRAFT_INVOICE_DATA(state, { key, val }) {
         const keys = key.split('.');
         if (keys.length === 1) {
            state.draftInvoiceData[keys[0]] = val;
         } else if (keys.length === 2) {
            if (!state.draftInvoiceData[keys[0]]) state.draftInvoiceData[keys[0]] = {};
            state.draftInvoiceData[keys[0]][keys[1]] = val;
         }
      },
      RESET_DRAFT_INVOICE_DATA(state) {
         state.draftInvoiceData = {};
      },
      SET_COUPON_DIALOG_VISIBLE(state, val) {
         state.couponDialogVisible = val;
      },
   },
   actions: {
      insertProduct({ commit }, { product }) {
         commit('SET_CURRENT_PRODUCT', product);
      },
      clearProduct({ commit }) {
         commit('SET_CURRENT_PRODUCT', null);
      },
      setInvoiceData({ commit }, { key, val }) {
         commit('SET_INVOICE_DATA', { key, val });
      },
      findItemById({ commit, state }, { apiPath, id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/${id}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      updateItem({ commit, state }, { apiPath, id, credentials }) {
         // commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  // commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  // commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      //Draft Invoice
      setDraftInvoiceData({ commit }, values) {
         commit('SET_DRAFT_INVOICE_DATA', { values });
      },
      appendDraftInvoiceData({ commit }, { key, val }) {
         commit('APPEND_DRAFT_INVOICE_DATA', { key, val });
      },
      resetDraftInvoiceData({ commit }) {
         commit('RESET_DRAFT_INVOICE_DATA');
      },
      showCouponDialog({ commit }, val) {
         commit('SET_COUPON_DIALOG_VISIBLE', val);
      },
   },
};
