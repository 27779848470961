const path = 'ModalModule';
const ModalActions = {
   OPEN_MODEL: `${path}/openModel`,
   OPEN_VIEW_MODEL: `${path}/openViewModel`,
   OPEN_INFO_MODEL: `${path}/openInfoModel`,
   CLOSE_MODEL: `${path}/closeModel`,
   CLOSE_VIEW_MODEL: `${path}/closeViewModel`,
   CLOSE_INFO_MODEL: `${path}/closeInfoModel`,
   SET_SECONDARY_MODAL_VALUE: `${path}/setSecondaryModalValue`,
   OPEN_SPECIFIC_MODEL: `${path}/openSpecificModel`,
   CLOSE_SPECIFIC_MODEL: `${path}/closeSpecificModel`,
   SET_NEW_ITEM_VALUE: `${path}/setNewItemValue`,
};

const ModalNamesConstants = {
   ACCOUNTS: 'accounts',
   WAREHOUSE: 'warehouse',
};

export { ModalActions, ModalNamesConstants };
