import AuthService from '@/core/services/AuthService';

const AttendanceMenuConfig = () => {
   const isManager =
      AuthService.hasPermission('read_attendance_reports') ||
      AuthService.hasPermission('read_hr_employee_manger');

   return [
      {
         heading: 'settings',
         route: '/settings',
         module_id: 25,
         pages: [
            {
               heading: 'attendanceSettings',
               route: '/attendance-management/attendance-settings',
               permission: 'hr_setting',
            },
            {
               sectionTitle: '_General Settings',
               sub: [
                  {
                     heading: 'Countries',
                     route: '/attendance-management/attendance-countries',
                     permission: 'hr_countries',
                  },
                  {
                     heading: 'Nationalities',
                     route: '/attendance-management/attendance-nationalities',
                     permission: 'nationalities',
                  },
                  {
                     heading: 'Languages',
                     route: '/attendance-management/languages',
                     permission: 'hr_languages',
                  },
                  {
                     heading: 'Religions',
                     route: '/attendance-management/religions',
                     permission: 'hr_religions',
                  },
                  {
                     heading: 'Marital status',
                     route: '/attendance-management/attendance-social-status',
                     permission: 'hr_marital_statuses',
                  },
               ],
            },
            {
               sectionTitle: 'Jobs Settings',
               sub: [
                  {
                     heading: 'Work systems',
                     route: '/attendance-management/attendance-work-systems',
                     permission: 'hr_work_systems',
                  },
                  {
                     heading: 'Job Types',
                     route: '/attendance-management/attendance-job-types',
                     permission: 'hr_job_types',
                  },
                  {
                     heading: 'Jobs',
                     route: '/attendance-management/attendance-jobs',
                     permission: 'hr_jobs',
                  },
                  {
                     heading: 'Jobs Classifications',
                     route: '/attendance-management/attendance-jobs-classifications',
                     permission: 'hr_job_classifications',
                  },
                  {
                     heading: 'departments',
                     route: '/attendance-management/attendance-departments',
                     permission: 'hr_departments',
                  },
                  {
                     heading: 'Work Locations',
                     route: '/attendance-management/attendance-work-locations',
                     permission: 'hr_work_locations',
                  },
                  {
                     heading: 'Work Times',
                     route: '/attendance-management/attendance-work-times',
                     permission: 'hr_work_times',
                  },
                  {
                     heading: 'workTimesSchedules',
                     route: '/attendance-management/work-times-schedules',
                     permission: 'hr_work_time_schedules',
                  },
               ],
            },
            {
               sectionTitle: 'Other Settings',
               sub: [
                  {
                     heading: 'Employers',
                     route: '/attendance-management/employers',
                     permission: 'hr_employers',
                  },
               ],
            },
         ],
      },
      {
         heading: 'Daily Transactions',
         route: '#',
         module_id: 25,
         pages: [
            {
               heading: 'employees',
               route: '/attendance-management/employees',
               permission: 'hr_employees',
               meta: {
                  title: 'employees',
               },
            },
            {
               heading: 'Update Employee Id',
               route: '/attendance-management/daily-transactions/code-transactions',
               permission: 'hr_employee_number_movements',
            },
            {
               heading: 'vacation',
               route: '/attendance-management/daily-transactions/vacation',
               permission: 'hr_vacation_movements',
            },
            {
               sectionTitle: 'Attendance',
               route: 'inventory',
               role: 'employee',
               sub: [
                  {
                     heading: 'employee Attendance',
                     route: '/attendance-management/daily-transactions/employee-attendance-employee',
                     permission: 'hr_admin_employee_attendances',
                  },
                  {
                     heading: 'Employee Attendance Location',
                     route: '/attendance-management/daily-transactions/employee-attendance-location',
                     permission: 'hr_admin_employee_attendances',
                  },
                  {
                     heading: 'Employee Attendance Department',
                     route: '/attendance-management/daily-transactions/employee-attendance-department',
                     permission: 'hr_admin_employee_attendances',
                  },
                  {
                     heading: 'Employee Attendance',
                     route: '/attendance-management/daily-transactions/employee-attendance',
                     permission: 'hr_admin_employee_attendances',
                  },
               ],
            },
            {
               heading: 'Dismiss Permission Request',
               route: '/attendance-management/daily-transactions/leave-request',
               permission: 'hr_leave_requests',
               role: 'employee',
            },
            {
               heading: 'Delay Permission Request',
               route: '/attendance-management/daily-transactions/delay-request',
               permission: 'hr_delay_requests',
               role: 'employee',
            },
            {
               heading: 'Import Attendance And Departure',
               route: '/attendance-management/daily-transactions/import-employee-attendance',
               permission: 'hr_admin_employee_attendances',
            },
            {
               heading: 'Changing temporary working hours',
               route: '/attendance-management/daily-transactions/changing-temporary-working-hours',
               permission: 'hr_work_time_temporary_changes',
            },
            {
               heading: 'Post To Payroll',
               route: '/attendance-management/daily-transactions/migrate-to-salaries',
               permission: 'hr_migrate_salaries',
            },
            {
               heading: 'My Vacations',
               route: '/attendance-management/daily-transactions/employee-vacation',
               role: 'employee',
            },
            {
               heading: 'Overtime Approvals',
               route: '/attendance-management/daily-transactions/overtime-approvals',
               // permission: 'hr_migrate_salaries',
            },
         ].filter(Boolean),
      },
      {
         heading: 'Reports',
         route: '/reports',
         pages: [
            {
               heading: 'yearlyVacations',
               route: '/attendance-management/employees-yearly-vacations',
               permission: 'attendance_reports',
            },
            {
               heading: 'Attendance and departure',
               route: '/attendance-management/attendance-departure-report',
               permission: 'attendance_reports',
            },
            {
               heading: 'Employee Attendance and departure',
               route: '/attendance-management/my-employee-attendance-departure-report',
               permission: 'hr_employee_manger',
            },
            {
               heading: 'My Attendance and departure',
               route: '/attendance-management/my-attendance-departure-report',
               role: 'employee',
            },
            {
               heading: 'Late Time',
               route: '/attendance-management/late-time-report',
               permission: 'attendance_reports',
            },
            {
               heading: 'Employee Late Time',
               route: '/attendance-management/my-employee-late-time-report',
               permission: 'hr_employee_manger',
            },
            !isManager && {
               heading: 'My Late Time',
               route: '/attendance-management/my-late-time-report',
               role: 'employee',
            },
            {
               heading: 'Extra Hours',
               route: '/attendance-management/extra-hours-report',
               permission: 'attendance_reports',
            },
            !isManager && {
               heading: 'My Extra Hours',
               route: '/attendance-management/my-extra-hours-report',
               role: 'employee',
            },
            {
               heading: 'Employees Dismiss Permissions',
               route: '/attendance-management/leave-request-report',
               permission: 'attendance_reports',
            },
            !isManager && {
               heading: 'My Employees Dismiss Permissions',
               route: '/attendance-management/my-leave-request-report',
               role: 'employee',
            },
            {
               heading: 'Employees Delay Permissions',
               route: '/attendance-management/delay-request-report',
               permission: 'attendance_reports',
            },
            !isManager && {
               heading: 'My Employees Delay Permissions',
               route: '/attendance-management/my-delay-request-report',
               role: 'employee',
            },
            {
               heading: 'Employees Absence',
               route: '/attendance-management/absence',
               permission: 'hr_employee_manger',
            },
            !isManager && {
               heading: 'My Absence',
               route: '/attendance-management/my-absence',
            },
            {
               heading: 'My Vacations',
               route: '/attendance-management/my-vacations',
               role: 'employee',
            },
         ].filter(Boolean),
      },
   ];
};

export default AttendanceMenuConfig;
