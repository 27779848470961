enum Actions {
   // action types
   ADD_BODY_CLASSNAME = 'addBodyClassName',
   REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
   ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
   REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
   ADD_CLASSNAME = 'addClassName',
   VERIFY_AUTH = 'verifyAuth',
   REQUEST_VERIFY_AUTH = 'requestVerifyAuth',
   LOGIN = 'login',
   TRY_DOSO = 'tryDoso',
   SEND_OTP = 'sendOtp',
   GET_PHONE_VERIFICATION = 'getPhoneVerification',
   ADMIN_PANEL_LOGIN = 'adminPanelLogin',
   FIRE_BASE_LOGIN = 'fireBaseLogin',
   LOGOUT = 'logout',
   REGISTER = 'register',
   REGISTER_VISITOR = 'registerVisitor',
   UPDATE_USER = 'updateUserAction',
   FORGOT_PASSWORD = 'forgotPassword',
   RESET_PASSWORD = 'resetPassword',
   CHECK_RESET_TOKEN = 'CHECK_RESET_TOKEN',
   SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
   SET_BREADCRUMB_PATH_ACTION = 'setBreadcrumbPathAction',
   SET_USER = 'setUserAction',
   SWITCH_COMAPNY = 'switchCompany',
   USER_COMPLETE_PROFILE = 'userCompleteProfile',
   CREATE_COMPANY = 'craeteCompany',
   CREATE_COMPANY_WITHOUT_LOGIN = 'createCompanyWithoutLogin',
   ADMIN_FORGOT_PASSWORD = 'adminForgotPassword',
   ADMIN_RESET_PASSWORD = 'adminResetPassword',
   ADMIN_CHECK_RESET_TOKEN = 'ADMIN_CHECK_RESET_TOKEN',
   SET_ADMIN = 'setAdminAction',
   GET_CURRENT_COMPANY = 'getCurrentCompany',
   VERIFY_EMAIL = 'verifyEmail',
   RESEND_VERIFY_EMAIL = 'resendVerifyEmail',
   RESEND_INVITATION = 'resendInvitation',
   SET_COMPANY = 'setCompanyAction',
   HANDLE_NOTIFICATION = 'handleNotification',
}

enum Mutations {
   // mutation types
   SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
   PURGE_AUTH = 'logOut',
   SET_AUTH = 'setAuth',
   SET_ADMIN_AUTH = 'setAdminAuth',
   SET_COMPANY = 'setCompany',
   SET_TOKEN = 'setToken',
   SET_USER = 'setUser',
   UPDATE_USER = 'updateUser',
   SET_PASSWORD = 'setPassword',
   SET_FCM_TOKEN = 'setFCMToken',
   SET_ERROR = 'setError',
   SET_OBJ_ERROR = 'setObjError',
   SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
   SET_BREADCRUMB_PATH_MUTATION = 'setBreadcrumbPathMutation',
   SET_LAYOUT_CONFIG = 'setLayoutConfig',
   RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
   OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
   OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
   SET_ADMIN = 'setAdmin',
   SET_REQUEST = 'setRequest',
   INCREASE_VERIFY_AUTH_REQUESTS_COUNT = 'increaseVerifyAuthRequestsCount',
   RESET_VERIFY_AUTH_REQUESTS_COUNT = 'resetVerifyAuthRequestsCount',
   INCREASE_NOTIFICATIONS_COUNT = 'increaseNotificationsCount',
   RESET_NOTIFICATIONS_COUNT = 'resetNotificationsCount',
}

export { Actions, Mutations };
