import axios from 'axios';

export default {
   namespaced: true,
   state: {
      crudLoading: false,
      itemLoading: false,
   },
   mutations: {
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_ITEM_LOADING(state, payload) {
         state.itemLoading = payload;
      },
   },
   actions: {
      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(`${apiPath}`, credentials).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
      findItemById({ commit }, { apiPath, id, params }) {
         commit('SET_ITEM_LOADING', true);

         return axios.get(`${apiPath}/${id}`, { params }).finally(() => {
            commit('SET_ITEM_LOADING', false);
         });
      },
   },
};
