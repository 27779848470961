import axios from 'axios';

const path = 'Approvals';

export const ApprovalsActions = {
   APPROVE: `${path}/approve`,
   REJECT: `${path}/reject`,
};

export default {
   namespaced: true,
   state: {
      loading: false,
   },
   mutations: {
      setLoading(state, val) {
         state.loading = val;
      },
   },
   actions: {
      approve({ commit }, { apiPath, data }) {
         commit('setLoading', true);

         return axios.post(`${apiPath}/approve`, data).finally(() => {
            commit('setLoading', false);
         });
      },
      reject({ commit }, { apiPath, data }) {
         commit('setLoading', true);
         
         return axios.post(`${apiPath}/reject`, data).finally(() => {
            commit('setLoading', false);
         });
      },
   },
};
