import { RouteRecordRaw } from 'vue-router';
import settingRouter from './settingRouter';
import reportRouter from './reportRouter';
import dailyMovementsRouter from './dailyMovementsRouter';

const assetsRouters: Array<RouteRecordRaw> = [
   ...settingRouter,
   ...reportRouter,
   ...dailyMovementsRouter,
];

export default assetsRouters;
