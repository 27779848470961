import axios from 'axios';

const CustomsSettingsModule = {
  namespaced: true,
  loading: false,
  crudLoading: false,
  state: {
    itemEditable: {},
    helper: {},
    settings: {},
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_HELPER(state, payload) {
      state.helper = payload.data;
    },
    SET_SETTING(state, payload) {
      let data = payload.data;
      state.itemEditable = data.item;
      state.settings = data.item;
      state.has_sales_notice = data.has_notice;
      state.company_setting = data.company_setting;
    },
  },
  actions: {
    getSetting({ commit }) {
      commit('SET_LOADING', true);

      return new Promise((resolve, reject) => {
        axios
          .get(`customs/setting/get-setting`)
          .then((res) => {
            commit('SET_SETTING', {
              data: res.data.result,
            });
            commit('SET_HELPER', {
              data: res.data.result?.helper,
            });
            commit('SET_LOADING', false);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    updateSetting({ commit }, data) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`customs/setting/update-setting`, data)
          .then((res) => {
            commit('SET_CRUD_LOADING', false);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {},
};
export default CustomsSettingsModule;
