const path = 'DashboardModule';
const apiPath = 'invoicing/dashboard';

const DashboardModelActions = {
    //action type
    GET_DATA : `${path}/getStatisticData`,
    GET_PURCHASE_DATA : `${path}/getPurchaseStatisticData`,
};

const DashboardModelAPIsConstants = {
    API_PATH : `${apiPath}`
}

export {DashboardModelActions, DashboardModelAPIsConstants};
