import { RouteRecordRaw } from "vue-router";

const settingRouter: Array<RouteRecordRaw> = [
  {
    path: "attendance-dashboard",
    name: "attendance-dashboard",
    meta: {
      title: "Attendance",
    },
    component: () =>
      import(
        "@/views/modules/attendance/views/dashboard/AttendanceDashboard.vue"
      ),
  },
  {
    path: "attendance-settings",
    name: "attendance-general-settings",
    meta: {
      permission: "hr_setting",
      title: "attendanceSettings",
    },
    component: () =>
      import(
        "@/views/modules/attendance/views/settings/GeneralSettings/index.vue"
      ),
  },
  {
    path: "attendance-countries",
    name: "attendance-countries",
    meta: {
      permission: "hr_countries",
      title: "Countries",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/countries/List.vue"
      ),
  },
  {
    path: "languages",
    name: "attendance-languages",
    meta: {
      permission: "hr_languages",
      title: "Languages",
    },
    component: () =>
        import(
            "@/views/modules/hr/views/crud/languages/List.vue"
            ),
  },
  {
    path: "religions",
    name: "attendance-religions",
    meta: {
      permission: "hr_religions",
      title: "Religions",
    },
    component: () =>
        import(
            "@/views/modules/hr/views/crud/religion/List.vue"
            ),
  },
  {
    path: "attendance-nationalities",
    name: "attendance-nationalities",
    meta: {
      permission: "nationalities",
      title: "Nationalities",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/nationality/List.vue"
      ),
  },
  {
    path: "attendance-social-status",
    name: "attendance-social-status",
    meta: {
      permission: "hr_marital_statuses",
      title: "Marital status",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/social-status/List.vue"
      ),
  },
  {
    path: "attendance-departments",
    name: "attendance-departments",
    meta: {
      permission: "hr_departments",
      title: "departments",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/departments/List.vue"
      ),
  },
  {
    path: "attendance-job-types",
    name: "attendance-job-types",
    meta: {
      permission: "hr_job_types",
      title: "Job Types",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/job-types/List.vue"
      ),
  },
  {
    path: "attendance-jobs",
    name: "attendance-jobs",
    meta: {
      permission: "hr_jobs",
      title: "Jobs",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/jobs/List.vue"
      ),
  },
  {
    path: "attendance-work-locations",
    name: "attendance-work-locations",
    meta: {
      permission: "hr_work_locations",
      title: "Work Locations",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/work-locations/List.vue"
      ),
  },
  {
    path: "attendance-work-systems",
    name: "attendance-work-systems",
    meta: {
      permission: "hr_work_systems",
      title: "Work systems",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/work-systems/List.vue"
      ),
  },

  {
    path: "attendance-jobs-classifications",
    name: "attendance-jobs-classifications",
    meta: {
      permission: "hr_job_classifications",
      title: "Jobs Classifications",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/jobs-classifications/List.vue"
      ),
  },
  {
    path: "attendance-work-times",
    name: "attendance-work-times",
    meta: {
      permission: "hr_work_times",
      title: "Work Times",
    },
    component: () =>
      import(
        "@/views/modules/attendance/views/settings/work-times/List.vue"
      ),
  },
  {
    path: "work-times-schedules",
    name: "work-times-schedules-list",
    meta: {
      permission: "hr_work_time_schedules",
      title: "workTimesSchedules",
    },
    component: () =>
      import(
        "@/views/modules/attendance/views/settings/work-times-schedules/List.vue"
      ),
  },

  {
    path: "employers",
    name: "attendance-employers",
    meta: {
      permission: "hr_employers",
      title: "Employers",
    },
    component: () =>
        import(
            "@/views/modules/hr/views/crud/employers/List.vue"
            ),
  },
];

export default settingRouter;
