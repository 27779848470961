import AdminModulesModule from './modules/AdminModulesModule.js';
import AdminSubscriptionModule from './modules/AdminSubscriptionModule.js';
import AdminCustomerModule from './modules/AdminCustomerModule.js';
import AdminCompanyModule from './modules/AdminCompanyModule.js';
import AdminNotifications from './modules/AdminNotifications.js';
import AdminPrivacyPolicySettings from './modules/PrivacyPolicySettings';
import AdminBlogsSettings from './modules/BlogsSettings';
import AdminDashboard from './modules/Dashboard';

const adminPanelStore = {
  AdminModulesModule,
  AdminSubscriptionModule,
  AdminCustomerModule,
  AdminCompanyModule,
  AdminNotifications,
  AdminPrivacyPolicySettings,
  AdminBlogsSettings,
  AdminDashboard,
};

export default adminPanelStore;
