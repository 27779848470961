<template>
   <a
      :href="`https://wa.me/${whatsappNumber}`"
      target="_blank"
      class="d-flex align-items-center btn contact-us-btn"
   >
      <i class="fab fa-whatsapp"></i>
   </a>
</template>

<script>
export default {
   computed: {
      whatsappNumber() {
         return (
            this.$store.state.CompanyModule.extra_data?.contact_us?.whatsapp_number ||
            '966580842040'
         );
      },
   },
};
</script>

<style lang="scss" scoped>
.contact-us-btn {
   position: fixed;
   z-index:10;
   bottom: 10px;
   left: 10px;
   background: var(--bs-primary);
   border-radius: 50%;
   aspect-ratio: 1/1;
   

   i {
      font-size: 25px;
      color: white;
      margin-left: 3px;
   }
}

.fa-whatsapp {
   font-size: 20px;
}
</style>
