const apiPath = '/warehouse/stocktakings';
const path = 'StocktakingModule';


const StocktakingAPIsConstants = {
  API_PATH : `${apiPath}`,
}
const StocktakingModelActions = {
  GENERATE_STOCKTAKING_NUMBER: `${path}/generateStocktakingNumber`,
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  APPROVE_ITEM: `${path}/approveItem`,
  FIND_ITEM_BY_ID : `${path}/findItemById`,
  EXPORT_ITEM_BY_ID : `${path}/exportItemById`,
  APPROVE_STOCKTAKING : `${path}/approveStocktaking`,
  IMPORT_ITEM_BY_ID : `${path}/importItemById`,
}

export { StocktakingAPIsConstants, StocktakingModelActions };