import { RouteRecordRaw } from 'vue-router';
import settingRouter from './settingRouter';
import invoiceRouter from './invoiceRouter';
import movementRouter from './movementRouter';
import reportRouter from './reportRouter';

const cargoRouters: Array<RouteRecordRaw> = [
  ...settingRouter,
  ...movementRouter,
  ...invoiceRouter,
  ...reportRouter,
];

export default cargoRouters;
