import store from '@/store';
import { App } from 'vue';

export type Listener = (isDemo: boolean) => void;

let listeners: Listener[] = [];

export const isDemoCompany = () => {
   return Boolean(store.getters.currentCompany?.is_demo);
};

export const whenIsDemoCompany = (callback: Listener) => {
   if (typeof callback != 'function') {
      return console.error('whenIsDemoCompany parameter must be a function', { callback });
   }

   listeners.push(callback);
};

export const callListeners = (isDemo: boolean) => {
   listeners.forEach((listener) => {
      if (typeof listener == 'function') listener(isDemo);
   });

   listeners = [];
};

export const DemoCompanyPlugin = {
   install: (app: App<Element>) => {
      app.config.globalProperties.$isDemoCompany = isDemoCompany;
      app.config.globalProperties.$whenIsDemoCompany = whenIsDemoCompany;
   },
};
