import axios from "axios";

const CustomsDealsModule = {
  namespaced: true,

  state: {
    itemEditable: {},
    dataName: "",
    loading: false,
    crudLoading: false,
    loadingItem: false,
  },

  mutations: {
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item["translations"] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },

    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },

    RESET_ALL(state) {
      state.dataName = "";
      state.loading = false;
      state.itemEditable = {};
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET_ALL");
    },
    getInitData({ commit, dispatch,state }, { apiPath }) {
      return new Promise((resolve, reject) => {
        axios
            .get(`${apiPath}/init-data`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
    findItemById({ commit, state }, { apiPath, id, params }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/${id}`, { params })
          .then((res) => {
            commit("SET_ITEM_EDITABLE", {
              itemEditable: res.data.result,
            });
            resolve(res);
            commit("SET_LOADING_ITEM", false);
          })
          .catch((error) => {
            commit("SET_LOADING_ITEM", false);
            reject(error);
          });
      });
    },

    addItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    updateItem({ commit, state }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },
    reopenDeal({ commit, state }, { credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post(`/customs/customs-operation/open-deal`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },
    closeDeal({ commit, state }, { credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post(`/customs/customs-operation/close-deal`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },
  },

  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default CustomsDealsModule;
