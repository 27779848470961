const ExternalCustomsMenuConfig = [
    {
      heading: 'Daily Transactions', 
      route: '#',
      name: 'daily_movements',
      pages: [
        {
          heading: 'deals',
          route: '/external-client/customs/external-client-deals',
        },
        {
          heading: 'Invoices',
          route: '/external-client/customs/invoices',
        },
      ],
    },
  ];
  export default ExternalCustomsMenuConfig;
  