import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   // {
   //    path: 'pos-dashboard',
   //    name: 'pos-dashboard',
   //    meta: {
   //       title: 'POS',
   //    },
   //    component: () => import('@/views/modules/pos/views/dashboard/Index.vue'),
   // },
   {
      path: 'sections',
      name: 'casher-sections',
      meta: {
         permission: 'pos_sections',
         title: 'Sections',
      },
      component: () => import('@/views/modules/pos/views/crud/sections/List.vue'),
   },
   {
      path: 'new-invoice',
      name: 'casher-invoice',
      meta: {
         // permission: 'pos_sections',
         title: 'Invoices',
      },
      component: () => import('@/views/modules/casher/views/invoices/Add.vue'),
   },
   {
      path: 'new-invoice/:id',
      name: 'casher-invoice-update',
      meta: {
         // permission: 'pos_sections',
         title: 'Invoices',
      },
      component: () => import('@/views/modules/casher/views/invoices/Add.vue'),
   },
   {
      path: 'credit-invoice',
      name: 'credit-invoice',
      meta: {
         // permission: 'pos_sections',
         title: 'Sales credit notice',
      },
      component: () => import('@/views/modules/casher/views/invoices/Add.vue'),
   },
   {
      path: 'print-previous-invoices',
      name: 'print-previous-invoices',
      meta: {
         title: 'Print Previous Invoices',
      },
      component: () => import('@/views/modules/casher/views/print-previous-invoices/View.vue'),
   },
   {
      path: 'pending-invoice',
      name: 'casher-pending-invoice',
      meta: {
         permission: 'pos_invoice_sales_drafts',
         title: 'Pending invoice',
      },
      component: () => import('@/views/modules/casher/views/invoices/draft/List.vue'),
   },
   {
      path: 'products-availability',
      name: 'casher-products-availability',
      meta: {
         permission: 'pos_availability_products',
         title: 'Products Availability',
      },
      component: () => import('@/views/modules/casher/views/invoices/products-availability/List.vue'),
   },
   {
      path: 'expenses',
      name: 'casher-expenses',
      meta: {
         permission: 'pos_expense_vouchers',
         type: 'write',
         title: 'Expenses',
      },
      component: () => import('@/views/modules/casher/views/crud/payment-voucher/Add.vue'),
   },
   {
      path: 'reports',
      name: 'casher-reports',
      meta: {
         // permission: 'pos_expense_vouchers',
         // type: 'write',
         title: 'Reports',
      },
      component: () => import('@/views/modules/casher/views/report/Index.vue'),
   },
   {
      path: '/user-profile-casher',
      name: 'user-profile-casher',
      component: () => import('@/views/pages/user-profile/edit.vue'),
      meta: {
         title: 'My Profile',
      },
   },
];

export default settingRouter;
