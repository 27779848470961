const apiPath = '/hr/attendance/migrate/migrate-to-salaries';
const path = 'MigrateToSalariesModule';

const MigrateToSalariesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const MigrateToSalariesModelActions = {
  IMPORT: `${path}/import`,
}
export { MigrateToSalariesAPIsConstants, MigrateToSalariesModelActions };
