import axios from 'axios';
import { AttendanceDepartureReportAPIsConstants } from '../../enums/reports/AttendanceDepartureReportEnums';
import DownloadService from '../../../../../../core/services/DownloadService';

const AttendanceDepartureReportModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    dataName: '',
    loading: false,
    crudLoading: false,
    loadingItem: false,
    editableId: null,
    isPagination: false,
    isCloseModal: false,
    bindingItem: {},
    bindProductValidation: {},
    pagination: {
      page: 1,
      total: 0,
      rowsPerPage: 10,
    },
    invoiceModel: {},
  },
  mutations: {
    SET_INVOICE_MODEL(state, payload) {
      state.invoiceModel = payload.item;
    },
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },
    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      // let arr = [];
      //
      // if (payload.itemEditable.item.translations) {
      //   payload.itemEditable.item.translations.map((lang) => {
      //     arr[lang.locale] = lang;
      //   });
      //   payload.itemEditable.item['translations'] = arr;
      // }

      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = '';
      state.loading = false;
      state.itemEditable = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
    RESET_EDITABLE(state) {
      state.loading = false;
      state.itemEditable = {};
      state.editableId = null;
    },
    RESET_INVOICE_DATA(state) {
      state.bindingItem = {};
      state.bindProductValidation = {};
      state.invoiceModel = {};
    },
    RESET_BINDING_DATA(state) {
      state.bindingItem = {};
      state.bindProductValidation = {};
    },
    SET_EDITABLE_ID(state, id) {
      state.editableId = id;
    },
    SET_ROWS_PER_PAGE(state, payload) {
      state.pagination['rowsPerPage'] = payload;
      state.pagination['page'] = 1;
    },
    SET_TOTAL_NUM(state, payload) {
      state.pagination['total'] = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.pagination['page'] = payload;
    },
    SET_IS_PAGINATION(state, payload) {
      state.isPagination = payload;
    },
    SET_IS_CLOSE_MODAL(state, payload) {
      state.isCloseModal = payload;
    },
  },
  actions: {
    setRowsPerPage({ commit }, payload) {
      commit('SET_ROWS_PER_PAGE', payload);
    },
    setTotalNum({ commit }, payload) {
      commit('SET_TOTAL_NUM', payload);
    },
    setCurrentPage({ commit }, payload) {
      commit('SET_CURRENT_PAGE', payload);
    },
    setIsPagination({ commit }, payload) {
      commit('SET_IS_PAGINATION', payload);
    },
    setDataName({ commit }, name) {
      commit('SET_DATA_Name', name);
    },
    setIsCloseModal({ commit }, payload) {
      commit('SET_IS_CLOSE_MODAL', payload);
    },
    setEditableId({ commit }, id) {
      commit('SET_EDITABLE_ID', id);
    },
    reset({ commit }) {
      commit('RESET_ALL');
    },
    resetEditable({ commit }) {
      commit('RESET_EDITABLE');
    },
    resetInvoiceDate({ commit }) {
      commit('RESET_INVOICE_DATA');
    },
    resetBindingDate({ commit }) {
      commit('RESET_BINDING_DATA');
    },

    // AttendanceDepartureReport({ commit, dispatch }, credentials) {
    //   commit('SET_LOADING', true);

    //   if (credentials.pdf == 1) {
    //     DownloadService.ddd(`${AttendanceDepartureReportAPIsConstants.API_PATH}/total-ledger-report`,credentials).then(()=>
    //       commit('SET_LOADING', false)
    //     );
    //     return;
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`${AttendanceDepartureReportAPIsConstants.API_PATH}/total-ledger-report`, {
    //         params: credentials,
    //       })
    //       .then((response) => {
    //         commit('SET_ITEM_EDITABLE', {
    //           itemEditable: response.data.result,
    //         });
    //         commit('SET_LOADING', false);
    //         resolve(response);
    //       })
    //       .catch((error) => {
    //         commit('SET_LOADING', false);
    //         reject(error);
    //       });
    //   });
    // },
    getReport({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${AttendanceDepartureReportAPIsConstants.API_PATH}/attendance-departure-report`,credentials).then(()=>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${AttendanceDepartureReportAPIsConstants.API_PATH}/attendance-departure-report`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getMyReport({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true);

      if (credentials.pdf == 1) {
        DownloadService.ddd(`${AttendanceDepartureReportAPIsConstants.API_PATH}/attendance-departure-employee-report`, credentials).then(() =>
          commit('SET_LOADING', false)
        );
        return;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${AttendanceDepartureReportAPIsConstants.API_PATH}/attendance-departure-employee-report`, {
            params: credentials,
          })
          .then((response) => {
            if (response.data.result.report)
              commit('SET_ITEM_EDITABLE', {
                itemEditable: response.data.result,
              });
            commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default AttendanceDepartureReportModule;
