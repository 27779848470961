const path = 'CargoSalesReportModule';
const apiPath = '/transport/report';

const CargoSalesReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_SALES_REPORT: `${path}/generateSalesReport`,
  GENERATE_MODEL_REPORT: `${path}/generateModelReport`,
  GENERATE_PRODUCT_SALES_REPORT: `${path}/generateProductSalesReport`,
  GENERATE_SALES_BUSINESSMOVEMENT_REPORT: `${path}/generateSalesBusinessMovementReport`,
  GENERATE_CLIENT_ACCOUNT_STATEMENT_REPORT: `${path}/generateClientAccountStatementReport`,
  GENERATE_CLIENT_GROUP_DETAILED_SALES_REPORT: `${path}/generateClientGroupDetailedSalesReport`,
  GENERATE_CLIENT_DETAILED_SALES_REPORT: `${path}/generateClientDetailedSalesReport`,
  GENERATE_CLIENT_TOTAL_SALES_REPORT: `${path}/generateClientTotalSalesReport`,
  GENERATE_SALESMAN_DETAILED_SALES_REPORT: `${path}/generateSalesmanDetailedSalesReport`,
  GENERATE_SALESMAN_TOTAL_SALES_REPORT: `${path}/generateSalesmanTotalSalesReport`,
};

const CargoSalesReportAPIsConstants = {
  // api's
  API_PATH: `${apiPath}`,
};

export { CargoSalesReportAPIsConstants, CargoSalesReportActions };
