const path = "EmployeesCareerInfoModule";
const apiPath = "/hr/report/employee/employee-career-info-report";

const EmployeesCareerInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesCareerInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesCareerInfoAPIsConstants, EmployeesCareerInfoActions };
