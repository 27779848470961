import { RouteRecordRaw } from "vue-router";

const settingRouter: Array<RouteRecordRaw> = [
  {
    path: "general-settings",
    name: "salaries-management-general-settings",
    meta: {
      permission: 'hr_setting',
      title: "HR and Payroll Settings",
    },
    component: () =>
        import("@/views/modules/hr/views/settings/GeneralSettings/index.vue"),
  },
  {
    path: "spend-types",
    name: "spend-types",
    meta: {
      permission: 'hr_spend_types',
      title: "Spend Types",
    },
    component: () =>
      import("@/views/modules/salaries/views/crud/spend-types/List.vue"),  
  },
  {
    path: "wage-items",
    name: "wage-items",
    meta: {
      permission: 'hr_wage_items',
      title: "Wage Items",
    },
    component: () =>
      import("@/views/modules/salaries/views/crud/wage-items/List.vue"),  
  },
  {
    path: "wage-items-description",
    name: "wage-items-description",
    meta: {
      permission: 'hr_wage_item_descriptions',
      title: "Wage Items Description",
    },
    component: () =>
      import("@/views/modules/salaries/views/crud/wage-items-description/List.vue"),  
  },
  {
    path: "salaries-dashboard",
    name: "salaries-dashboard",
    meta: {
      permission: 'hr_salaries_dashboard',
      title: "Payroll",
    },
    component: () =>
      import("@/views/modules/salaries/views/dashboard/SalariesDashboard.vue"),
  },
  // {
  //   path: "trainings-settings",
  //   name: "salaries-trainings-settings",
  //   component: () =>
  //     import(
  //       "@/views/modules/settings/views/settings/salaries-settings/trainings/List.vue"
  //     ),
  // },
];

export default settingRouter;
