const path = 'BudgetReportModule';
const apiPath = '/accounting/report';

const BudgetReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_BUDGET_REPORT : `${path}/generateBudgetReport`,
  GENERATE_INCOME_STATEMENT_REPORT : `${path}/generateIncomeStatementReport`,
}

const BudgetReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {BudgetReportAPIsConstants, BudgetReportModelActions };
