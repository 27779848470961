const path = "EmployeesDocumentsInfoModule";
const apiPath = "/hr/report/movements/documents-info-report";

const EmployeesDocumentsInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesDocumentsInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesDocumentsInfoAPIsConstants, EmployeesDocumentsInfoActions };
