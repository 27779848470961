const apiPath = '/hr/salaries/hr-migrate/migrate-salary-effects';
const path = 'TransferSalaryiesModule';

const TransferSalaryiesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const TransferSalaryiesModelActions = {
  ADD_ITEM: `${path}/addItem`,
}
export { TransferSalaryiesAPIsConstants, TransferSalaryiesModelActions };
