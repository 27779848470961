import ElectronicApprovalSettingsModule from './modules/ElectronicApprovalSettings';
import EApprovalMovementSettingsModule from './modules/ElectronicApprovalMovementSettings';
import EApprovalDashboard from './modules/Dashboard';

const electronicApprovalsStore = {
   ElectronicApprovalSettingsModule,
   EApprovalMovementSettingsModule,
   EApprovalDashboard,
};

export default electronicApprovalsStore;
