const apiPath = '/hr/attendance/hr-delay-requests-as-employee';
const path = 'DelayRequestAsEmployeeModule';
const DelayRequestAsEmployeeAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const DelayRequestAsEmployeeActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  APPROVE_ITEM: `${path}/approveItem`,
  UNAPPROVE_ITEM: `${path}/unapproveItem`,
};

export { DelayRequestAsEmployeeAPIsConstants, DelayRequestAsEmployeeActions };
