import axios from 'axios';
import { DashboardModelAPIsConstants } from '@/views/modules/invoicing/store/enums/DashboardModelEnum';

const DashboardModule = {
   namespaced: true,
   state: {
      dashboardValues: {},
      purchaseDashboardValues: {},
      loading: false,
      crudLoading: false,
      loadingItem: false,
   },
   mutations: {
      SET_DASHBOARD_MODEL_DATA(state, payload) {
         state.dashboardValues = payload.item;
      },
      SET_PURCHASE_DASHBOARD_MODEL_DATA(state, payload) {
         state.purchaseDashboardValues = payload.item;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      RESET_ALL(state) {
         state.loading = false;
         state.dashboardValues = {};
      },
   },
   actions: {
      getStatisticData({ commit }, params) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${DashboardModelAPIsConstants.API_PATH}/get-statistic-data`, { params })
               .then((response) => {
                  commit('SET_DASHBOARD_MODEL_DATA', {
                     item: response.data.result,
                  });
                  commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      getPurchaseStatisticData({ commit, dispatch }, params) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${DashboardModelAPIsConstants.API_PATH}/get-purchase-statistic-data`, {
                  params,
               })
               .then((response) => {
                  commit('SET_PURCHASE_DASHBOARD_MODEL_DATA', {
                     item: response.data.result,
                  });
                  commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
   getters: {},
};

export default DashboardModule;
