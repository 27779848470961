const path = 'TotalSummarySalariesReportModule';
const apiPath = 'hr/salaries/report';

const TotalSummarySalariesReportModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT_DEPARTMENT: `${path}/getReportDepartment`,
  GET_REPORT_LOCATION: `${path}/getReportLocation`,
  GET_REPORT_EMPLOYER: `${path}/getReportEmployer`,
  GET_REPORT_COMPANY: `${path}/getReportCompany`,
  // GENERATE_DETAILED_TotalSummarySalaries_REPORT : `${path}/generateDetailedTotalSummarySalariesReport`,
}

const TotalSummarySalariesReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {TotalSummarySalariesReportAPIsConstants, TotalSummarySalariesReportModelActions };
