import { RouteRecordRaw } from 'vue-router';
import settingRouter from './settingRouter';
import reportRouter from './reportRouter';
import dailyMovementsRouter from './dailyMovementsRouter';

const posRouters: Array<RouteRecordRaw> = [
  ...settingRouter,
  ...reportRouter,
  ...dailyMovementsRouter,
];

export default posRouters;
