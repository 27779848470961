const path = "EmployeesYearlyVacationsModule";
const apiPath = "/hr/attendance/report/vacation-movement-report";

const EmployeesYearlyVacationsActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesYearlyVacationsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesYearlyVacationsAPIsConstants, EmployeesYearlyVacationsActions };
