const PATH = '/pos-management';
import { MODULES_IDS } from '@/views/modules/shared/constants/ModulesNames';

const PosMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: MODULES_IDS.POS_MANAGEMENT,
      pages: [
         {
            heading: 'Pos Settings',
            route: `${PATH}/setting`,
            permission: 'pos_setting',
         },
         {
            heading: 'Delivery Zones',
            route: `${PATH}/delivery-zones`,
            permission: 'pos_delivery_zones',
         },
         {
            heading: 'Branches',
            route: `${PATH}/branches`,
            permission: 'pos_branches',
         },
         {
            heading: 'Sections',
            route: `${PATH}/sections`,
            permission: 'pos_sections',
         },
         {
            heading: 'Points Of Sales',
            route: `${PATH}/pos`,
            permission: 'pos_point_sales',
         },
         {
            heading: 'Offers',
            route: `${PATH}/offers`,
            permission: 'pos_offers',
         },
         {
            heading: 'Coupons',
            route: `${PATH}/coupons`,
            permission: 'pos_coupons',
         },
         {
            heading: 'Expenses',
            route: `${PATH}/expenses`,
            permission: 'pos_expenses',
         },
         {
            heading: 'Delivery Employees',
            route: `${PATH}/delivery-employees`,
            permission: 'pos_delivery_men',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '#',
      module_id: MODULES_IDS.POS_MANAGEMENT,
      pages: [
         {
            heading: 'Start End Shift',
            route: `${PATH}/fund`,
            permission: 'pos_fund_movements',
         },
         {
            heading: 'Drafts',
            route: `${PATH}/drafts`,
            permission: 'pos_invoice_sales_drafts',
         },
         {
            heading: 'Expenses',
            route: `${PATH}/payment-voucher`,
            permission: 'pos_expenses',
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Offers',
            route: `${PATH}/reports/offers`,
            permission: 'pos_reports',
         },
         {
            heading: 'Shift Transaction',
            route: `${PATH}/reports/shift-transaction`,
            permission: 'pos_reports',
         },
         {
            heading: 'Invoicing',
            route: `${PATH}/reports/invoices`,
            permission: 'pos_reports',
         },
         {
            heading: 'Draft Invoices',
            route: `${PATH}/reports/draft-invoices`,
            permission: 'pos_reports',
         },
         {
            heading: 'Daily Sales Summary',
            route: `${PATH}/reports/daily-sales-summary`,
            permission: 'pos_reports',
         },
      ],
   },
];
export default PosMenuConfig;
