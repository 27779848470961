import store from '@/store/';
import JwtService from '@/core/services/JwtService';

class AuthService {
   public static hasModule(moduleId): boolean {
      return store.getters.currentCompany.modules?.filter((e) => e.id === moduleId).length > 0;
   }

   public static userHasModule(module_id): boolean {
      return store.getters.currentUser.roles?.filter((e) => e.module_id === module_id).length > 0;
   }

   public static hasPermission(permission): boolean {
      return store.getters.currentUser.permissions?.filter((e) => e.name === permission).length > 0;
   }
   public static hasRole(role): boolean {
      if (!JwtService.isAdminDomain()) {
         return store.getters.currentUser.roles?.filter((e) => e.name === role).length > 0;
      }

      return store.getters.currentUser.role?.filter((e) => role?.split('|').includes(e)).length > 0;
   }
}

export default AuthService;
