const path = 'AttendanceDepartureReportModule';
const apiPath = 'hr/attendance/report';

const AttendanceDepartureReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  GET_MY_REPORT: `${path}/getMyReport`,
  // GENERATE_DETAILED_AttendanceDeparture_REPORT : `${path}/generateDetailedAttendanceDepartureReport`,
}

const AttendanceDepartureReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {AttendanceDepartureReportAPIsConstants, AttendanceDepartureReportActions };
