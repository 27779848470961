import axios from 'axios';

const path = 'AssetsDashboardModule';

export const AssetsDashboardActions = {
   GET_DATA: `${path}/getAssetsStatisticData`,
};

export const AssetsDashboardAPIsConstants = {
   API_PATH: 'asset/dashboard/get-statistic-data',
};

const AssetsDashboardModule = {
   namespaced: true,
   state: {
      loading: false,
      data: {},
   },
   mutations: {
      SET_DATA(state, payload) {
         state.data = payload.item;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      getAssetsStatisticData({ commit }) {
         commit('SET_LOADING', true);

         return axios
            .get(AssetsDashboardAPIsConstants.API_PATH)
            .then((response) => {
               commit('SET_DATA', { item: response.data.result });
            })
            .finally(() => commit('SET_LOADING', false));
      },
   },
};

export default AssetsDashboardModule;
