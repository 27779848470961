const apiPath = "/hr/hr-end-of-service";
const path = "TerminationModule";

const TerminationAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const TerminationModuleActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  CALCULATE_AMOUNT: `${path}/calculateAmount`,
};

export { TerminationAPIsConstants, TerminationModuleActions };
