const apiPath = '/hr/salaries/hr-migrate/create-new-month';
const path = 'CreateNewMonthModule';

const CreateNewMonthAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const CreateNewMonthModelActions = {
  ADD_ITEM: `${path}/addItem`,
}
export { CreateNewMonthAPIsConstants, CreateNewMonthModelActions };
