import DownloadService from '@/core/services/DownloadService';
import axios from 'axios';
import { MuqeemEmployeesInfoAPIsConstants } from '../../enums/reports/muqeem/EmployeesInfo';

export default {
   namespaced: true,
   state: {
      loading: false,
      itemEditable: {},
      editableId: null,
   },
   mutations: {
      RESET_EDITABLE(state) {
         state.loading = false;
         state.itemEditable = {};
         state.editableId = null;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
   },
   actions: {
      resetEditable({ commit }) {
         commit('RESET_EDITABLE');
      },
      generateReport({ commit }, credentials) {
         commit('SET_LOADING', true);

         return DownloadService.downloadPDF(`${MuqeemEmployeesInfoAPIsConstants.API_PATH}`, {
            method: 'get',
            params: {
               ...credentials,
               filters: { ...credentials.filters, print: 1 },
            },
         }).finally(() => commit('SET_LOADING', false));
      },
   },
};
