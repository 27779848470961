import {useI18n} from "vue-i18n";
import {CookieService} from "@/core/services/CookieService";

class AdminLanguageService {

  public static setLang(lang) {
    const i18n = useI18n();
    document.documentElement.setAttribute('adminLang', lang);
    i18n.locale.value = lang;
    CookieService.setCookie('adminLang', lang);
  }

  public static setLangWithReload(lang) {
    CookieService.setCookie('adminLang', lang);
    window.location.reload();
  }

  public static setLangCookie(lang) {
    CookieService.setCookie('adminLang', lang);
  }

  public static setLangWithoutReload(lang) {
    CookieService.setCookie('adminLang', lang);
  }

  public static getLang() {
    return CookieService.getCookie('adminLang');
  }

}

export default AdminLanguageService;
