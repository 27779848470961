const path = 'OperationLogFromMuqeemModule';
const apiPath = '/hr/report/muqeem/muqeem-operations-report';

const OperationLogFromMuqeemReportActions = {
   RESET_EDITABLE: `${path}/resetEditable`,
   GENERATE_REPORT: `${path}/generateReport`,
};

const OperationLogFromMuqeemReportAPIsConstants = {
   API_PATH: `${apiPath}`,
};

export { OperationLogFromMuqeemReportAPIsConstants, OperationLogFromMuqeemReportActions };
