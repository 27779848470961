const path = 'AccountingSettingModule';
const apiPath = '/accounting/setting';

const AccountingSettingModelActions = {
  // action types
  GET_SETTINGS : `${path}/getSetting`,
  UPDATE_SETTINGS : `${path}/updateSetting`

}

const AccountingSettingAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  GET_SETTINGS : `${apiPath}/get-setting`,
  UPDATE_SETTINGS : `${apiPath}/update-setting`,
}

export { AccountingSettingAPIsConstants, AccountingSettingModelActions };
