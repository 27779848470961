const path = "EmployeesMovementsInfoModule";
const apiPath = "/hr/report/movements/movement-report";

const EmployeesMovementsInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesMovementsInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesMovementsInfoAPIsConstants, EmployeesMovementsInfoActions };
