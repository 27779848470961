import axios from 'axios';
import DownloadService from '../../../../../../core/services/DownloadService';
import { AbsenceReportAPIsConstants } from '../../enums/reports/AbsenceReport';

const LeaveRequestReportModule = {
   namespaced: true,
   state: {
      DataTable: [],
      itemEditable: {},
      loading: false,
   },
   mutations: {
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      getMyReport({ commit }, credentials) {
         commit('SET_LOADING', true);

         const params = { ...credentials, type: 'absence' };

         if (params.excel == 1) {
            return DownloadService.ddd(AbsenceReportAPIsConstants.EMPLOYEE_API_PATH, params).then(() =>
               commit('SET_LOADING', false),
            );
         }

         return axios
            .get(AbsenceReportAPIsConstants.EMPLOYEE_API_PATH, { params })
            .then((response) => {
               commit('SET_ITEM_EDITABLE', { itemEditable: response.data.result });
               return response;
            })
            .finally(() => commit('SET_LOADING', false));
      },
      getReport({ commit }, credentials) {
         commit('SET_LOADING', true);

         const params = { ...credentials, type: 'absence' };

         if (params.excel == 1) {
            return DownloadService.ddd(AbsenceReportAPIsConstants.API_PATH, params).then(() =>
               commit('SET_LOADING', false),
            );
         }

         return axios
            .get(AbsenceReportAPIsConstants.API_PATH, { params })
            .then((response) => {
               commit('SET_ITEM_EDITABLE', { itemEditable: response.data.result });
               return response;
            })
            .finally(() => commit('SET_LOADING', false));
      },
   },
};
export default LeaveRequestReportModule;
