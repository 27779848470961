const path = 'ExpensesReportModule';
const apiPath = 'customs/report';

const ExpensesReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_Expenses_REPORT : `${path}/generateDetailedExpensesReport`,
}

const ExpensesReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {ExpensesReportAPIsConstants, ExpensesReportActions };
