const apiPath = '/hr/attendance/hr-employee-attendance';
const path = 'HREmployeeAttendanceModule';

const HREmployeeAttendanceAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const HREmployeeAttendanceModelActions = {
  GET_LAST_ATTENDANCE: `${path}/getLastAttendance`,
  CLOCK_IN: `${path}/clockIn`,
  CLOCK_OUT: `${path}/clockOut`,
  CHECK_IF_IN_SITE: `${path}/checkIfInSite`,
}
export { HREmployeeAttendanceAPIsConstants, HREmployeeAttendanceModelActions };
