import axios from 'axios';
import { MigrateToSalariesAPIsConstants } from "./../enums/daily-movements/MigrateToSalariesEnums";
import DownloadService from '@/core/services/DownloadService';

const MigrateToSalariesModule = {
    namespaced: true,
    mutations: {},
    actions: {
        import({ commit, state }, { apiPath }) {

            return new Promise((resolve, reject) => {
                axios
                    .get(`${apiPath}`)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
}
export default MigrateToSalariesModule;