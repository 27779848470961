const apiPath = "/hr/hr-employee-number-movements";
const path = "CodeTransactionsModule";

const CodeTransactionsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const CodeTransactionsActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  CALCULATE_AMOUNT: `${path}/calculateAmount`,
};

export { CodeTransactionsAPIsConstants, CodeTransactionsActions };
