import axios from 'axios';

const apiPath = (query) => `/pos/pos-coupons/search/${query}`;
const path = 'PosCoupon';

export const PosCouponAPIsConstants = {
   API_PATH: apiPath,
};

export const PosCouponActions = {
   SEARCH: `${path}/search`,
   APPLY_COUPON: `${path}/applyCoupon`,
   DELETE_COUPON: `${path}/deleteCoupon`,
};

export default {
   namespaced: true,
   state: {
      currentCoupon: null,
   },
   mutations: {
      SET_CURRENT_COUPON(state, val) {
         state.currentCoupon = val;
      },
   },
   actions: {
      search({ commit }, { query }) {
         return axios.get(PosCouponAPIsConstants.API_PATH(query)).then(({ data }) => {
            return data.result.item;
         });
      },
      applyCoupon({ commit }, coupon) {
         commit('SET_CURRENT_COUPON', coupon);
      },
      deleteCoupon({ commit }) {
         commit('SET_CURRENT_COUPON', null);
      },
   },
};
