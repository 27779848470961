const SalariesMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 4,
      pages: [
         // {
         //   heading: "Payroll Settings",
         //   route: "/salaries-management/general-settings",
         //   // permission: "salaries_setting",

         // },
         {
            heading: 'HR and Payroll Settings',
            route: '/salaries-management/general-settings',
            permission: 'hr_setting',
         },
         {
            heading: 'Spend Types',
            route: '/salaries-management/spend-types',
            permission: 'hr_wage_items',
         },
         {
            heading: 'Wage Items',
            route: '/salaries-management/wage-items',
            permission: 'hr_spend_types',
         },
         {
            heading: 'Wage Items Description',
            route: '/salaries-management/wage-items-description',
            permission: 'hr_wage_item_descriptions',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '#',
      module_id: 4,
      name:"daily_movements",
      pages: [
         {
            heading: 'Earnings & Deductions',
            route: '/salaries-management/daily-transactions/employees-earnings-deductions',
            permission: 'hr_entitlement_deduction_movements',
         },
         {
            heading: 'Transfer of salary and allowances from HR',
            route: '/salaries-management/daily-transactions/salaries-transfer-salaries',
            permission: 'hr_migrate_salary_effects',
         },
         {
            heading: 'Transfer salaries',
            route: '/salaries-management/daily-transactions/salaries-transfer',
            permission: 'hr_migrate_salaries',
         },
         {
            heading: 'Installments',
            route: '/salaries-management/daily-transactions/installments',
            permission: 'hr_migrate_to_accounting',
         },
         {
            heading: 'Salary Items',
            route: '/salaries-management/daily-transactions/salaries-items',
            permission: 'hr_entitlement_deduction_movements',
         },

         {
            heading: 'Calculate Salaries',
            route: '/salaries-management/daily-transactions/calculate-salaries',
            permission: 'hr_calculate_salaries',
         },
         {
            heading: 'Create New Month',
            route: '/salaries-management/daily-transactions/new-month',
            permission: 'hr_create_new_month',
         },
         // {
         //    heading: 'Post To Accounting',
         //    route: '/salaries-management/daily-transactions/transfer-to-accounting',
         //    permission: 'hr_migrate_to_accounting',
         // },
         // {
         //    heading: 'transferringLog',
         //    route: '/salaries-management/daily-transactions/transfer-log',
         //    permission: 'hr_migrate_to_accounting',
         // },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Employee Installments',
            route: '/salaries-management/employee-installments-report',
            permission: 'hr_salaries_reports',
         },
         {
            heading: 'Employee Pay Slip',
            route: '/salaries-management/employee-pay-slip-report',
            permission: 'hr_salaries_reports',
         },
         {
            heading: 'Wage Item',
            route: '/salaries-management/wage-item-report',
            permission: 'hr_salaries_reports',
         },
         {
            heading: 'Employee Salaries',
            route: '/salaries-management/employee-salaries',
            permission: 'hr_salaries_reports',
         },
         {
            heading: 'Employee Salaries In Duration',
            route: '/salaries-management/employee-salaries-in-duration',
            permission: 'hr_salaries_reports',
         },
         {
            sectionTitle: 'Total Summary Salaries',
            sub: [
               {
                  heading: 'DEPARTMENT',
                  route: '/salaries-management/total-summary-salaries-report/department',
                  permission: 'hr_salaries_reports',
               },
               {
                  heading: 'Location',
                  route: '/salaries-management/total-summary-salaries-report/location',
                  permission: 'hr_salaries_reports',
               },
               {
                  heading: 'employer',
                  route: '/salaries-management/total-summary-salaries-report/employer',
                  permission: 'hr_salaries_reports',
               },
               {
                  heading: 'company',
                  route: '/salaries-management/total-summary-salaries-report/company',
                  permission: 'hr_salaries_reports',
               },
            ],
         },
      ],
   },
];
export default SalariesMenuConfig;
