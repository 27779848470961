const path = 'AdminModulesModule';
const apiPath = '/admin-panal/setting';

const AdminProfileActions = {
  // action types
  GET_DATA : `${path}/getBank`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
  GET_PROFILE : `${path}/getProfile`,
  UPDATE_PROFILE : `${path}/updateProfile`,
  CHANGE_PASSWORD : `${path}/changePassword`,
}

// const BankMutations = {
//   // mutation types
//   SET_ERROR : `${path}/setError`,
//   RESET_DATA : `${path}/resetData`,
//   SET_DATA : `${path}/setBank`,
//   SET_LIST_DATA : `${path}/setListData`,
// }

const AdminProfileAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { AdminProfileActions,AdminProfileAPIsConstants };
