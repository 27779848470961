const path = 'AbsenceReport';
const apiPath = 'hr/attendance/report';

const AbsenceReportActions = {
   RESET_EDITABLE: `${path}/resetEditable`,
   GET_REPORT: `${path}/getReport`,
   GET_MY_REPORT: `${path}/getMyReport`,
};

const AbsenceReportAPIsConstants = {
   API_PATH: `${apiPath}/attendance-report`,
   EMPLOYEE_API_PATH: `${apiPath}/attendance-employee-report`,
};

export { AbsenceReportAPIsConstants, AbsenceReportActions };
