import axios from 'axios';

const UsersModule = {
  namespaced: true,
  state: {
    DataTable: [],
  },
  mutations: {
    SET_DATA_TABLE(state, payload) {
      console.log(payload.data.data);

      state.DataTable = payload.data.data.map((row) => {
        if (row.translations) {
          let arr = [];
          row.translations.map((lang) => {
            arr[lang.locale] = lang;
            row[lang.locale] = lang;
          });
          row['translations'] = arr;
        }

        return row;
      });
    },
  },
  actions: {
    getDataTable({ commit }) {
      axios
        .get(`user/users`)
        .then((res) => {
          commit('SET_DATA_TABLE', {
            data: res.data.result.users,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {
    isMarkedAsRead: (state) => (id) => {
      let index = state.notifications.findIndex((obj) => obj.id === id);
      if (index > -1) return state.notifications[index].read === '1';
      return false;
    },
  },
};
export default UsersModule;
