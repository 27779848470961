import axios from 'axios';
import { overrideUsersCount } from '@/core/services/SubscriptionInfo';

const SubscriptionModule = {
   namespaced: true,
   state: {
      // list: {},
   },
   mutations: {
      // SET_LIST(state, payload) {
      //   state.list[payload.listName] = payload.list;
      // },
   },
   actions: {
      getSubscribePlans({ commit, state, dispatch }, params) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/getSubscribePlans', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getPlanDetail({ commit, state, dispatch }, params) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/get-plan-detail', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      subscribePay({ commit, state, dispatch }, { params }) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/subscribePay', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      generatePayUrl({ commit, state, dispatch }, params) {
         return new Promise((resolve, reject) => {
            axios
               .get('subscription/payment/generate-paytabs-payment-url', { params: { ...params } })
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      startFreeTrial({ commit, state, dispatch }, { params }) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .get('subscription/start-free-trial', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      subscribeRenew({ commit, state, dispatch }, { params }) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/renewSubscribe', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getUpdateSubscriptionInfo({ commit, state, dispatch }, params) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/get-update-subscription-info', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getBundleUpdateSubscriptionInfo({ commit, state, dispatch }, params) {
         overrideUsersCount(params);
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/get-bundle-update-subscription-info', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      updateSubscribe({ commit, state, dispatch }, { params }) {
         return new Promise((resolve, reject) => {
            axios
               .post('subscription/update-subscribe', params)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      // getListByName: (state) => (id) => {
      //   return state.DataTable.find((x) => x.id === id);
      // },
   },
};
export default SubscriptionModule;
