import { createI18n } from 'vue-i18n/index';
const en = require('./locales/en.json');
const ar = require('./locales/ar.json');
import LanguageService from '@/core/services/LanguageService';
import JwtService from '@/core/services/JwtService';
import AdminLanguageService from '@/core/services/admin/AdminLanguageService';

const messages = {
   en,
   ar,
};

const i18n = createI18n({
   legacy: false,
   locale: JwtService.isAdminDomain()
      ? AdminLanguageService.getLang()
         ? (AdminLanguageService.getLang() as string)
         : 'ar'
      : LanguageService.getLang()
      ? (LanguageService.getLang() as string)
      : 'ar',
   globalInjection: true,
   missingWarn: false,
   fallbackWarn: false,
   messages,
});

export const $t = i18n.global.t;
export const $tc = i18n.global.tc;

export default i18n;
