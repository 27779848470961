import Swal, { SweetAlertResult } from 'sweetalert2';
import i18n from '@/core/plugins/i18n';
import axios from 'axios';

class AlertService {
   public static success(message, title = '') {
      const { t } = i18n.global;
      return new Promise((resolve) => {
         Swal.fire({
            title: title != '' ? t(message) : '',
            text: title != '' ? title : t(message),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: t('Ok, got it!'),
            customClass: {
               confirmButton: 'btn fw-bold btn-light-primary alert-confirm-button',
            },
         }).then(function (resp) {
            resolve(resp);
         });
      });
   }

   public static error(error) {
      const { t } = i18n.global;
      let message = error.response.data.message;

      if (typeof message === 'object') message = message.ar || message;

      Swal.fire({
         text: t(message),
         icon: 'error',
         buttonsStyling: false,
         confirmButtonText: t('close'),
         customClass: {
            confirmButton: 'btn fw-bold btn-light-danger alert-confirm-button',
         },
      });
   }

   public static async errorWithCustomButton(
      message,
      confirmButtonText = 'Ok, got it!',
      cancelButtonText = 'Cancel',
      cancelButtonColor = '#aaaaaa',
   ) {
      const { t } = i18n.global;
      const text = t(message);
      let result: any = false;

      await Swal.fire({
         title: t(text),
         icon: 'error',
         showCancelButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: cancelButtonColor,
         confirmButtonText: t(confirmButtonText),
         cancelButtonText: t(cancelButtonText),
         buttonsStyling: true,
      }).then((confirmed) => {
         result = confirmed;
      });

      return result;
   }

   public static errorText(message, options: any = {}) {
      const { t } = i18n.global;
      const { html } = options || {};

      return new Promise((resolve) => {
         Swal.fire({
            text: t(message || ''),
            icon: 'error',
            html,
            buttonsStyling: false,
            confirmButtonText: t('close'),
            customClass: {
               confirmButton: 'btn fw-bold btn-light-danger alert-confirm-button',
            },
         }).then(function (resp) {
            resolve(resp);
         });
      });
   }

   public static requiredError(message) {
      const { t } = i18n.global;
      const text = t(message) + ' ' + t('is required');
      Swal.fire({
         text: text,
         icon: 'error',
         buttonsStyling: false,
         confirmButtonText: t('close'),
         customClass: {
            confirmButton: 'btn fw-bold btn-light-danger alert-confirm-button',
         },
      });
   }

   public static async warning(message, confirmButtonText = 'delete', cancelButtonText = 'Cancel') {
      const { t } = i18n.global;
      const text = t(message);

      return await Swal.fire({
         title: t(text),
         showCancelButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: '#FF5D5D',
         confirmButtonText: t(confirmButtonText),
         cancelButtonText: t(cancelButtonText),
         buttonsStyling: true,
      });
   }

   public static async successWithCustomButton(
      message,
      {
         confirmButtonText = 'Ok, got it!',
         cancelButtonText = 'Cancel',
         cancelButtonColor = '#FF5D5D',
      },
   ) {
      const { t } = i18n.global;
      const text = t(message);
      let result: any = false;

      await Swal.fire({
         title: t(text),
         icon: 'success',
         showCancelButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: cancelButtonColor,
         confirmButtonText: t(confirmButtonText),
         cancelButtonText: t(cancelButtonText),
         buttonsStyling: true,
      }).then((confirmed) => {
         result = confirmed;
      });

      return result;
   }

   public static async successWithPrintButton(message) {
      const { t } = i18n.global;
      const text = t(message);

      const res = await Swal.fire({
         title: t(text),
         icon: 'success',
         showCancelButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: '#aaaaaa',
         confirmButtonText: t('Ok, got it!'),
         cancelButtonText: t('Print'),
         buttonsStyling: true,
         customClass: {
            cancelButton: 'swal-print-button alert-cancel-button',
         },
      });

      return res;
   }

   public static async successWithNew(message) {
      const { t } = i18n.global;
      const text = t(message);

      const res = await Swal.fire({
         title: t(text),
         icon: 'success',
         showCancelButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: '#aaaaaa',
         confirmButtonText: t('Ok, got it!'),
         cancelButtonText: t('New'),
         buttonsStyling: true,
         customClass: {
            cancelButton: 'swal-new-button alert-cancel-button',
         },
      });

      return { new: !res.isConfirmed };
   }

   public static async successWithNewAndPrint(message, title = '') {
      const { t } = i18n.global;
      const text = t(message);

      const res = await Swal.fire({
         title: title != '' ? t(message) : '',
         text: title != '' ? title : t(message),
         icon: 'success',
         showCancelButton: true,
         showDenyButton: true,
         confirmButtonColor: '#3360F2',
         cancelButtonColor: '#aaaaaa',
         denyButtonColor: '#aaaaaa',
         confirmButtonText: t('Ok'),
         cancelButtonText: t('New'),
         denyButtonText: t('Print'),
         buttonsStyling: true,
         customClass: {
            cancelButton: 'swal-new-button alert-cancel-button',
            denyButton: 'swal-print-button alert-cancel-button',
            confirmButton: 'w-100px',
         },
      });
      return { new: res.isDismissed, print: res.isDenied };
   }

   public static close() {
      Swal.close();
   }
}

export default AlertService;
