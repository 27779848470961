const apiPath = '/hr/attendance/hr-leave-requests';
const path = 'LeaveRequestModule';
const LeaveRequestAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const LeaveRequestActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  APPROVE_ITEM: `${path}/approveItem`,
  UNAPPROVE_ITEM: `${path}/unapproveItem`,
};

export { LeaveRequestAPIsConstants, LeaveRequestActions };
