const path = 'ListModule';
const ListActions = {
  // action types
  SET_LIST : `${path}/SET_LIST`,
  GET_LIST_BY_NAME : `${path}/getListByName`,
  GET_BULK_LIST_BY_NAME : `${path}/getBulkListByName`,
  GET_EXTERNAL_LIST_BY_NAME : `${path}/getExternalListByName`,
  GET_BULK_EXTERNAL_LIST_BY_NAME : `${path}/getBulkExternalListByName`,
  CLEAR_CACHE : `${path}/clearCache`,
  RESET_LIST : `${path}/resetList`,

}

export { ListActions };
