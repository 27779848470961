const path = 'CargoJournalEntryPostingsModule';
const apiPath = '/cargo/cargo-accounting';

const CargoJournalEntryPostingsModelActions = {
  // action types
  MAKE_ITEM: `${path}/createPosting`,

}

const CargoJournalEntryPostingsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { CargoJournalEntryPostingsAPIsConstants, CargoJournalEntryPostingsModelActions };
