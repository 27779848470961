const apiPath = '/hr/salaries/hr-installment-movements';
const path = 'InstallmentsModule';

const InstallmentsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const InstallmentsModelActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  SETTLE_INSTALLMENT: `${path}/settleInstallment`,
  // IMPORT_ITEM_BY_ID: `${path}/importItemById`,
}
export { InstallmentsAPIsConstants, InstallmentsModelActions };
