const path = 'ExtraHoursReportModule';
const apiPath = 'hr/attendance/report';

const ExtraHoursReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  GET_MY_REPORT: `${path}/getMyReport`,
  // GENERATE_DETAILED_AttendanceDeparture_REPORT : `${path}/generateDetailedExtraHoursReport`,
}

const ExtraHoursReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {ExtraHoursReportAPIsConstants, ExtraHoursReportActions };
