import { RouteRecordRaw } from 'vue-router';

const dailyMovementRouter: Array<RouteRecordRaw> = [
   {
      path: 'customs-journal-posting',
      name: 'customs-journal-posting',
      component: () => import('@/views/modules/customs/views/settings/journal-posting/base.vue'),
   },
   {
      path: '/customs-management/customs-dashboard',
      name: 'customs-management-dashboard',
      meta: {
         title: 'Customs',
      },
      component: () => import('@/views/modules/customs/views/CustomsDashboard.vue'),
   },
   {
      path: '/customs-management/customs-model-settings',
      name: 'customs-management-customs-model-settings',
      meta: {
         permission: 'customs_invoice_models',
         title: 'Workflow Forms',
         type: 'read',
      },
      component: () => import('@/views/modules/customs/views/settings/invoice-model/List.vue'),
   },
   {
      path: '/customs-management/add-model-settings',
      name: 'customs-management-customs-add-model-settings',
      meta: {
         permission: 'customs_invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'update-model-settings/:id',
      name: 'customs-management-customs-update-model-settings',
      props: true,
      meta: {
         permission: 'customs_invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'view-model-settings/:id',
      name: 'customs-management-customs-view-model-settings',
      props: true,
      meta: {
         permission: 'customs_invoice_models',
         title: 'Workflow Forms',
      },
      component: () => import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'customs-sales-financial-transactions',
      name: 'customs-management-sales-financial-transactions',
      meta: {
         title: 'Financial Transactions',
      },
      component: () =>
         import('@/views/modules/customs/views/settings/financial-transactions/sales/List.vue'),
   },
   {
      path: 'add-customs-sales-financial-transactions',
      name: 'add-customs-sales-financial-transactions',
      meta: {
         title: 'Financial Transactions',
      },
      component: () =>
         import('@/views/modules/customs/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'update-customs-sales-financial-transactions/:id',
      name: 'update-customs-sales-financial-transactions',
      meta: {
         title: 'Financial Transactions',
      },
      component: () =>
         import('@/views/modules/customs/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'customs-sales-financial-transactions/:id/:type',
      name: 'customs-management-view-sales-financial-transactions',
      meta: {
         title: 'Financial Transactions',
      },
      component: () =>
         import('@/views/modules/customs/views/settings/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'invoices/:type/:modelId/:invoiceId',
      name: 'customs-management-view-invoice',
      meta: {
         permission: 'customs_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      props: true,
      component: () => import('@/views/modules/customs/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'invoices/edit/:modelId/:invoiceId',
      name: 'customs-management-edit-invoice',
      meta: {
         permission: 'customs_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/customs/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'invoices/add/:modelId',
      name: 'customs-management-add-invoice',
      meta: {
         permission: 'customs_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/customs/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'customs-invoices/list/:modelId',
      name: 'customs-management-sales-invoices',
      meta: {
         permission: 'customs_invoices',
         title: 'sales daily transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/customs/views/settings/invoices/sales/List.vue'),
   },
   {
      name: 'customs-reopen-deal',
      path: 'customs-reopen-deal',
      meta: {
         title: 'Reopen a Deal',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/deals/reopen-deal/List.vue'),
   },
   {
      name: 'customs-reopen-deal-add',
      path: 'customs-reopen-deal/add',
      meta: {
         title: 'Reopen a Deal',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/deals/reopen-deal/Add.vue'),
   },
   {
      name: 'customs-close-deal',
      path: 'customs-close-deal',
      meta: {
         title: 'Close a Deal',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/deals/close-deal/List.vue'),
   },
   {
      name: 'customs-close-deal-add',
      path: 'customs-close-deal/add',
      meta: {
         title: 'Close a Deal',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/deals/close-deal/Add.vue'),
   },
   {
      path: 'deals/add',
      name: 'customs-deals-add',
      meta: {
         title: 'Received Deals',
      },
      component: () => import('@/views/modules/customs/views/daily-movements/deals/Add.vue'),
   },
   {
      path: 'deals/edit/:id',
      name: 'customs-deals-edit',
      meta: {
         title: 'Received Deals',
      },
      component: () => import('@/views/modules/customs/views/daily-movements/deals/Add.vue'),
   },
   {
      path: 'deals/:id/:view',
      name: 'customs-deals-view',
      meta: {
         title: 'Received Deals',
      },
      component: () => import('@/views/modules/customs/views/daily-movements/deals/Add.vue'),
   },

   {
      path: 'update-deals',
      name: 'customs-update-deals-list',
      meta: {
         title: 'Enter Deal Procedure',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/update-deals/List.vue'),
   },

   {
      path: 'receipt-voucher',
      name: 'customs-receipt-voucher',
      meta: {
         permission: 'customs_receipt_vouchers',
         title: 'receipt vouchers',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/receipt-voucher/List.vue'),
   },

   {
      path: 'customs-receipt-view-model-settings/:id',
      name: 'customs-receipt-customs-management-view-model-settings',
      props: true,
      meta: {
         permission: 'customs_receipt_vouchers',
         title: 'receipt vouchers',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'customs-receipt-voucher-add',
      name: 'customs-receipt-voucher-add',
      meta: {
         permission: 'customs_receipt_vouchers',
         title: 'receipt vouchers',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'update-receipt-voucher/:id',
      name: 'customs-receipt-voucher-update',
      meta: {
         permission: 'customs_receipt_vouchers',
         title: 'receipt vouchers',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/receipt-voucher/Add.vue'),
   },
   {
      path: 'update-receipt-voucher/:id/:view',
      name: 'customs-receipt-voucher-view',
      meta: {
         permission: 'customs_receipt_vouchers',
         title: 'receipt vouchers',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/receipt-voucher/Add.vue'),
   },
   //end::receipt-voucher
   //begin::payment-voucher
   {
      path: 'payment-voucher',
      name: 'customs-payment-voucher',
      meta: {
         permission: 'customs_payment_vouchers',
         title: 'payment vouchers',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/payment-voucher/List.vue'),
   },
   {
      path: 'customs-management-payment-view-model-settings/:id',
      name: 'customs-management-payment-view-model-settings',
      props: true,
      meta: {
         permission: '',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'customs-payment-voucher-add',
      name: 'customs-payment-voucher-add',
      meta: {
         permission: 'customs_payment_vouchers',
         title: 'payment vouchers',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'update-payment-voucher/:id',
      name: 'customs-payment-voucher-update',
      meta: {
         permission: 'customs_payment_vouchers',
         title: 'payment vouchers',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/payment-voucher/Add.vue'),
   },
   {
      path: 'update-payment-voucher/:id/:view',
      name: 'customs-payment-voucher-view',
      meta: {
         permission: 'customs_payment_vouchers',
         title: 'payment vouchers',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/payment-voucher/Add.vue'),
   },
   //end::payment-voucher
   {
      path: 'journal-posting',
      name: 'customs-journal-posting',
      meta: {
         title: 'Post to Accounting',
         permission: 'customs_postings',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/journal-posting/base.vue'),
   },
   {
      path: 'transferring-log',
      name: 'customs-transferring-log',
      meta: {
         title: 'transferringLog',
         permission: 'customs_postings',
      },
      component: () =>
         import('@/views/modules/customs/views/daily-movements/journal-posting/List.vue'),
   },
];

export default dailyMovementRouter;
