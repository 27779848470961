import { mergeCrudStore } from '@/core/stores/curd';
import axios from 'axios';
import { PurchaseFinancialTransactionAPIsConstants } from '../enums/PurchaseFinancialTransactionEnums';

const PurchaseFinancialTransactionModule = mergeCrudStore({
   namespaced: true,
   actions: {
      getInitData(_, { params }) {
         return axios.get(`${PurchaseFinancialTransactionAPIsConstants.API_PATH}/get-init-data`, {
            params,
         });
      },
      getData({ commit, state, dispatch }, apiPath) {
         commit('SET_LOADING', true);
         let query = state.isPagination
            ? `?pageSize=${state.pagination.rowsPerPage}&page=${state.pagination.page}`
            : '';
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}${query}`)
               .then((res) => {
                  if (state.isPagination)
                     commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
                  commit('SET_DATA_TABLE', {
                     DataTable: res.data.result[state.dataName].data,
                  });
                  commit('SET_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  console.log(error);
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      findItemById({ commit, state }, { apiPath, id, params }) {
         commit('SET_LOADING_ITEM', true);
         axios
            .get(`${apiPath}/${id}`, { params })
            .then((res) => {
               commit('SET_LOADING_ITEM', false);
               commit('SET_ITEM_EDITABLE', {
                  itemEditable: JSON.parse(JSON.stringify(res.data.result)),
               });
            })
            .catch((error) => {
               commit('SET_LOADING_ITEM', false);
               console.log(error);
            });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
});

export default PurchaseFinancialTransactionModule;
