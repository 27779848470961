import axios from "axios";
import {CargoDashboardModelAPIsConstants} from "@/views/modules/cargo/store/enums/CargoDashboardModelEnum";

const CargoDashboardModule = {
    namespaced: true,
    state: {
        salesDashboardValues: {},
        purchaseDashboardValues: {},
        cargoDashboardValues: {},
        loading: false,
        crudLoading: false,
        loadingItem: false,
    },
    mutations: {
        SET_CARGO_DASHBOARD_MODEL_DATA(state, payload) {
            state.cargoDashboardValues = payload.item;
        },
        SET_SALES_DASHBOARD_MODEL_DATA(state, payload) {
            state.salesDashboardValues = payload.item;
        },
        SET_PURCHASE_DASHBOARD_MODEL_DATA(state, payload) {
            state.purchaseDashboardValues = payload.item;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_CRUD_LOADING(state, payload) {
            state.crudLoading = payload;
        },
        SET_LOADING_ITEM(state, payload) {
            state.loadingItem = payload;
        },
        RESET_ALL(state) {
            state.loading = false;
            state.salesDashboardValues = {};
            state.purchaseDashboardValues = {};
        },
    },
    actions: {
        GetSalesStatisticData({commit, dispatch}) {
            commit('SET_LOADING',true)
            return new Promise((resolve, reject) => {
                axios.get(`${CargoDashboardModelAPIsConstants.API_PATH}/get-sales-statistic-data`)
                    .then((response) => {
                        commit('SET_SALES_DASHBOARD_MODEL_DATA', {
                            item: response.data.result
                        });
                        commit('SET_LOADING',false)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LOADING',false)
                        reject(error)
                    })
            })
        },
        GetPurchaseStatisticData({commit, dispatch}) {
            commit('SET_LOADING',true)
            return new Promise((resolve, reject) => {
                axios.get(`${CargoDashboardModelAPIsConstants.API_PATH}/get-purchase-statistic-data`)
                    .then((response) => {
                        commit('SET_PURCHASE_DASHBOARD_MODEL_DATA', {
                            item: response.data.result
                        });
                        commit('SET_LOADING',false)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LOADING',false)
                        reject(error)
                    })
            })
        },
        
        getCargoStatisticData({commit, dispatch}) {
            commit('SET_LOADING',true)
            return new Promise((resolve, reject) => {
                axios.get(`${CargoDashboardModelAPIsConstants.API_PATH}/get-cargo-statistic-data`)
                    .then((response) => {
                        commit('SET_CARGO_DASHBOARD_MODEL_DATA', {
                            item: response.data.result
                        });
                        commit('SET_LOADING',false)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LOADING',false)
                        reject(error)
                    })
            })
        },
        
    },
    getters: {}
}

export default CargoDashboardModule
