import axios from 'axios';

const apiPath = '/pos/sales-invoice/get-invoice-model-by-invoice-number';
const path = 'PosInvoiceSearchModule';

export const PosInvoiceSearchAPIsConstants = {
   API_PATH: apiPath,
};

export const PosInvoiceSearchActions = {
   GET_INVOICE_BY_NUMBER: `${path}/getInvoiceByNumber`,
};

const PosInvoiceSearchModule = {
   namespaced: true,
   actions: {
      getInvoiceByNumber({ commit }, { invoice_number }) {
         return axios.get(PosInvoiceSearchAPIsConstants.API_PATH, { params: { invoice_number } });
      },
   },
};

export default PosInvoiceSearchModule;
