const path = 'GeneralJournalReportModule';
const apiPath = '/accounting/report';

const GeneralJournalReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_DETAILED_GENERAL_JOURNAL_REPORT : `${path}/generateDetailedGeneralJournalReport`,
}

const GeneralJournalReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {GeneralJournalReportAPIsConstants, GeneralJournalReportModelActions };
