import axios from 'axios';

const path = 'Meals';
const apiPath = '/restaurant/meals';

export const MealsAPIsConstants = {
   API_PATH: `${apiPath}`,
   MEAL_ITEM: (id) => `${apiPath}/${id}`,
};

export const MealsActions = {
   ADD_MEAL: `${path}/addMeal`,
   GET_MEAL: `${path}/getMeal`,
};

const MealsStore = {
   namespaced: true,
   state: {
      crudLoading: false,
   },
   mutations: {
      SET_CRUD_LOADING(state, val) {
         state.crudLoading = val;
      },
   },
   actions: {
      addMeal({ commit }, { data }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(MealsAPIsConstants.API_PATH, data).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
      getMeal({ commit }, { id }) {
         commit('SET_CRUD_LOADING', true);

         return axios.get(MealsAPIsConstants.MEAL_ITEM(id)).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
   },
};

export default MealsStore;
