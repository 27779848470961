// import {sassTrue} from "sass";

export const ModalNames = {
   WAREHOUSES: 'WAREHOUSES',
   ACCOUNTS: 'ACCOUNTS',
   VENDORS: 'VENDORS',
   CLIENTS: 'CLIENTS',
   UNITS: 'UNITS',
};

const ModalModule = {
   namespaced: true,
   state: {
      ModelOpen: false,
      ModelViewOpen: false,
      isOpenSecondaryModal: false,
      isOpenInfoModal: false,
      secondaryModalValue: '',
      params: {},
      openSpecific: {},
      newItemValue: {},
   },
   mutations: {
      SET_MODEL_OPEN(state, payload) {
         if (payload.open && !state.ModelOpen) state.ModelOpen = payload.open;
         else if (payload.open && state.ModelOpen) state.isOpenSecondaryModal = payload.open;

         if (!payload.open && state.isOpenSecondaryModal) {
            state.isOpenSecondaryModal = payload.open;
         } else state.ModelOpen = payload.open;

         state.params = payload.params;
      },
      SET_SECONDARY_MODAL_VALUE(state, id) {
         state.secondaryModalValue = id;
      },
      SET_MODEL_OPEN_VIEW(state, payload) {
         state.ModelViewOpen = payload.open;
         state.params = payload.params;
      },
      SET_MODEL_OPEN_INFO(state, payload) {
         state.isOpenInfoModal = payload.open;
      },
      SET_MODEL_SPECIFIC(state, payload) {
         state.openSpecific[payload.modalName] = payload.open;
      },
      SET_NEW_ITEM_VALUE(state, { modalName, id }) {
         state.newItemValue = { ...state.newItemValue, [modalName]: id };
      },
   },
   actions: {
      closeSpecificModel({ commit }, modalName) {
         commit('SET_MODEL_SPECIFIC', { open: false, modalName: modalName });
      },
      openSpecificModel({ commit }, modalName) {
         commit('SET_MODEL_SPECIFIC', { open: true, modalName: modalName });
      },

      closeModel({ commit }) {
         commit('SET_MODEL_OPEN', { open: false, params: {} });
      },
      closeViewModel({ commit }) {
         commit('SET_MODEL_OPEN_VIEW', { open: false, params: {} });
      },
      closeInfoModel({ commit }) {
         commit('SET_MODEL_OPEN_INFO', { open: false });
      },
      openModel({ commit }, params = {}) {
         commit('SET_MODEL_OPEN', { open: true, params });
      },
      openViewModel({ commit }, params = {}) {
         commit('SET_MODEL_OPEN_VIEW', { open: true, params });
      },
      openInfoModel({ commit }) {
         commit('SET_MODEL_OPEN_INFO', { open: true });
      },
      setSecondaryModalValue({ commit }, id) {
         commit('SET_SECONDARY_MODAL_VALUE', id);
      },
      setNewItemValue({ commit }, { modalName, id }) {
         commit('SET_NEW_ITEM_VALUE', { modalName, id });
      },
   },
   getters: {},
};
export default ModalModule;
