const apiPath = '/hr/hr-vacation-movements';
const path = 'VacationModule';

const VacationAPIsConstants = {
   API_PATH: `${apiPath}`,
   APPROVE: (id) => `${apiPath}/approve-request/${id}`,
   REJECT: (id) => `${apiPath}/reject-request/${id}`,
};

const VacationModelActions = {
   ADD_ITEM: `${path}/addItem`,
   UPDATE_ITEM: `${path}/updateItem`,
   EDIT_ITEM: `${path}/editItem`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   CALCULATE_AMOUNT: `${path}/calculateAmount`,
   APPROVE: `${path}/approve`,
   REJECT: `${path}/reject`,
};
export { VacationAPIsConstants, VacationModelActions };
