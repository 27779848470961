const path = 'FinancialYear';
const apiPath = '/core/financial-year';

const FinancialYearActions = {
  // action types
  GET_DATA : `${path}/getFinancialYear`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
}

const FinancialYearMutations = {
  // mutation types
  SET_ERROR : `${path}/setError`,
  RESET_DATA : `${path}/resetData`,
  SET_DATA : `${path}/setFinancialYear`,
  SET_LIST_DATA : `${path}/setListData`,
}

const FinancialYearAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { FinancialYearActions, FinancialYearMutations,FinancialYearAPIsConstants };
