const PATH = '/electronic-approval-management';

const ElectronicApprovalsMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 45,
      pages: [
         {
            heading: 'Electronic Approval Settings',
            route: `${PATH}/settings`,
            permission: 'e_approval_setting',
         },
         {
            heading: 'Transactions Settings',
            route: `${PATH}/transactions-settings`,
            type: 'write',
            permission: 'e_approval_movements',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '#',
      module_id: 45,
      pages: [
         {
            heading: 'Approvals',
            route: `${PATH}/approvals`,
            permission: 'e_approval_movements',
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [],
   },
];
export default ElectronicApprovalsMenuConfig;
