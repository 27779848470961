import axios from 'axios';
import { LeaveRequestAPIsConstants } from "./../enums/daily-movements/LeaveRequestEnums";

const LeaveRequestModule = {
  namespaced: true,
    state:{
        loadingItem: false,
        itemEditable: {},
    },

  mutations: {
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },

    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },

    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item["translations"] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },

    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },

    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = "";
      state.loading = false;
      state.itemEditable = {};
      state.tableFilters = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
  },

  actions: {
    
    approveItem({ commit, state }, { apiPath, id }) {
        commit('SET_CRUD_LOADING', true);
        return new Promise((resolve, reject) => {
            axios
                .get(`${LeaveRequestAPIsConstants.API_PATH}/approve-request/${id}`)
                .then((response) => {
                    commit('SET_CRUD_LOADING', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('SET_CRUD_LOADING', false);
                    reject(error);
                });
        });
    },
    unapproveItem({ commit, state }, { apiPath, id }) {
        commit('SET_CRUD_LOADING', true);
        return new Promise((resolve, reject) => {
            axios
                .get(`${LeaveRequestAPIsConstants.API_PATH}/reject-request/${id}`)
                .then((response) => {
                    commit('SET_CRUD_LOADING', false);
                    resolve(response);
                })
                .catch((error) => {
                    commit('SET_CRUD_LOADING', false);
                    reject(error);
                });
        });
    },
    findItemById({ commit, state }, { id }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${LeaveRequestAPIsConstants.API_PATH}/${id}`)
          .then((res) => {
            commit("SET_LOADING_ITEM", false);
            commit("SET_ITEM_EDITABLE", {
              itemEditable: res.data.result,
            });
            resolve(res);
          })
          .catch((error) => {
            commit("SET_LOADING_ITEM", false);
            reject(error);
          });
      });
    },

    addItem({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    editItem({ commit, state }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    updateItem({ commit, state }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },

    calculateAmount({ commit }, { apiPath, credentials }) {
      commit("SET_CRUD_LOADING", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/calculate-amount`, { params: credentials })
          .then((response) => {
            commit("SET_CRUD_LOADING", false);
            resolve(response);
          })
          .catch((error) => {
            commit("SET_CRUD_LOADING", false);
            reject(error);
          });
      });
    },
  },
};

export default LeaveRequestModule;
