import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
   apiKey: 'AIzaSyAExvmJmNmDycmmUeDLMOH5rbcfYcqzNVI',
   authDomain: 'doso-erp.firebaseapp.com',
   projectId: 'doso-erp',
   storageBucket: 'doso-erp.appspot.com',
   messagingSenderId: '723162740792',
   appId: '1:723162740792:web:c80e8fce7d9fec369c4ecd',
   measurementId: 'G-ZBYVGP6TDY',
};

let messaging = {};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);
isSupported().then((supported) => {
   if (!supported) return;

   messaging = getMessaging(firebaseApp);
});
