import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'transport-dashboard',
      name: 'transport-dashboard',
      meta: {
         title: 'Transportation',
      },
      component: () => import('@/views/modules/cargo/views/dashboard/Index.vue'),
   },
   {
      path: 'settings-transportation',
      name: 'settings-transportation',
      meta: {
         permission: 'transport_setting',
         title: 'Transportation Settings',
         // type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/settings/Setting.vue'),
   },
   {
      path: 'unit',
      name: 'transportation-unit',
      meta: {
         permission: 'units',
         title: 'Unit Of Measure',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/unit/List.vue'),
   },
   {
      path: 'taxes',
      name: 'transportation-taxes',
      meta: {
         permission: 'taxes',
         title: 'Taxes',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/taxes/List.vue'),
   },
   {
      path: 'salesman',
      name: 'transportation-salesman',
      meta: {
         permission: 'salesmen',
         title: 'salesmans',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/salesman/List.vue'),
   },
   {
      path: 'client-group',
      name: 'transportation-client-group',
      meta: {
         permission: 'client_groups',
         title: 'Clients Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client-group/List.vue'),
   },
   {
      path: 'clients',
      name: 'transportation-clients',
      meta: {
         permission: 'clients',
         title: 'Clients',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client/List.vue'),
   },
   {
      path: 'transporter-group',
      name: 'transportation-transporter-group',
      meta: {
         permission: 'vendor_groups',
         title: 'Transporter Groups',
      },
      component: () => import('@/views/modules/cargo/views/crud/transporter-group/List.vue'),
   },
   {
      path: 'transporter',
      name: 'transportation-transporter',
      meta: {
         title: 'Transporters',
      },
      component: () => import('@/views/modules/cargo/views/crud/transporter/List.vue'),
   },
   {
      path: 'services',
      name: 'transport-management-services',
      meta: {
         permission: 'transport_products',
         title: 'products',
      },
      component: () => import('@/views/modules/cargo/views/crud/products/main.vue'),
      children: [
         {
            path: 'add-service',
            name: 'transport-management-add-service',
            meta: {
               permission: 'transport_products',
               title: 'Services',
               type: 'write',
            },
            component: () => import('@/views/modules/cargo/views/crud/products/Add.vue'),
         },
         {
            path: 'update-service/:id',
            name: 'transport-management-update-service',
            meta: {
               permission: 'transport_products',
               title: 'Services',
               type: 'write',
            },
            component: () => import('@/views/modules/cargo/views/crud/products/Add.vue'),
         },
         {
            path: 'view-service/:id',
            name: 'transport-management-view-service',
            meta: {
               permission: 'transport_products',
               title: 'Services',
               type: 'write',
            },
            component: () => import('@/views/modules/cargo/views/crud/products/Add.vue'),
         },
      ],
   },
   {
      path: 'model-settings',
      name: 'transport-management-transport-model-settings',
      meta: {
         permission: 'transport_invoice_models',
         title: 'Workflow Forms',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoice-model/List.vue'),
   },
   {
      path: 'add-model-settings',
      name: 'transport-management-transport-add-model-settings',
      meta: {
         permission: 'transport_invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'update-model-settings/:id',
      name: 'transport-management-transport-update-model-settings',
      props: true,
      meta: {
         permission: 'transport_invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'view-model-settings/:id',
      name: 'transport-management-transport-view-model-settings',
      props: true,
      meta: {
         permission: 'transport_invoice_models',
         title: 'Workflow Forms',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
   },

   {
      path: 'transport-directions',
      name: 'transport-directions',
      meta: {
         permission: 'transport_directions',
         title: 'Directions',
      },
      component: () => import('@/views/modules/cargo/views/crud/directions/List.vue'),
   },
   {
      path: 'transport-drivers',
      name: 'transport-drivers',
      meta: {
         permission: 'transport_drivers',
         title: 'Drivers',
      },
      component: () => import('@/views/modules/cargo/views/crud/drivers/List.vue'),
   },
   {
      path: 'transport-trucks',
      name: 'transport-trucks',
      meta: {
         permission: 'transport_trucks',
         title: 'Trucks',
      },
      component: () => import('@/views/modules/cargo/views/crud/trucks/List.vue'),
   },
   {
      path: 'transport-trailers',
      name: 'transport-trailers',
      meta: {
         permission: 'transport_trailers',
         title: 'Trailers',
      },
      component: () => import('@/views/modules/cargo/views/crud/trailers/List.vue'),
   },
   {
      path: 'transport-cars',
      name: 'transport-cars',
      meta: {
         permission: 'transport_cars',
         title: 'Cars',
      },
      component: () => import('@/views/modules/cargo/views/crud/cars/List.vue'),
   },
   {
      path: 'transport-car-expenses',
      name: 'transport-car-expenses',
      meta: {
         permission: 'transport_car_expenses',
         title: 'Cars Expenses',
      },
      component: () => import('@/views/modules/cargo/views/crud/car-expenses/List.vue'),
   },
];

export default settingRouter;
