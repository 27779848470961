const apiPath = '/hr/muqeem/occupation';
const path = 'UpdateEmployeeJob';

const UpdateEmployeeJobAPIsConstants = {
   API_PATH: `${apiPath}`,
   CHANGE: `${apiPath}/change`,
};

const UpdateEmployeeJobActions = {
   CHANGE: `${path}/change`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
};

export { UpdateEmployeeJobAPIsConstants, UpdateEmployeeJobActions };
