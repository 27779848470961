import { RouteRecordRaw } from 'vue-router';

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      children: [
         {
            path: 'offers',
            name: 'pos-offers-reports',
            meta: {
               title: 'Offers',
               permission: 'pos_reports',
            },
            component: () =>
               import('@/views/modules/pos/views/reports/offers/base.vue'),
         },
         {
            path: 'shift-transaction',
            name: 'pos-shift-transaction-reports',
            meta: {
               title: 'Shift Transaction',
               permission: 'pos_reports',
            },
            component: () =>
               import('@/views/modules/pos/views/reports/shift-movement/base.vue'),
         },
         {
            path: 'invoices',
            name: 'pos-invoices-reports',
            meta: {
               title: 'Invoicing',
               permission: 'pos_reports',
            },
            component: () =>
               import('@/views/modules/pos/views/reports/invoices/base.vue'),
         },
         {
            path: 'draft-invoices',
            name: 'pos-draft-invoices-reports',
            meta: {
               title: 'Draft Invoices',
               permission: 'pos_reports',
            },
            component: () =>
               import('@/views/modules/pos/views/reports/draft-invoices/base.vue'),
         },
         {
            path: 'daily-sales-summary',
            name: 'pos-daily-sales-summary-reports',
            meta: {
               title: 'Daily Sales Summary',
               permission: 'pos_reports',
            },
            component: () =>
               import('@/views/modules/pos/views/reports/daily-sales-summary/base.vue'),
         },
      ],
   },
];

export default reportRouter;
