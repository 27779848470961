const http = process.env.NODE_ENV == 'development' ? 'http://' : 'https://';

class UrlService {
   public static redirectWithSubdomain(path = '') {
      window.location.href = `${http}${process.env.VUE_APP_DOMAIN_NAME}${path}`;
   }

   public static redirectWithoutSubdomain(path = '') {
      window.location.href = `${http}${process.env.VUE_APP_DOMAIN_NAME}${path}`;
   }

   /**
    * get module name from path like attendance-settings
    */
   public static getModuleName() {
      let moduleName = '';
      if (window?.location && window?.location?.hash) {
         moduleName = window.location.hash.split('/')[1];
      }
      return moduleName;
   }

   /**
    * get module name from path like attendance
    */
   public static getModuleName2() {
      let moduleName = '';
      if (window?.location && window?.location?.hash) {
         moduleName = window.location.hash.split('/')[1];
         if (moduleName === 'settings') moduleName = window.location.hash.split('/')[2];
      }
      return moduleName?.split('-')?.[0];
   }

   /**
    * Check if i am in specific module
    * @param moduleName MODULES_FIRST_NAMES
    */
   public static isSpecificModule(moduleName) {
      return this.getModuleName2() === moduleName;
   }
}

export default UrlService;
