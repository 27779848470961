const path = 'SalariesSettingModule';
const apiPath = '/salaries/setting';

const SalariesSettingModelActions = {
  // action types
  GET_SETTINGS : `${path}/getSetting`,
  UPDATE_SETTINGS : `${path}/updateSetting`

}

const SalariesSettingAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  GET_SETTINGS : `${apiPath}/get-setting`,
  UPDATE_SETTINGS : `${apiPath}/update-setting`,
}

export { SalariesSettingAPIsConstants, SalariesSettingModelActions };
