const path = 'GuestModule';
const apiPath = 'guest';

const GuestActions = {
    // action types
    SEND_CONTACT_FORM : `${path}/SendContactForm`,
}
const GuestEnumAPIsConstants = {
    API_PATH : `${apiPath}`
}
export { GuestActions,GuestEnumAPIsConstants };
