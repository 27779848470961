import axios from 'axios';

const path = 'AdminPrivacyPolicySettings';
const apiPath = '/admin-panal/privacy-policies';

export const PrivacyPolicyActions = {
   GET_SETTINGS: `${path}/getSetting`,
   UPDATE_SETTINGS: `${path}/updateSetting`,
};

export const PrivacyPolicyAPIsConstants = {
   API_PATH: apiPath,
};

const RestaurantsSettings = {
   namespaced: true,
   state: {
      loading: false,
      crudLoading: false,
      loadingItem: false,
      itemEditable: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      SET_SETTING(state, payload) {
         const data = payload.data;
         state.itemEditable = data.item;
      },
   },
   actions: {
      getSetting({ commit }) {
         commit('SET_LOADING_ITEM', true);

         return axios
            .get(`${apiPath}/1`)
            .then((res) => {
               commit('SET_SETTING', { data: res.data.result });
               return res;
            })
            .finally(() => commit('SET_LOADING_ITEM', false));
      },
      updateSetting({ commit }, credentials) {
         commit('SET_CRUD_LOADING', true);

         return axios
            .put(`${apiPath}/1`, credentials)
            .finally(() => commit('SET_CRUD_LOADING', false));
      },
   },
};

export default RestaurantsSettings;
