import { RouteRecordRaw } from 'vue-router';

const invoiceRouter: Array<RouteRecordRaw> = [
   {
      path: 'sales-invoices/edit/:modelId/:invoiceId',
      name: 'transport-management-edit-sales-invoice',
      meta: {
         permission: 'transport_sales_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/:type/:modelId/:invoiceId',
      name: 'transport-management-view-sales-invoice',
      meta: {
         permission: 'transport_sales_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      props: true,
      component: () => import('@/views/modules/cargo/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/add/:modelId/:noticeIds?',
      name: 'transport-management-add-sales-invoice',
      meta: {
         permission: 'transport_sales_invoices',
         title: 'sales daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/sales/Add.vue'),
   },
   {
      path: 'sales-invoices/list/:modelId',
      name: 'transport-management-sales-invoices',
      meta: {
         permission: 'transport_sales_invoices',
         title: 'sales daily transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/sales/List.vue'),
   },
   {
      path: 'purchase-invoices/edit/:modelId/:invoiceId',
      name: 'transport-management-edit-purchase-invoice',
      meta: {
         permission: 'transport_purchase_invoices',
         title: 'purchase daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoices/:type/:modelId/:invoiceId',
      name: 'transport-management-view-purchase-invoice',
      meta: {
         permission: 'transport_purchase_invoices',
         title: 'purchase daily transactions',
         type: 'write',
      },
      props: true,
      component: () => import('@/views/modules/cargo/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoices/add/:modelId/:noticeIds?',
      name: 'transport-management-add-purchase-invoice',
      meta: {
         permission: 'transport_purchase_invoices',
         title: 'purchase daily transactions',
         type: 'write',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/purchase/Add.vue'),
   },
   {
      path: 'purchase-invoice/list/:modelId',
      name: 'transport-management-purchase-invoices',
      meta: {
         permission: 'transport_purchase_invoices',
         title: 'purchase daily transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/settings/invoices/purchase/List.vue'),
   },

   {
      path: 'purchase-financial-transactions',
      name: 'transport-management-purchase-financial-transactions',
      meta: {
         permission: 'transport_purchase_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/cargo/views/financial-transactions/purchase/List.vue'),
   },
   {
      path: 'add-purchase-financial-transactions',
      name: 'transport-management-add-purchase-financial-transactions',
      meta: {
         permission: 'transport_purchase_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/cargo/views/financial-transactions/purchase/Add.vue'),
   },
   {
      path: 'update-purchase-financial-transactions/:id',
      name: 'transport-management-update-purchase-financial-transactions',
      meta: {
         permission: 'transport_purchase_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () =>
         import('@/views/modules/cargo/views/financial-transactions/purchase/Add.vue'),
   },
   {
      path: 'purchase-financial-transactions/:id/:type',
      name: 'transport-management-view-purchase-financial-transactions',
      meta: {
         permission: 'transport_purchase_invoice_financial_transactions',
         title: 'Financial Transactions',
      },
      component: () =>
         import('@/views/modules/cargo/views/financial-transactions/purchase/Add.vue'),
   },

   {
      path: 'sales-financial-transactions',
      name: 'transport-management-sales-financial-transactions',
      meta: {
         permission: 'transport_sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/financial-transactions/sales/List.vue'),
   },
   {
      path: 'add-sales-financial-transactions',
      name: 'transport-management-add-sales-financial-transactions',
      meta: {
         permission: 'transport_sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'update-sales-financial-transactions/:id',
      name: 'transport-management-update-sales-financial-transactions',
      meta: {
         permission: 'transport_sales_invoice_financial_transactions',
         title: 'Financial Transactions',
         type: 'read',
      },
      component: () => import('@/views/modules/cargo/views/financial-transactions/sales/Add.vue'),
   },
   {
      path: 'sales-financial-transactions/:id/:type',
      name: 'transport-management-view-sales-financial-transactions',
      meta: {
         permission: 'transport_sales_invoice_financial_transactions',
         title: 'Financial Transactions',
      },
      component: () => import('@/views/modules/cargo/views/financial-transactions/sales/Add.vue'),
   },
];

export default invoiceRouter;
