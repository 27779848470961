const apiPath = '/hr/hr-sanction-movements';
const path = 'PenaltiesModule';

const PenaltiesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const PenaltiesModelActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  // EXPORT_ITEM_BY_ID: `${path}/exportItemById`,
  // IMPORT_ITEM_BY_ID: `${path}/importItemById`,
}
export { PenaltiesAPIsConstants, PenaltiesModelActions };
