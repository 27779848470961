import { RouteRecordRaw } from 'vue-router';

const receiptRouter: Array<RouteRecordRaw> = [
  {
    path: 'accounting-approval-list',
    name: 'accounting-approval-list',
    meta: {
      // permission: 'approval_list',
      title: 'approval Receipt',
    },
    component: () =>
      import('@/views/modules/accounting/views/receipts/approval-list.vue'),
  },
  {
    path: 'accounting-unapproval-list',
    name: 'accounting-unapproval-list',
    meta: {
      // permission: 'unapproval_list',
      title: 'unapproval Receipt',
    },
    component: () =>
      import('@/views/modules/accounting/views/receipts/unapproval-list.vue'),
  },
];

export default receiptRouter;
