import store from '@/store';
import { Actions } from '@/store/enums/StoreEnums';
import AuthService from '@/core/services/AuthService';
import { MODULES_IDS } from '@/views/modules/shared/constants/ModulesNames';

const PATH = '/settings';
const POS_PATH = 'pos-settings';

const sortModules = async (links) => {
   const currentCompany = await store.dispatch(Actions.GET_CURRENT_COMPANY);
   const modules = currentCompany.modules || [];
   const ids = modules.map((m) => m.id);

   const sortedModules = [...links.filter((l) => !l.module_id)];

   ids.forEach((id) => {
      sortedModules.push(...links.filter((m) => m.module_id == id));
   });

   sortedModules.push(...links.filter((l) => l.module_id && !ids.includes(l.module_id)));

   return sortedModules;
};

const SettingsMenuConfig = async () => {
   const hasAccounting = AuthService.hasModule(MODULES_IDS.ACCOUNTING_MANAGEMENT);
   const isZatcaPhase2 = store.state['CompanyModule'].companySetting?.is_phase2_zatca;

   const links = [
      {
         heading: 'General Settings',
         route: '/settings/general-settings',
         module_id: null,
         pages: [
            {
               heading: 'Company Info',
               route: '/settings/general-settings/company-info',
               permission: 'company',
            },
            {
               heading: 'Branches',
               route: `/settings/general-settings/branches`,
               permission: 'branches',
            },
            isZatcaPhase2 && {
               heading: 'devices',
               route: `/settings/general-settings/zatca-devices`,
               permission: 'branches',
            },
            {
               heading: 'Fiscal Year',
               route: '/settings/general-settings/financial-year',
               permission: 'financial_years',
            },
            hasAccounting && {
               heading: 'Close Fiscal Year',
               permission: 'close_financial_years',
               functionName: 'closeFinancialYear',
               type: 'write',
            },
            hasAccounting && {
               heading: 'cycleAccountsBalance',
               // permission: 'close_financial_years',
               functionName: 'fiscalYearRotation',
               // type: 'write',
            },
            {
               heading: 'Currencies',
               route: '/settings/general-settings/currencies',
               permission: 'currencies',
            },
            {
               heading: 'Banks',
               route: '/settings/general-settings/banks',
               permission: 'banks',
            },
            {
               heading: 'Cards',
               route: '/settings/general-settings/cards',
               permission: 'cards',
            },
            {
               heading: 'Permissions',
               route: '/settings/general-settings/permissions',
               permission: 'permissions',
            },
            {
               heading: 'Users',
               route: '/settings/general-settings/users',
               permission: 'users',
            },
            {
               heading: 'Company Documents',
               route: '/settings/general-settings/company-documents',
               permission: 'company_documents',
            },
            {
               heading: 'Activity Log',
               route: '/settings/common/activity-log',
               role: 'owner',
            },
            {
               heading: 'print models',
               route: '/settings/general-settings/printmodels',
               role: 'owner',
            },
         ].filter(Boolean),
      },
      {
         heading: 'Invoice Settings',
         route: '/settings/common',
         module_id: 1,
         pages: [
            {
               heading: 'Invoice settings',
               route: '/settings/common/invoice-setting',
               permission: 'invoice_setting',
            },
            {
               heading: 'Unit Of Measure',
               route: '/settings/common/unit',
               permission: 'units',
            },
            {
               heading: 'Taxes',
               route: '/settings/common/taxes',
               permission: 'taxes',
            },
            {
               heading: 'product categories',
               route: '/settings/common/product-categories',
               permission: 'product_categories',
            },
            {
               heading: 'products',
               route: '/settings/common/products',
               permission: 'products',
            },
            {
               heading: 'Commission Policy',
               route: '/settings/common/commission-policy',
               permission: 'commission_policies',
            },
            {
               heading: 'salesmans',
               route: '/settings/common/salesman',
               permission: 'salesmen',
            },
            // {
            //   heading: "Salesman Commissions",
            //   route: "/settings/common/salesman",
            //   permission: "salesmen"
            // },
            {
               heading: 'Clients Groups',
               route: '/settings/common/client-group',
               permission: 'client_groups',
            },
            {
               heading: 'Clients',
               route: '/settings/common/clients',
               permission: 'clients',
            },
            {
               heading: 'Workflow Forms',
               module_id: 1,
               route: '/settings/common/model-settings',
               permission: 'invoice_models',
            },
            {
               heading: 'Import Result',
               route: '/settings/common/import-result',
               permission: 'log_exports',
            },
            {
               heading: 'Import Log',
               route: '/settings/common/import-log',
               permission: 'log_exports',
            },
            // {
            //   heading: 'Vendor Groups',
            //   route: '/settings/common/vendor-group',
            //   permission: 'vendor_groups',
            // },
            // {
            //   heading: 'Vendors',
            //   route: '/settings/common/vendor',
            //   permission: 'vendors',
            // },
         ],
      },
      {
         heading: 'Warehouse Settings',
         route: '#',
         module_id: 2,
         pages: [
            {
               heading: 'Warehouse settings',
               route: '/settings/common/warehouse-setting',
               permission: 'warehouse_setting',
            },
            {
               heading: 'Warehouses',
               route: '/settings/common/warehouse',
               permission: 'warehouses',
            },
            {
               heading: 'Unit Of Measure',
               route: '/settings/common/unit',
               permission: 'units',
            },
            {
               heading: 'Taxes',
               route: '/settings/common/taxes',
               permission: 'taxes',
            },
            {
               heading: 'product categories',
               route: '/settings/common/product-categories',
            },
            {
               heading: 'products',
               route: '/settings/common/products',
               permission: 'products',
            },

            {
               heading: 'Commission Policy',
               route: '/settings/common/commission-policy',
               permission: 'commission_policies',
            },
            {
               heading: 'salesmans',
               route: '/settings/common/salesman',
               permission: 'salesmen',
            },
            // {
            //   heading: "Agent Commissions",
            //   route: "/settings/common/Agent",
            //   permission: "salesmen"
            // },
            {
               heading: 'Clients Groups',
               route: '/settings/common/client-group',
               permission: 'client_groups',
            },
            {
               heading: 'Vendor Groups',
               route: '/settings/common/vendor-group',
               permission: 'vendor_groups',
            },
            {
               heading: 'Clients',
               route: '/settings/common/clients',
               permission: 'clients',
            },
            {
               heading: 'Vendors',
               route: '/settings/common/vendor',
               permission: 'vendors',
            },
            {
               heading: 'Workflow Forms',
               module_id: 2,
               route: '/settings/common/model-settings',
            },
            {
               heading: 'Import Result',
               route: '/settings/common/import-result',
               permission: 'log_exports',
            },
            {
               heading: 'Import Log',
               route: '/settings/common/import-log',
               permission: 'log_exports',
            },
         ],
      },
      {
         heading: 'Account Settings',
         route: '#',
         module_id: 3,
         pages: [
            {
               heading: 'Accounting settings',
               route: '/settings/account-settings/mangement-settings-accounting',
            },
            {
               heading: 'Cost Center',
               route: '/settings/account-settings/mangement-cost-center',
               permission: 'cost_centers',
            },
            {
               heading: 'Cost Center Groups',
               route: '/settings/account-settings/mangement-cost-center-groups',
               permission: 'cost_center_groups',
            },
            {
               heading: 'projects',
               route: '/settings/account-settings/mangement-projects',
               permission: 'projects',
            },
            {
               heading: 'project groups',
               route: '/settings/account-settings/mangement-project-groups',
               permission: 'project_groups',
            },
            // {
            //   heading: 'asset groups',
            //   route: '/settings/account-settings/mangement-asset-groups',
            //   permission: 'asset_groups',
            // },
            // {
            //   heading: 'assets',
            //   route: '/settings/account-settings/mangement-assets',
            //   permission: 'assets',
            // },
            {
               heading: 'Chart Of Account',
               route: '/settings/account-settings/mangement-journal-entries-list',
               permission: 'account',
            },
            {
               heading: 'account groups',
               route: '/settings/account-settings/mangement-account-groups',
               permission: 'account_groups',
            },
            {
               heading: 'Periodical Journal Templates',
               route: '/settings/account-settings/journal-entry-template',
            },
         ],
      },
      {
         heading: 'Human Resource',
         route: '#',
         module_id: 4,
         pages: [
            {
               heading: 'HR and Payroll Settings',
               route: '/settings/hr-settings/general-settings',
               permission: 'hr_setting',
            },
            {
               sectionTitle: '_General Settings',
               sub: [
                  {
                     heading: 'Countries',
                     route: '/settings/hr-settings/mangement-countries',
                     permission: 'hr_countries',
                  },
                  {
                     heading: 'Nationalities',
                     route: '/settings/hr-settings/mangement-nationalities',
                     permission: 'nationalities',
                  },
                  {
                     heading: 'Languages',
                     route: '/settings/hr-settings/mangement-languages',
                     permission: 'hr_languages',
                  },
                  {
                     heading: 'Religions',
                     route: '/settings/hr-settings/mangement-religions',
                     permission: 'hr_religions',
                  },
                  {
                     heading: 'Marital status',
                     route: '/settings/hr-settings/mangement-social-status',
                     permission: 'hr_marital_statuses',
                  },
                  {
                     heading: 'Educational Qualifications',
                     route: '/settings/hr-settings/mangement-educational-qualifications',
                     permission: 'hr_educational_qualifications',
                  },

                  {
                     heading: 'Educational Bodies',
                     route: '/settings/hr-settings/mangement-educational-bodies',
                     permission: 'hr_educational_institutions',
                  },
                  {
                     heading: 'Specializations',
                     route: '/settings/hr-settings/mangement-specializations',
                     permission: 'hr_specialties',
                  },
                  {
                     heading: 'trainingsSettings',
                     route: '/settings/hr-settings/mangement-trainings-settings',
                     permission: 'hr_trainings',
                  },
                  {
                     heading: 'trainersSettings',
                     route: '/settings/hr-settings/mangement-trainers-settings',
                     permission: 'hr_trainers',
                  },
                  {
                     heading: 'Appreciations',
                     route: '/settings/hr-settings/mangement-appreciations',
                     permission: 'hr_appreciations',
                  },
               ],
            },

            {
               sectionTitle: 'Jobs Settings',
               sub: [
                  {
                     heading: 'Work systems',
                     route: '/settings/hr-settings/mangement-work-systems',
                     permission: 'hr_work_systems',
                  },
                  {
                     heading: 'Job Types',
                     route: '/settings/hr-settings/mangement-job-types',
                     permission: 'hr_job_types',
                  },
                  {
                     heading: 'Jobs',
                     route: '/settings/hr-settings/mangement-jobs',
                     permission: 'hr_jobs',
                  },
                  {
                     heading: 'Jobs Classifications',
                     route: '/settings/hr-settings/mangement-jobs-classifications',
                     permission: 'hr_job_classifications',
                  },
                  {
                     heading: 'Salary Cashing entities',
                     route: '/settings/hr-settings/mangement-dismisses',
                     permission: 'hr_tellers',
                  },
                  {
                     heading: 'departments',
                     route: '/settings/hr-settings/mangement-departments',
                     permission: 'hr_departments',
                  },
                  {
                     heading: 'Work Locations',
                     route: '/settings/hr-settings/mangement-work-locations',
                     permission: 'hr_work_locations',
                  },
               ],
            },

            {
               sectionTitle: 'Other Settings',
               sub: [
                  {
                     heading: 'Documents',
                     route: '/settings/hr-settings/mangement-documents',
                     permission: 'hr_documents',
                  },
                  {
                     heading: 'Custodies',
                     route: '/settings/hr-settings/mangement-custodies',
                     permission: 'project_groups',
                  },
                  {
                     heading: 'Sanctions',
                     route: '/settings/hr-settings/mangement-sanctions',
                     permission: 'project_groups',
                  },
                  {
                     heading: 'Sanctions Reasons',
                     route: '/settings/hr-settings/mangement-sanctions-reasons',
                     permission: 'cost_centers',
                  },
                  {
                     heading: 'Employers',
                     route: '/settings/hr-settings/mangement-employers',
                     permission: 'project_groups',
                  },
                  {
                     heading: 'Evaluation Items',
                     route: '/settings/hr-settings/mangement-evaluation-items',
                     permission: 'project_groups',
                  },
               ],
            },
         ],
      },
      {
         heading: 'Payroll',
         route: '#',
         module_id: 4,
         pages: [
            {
               heading: 'HR and Payroll Settings',
               route: '/settings/salaries-settings/general-settings',
               permission: 'hr_setting',
            },
            {
               heading: 'Spend Types',
               route: '/settings/salaries-settings/mangement-spend-types',
               permission: 'hr_wage_items',
            },
            {
               heading: 'Wage Items',
               route: '/settings/salaries-settings/mangement-wage-items',
               permission: 'hr_spend_types',
            },
            {
               heading: 'Wage Items Description',
               route: '/settings/salaries-settings/mangement-wage-items-description',
               permission: 'hr_wage_item_descriptions',
            },
         ],
      },
      {
         heading: 'Attendance',
         route: '#',
         module_id: 25,
         pages: [
            {
               heading: 'attendanceSettings',
               route: '/settings/attendance-settings/attendance-settings',
               permission: 'hr_setting',
            },
            {
               sectionTitle: '_General Settings',
               sub: [
                  {
                     heading: 'Countries',
                     route: '/settings/attendance-settings/mangement-attendance-countries',
                     permission: 'hr_countries',
                  },
                  {
                     heading: 'Nationalities',
                     route: '/settings/attendance-settings/mangement-attendance-nationalities',
                     permission: 'nationalities',
                  },
                  {
                     heading: 'Languages',
                     route: '/settings/attendance-settings/mangement-attendance-languages',
                     permission: 'hr_languages',
                  },
                  {
                     heading: 'Religions',
                     route: '/settings/attendance-settings/mangement-attendance-religions',
                     permission: 'hr_religions',
                  },
                  {
                     heading: 'Marital status',
                     route: '/settings/attendance-settings/mangement-attendance-social-status',
                     permission: 'hr_marital_statuses',
                  },
               ],
            },
            {
               sectionTitle: 'Jobs Settings',
               sub: [
                  {
                     heading: 'Work systems',
                     route: '/settings/attendance-settings/mangement-attendance-work-systems',
                     permission: 'hr_work_systems',
                  },
                  {
                     heading: 'Job Types',
                     route: '/settings/attendance-settings/mangement-attendance-job-types',
                     permission: 'hr_job_types',
                  },
                  {
                     heading: 'Jobs',
                     route: '/settings/attendance-settings/mangement-attendance-jobs',
                     permission: 'hr_jobs',
                  },
                  {
                     heading: 'Jobs Classifications',
                     route: '/settings/attendance-settings/mangement-attendance-jobs-classifications',
                     permission: 'hr_job_classifications',
                  },
                  {
                     heading: 'departments',
                     route: '/settings/attendance-settings/mangement-attendance-departments',
                     permission: 'hr_departments',
                  },
                  {
                     heading: 'Work Locations',
                     route: '/settings/attendance-settings/mangement-attendance-work-locations',
                     permission: 'hr_work_locations',
                  },

                  {
                     heading: 'Work Times',
                     route: '/settings/attendance-settings/mangement-attendance-work-times',
                     permission: 'hr_work_times',
                  },
                  {
                     heading: 'workTimesSchedules',
                     route: '/settings/attendance-settings/mangement-attendance-work-times-schedules',
                     permission: 'hr_work_time_schedules',
                  },
               ],
            },
            {
               sectionTitle: 'Other Settings',
               sub: [
                  {
                     heading: 'Employers',
                     route: '/settings/attendance-settings/mangement-attendance-employers',
                     permission: 'hr_employers',
                  },
               ],
            },
         ],
      },
      {
         heading: 'Customs',
         route: '#',
         module_id: 30,
         pages: [
            {
               heading: 'customs settings',
               route: '/settings/customs-settings/mangement-customs-setting',
               permission: 'customs_setting',
            },
            {
               heading: 'Unit Of Measure',
               route: '/settings/customs-settings/mangement-customs-unit',
               permission: 'units',
            },
            {
               heading: 'Taxes',
               route: '/settings/customs-settings/mangement-customs-taxes',
               permission: 'taxes',
            },

            {
               heading: 'Clients Groups',
               route: '/settings/customs-settings/mangement-customs-client-group',
               permission: 'client_groups',
            },
            {
               heading: 'Clients',
               route: '/settings/customs-settings/mangement-customs-clients',
               permission: 'clients',
            },
            {
               heading: 'customsBrokers',
               route: '/settings/customs-settings/mangement-customs-brokers',
            },
            {
               heading: 'Agents',
               route: '/settings/customs-settings/mangement-customs-agent',
               permission: 'customs_agents',
            },
            {
               heading: 'Nationalities',
               route: '/settings/customs-settings/nationalities',
               permission: 'nationalities',
            },
            {
               heading: 'containers',
               route: '/settings/customs-settings/mangement-customs-containers',
               permission: 'customs_containers',
            },
            {
               heading: 'Documents',
               route: '/settings/customs-settings/mangement-customs-documents',
               permission: 'customs_documents',
            },
            {
               heading: 'services',
               route: '/settings/customs-settings/mangement-customs-services',
               permission: 'customs_services',
            },
            {
               heading: 'customTransactions',
               route: '/settings/customs-settings/mangement-customs-transactions',
               permission: 'customs_transactions',
            },
            {
               heading: 'expensesAndIncomes',
               route: '/settings/customs-settings/mangement-customs-expenses-incomes',
               permission: 'customs_expense_revenues',
            },
            {
               heading: 'Workflow Forms',
               // module_id: 1,
               route: '/settings/customs-settings/mangement-customs-model-settings',
               permission: 'customs_invoice_models',
            },
            {
               heading: 'invoiceItems',
               route: '/settings/customs-settings/mangement-customs-invoice-items',
               permission: 'customs_invoice_items',
            },
         ],
      },
      {
         heading: 'Transportation',
         route: '#',
         module_id: 35,
         pages: [
            {
               heading: 'Transportation Settings',
               route: '/settings/transport-settings/settings-transportation',
               permission: 'transport_setting',
            },
            {
               heading: 'Unit Of Measure',
               route: '/settings/transport-settings/unit',
               permission: 'units',
            },
            {
               heading: 'Taxes',
               route: '/settings/transport-settings/taxes',
               permission: 'taxes',
            },
            {
               heading: 'products',
               route: '/settings/transport-settings/products',
               permission: 'products',
            },
            {
               heading: 'salesmans',
               route: '/settings/transport-settings/salesman',
               permission: 'salesmen',
            },
            {
               heading: 'Clients Groups',
               route: '/settings/transport-settings/client-group',
               permission: 'client_groups',
            },
            {
               heading: 'Transporter Groups',
               route: '/settings/transport-settings/transporter-group',
               permission: 'vendor_groups',
            },
            {
               heading: 'Clients',
               route: '/settings/transport-settings/clients',
               permission: 'clients',
            },
            {
               heading: 'transporters',
               route: '/settings/transport-settings/transporter',
               // permission: "transports",
            },
            {
               heading: 'Trailers',
               route: '/settings/transport-settings/transport-trailers',
               permission: 'transport_trailers',
            },
            {
               heading: 'Directions',
               route: '/settings/transport-settings/transport-directions',
               permission: 'transport_directions',
            },
            {
               heading: 'Drivers',
               route: '/settings/transport-settings/transport-drivers',
               permission: 'transport_drivers',
            },
            {
               heading: 'Trucks',
               route: '/settings/transport-settings/transport-trucks',
               permission: 'transport_trucks',
            },
            {
               heading: 'Cars',
               route: '/settings/transport-settings/transport-cars',
               permission: 'transport_cars',
            },
            {
               heading: 'Cars Expenses',
               route: '/settings/transport-settings/transport-car-expenses',
               permission: 'transport_car_expenses',
            },
            {
               heading: 'Workflow Forms',
               module_id: 35,
               route: '/settings/transport-settings/model-settings',
            },
         ],
      },
      {
         heading: 'ElectronicApprovalsModule',
         route: '#',
         module_id: 45,
         pages: [
            {
               heading: 'Electronic Approval Settings',
               route: '/settings/electronic-settings/settings-electronic',
               permission: 'e_approval_setting',
            },
            {
               heading: 'Transactions Settings',
               route: '/settings/electronic-settings/transactions-settings',
               permission: 'e_approval_movements',
            },
         ],
      },

      {
         heading: 'Assets',
         route: '#',
         module_id: 50,
         pages: [
            {
               heading: 'Assets Settings',
               route: `${PATH}/assets-settings/settings`,
               permission: 'asset_setting',
            },
            {
               heading: 'Assets Categories',
               route: `${PATH}/assets-settings/assets-categories`,
               permission: 'asset_categories',
            },
            {
               heading: 'Assets',
               route: `${PATH}/assets-settings/assets`,
               permission: 'products',
            },
            {
               heading: 'Work Locations',
               route: `${PATH}/assets-settings/work-locations`,
               permission: 'asset_locations',
            },
            {
               heading: 'Acquisition Methods',
               route: `${PATH}/assets-settings/acquisition-methods`,
               permission: 'asset_acquisition_methods',
            },
         ],
      },

      {
         heading: 'POS',
         route: '#',
         module_id: 51,
         pages: [
            {
               heading: 'Pos Settings',
               route: `${PATH}/${POS_PATH}/settings`,
               permission: 'pos_setting',
            },
            {
               heading: 'Delivery Zones',
               route: `${PATH}/${POS_PATH}/delivery-zones`,
               permission: 'pos_delivery_zones',
            },
            {
               heading: 'Branches',
               route: `${PATH}/${POS_PATH}/branches`,
               permission: 'pos_branches',
            },
            {
               heading: 'Sections',
               route: `${PATH}/${POS_PATH}/sections`,
               permission: 'pos_sections',
            },
            {
               heading: 'Points Of Sales',
               route: `${PATH}/${POS_PATH}/pos`,
               permission: 'pos_point_sales',
            },
            // {
            //    heading: 'Unit Of Measure',
            //    route: `${PATH}/${POS_PATH}/unit`,
            //    permission: 'units',
            // },
            // {
            //    heading: 'Taxes',
            //    route: `${PATH}/${POS_PATH}/taxes`,
            //    permission: 'taxes',
            // },
            // {
            //    heading: 'Clients',
            //    route: `${PATH}/${POS_PATH}/clients`,
            //    permission: 'clients',
            // },
            {
               heading: 'Offers',
               route: `${PATH}/${POS_PATH}/offers`,
               permission: 'pos_offers',
            },
            {
               heading: 'Coupons',
               route: `${PATH}/${POS_PATH}/coupons`,
               permission: 'pos_coupons',
            },
            {
               heading: 'Expenses',
               route: `${PATH}/${POS_PATH}/expenses`,
               permission: 'pos_expenses',
            },
            {
               heading: 'Delivery Employees',
               route: `${PATH}/${POS_PATH}/delivery-employees`,
               permission: 'pos_delivery_men',
            },
         ],
      },
   ];

   return sortModules(links);
};

export default SettingsMenuConfig;
