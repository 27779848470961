const path = 'AdminSubscriptionModule';
const apiPath = '/admin-panal/company_subscriptions';
const transactionsPath = '/admin-panal/transactions';

const SubscriptionsActions = {
  // action types
  GET_DATA : `${path}/getData`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
  ACCEPT_TRANSACTION : `${path}/acceptTransaction`,
  DECLINE_TRANSACTION : `${path}/declineTransaction`,
  FIND_ITEM_BY_ID : `${path}/findItemById`,
  SET_EDITABLE_ID : `${path}/setEditableId`,
}

const SubscriptionsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  ACCEPT_TRANSACTION : `${transactionsPath}/acceptTransaction`,
  DECLINE_TRANSACTION : `${transactionsPath}/declineTransaction`,
}

export {SubscriptionsAPIsConstants, SubscriptionsActions };
