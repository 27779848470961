import axios from 'axios';
import store from '@/store/index.ts';
import InvoiceMenuConfig from '@/core/config/menus/InvoiceMenuConfig';
import CustomsMenuConfig from '@/core/config/menus/CustomsMenuConfig';
import SettingsMenuConfig from '@/core/config/menus/SettingsMenuConfig';
import AdminPanelMenuConfig from '@/core/config/menus/AdminPanelMenuConfig';
import { ListActions } from '@/store/enums/ListEnum.ts';
import WarehouseMenuConfig from '@/core/config/menus/WarehouseMenuConfig';
import CargoMenuConfig from '@/core/config/menus/CargoMenuConfig';
import AccountingMenuConfig from '@/core/config/menus/AccountingMenuConfig';
import HrMenuConfig from '@/core/config/menus/HrMenuConfig';
import AttendanceMenuConfig from '@/core/config/menus/AttendanceMenuConfig';
import SalariesMenuConfig from '@/core/config/menus/SalariesMenuConfig';
import TransportMovementMenuConfig from '@/core/config/menus/external-client/TransportMovementMenuConfig';
import ExternalCustomsMenuConfig from '@/core/config/menus/external-client/CustomsMenuConfig';
import AuthService from '@/core/services/AuthService';
import VendorTransportMovementMenuConfig from '@/core/config/menus/external-vendor/TransportMovementMenuConfig';
import { MuqueemActions } from '@/views/modules/hr/store/enums/MuqeemEnums';
import RestaurantsMenuConfig from '@/core/config/menus/Restaurants';
import { CompanyActions } from '@/views/modules/settings/store/modules/CompanyModule.js';
import ElectronicApprovalsMenuConfig from '@/core/config/menus/ElectronicApprovals';
import AssetsMenuConfig from '@/core/config/menus/Assets';
import PosMenuConfig from '@/core/config/menus/Pos';
import { Mutations } from '../enums/StoreEnums';

const MainMenuModule = {
   namespaced: true,

   state: {
      MainMenuConfig: [],
      firstLoad: true,
      currentActiveMenu: '',
      notifications: [],
      notificationsPage: 1,
      loading: false,
      notification_loading: true,
   },

   mutations: {
      async SET_MENU_CONFIG(state, payload) {
         state.currentActiveMenu = payload.name;
         switch (payload.name) {
            case 'invoice': {
               state.MainMenuConfig = InvoiceMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name === 'daily_movements',
               );

               store
                  .dispatch(
                     ListActions.GET_LIST_BY_NAME,
                     {
                        name: 'invoice-models-for-menu',
                        disableCache: true,
                     },
                     { root: true },
                  )
                  .then((resp) => {
                     let salesData = resp?.data?.result?.list?.[2] ?? [];
                     let tmpArr = [];

                     if (AuthService.hasPermission('read_sales_daily_movements')) {
                        //Sales

                        if (salesData.length > 0) {
                           salesData.forEach((item) => {
                              tmpArr.push({
                                 heading: item.name,
                                 route: '/invoicing-management/sales-invoices/list/' + item.id,
                              });
                           });
                        } else {
                           tmpArr.push({
                              heading: 'Manage Workflow',
                              route: '/invoicing-management/model-settings',
                              classes: 'menu-blue-background',
                           });
                        }

                        tmpArr.push({
                           heading: 'Financial Transactions',
                           route: '/invoicing-management/sales-financial-transactions',
                        });

                        daily_movements.pages = tmpArr;

                        store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                           let settings = resp?.data?.result?.company?.invoice_settings;
                           if (settings?.bind_with_accounting) {
                              let transferMenu = daily_movements.pages.find(
                                 (el) => el.name === 'transfer_menu',
                              );

                              if (!transferMenu) {
                                 daily_movements.pages.push(
                                    {
                                       heading: 'Post To Accounting',
                                       route: '/invoicing-management/invoicing-journal-posting',
                                       module_id: 3,
                                       name: 'transfer_menu',
                                    },
                                    {
                                       heading: 'transferringLog',
                                       route: '/invoicing-management/transferring-log',
                                       module_id: 3,
                                    },
                                 );
                              }
                           }
                        });
                     }
                  });

               break;
            }
            case 'customs': {
               state.MainMenuConfig = CustomsMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name === 'daily_movements',
               );

               store
                  .dispatch(
                     ListActions.GET_LIST_BY_NAME,
                     {
                        name: 'customs-invoice-models',
                        filters: { invoice_model_kind_id: 2 }, //Sales
                        disableCache: true,
                     },
                     { root: true },
                  )
                  .then((resp) => {
                     //Sales
                     let salesData = resp?.data?.result?.list ?? [];
                     let tmpArr = [];

                     tmpArr.push(
                        {
                           sectionTitle: 'Deals',
                           route: '/customs-management/customs-management-deals-list',
                        },
                        {
                           heading: 'receivedDealsInformation',
                           route: '/customs-management/received-deals',
                        },
                        {
                           sectionTitle: 'Models',
                           route: '/customs-management/models-list',
                        },
                        {
                           heading: 'Financial Transactions',
                           route: '/customs-management/customs-sales-financial-transactions',
                        },
                     );

                     state.MainMenuConfig[1].pages = tmpArr;
                     state.MainMenuConfig[1].pages[0]['sub'] = [];
                     state.MainMenuConfig[1].pages[2]['sub'] = [];
                     if (salesData.length > 0) {
                        salesData.forEach((item) => {
                           state.MainMenuConfig[1].pages[2].sub.push({
                              heading: item.name,
                              route: '/customs-management/customs-invoices/list/' + item.id,
                           });
                        });
                     }

                     state.MainMenuConfig[1].pages[0].sub.push(
                        {
                           heading: 'Received Deals',
                           route: '/customs-management/deals',
                        },
                        {
                           heading: 'Enter Deal Procedure',
                           route: '/customs-management/update-deals',
                        },
                        {
                           heading: 'Update Deal Procedures',
                           route: '/customs-management/deal-procedure',
                        },
                        {
                           heading: 'receipt vouchers',
                           route: '/customs-management/receipt-voucher',
                           permission: 'customs_receipt_vouchers',
                        },
                        {
                           heading: 'payment vouchers',
                           route: '/customs-management/payment-voucher',
                           permission: 'customs_payment_vouchers',
                        },
                        {
                           heading: 'Close a Deal',
                           route: '/customs-management/customs-close-deal',
                        },
                        {
                           heading: 'Reopen a Deal',
                           route: '/customs-management/customs-reopen-deal',
                        },
                     );

                     store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                        let settings = resp?.data?.result?.company?.customs_settings;
                        if (settings?.bind_with_accounting) {
                           let transferMenu = daily_movements.pages.find(
                              (el) => el.name === 'transfer_menu',
                           );

                           if (!transferMenu) {
                              daily_movements.pages.push(
                                 {
                                    heading: 'Post To Accounting',
                                    route: '/customs-management/journal-posting',
                                    permission: 'customs_postings',
                                    type: 'write',
                                    name: 'transfer_menu',
                                 },
                                 {
                                    heading: 'transferringLog',
                                    route: '/customs-management/transferring-log',
                                    permission: 'customs_postings',
                                 },
                              );
                           }
                        }
                     });
                  });
               break;
            }

            case 'warehouse': {
               state.MainMenuConfig = WarehouseMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name === 'daily_movements',
               );
               let purchaseMenu = daily_movements.pages.find((el) => el.name === 'purchase');
               let salesMenu = daily_movements.pages.find((el) => el.name === 'sales');

               store
                  .dispatch(
                     ListActions.GET_LIST_BY_NAME,
                     {
                        name: 'invoice-models-for-menu',
                        disableCache: true,
                     },
                     { root: true },
                  )
                  .then((resp) => {
                     let salesData = resp?.data?.result?.list?.[2] ?? [];
                     let purchaseData = resp?.data?.result?.list?.[1] ?? [];

                     if (AuthService.hasPermission('read_purchase_daily_movements')) {
                        //Purchase
                        purchaseMenu['sub'] = [];
                        if (purchaseData.length > 0) {
                           purchaseData.forEach((item) => {
                              purchaseMenu.sub.push({
                                 heading: item.name,
                                 route: '/warehouse-management/purchase-invoice/list/' + item.id,
                              });
                           });
                        } else {
                           purchaseMenu.sub.push({
                              heading: 'Manage Workflow',
                              route: '/warehouse-management/model-settings',
                              classes: 'menu-blue-background',
                           });
                        }

                        purchaseMenu.sub.push({
                           heading: 'Financial Transactions',
                           route: '/warehouse-management/purchase-financial-transactions',
                           permission: 'purchase_invoice_financial_transactions',
                        });
                     }

                     if (AuthService.hasPermission('read_sales_daily_movements')) {
                        //Sales
                        salesMenu['sub'] = [];

                        if (salesData.length > 0) {
                           salesData.forEach((item) => {
                              salesMenu.sub.push({
                                 heading: item.name,
                                 route: '/warehouse-management/sales-invoices/list/' + item.id,
                              });
                           });
                        } else {
                           salesMenu.sub.push({
                              heading: 'Manage Workflow',
                              route: '/warehouse-management/model-settings',
                              classes: 'menu-blue-background',
                           });
                        }

                        salesMenu.sub.push({
                           heading: 'Financial Transactions',
                           route: '/warehouse-management/sales-financial-transactions',
                           permission: 'sales_invoice_financial_transactions',
                        });
                     }
                  });

               store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                  let settings = resp?.data?.result?.company?.warehouse_settings;
                  if (settings?.bind_with_accounting) {
                     let transferMenu = daily_movements.pages.find(
                        (el) => el.name === 'transfer_menu',
                     );

                     if (!transferMenu) {
                        daily_movements.pages.push(
                           {
                              heading: 'Post to Accounting',
                              route: '/warehouse-management/warehouse-journal-posting',
                              module_id: 3,
                              name: 'transfer_menu',
                           },
                           {
                              heading: 'Posting Log',
                              route: '/warehouse-management/transferring-log',
                              module_id: 3,
                           },
                        );
                     }
                  }
               });
               break;
            }
            case 'cargo': {
               state.MainMenuConfig = CargoMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name == 'daily_movements',
               );

               let purchaseMenu = daily_movements.pages.find((el) => el.name == 'purchase');
               let salesMenu = daily_movements.pages.find((el) => el.name == 'sales');

               store
                  .dispatch(
                     ListActions.GET_LIST_BY_NAME,
                     {
                        name: 'transport-invoice-models-for-menu',
                        disableCache: true,
                     },
                     { root: true },
                  )
                  .then((resp) => {
                     let salesData = resp?.data?.result?.list?.[2] ?? [];
                     let purchaseData = resp?.data?.result?.list?.[1] ?? [];

                     if (purchaseData) {
                        //Purchase
                        purchaseMenu['sub'] = [];
                        if (purchaseData.length > 0) {
                           purchaseData.forEach((item) => {
                              purchaseMenu.sub.push({
                                 heading: item.name,
                                 route: '/transport-management/purchase-invoice/list/' + item.id,
                              });
                           });
                        }

                        purchaseMenu.sub.push({
                           heading: 'Financial Transactions',
                           route: '/transport-management/purchase-financial-transactions',
                        });
                     }

                     if (salesData) {
                        //Sales
                        salesMenu['sub'] = [];

                        if (salesData.length > 0) {
                           salesData.forEach((item) => {
                              salesMenu.sub.push({
                                 heading: item.name,
                                 route: '/transport-management/sales-invoices/list/' + item.id,
                              });
                           });
                        }

                        salesMenu.sub.push({
                           heading: 'Financial Transactions',
                           route: '/transport-management/sales-financial-transactions',
                        });
                     }
                  });

               store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                  let settings = resp?.data?.result?.company?.transport_settings;
                  if (settings?.bind_with_accounting) {
                     let transferMenu = daily_movements.pages.find(
                        (el) => el.name === 'transfer_menu',
                     );

                     if (!transferMenu) {
                        daily_movements.pages.push(
                           {
                              heading: 'Post To Accounting',
                              route: '/transport-management/journal-posting',
                              permission: 'transport_postings',
                              type: 'write',
                              name: 'transfer_menu',
                           },
                           {
                              heading: 'transferringLog',
                              route: '/transport-management/transferring-log',
                              permission: 'transport_postings',
                           },
                        );
                     }
                  }
               });

               break;
            }

            case 'accounting': {
               state.MainMenuConfig = AccountingMenuConfig;
               if (payload.data) {
                  if (payload.type && payload.type === 1) {
                     //Purchase
                     state.MainMenuConfig[1].pages[0]['sub'] = [];

                     if (payload.data.length > 0) {
                        payload.data.forEach((item) => {
                           state.MainMenuConfig[1].pages[0].sub.push({
                              heading: item.name,
                              route: '/accounting-management/purchase-invoice/list/' + item.id,
                           });
                        });
                     }

                     state.MainMenuConfig[1].pages[0].sub.push({
                        heading: 'Financial Transactions',
                        route: '/accounting-management/purchase-financial-transactions',
                     });
                  }

                  if (payload.type && payload.type === 2) {
                     //Sales
                     state.MainMenuConfig[1].pages[1]['sub'] = [];

                     if (payload.data.length > 0) {
                        payload.data.forEach((item) => {
                           state.MainMenuConfig[1].pages[1].sub.push({
                              heading: item.name,
                              route: '/accounting-management/sales-invoices/list/' + item.id,
                           });
                        });
                     }

                     state.MainMenuConfig[1].pages[1].sub.push({
                        heading: 'Financial Transactions',
                        route: '/accounting-management/sales-financial-transactions',
                     });
                  }
               }
               break;
            }

            case 'salaries': {
               state.MainMenuConfig = SalariesMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name === 'daily_movements',
               );

               store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                  let settings = resp?.data?.result?.company?.hr_settings;
                  if (settings?.bind_with_accounting) {
                     let transferMenu = daily_movements.pages.find(
                        (el) => el.name === 'transfer_menu',
                     );

                     if (!transferMenu) {
                        daily_movements.pages.push(
                           {
                              heading: 'Post to Accounting',
                              route: '/salaries-management/daily-transactions/transfer-to-accounting',
                              name: 'transfer_menu',
                              permission: 'hr_migrate_to_accounting',
                           },
                           {
                              heading: 'Posting Log',
                              route: '/salaries-management/daily-transactions/transfer-log',
                              permission: 'hr_migrate_to_accounting',
                           },
                        );
                     }
                  }
               });
               break;
            }
            case 'attendance': {
               state.MainMenuConfig = AttendanceMenuConfig();
               break;
            }
            case 'hr': {
               state.MainMenuConfig = HrMenuConfig();
               let dailyMovements = state.MainMenuConfig.find((el) => el.id === 'dailyMovements');
               let hrReports = state.MainMenuConfig.find((el) => el.id === 'hrReports');

               if (payload?.data?.linked_with_muqeem) {
                  if (dailyMovements?.pages?.find((el) => el.id === 'muqeemMovement')) return;
                  //Add muqeem to daily transactions
                  let muqeemMovement = {
                     sectionTitle: 'Muqeem Operations',
                     id: 'muqeemMovement',
                     placement: 'right-end',
                     sub: [
                        {
                           heading: 'Exit Reenter',
                           route: '/hr-management/daily-transactions/exit-reenter',
                           permission: 'hr_muqeem_exit_reenter_operations',
                        },
                        {
                           heading: 'Final Exit',
                           route: '/hr-management/daily-transactions/final-exit',
                           permission: 'hr_muqeem_final_exit_operations',
                        },
                        {
                           heading: 'Renew Passport',
                           route: '/hr-management/daily-transactions/renew-passport',
                           permission: 'hr_muqeem_passport_operations',
                        },
                        {
                           heading: 'Extend Passport',
                           route: '/hr-management/daily-transactions/extend-passport',
                           permission: 'hr_muqeem_passport_operations',
                        },
                        {
                           heading: 'Update Employee Job',
                           route: '/hr-management/daily-transactions/update-employee-job',
                           permission: 'hr_muqeem_occupation_operations',
                        },
                        {
                           heading: 'Issue Iqama',
                           route: '/hr-management/daily-transactions/issue-iqama',
                           permission: 'hr_muqeem_iqama_operations',
                        },
                        {
                           heading: 'Transfer Iqama',
                           route: '/hr-management/daily-transactions/transfer-iqama',
                           permission: 'hr_muqeem_iqama_operations',
                        },
                        {
                           heading: 'Extend Iqama',
                           route: '/hr-management/daily-transactions/renew-iqama',
                           permission: 'hr_muqeem_iqama_operations',
                        },
                     ],
                  };

                  dailyMovements?.pages?.push(muqeemMovement);

                  // add muqeem to reports
                  let muqeemReports = {
                     sectionTitle: 'Muqeem',
                     id: 'muqeemReports',
                     sub: [
                        {
                           heading: 'Employees Info (Muqeem)',
                           route: '/hr-management/employees-info-muqeem',
                           permission: 'hr_reports',
                        },
                        {
                           heading: 'Muqeem Operations Log',
                           route: '/hr-management/muqeem-operations-log',
                           permission: 'hr_reports',
                        },
                        {
                           heading: 'Operations Log From Muqeem',
                           route: '/hr-management/operations-log-from-muqeem',
                           permission: 'hr_reports',
                        },
                     ],
                  };

                  hrReports?.pages?.push(muqeemReports);
               }

               break;
            }
            case 'settings': {
               state.MainMenuConfig = await SettingsMenuConfig();
               break;
            }

            case 'admin-panel': {
               state.MainMenuConfig = AdminPanelMenuConfig;
               break;
            }

            case 'transport-movement': {
               state.MainMenuConfig = TransportMovementMenuConfig;
               break;
            }
            case 'transport-movement-external-vendor': {
               state.MainMenuConfig = VendorTransportMovementMenuConfig;
               break;
            }
            case 'external-customs': {
               state.MainMenuConfig = ExternalCustomsMenuConfig;
               break;
            }

            case 'restaurants': {
               state.MainMenuConfig = RestaurantsMenuConfig;
               break;
            }

            case 'electronic-approvals': {
               state.MainMenuConfig = ElectronicApprovalsMenuConfig;
               break;
            }

            case 'assets': {
               state.MainMenuConfig = AssetsMenuConfig;
               let daily_movements = state.MainMenuConfig.find(
                  (el) => el.name === 'daily_movements',
               );

               store.dispatch(CompanyActions.GET_COMPANY_SETTING).then((resp) => {
                  let settings = resp?.data?.result?.company?.asset_settings;
                  if (settings?.bind_with_accounting) {
                     let transferMenu = daily_movements.pages.find(
                        (el) => el.name === 'transfer_menu',
                     );

                     if (!transferMenu) {
                        daily_movements.pages.push(
                           {
                              heading: 'Post To Accounting',
                              route: '/assets-management/journal-posting',
                              permission: 'asset_postings',
                              type: 'write',
                              name: 'transfer_menu',
                           },
                           {
                              heading: 'transferringLog',
                              route: '/assets-management/transferring-log',
                              permission: 'asset_postings',
                           },
                        );
                     }
                  }
               });
               break;
            }

            case 'pos': {
               state.MainMenuConfig = PosMenuConfig;
               break;
            }

            case '': {
               state.MainMenuConfig = [];
               break;
            }

            default: {
               break;
            }
         }
      },

      SET_FIRST_LOAD(state, payload) {
         state.firstLoad = payload;
      },

      setNotifications(state, payload) {
         state.notifications = payload;
      },

      resetNotificationsPage(state) {
         state.notificationsPage = 1;
      },

      incrementNotificationsPage(state) {
         state.notificationsPage += 1;
      },

      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_NOTIFICATION_LOADING(state, payload) {
         state.notification_loading = payload;
      },
   },

   actions: {
      setMenuConfig({ commit, state }, payload) {
         commit('SET_MENU_CONFIG', { name: payload });

         if (payload === 'customs') {
         }

         if (payload === 'invoice') {
         }
         if (payload === 'cargo') {
         }

         if (payload === 'hr') {
            store.dispatch(MuqueemActions.GET_DATA).then((resp) => {
               commit('SET_MENU_CONFIG', {
                  name: payload,
                  data: resp?.data?.result?.item ?? {},
               });
            });
         }
      },

      setNotifications({ commit }) {
         commit('SET_NOTIFICATION_LOADING', true);
         commit('resetNotificationsPage');

         return new Promise((resolve, reject) => {
            axios
               .get(`/company/notifications`)
               .then((response) => {
                  commit(Mutations.RESET_NOTIFICATIONS_COUNT, {}, { root: true });

                  commit('setNotifications', response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               })
               .finally(() => {
                  commit('SET_NOTIFICATION_LOADING', false);
               });
         });
      },

      setNotificationsByPage({ commit, state }) {
         commit('SET_NOTIFICATION_LOADING', true);
         commit('incrementNotificationsPage');

         return new Promise((resolve, reject) => {
            axios
               .get(`/company/notifications?page=${state.notificationsPage}`)
               .then((response) => {
                  commit('setNotifications', response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               })
               .finally(() => {
                  commit('SET_NOTIFICATION_LOADING', false);
               });
         });
      },
   },

   getters: {
      notifications(state) {
         return state.notifications;
      },

      notificationsIsLastPage(state) {
         return (
            state.notifications?.notifications?.current_page ==
            state.notifications?.notifications?.last_page
         );
      },

      loading(state) {
         return state.loading;
      },
      notification_loading(state) {
         return state.notification_loading;
      },
   },
};
export default MainMenuModule;
