import axios from "axios";
import { PrintModelAPIsConstants } from "../enums/PrintModelsEnums";

const PrintModelModule = {
  namespaced: true,
  state: {
    loading: false,
    item: {},
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    RESET(state) {
      state.item = {};
    },
  },
  actions: {
    getItem({ commit }, id) {
      commit("SET_LOADING", true);

      axios
        .get(`${PrintModelAPIsConstants.API_PATH}/preview/${id}`)
        .then(({ data }) => {
          commit("SET_ITEM", data.result.invoice_print_template);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },
  },
};
export default PrintModelModule;
