const apiPath = '/hr/muqeem/iqama';
const path = 'Iqama';

const IqamaAPIsConstants = {
   API_PATH: `${apiPath}`,
   ISSUE: `${apiPath}/issue`,
   TRANSFER: `${apiPath}/transfer`,
   RENEW: `${apiPath}/renew`,
};

const IqamaActions = {
   ADD_ITEM: `${path}/addItem`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   TRANSFER: `${path}/transfer`,
   RENEW: `${path}/renew`,
};

export { IqamaAPIsConstants, IqamaActions };
