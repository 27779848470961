import axios from 'axios';

const path = 'EApprovalDashboard';
const apiPath = '/e-approval/dashboard/get-statistic-data';

export const EApprovalDashboardActions = {
   GET_DATA: `${path}/getData`,
};

export const EApprovalDashboardAPIsConstants = {
   API_PATH: apiPath,
};

const EApprovalDashboard = {
   namespaced: true,
   state: {
      loading: false,
      data: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_DATA(state, payload) {
         state.data = payload;
      },
   },
   actions: {
      getData({ commit }) {
         commit('SET_LOADING', true);

         return axios
            .get(EApprovalDashboardAPIsConstants.API_PATH)
            .then(({ data }) => {
               commit('SET_DATA', data.result);
               return data;
            })
            .finally(() => commit('SET_LOADING', false));
      },
   },
};

export default EApprovalDashboard;
