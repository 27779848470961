const path = "EmployeesLeaveSettlmentInfoModule";
const apiPath = "/hr/report/movements/leave-settlement-info-report";

const EmployeesLeaveSettlmentInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesLeaveSettlmentInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesLeaveSettlmentInfoAPIsConstants, EmployeesLeaveSettlmentInfoActions };
