import { crudActions, mergeCrudStore } from '@/core/stores/curd';

const apiPath = 'asset/asset-maintenance-plans';
const path = 'MaintenancePlansModule';

export const MaintenancePlansApiConstants = {
   API_PATH: apiPath,
};

export const MaintenancePlansActions = {
   ...crudActions(path),
};

const MaintenancePlansModule = mergeCrudStore({ namespaced: true });

export default MaintenancePlansModule;
