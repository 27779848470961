const apiPath = '/hr/salaries/hr-migrate/migrate-salaries';
const path = 'TransferModule';

const TransferAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const TransferModelActions = {
  ADD_ITEM: `${path}/addItem`,
}
export { TransferAPIsConstants, TransferModelActions };
