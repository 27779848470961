import { ListActions } from '@/store/enums/ListEnum';

export default {
   data() {
      return {};
   },
   computed: {
      listByName() {
         return (name) => this.$store.getters['ListModule/getListByName'](name);
      },
   },
   methods: {
      getListByName(options) {
         return new Promise((resolve, reject) => {
            this.$store
               .dispatch(ListActions.GET_LIST_BY_NAME, options)
               .then((response) => {
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getBulkListByName(lists = [], filters = {}) {
         this.$store.dispatch(ListActions.GET_BULK_LIST_BY_NAME, {
            lists: lists,
            filters: filters,
         });
      },
      resetList(name) {
         this.$store.dispatch(ListActions.RESET_LIST, name);
      },
   },
};
