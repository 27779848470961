import axios from 'axios';

const FiscalYearRotationModule = {
   namespaced: true,
   state: {
      loading: false,
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      cycleAccountsBalance({ commit }, { values }) {
         commit('SET_LOADING', true);

         return axios
            .post(`/accounting/financial-year-management/rebalance-accounts`, values)
            .finally(() => {
               commit('SET_LOADING', false);
            });
      },
   },
};

export default FiscalYearRotationModule;
