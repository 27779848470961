import axios from 'axios';
import { CustomsBrokersAPIsConstants } from '@/views/modules/customs/store/enums/daily-movements/CustomsBrokersEnums.ts';
import { mergeCrudStore } from '@/core/stores/curd';

const CustomsBrokersModule = mergeCrudStore({
   namespaced: true,
   state: { initData: {} },
   actions: {
      findItemById({ commit }, { id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${CustomsBrokersAPIsConstants.API_PATH}/${id}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      editItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      getInitData({ commit, state }, { apiPath }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/init-data`)
               .then((response) => {
                  commit('SET_LOADING', false);

                  state.initData = response.data.result;
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
});

export default CustomsBrokersModule;
