import axios from 'axios';
import { AdminNotificationsAPIsConstants } from '../enums/AdminNotifications';

export default {
   namespaced: true,
   actions: {
      sendNotificationsToCompanies(_, { data }) {
         return axios.post(AdminNotificationsAPIsConstants.API_PATH, data);
      },
   },
};
