import axios from 'axios';
import { IncomesReportAPIsConstants } from '../../enums/reports/IncomesReportEnums';
import DownloadService from '../../../../../../core/services/DownloadService';

const IncomesReportModule = {
   namespaced: true,
   state: {
      itemEditable: {},
      loading: false,
   },
   mutations: {
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      getReport({ commit, dispatch }, credentials) {
         commit('SET_LOADING', true);

         if (credentials.pdf == 1) {
            DownloadService.ddd(
               `${IncomesReportAPIsConstants.API_PATH}/customs-receipt-vouchers-report`,
               credentials,
            ).then(() => commit('SET_LOADING', false));
            return;
         }

         return new Promise((resolve, reject) => {
            axios
               .get(`${IncomesReportAPIsConstants.API_PATH}/customs-receipt-vouchers-report`, {
                  params: credentials,
               })
               .then((response) => {
                  if (response.data.result.report)
                     commit('SET_ITEM_EDITABLE', {
                        itemEditable: response.data.result,
                     });
                  commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
};
export default IncomesReportModule;
