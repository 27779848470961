const path = 'DealsReportModule';
const apiPath = 'customs/report';

const DealsReportActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  // GENERATE_DETAILED_Deals_REPORT : `${path}/generateDetailedDealsReport`,
}

const DealsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {DealsReportAPIsConstants, DealsReportActions };
