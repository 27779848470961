import UsersModule from './modules/UsersModule';
import CompanyModule from './modules/CompanyModule';
import BankModule from './modules/BankModule';
import FinancialYearModule from './modules/FinancialYearModule';
import PermissionModule from './modules/PermissionModule';
import RoleModule from './modules/RoleModule';
import ProductModule from './modules/ProductModule';
import UserProfileModule from './modules/UserProfileModule';
import StocktakingModule from '../../warehouse/store/modules/StocktakingModule';
import TrainingsTransactionsModule from './modules/TrainingsTransactionsModule';
import RecruitmentsModule from './modules/RecruitmentsModule';
import CustomsProductModule from './modules/customs/CustomsProductModule';
import CargoProductModule from '../../cargo/store/modules/CargoProductModule.js';
import ZatcaIntegrationModule from './modules/ZatcaIntegrationModule';
import PrintModelModule from './modules/PrintModelModule.js';
import { ClientModule } from './modules/Client';
import { ZatcaDevicesModule } from './modules/ZatcaDevices';

const settingsStore = {
   ZatcaIntegrationModule,
   UsersModule,
   CompanyModule,
   BankModule,
   FinancialYearModule,
   PermissionModule,
   RoleModule,
   ProductModule,
   StocktakingModule,
   UserProfileModule,
   TrainingsTransactionsModule,
   RecruitmentsModule,
   CustomsProductModule,
   CargoProductModule,
   PrintModelModule,
   ClientModule,
   ZatcaDevicesModule,
};

export default settingsStore;
