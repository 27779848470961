const path = "EmployeesTerminationInfoModule";
const apiPath = "/hr/report/movements/termination-info-report";

const EmployeesTerminationInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesTerminationInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesTerminationInfoAPIsConstants, EmployeesTerminationInfoActions };
