import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
   {
      path: 'warehouse-dashboard',
      name: 'warehouse-dashboard',
      meta: {
         title: 'WarehouseModule',
      },
      component: () => import('@/views/modules/warehouse/views/dashboard/Index.vue'),
   },
   {
      path: 'setting',
      name: 'settings-warehouse',
      meta: {
         permission: 'warehouse_setting',
         title: 'Warehouses Settings',
         type: 'read',
      },
      component: () => import('@/views/modules/warehouse/views/settings/Setting.vue'),
   },
   {
      path: 'warehouse',
      name: 'warehouse-warehouse',
      meta: {
         permission: 'warehouses',
         title: 'Warehouses',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/warehouse/List.vue'),
   },
   {
      path: 'unit',
      name: 'warehouse-unit',
      meta: {
         permission: 'units',
         title: 'Units',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/unit/List.vue'),
   },
   {
      path: 'taxes',
      name: 'warehouse-taxes',
      meta: {
         permission: 'taxes',
         title: 'Taxes',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/taxes/List.vue'),
   },
   {
      path: 'salesman',
      name: 'warehouse-salesman',
      meta: {
         permission: 'salesmen',
         title: 'salesmans',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/salesman/List.vue'),
   },
   {
      path: 'client-group',
      name: 'warehouse-client-group',
      meta: {
         permission: 'client_groups',
         title: 'Clients Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client-group/List.vue'),
   },
   {
      path: 'clients',
      name: 'warehouse-clients',
      meta: {
         permission: 'clients',
         title: 'Clients',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/client/List.vue'),
   },
   {
      path: 'vendor-group',
      name: 'warehouse-vendor-group',
      meta: {
         permission: 'vendor_groups',
         title: 'Vendor Groups',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue'),
   },
   {
      path: 'vendor',
      name: 'warehouse-vendor',
      meta: {
         permission: 'vendors',
         title: 'Vendors',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/vendor/List.vue'),
   },
   {
      path: 'product-categories',
      name: 'warehouse-product-categories',
      meta: {
         permission: 'product_categories',
         title: 'Product Categories',
      },
      component: () =>
         import(
            '@/views/modules/settings/views/settings/common-settings/product-categories/List.vue'
         ),
   },
   {
      path: 'products',
      name: 'warehouse-management-products',
      meta: {
         permission: 'products',
         title: 'Products',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/products/main.vue'),
      children: [
         {
            path: 'add-product',
            name: 'warehouse-management-add-product',
            meta: {
               permission: 'products',
               title: 'Products',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/settings/views/settings/common-settings/products/Add.vue'),
         },
         {
            path: 'update-product/:id',
            name: 'warehouse-management-update-product',
            meta: {
               permission: 'products',
               title: 'Products',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/settings/views/settings/common-settings/products/Add.vue'),
         },
         {
            path: 'view-product/:id',
            name: 'warehouse-management-view-product',
            meta: {
               permission: 'products',
               title: 'Products',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/settings/views/settings/common-settings/products/Add.vue'),
         },
      ],
   },
   {
      path: 'import-result',
      name: 'warehouse-management-import-result',
      meta: {
         permission: 'log_exports',
         title: 'Import Result',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/import-log/Result.vue'),
   },
   {
      path: 'import-log',
      name: 'warehouse-management-import-log',
      meta: {
         permission: 'log_exports',
         title: 'Import Log',
      },
      component: () =>
         import('@/views/modules/settings/views/settings/common-settings/import-log/Log.vue'),
   },
   {
      path: 'model-settings',
      name: 'warehouse-management-model-settings',
      meta: {
         permission: 'invoice_models',
         title: 'Workflow Forms',
         type: 'read',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoice-model/List.vue'),
   },
   {
      path: 'add-model-settings',
      name: 'warehouse-management-add-model-settings',
      meta: {
         permission: 'invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'add-model-settings/duplicate/:duplicationId',
      name: 'warehouse-management-add-duplicate-model-settings',
      meta: {
         permission: 'invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'update-model-settings/:id',
      name: 'warehouse-management-update-model-settings',
      props: true,
      meta: {
         permission: 'invoice_models',
         title: 'Workflow Forms',
         type: 'write',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'view-model-settings/:id',
      name: 'warehouse-management-view-model-settings',
      props: true,
      meta: {
         permission: '',
         title: 'Workflow Forms',
      },
      component: () => import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
   },
   {
      path: 'commission-policy',
      name: 'warehouse-management-commission-policy',
      meta: {
         permission: 'commission_policies',
         title: 'Commission Policy',
      },
      component: () =>
         import('@/views/modules/invoicing/views/settings/commission-policy/Settings.vue'),
   },
];

export default settingRouter;
