import { RouteRecordRaw } from "vue-router";

const settingRouter: Array<RouteRecordRaw> = [
  {
    path: "setting",
    name: "settings-customs",
    meta: {
      permission: "customs_setting",
      title: "Customs Settings",
      type: "read",
    },
    component: () =>
      import("@/views/modules/customs/views/settings/Setting.vue"),
  },
  {
    path: "unit",
    name: "customs-unit",
    meta: {
      permission: "units",
      title: "units",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/unit/List.vue"
      ),
  },
  {
    path: "taxes",
    name: "customs-taxes",
    meta: {
      permission: "taxes",
      title: "Taxes",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/taxes/List.vue"
      ),
  },
  {
    path: "agent",
    name: "customs-agent",
    meta: {
      permission: "customs_agents",
      title: "Agents",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/crud/agent/List.vue"
      ),
  },
  {
    path: "nationalities",
    name: "customs-nationalities",
    meta: {
      permission: "nationalities",
      title: "Nationalities",
    },
    component: () =>
      import(
        "@/views/modules/hr/views/crud/nationality/List.vue"
      ),
  },
  {
    path: "client-group",
    name: "customs-client-group",
    meta: {
      permission: "client_groups",
      title: "Clients Groups",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/client-group/List.vue"
      ),
  },
  {
    path: "clients",
    name: "customs-clients",
    meta: {
      permission: "clients",
      title: "Clients",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/client/List.vue"
      ),
  },
  {
    path: "vendor-group",
    name: "customs-vendor-group",
    meta: {
      permission: "vendor_groups",
      title: "Vendor Groups",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue"
      ),
  },
  {
    path: "vendor",
    name: "customs-vendor",
    meta: {
      permission: "vendors",
      title: "Vendors",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/vendor/List.vue"
      ),
  },
  {
    path: "product-categories",
    name: "customs-product-categories",
    meta: {
      permission: "product_categories",
      title: "Product Categories",
    },
    component: () =>
      import(
        "@/views/modules/settings/views/settings/common-settings/product-categories/List.vue"
      ),
  },
  {
    path: "services",
    name: "customs-management-customs-services",
    meta: {
      permission: "customs_services",
      title: "Services",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/crud/products/List.vue"
      ),
    children: [

    ],
  },
  {
    path: "add-service",
    name: "customs-management-add-customs-service",
    meta: {
      permission: "customs_services",
      title: "Services",
      type: "write",
    },
    component: () =>
        import(
            "@/views/modules/customs/views/crud/products/Add.vue"
            ),
  },
  {
    path: "update-service/:id",
    name: "customs-management-update-customs-service",
    meta: {
      permission: "customs_services",
      title: "Services",
      type: "write",
    },
    component: () =>
        import(
            "@/views/modules/customs/views/crud/products/Add.vue"
            ),
  },
  {
    path: "view-service/:id/:view",
    name: "customs-management-view-customs-service",
    meta: {
      permission: "customs_services",
      title: "Services",
      type: "write",
    },
    component: () =>
        import(
            "@/views/modules/customs/views/crud/products/Add.vue"
            ),
  },
  {
    path: "transactions",
    name: "customs-transactions",
    meta: {
      title: "customTransactions",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/transactions/List.vue"
      ),
  },
  {
    path: "brokers",
    name: "customs-brokers",
    meta: {
      permission: "customs_brokers",
      title: "customsBrokers",
    },
    component: () =>
      import("@/views/modules/customs/views/daily-movements/brokers/List.vue"),
  },
  {
    path: "containers",
    name: "customs-containers",
    meta: {
      permission: "customs_containers",
      title: "containers",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/containers/List.vue"
      ),
  },
  {
    path: "documents",
    name: "customs-documents",
    meta: {
      permission: "customs_documents",
      title: "Documents",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/documents/List.vue"
      ),
  },
  {
    path: "invoice-items",
    name: "customs-invoice-items",
    meta: {
      permission: "customs_invoice_items",
      title: "invoiceItems",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/invoice-items/List.vue"
      ),
  },
  {
    path: "expenses-incomes",
    name: "customs-expenses-incomes",
    meta: {
      permission: "customs_expense_revenues",
      title: "expensesAndIncomes",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/expenses-incomes/List.vue"
      ),
  },
  {
    path: "deals",
    name: "customs-deals-list",
    meta: {
      title: "Received Deals",
    },
    component: () =>
      import("@/views/modules/customs/views/daily-movements/deals/List.vue"),
  },
  {
    path: "received-deals",
    name: "received-deals-list",
    meta: {
      title: "receivedDealsInformation",
    },
    component: () =>
      import("@/views/modules/customs/views/daily-movements/received-deals/List.vue"),
  },
  {
    path: "deal-procedure",
    name: "deal-procedure-list",
    meta: {
      title: "Update Deal Procedures",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/procedure-deals/List.vue"
      ),
  },
  {
    path: "deal-procedure/add",
    name: "deal-procedure-add",
    meta: {
      title: "Update Deal Procedures",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/procedure-deals/add.vue"
      ),
  },
  {
    path: "deal-procedure/edit/:id",
    name: "deal-procedure-edit",
    meta: {
      title: "Update Deal Procedures",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/procedure-deals/add.vue"
      ),
  },
  {
    path: "deal-procedure/:id/:view",
    name: "deal-procedure-view",
    meta: {
      title: "Update Deal Procedures",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/procedure-deals/add.vue"
      ),
  },
  // {
  //   path: "deal-procedure/add",
  //   name: "deal-procedure-add",
  //   component: () =>
  //     import(
  //       "@/views/modules/customs/views/daily-movements/deal-procedure/Add.vue"
  //     ),
  // },
  {
    path: "update-deal/view/:id",
    name: "view-deal-edit",
    meta: {
      title: "receivedDealsInformation",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/received-deals/View.vue"
      ),
  },
  {
    path: "update-deal/edit/:id",
    name: "update-deal-edit",
    meta: {
      title: "Enter Deal Procedure",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/deal-procedure/Add.vue"
      ),
  },
  {
    path: "update-deal/:id/:view",
    name: "update-deal-view",
    meta: {
      title: "Enter Deal Procedure",
    },
    component: () =>
      import(
        "@/views/modules/customs/views/daily-movements/deal-procedure/Add.vue"
      ),
  },
];

export default settingRouter;
