import { RouteRecordRaw } from 'vue-router';

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      children: [],
   },
];

export default reportRouter;
