import { RouteRecordRaw } from 'vue-router';

const dailyMovementRouter: Array<RouteRecordRaw> = [
   {
      path: 'close-month',
      name: 'attendance-close-month',
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/close-month/index.vue'),
   },
   {
      path: 'employees',
      name: 'attendance-employees',
      meta: {
         title: 'employees',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/employees/List.vue'),
   },
   {
      path: 'daily-transactions/import-employee-attendance',
      name: 'import-employee-attendance',
      meta: {
         title: 'Import Attendance And Departure',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/attendance-departure/import.vue'),
   },
   {
      path: 'daily-transactions/migrate-to-salaries',
      name: 'migrate-to-salaries',
      meta: {
         title: 'Post To Payroll',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/migrate-to-salaries/import.vue'),
   },
   {
      path: 'daily-transactions/overtime-approvals',
      name: 'overtime-approvals',
      meta: {
         title: 'Overtime Approvals',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/overtime-approvals/List.vue'),
   },
   {
      path: 'employees/add',
      name: 'attendance-employees-add',
      meta: {
         title: 'employees',
      },
      component: () => import('@/views/modules/attendance/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'employees/edit/:id',
      name: 'attendance-employees-edit',
      meta: {
         title: 'employees',
      },
      props: true,
      component: () => import('@/views/modules/attendance/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'employees/:id/:view',
      name: 'attendance-employees-view',
      meta: {
         title: 'employees',
      },
      props: true,
      component: () => import('@/views/modules/attendance/views/daily-movements/employees/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions',
      name: 'attendance-management-employees-code-transactions-list',
      meta: {
         permissions: 'hr_employee_number_movements',
         title: 'Update Employee Id',
      },
      component: () =>
         import('@/views/modules/hr/views/daily-movements/code-transactions/List.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/add',
      name: 'attendance-management-employees-code-transactions-add',
      meta: {
         permissions: 'hr_employee_number_movements',
         title: 'Update Employee Id',
         type: 'write',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/edit/:id',
      name: 'attendance-management-employees-code-transactions-edit',
      meta: {
         permissions: 'hr_employee_number_movements',
         title: 'Update Employee Id',
         type: 'edit',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },
   {
      path: 'daily-transactions/code-transactions/:id/:view',
      name: 'attendance-management-employees-code-transactions-view',
      meta: {
         permissions: 'hr_employee_number_movements',
         title: 'Update Employee Id',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/code-transactions/Add.vue'),
   },

   {
      path: 'daily-transactions/employee-attendance',
      name: 'employee-attendance',
      meta: {
         permission: 'hr_admin_employee_attendances',
         title: 'Employee Attendance',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/employee-attendance/company.vue'),
   },
   {
      path: 'daily-transactions/employee-attendance-location',
      name: 'employee-attendance-location',
      meta: {
         permission: 'hr_admin_employee_attendances',
         title: 'Employee Attendance Location',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/employee-attendance/location.vue'
         ),
   },
   {
      path: 'daily-transactions/employee-attendance-department',
      name: 'employee-attendance-department',
      meta: {
         permission: 'hr_admin_employee_attendances',
         title: 'Employee Attendance Department',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/employee-attendance/department.vue'
         ),
   },
   {
      path: 'daily-transactions/employee-attendance-employee',
      name: 'employee-attendance-employee',
      meta: {
         permission: 'hr_admin_employee_attendances',
         title: 'employee Attendance',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/employee-attendance/employee.vue'
         ),
   },
   {
      path: 'daily-transactions/leave-request',
      name: 'leave-request',
      meta: {
         permission: 'hr_leave_requests',
         role: 'employee',
         type: 'read',
         title: 'Dismiss Permission Request',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/leave-request/List.vue'),
   },
   {
      path: 'daily-transactions/add-leave-request',
      name: 'add-leave-request',
      meta: {
         title: 'Dismiss Permission Request',
         permission: 'hr_leave_requests',
         type: 'write',
         role: 'employee',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/leave-request/Add.vue'),
   },
   {
      path: 'daily-transactions/update-leave-request/:id/:type',
      name: 'update-leave-request',
      meta: {
         title: 'Dismiss Permission Request',
         permission: 'hr_leave_requests',
         type: 'edit',
         role: 'employee',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/leave-request/Add.vue'),
   },
   {
      path: 'daily-transactions/delay-request',
      name: 'delay-request',
      meta: {
         title: 'Delay Permission Request',
         permission: 'hr_delay_requests',
         role: 'employee',
         type: 'read',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/delay-request/List.vue'),
   },
   {
      path: 'daily-transactions/add-delay-request',
      name: 'add-delay-request',
      meta: {
         title: 'Delay Permission Request',
         permission: 'hr_delay_requests',
         role: 'employee',
         type: 'write',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/delay-request/Add.vue'),
   },
   {
      path: 'daily-transactions/update-delay-request/:id/:type',
      name: 'update-delay-request',
      meta: {
         title: 'Delay Permission Request',
         permission: 'hr_delay_requests',
         role: 'employee',
         type: 'edit',
         operation: 'or',
      },
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/delay-request/Add.vue'),
   },

   {
      path: 'daily-transactions/changing-temporary-working-hours',
      name: 'changing-temporary-working-hours',
      meta: {
         permission: 'hr_work_time_temporary_changes',
         title: 'Changing temporary working hours',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/changing-temporary-working-hours/List.vue'
         ),
   },
   {
      path: 'daily-transactions/add-changing-temporary-working-hours',
      name: 'add-changing-temporary-working-hours',
      meta: {
         permission: 'hr_work_time_temporary_changes',
         type: 'write',
         title: 'Changing temporary working hours',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/changing-temporary-working-hours/Add.vue'
         ),
   },
   {
      path: 'daily-transactions/update-changing-temporary-working-hours/:id/:type',
      name: 'update-changing-temporary-working-hours',
      meta: {
         permission: 'hr_work_time_temporary_changes',
         type: 'edit',
         title: 'Changing temporary working hours',
      },
      component: () =>
         import(
            '@/views/modules/attendance/views/daily-movements/changing-temporary-working-hours/Add.vue'
         ),
   },
   {
      path: 'daily-transactions/dismiss-requests',
      name: 'attendance-dismiss-requests',
      component: () =>
         import('@/views/modules/attendance/views/daily-movements/dismiss-request/List.vue'),
   },
   {
      path: 'daily-transactions/employee-vacation',
      name: 'employee-vacation',
      meta: {
         title: 'My Vacations',
      },
      component: () => import('@/views/modules/attendance/views/daily-movements/vacation/List.vue'),
   },
   {
      path: 'daily-transactions/add-employee-vacation',
      name: 'add-employee-vacation',
      meta: {
         title: 'My Vacations',
      },
      component: () => import('@/views/modules/attendance/views/daily-movements/vacation/Add.vue'),
   },
   {
      path: 'daily-transactions/update-employee-vacation/:id/:type',
      name: 'update-employee-vacation',
      meta: {
         title: 'My Vacations',
      },
      component: () => import('@/views/modules/attendance/views/daily-movements/vacation/Add.vue'),
   },
   //Vacation
   {
      path: 'daily-transactions/vacation',
      name: 'attendance-management-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/List.vue'),
   },
   {
      path: 'daily-transactions/add-vacation',
      name: 'attendance-management-add-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/Add.vue'),
   },
   {
      path: 'daily-transactions/update-vacation/:id/:type',
      name: 'attendance-management-update-vacation',
      meta: {
         permission: 'hr_vacation_movements',
         title: 'vacation',
      },
      component: () => import('@/views/modules/hr/views/daily-movements/vacation/Add.vue'),
   },
];

export default dailyMovementRouter;
