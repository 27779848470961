import axios from 'axios';

const CargoInvoiceModelModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    dataName: '',
    loading: false,
    crudLoading: false,
    loadingItem: false,
    editableId: null,
    isPagination: false,
    isCloseModal: false,
    bindingItem: {},
    bindProductValidation: {},
    pagination: {
      page: 1,
      total: 0,
      rowsPerPage: 10,
    },
    invoiceModel: {},
  },
  mutations: {
    SET_INVOICE_MODEL(state, payload) {
      state.invoiceModel = payload.item;
    },
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },
    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item['translations'] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = '';
      state.loading = false;
      state.itemEditable = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
    RESET_EDITABLE(state) {
      state.loading = false;
      state.itemEditable = {};
      state.editableId = null;
    },
    RESET_INVOICE_DATA(state) {
      state.bindingItem = {};
      state.bindProductValidation = {};
      state.invoiceModel = {};
    },
    RESET_BINDING_DATA(state) {
      state.bindingItem = {};
      state.bindProductValidation = {};
    },
    SET_EDITABLE_ID(state, id) {
      state.editableId = id;
    },
    SET_ROWS_PER_PAGE(state, payload) {
      state.pagination['rowsPerPage'] = payload;
      state.pagination['page'] = 1;
    },
    SET_TOTAL_NUM(state, payload) {
      state.pagination['total'] = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.pagination['page'] = payload;
    },
    SET_IS_PAGINATION(state, payload) {
      state.isPagination = payload;
    },
    SET_IS_CLOSE_MODAL(state, payload) {
      state.isCloseModal = payload;
    },
  },
  actions: {
    setRowsPerPage({ commit }, payload) {
      commit('SET_ROWS_PER_PAGE', payload);
    },
    setTotalNum({ commit }, payload) {
      commit('SET_TOTAL_NUM', payload);
    },
    setCurrentPage({ commit }, payload) {
      commit('SET_CURRENT_PAGE', payload);
    },
    setIsPagination({ commit }, payload) {
      commit('SET_IS_PAGINATION', payload);
    },
    setDataName({ commit }, name) {
      commit('SET_DATA_Name', name);
    },
    setIsCloseModal({ commit }, payload) {
      commit('SET_IS_CLOSE_MODAL', payload);
    },
    setEditableId({ commit }, id) {
      commit('SET_EDITABLE_ID', id);
    },
    reset({ commit }) {
      commit('RESET_ALL');
    },
    resetEditable({ commit }) {
      commit('RESET_EDITABLE');
    },
    resetInvoiceDate({ commit }) {
      commit('RESET_INVOICE_DATA');
    },
    resetBindingDate({ commit }) {
      commit('RESET_BINDING_DATA');
    },
    getData({ commit, state, dispatch }, apiPath) {
      commit('SET_LOADING', true);
      let query = state.isPagination
        ? `?pageSize=${state.pagination.rowsPerPage}&page=${state.pagination.page}`
        : '';
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}${query}`)
          .then((res) => {
            if (state.isPagination)
              commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
            commit('SET_DATA_TABLE', {
              DataTable: res?.data?.result?.[state.dataName]?.data,
            });
            commit('SET_LOADING', false);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },

    findItemById({ commit, state }, { apiPath, id }) {
      commit('SET_LOADING_ITEM', true);
      axios
        .get(`${apiPath}/${id}`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: res.data.result,
          });
          // commit('SET_LOADING', false);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          console.log(error);
          // commit('SET_LOADING', false);
        });
    },

    addItem({ commit }, { apiPath, credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },

    updateItem({ commit, state }, { apiPath, credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${credentials.id}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },

    deleteItem({ commit, dispatch }, { apiPath, credentials }) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiPath}/${credentials}`)
          .then((response) => {
            dispatch('getData', apiPath);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },

    generateInvoiceNumber({ commit, dispatch }, { apiPath, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/generate-invoice-number/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInvoicesListToBinding(
      { commit, dispatch },
      { apiPath, id, current_model_id }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/get-invoices-list-to-binding/${id}`, {
            params: { current_model_id },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInvoicesListToBindNotice(
      { commit, dispatch },
      { apiPath, id, values }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/get-invoices-list-to-bind-notice`, {
            params: { ...values },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInvoiceModelList({ commit, dispatch }, { apiPath, filters = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/get-invoice-model-list`, { params: { ...filters } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInvoiceToBindById({ commit, dispatch, state }, { type, ...params }) {
      let apiPath = '';
      switch (type) {
        case 'sales':
          apiPath = '/transport/transport-sales-invoices/get-invoice-to-bind';
          break;
        case 'purchase':
          apiPath = '/transport/transport-purchase-invoices/get-invoice-to-bind';
          break;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}`, { params: params })
          .then((response) => {

            if(params.is_credit_notice || params.is_debit_notice){
              state.invoiceModel = {
                ...state.invoiceModel,
                currency_fraction:response.data.result.item.invoice_model.currency_fraction,
                quantity_fraction:response.data.result.item.invoice_model.currency_fraction,
                rounding_fraction:response.data.result.item.invoice_model.rounding_fraction
              };
            }

            state.bindingItem = response.data.result.item;
            state.bindProductValidation = {
              productsMax: response.data.result.productsMax,
              productsRemaining: response.data.result.productsRemaining,
              productsUsed: response.data.result.productsMax,
              can_exceed_limit: response.data.result.can_exceed_limit ?? false,
            };

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    findInvoiceModel({ commit }, { id, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/transport/transport-invoice-models/${id}`, { params })
          .then((res) => {
            commit('SET_INVOICE_MODEL', {
              item: res.data.result.item,
            });
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    toggleActive({ commit, dispatch }, { apiPath, id,active}) {
      return new Promise((resolve, reject) => {
        axios
            .get(`${apiPath}/toggle-active/${id}?active=${active}`)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
      });
    },
  },
  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default CargoInvoiceModelModule;
