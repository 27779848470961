import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import ModulesModule from '@/store/modules/ModulesModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import TableModule from '@/store/modules/TableModule';
import MainMenuModule from '@/store/modules/MainMenuModule';
import ModalModule from '@/store/modules/ModalModule';
import ListModule from '@/store/modules/ListModule';
import InviteModule from '@/store/modules/InviteModule';
import SubscriptionModule from '@/store/modules/SubscriptionModule';
import settingsStore from '@/views/modules/settings/store';
import accountingStore from '@/views/modules/accounting/store/index';
import invoicesStore from '@/views/modules/invoicing/store/index';
import customsStore from '@/views/modules/customs/store/index';
import warehouseStore from '@/views/modules/warehouse/store/index';
import cargoStore from '@/views/modules/cargo/store/index';
import adminPanelStore from '@/views/admin-panel/store/index';
import GuestModule from '@/store/modules/GuestModule';
import SharedStore from '@/views/modules/shared/store/index';
import hrGetSetting from '@/store/hr/hrGetSetting.js';
import hrStore from '@/views/modules/hr/store';
import salariesStore from '@/views/modules/salaries/store';
import attendanceStore from '@/views/modules/attendance/store';
import hrWorkTimes from '@/store/attendance/hrWorkTimes.js';
import customsServices from '@/store/customs/customsServices.js';
import customsTransactions from '@/store/customs/customsTransactions.js';
import customsDeals from '@/store/customs/customsDeals.js';
import customsNationalities from '@/store/customs/customsNationalities.js';
import attendanceGetSetting from './hr/attendanceGetSetting';
import GlobalReportModule from './modules/GlobalReportModule.js';
import ServicesModule from './modules/ServicesModule.js';
import restaurantsStore from '@/views/modules/restaurants/store';
import electronicApprovalsStore from '@/views/modules/electronic-approvals/store';
import Approvals from '@/store/modules/Approvals';
import AssetsStore from '@/views/modules/assets/store';
import posStore from '@/views/modules/pos/store';
import casherStore from '@/views/modules/casher/store';

config.rawError = true;

const store = createStore({
   modules: {
      AuthModule,
      ModulesModule,
      BodyModule,
      BreadcrumbsModule,
      ConfigModule,
      TableModule,
      MainMenuModule,
      ModalModule,
      ListModule,
      InviteModule,
      SubscriptionModule,
      GuestModule,
      ...settingsStore,
      ...invoicesStore,
      ...customsStore,
      ...warehouseStore,
      ...cargoStore,
      ...accountingStore,
      ...adminPanelStore,
      ...SharedStore,
      ...salariesStore,
      ...attendanceStore,
      ...hrStore,
      ...restaurantsStore,
      ...electronicApprovalsStore,
      ...AssetsStore,
      ...posStore,
      ...casherStore,
      hrGetSetting,
      attendanceGetSetting,
      hrWorkTimes,
      customsServices,
      customsTransactions,
      customsNationalities,
      customsDeals,
      GlobalReportModule,
      ServicesModule,
      Approvals,
   },
});

export default store;
