import axios from 'axios';
import {ModulesAPIsConstants} from "../enums/ApplicationListEnums";
import {AdminProfileAPIsConstants} from "../enums/AdminProfileEnums";
import {CustomersAPIsConstants} from "../enums/CustomersEnums";
import {AdminNotificationsAPIsConstants} from "@/views/admin-panel/store/enums/AdminNotificationsEnums";

const AdminModulesModule = {
  namespaced: true,
  state: {
    data: [],
    loading: false,
    notifications: [],
    notificationsPage: 1,
    notification_loading: true,
    loadingItem: false,
    itemEditable: {},
    CustomerEditable: {},
    changePasswordLoading: false,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      state.itemEditable = payload.itemEditable;
      // console.log("itemEditable",state.itemEditable);
    },
    SET_CUSTOMER_EDITABLE(state, payload) {
      let arr = [];

      state.CustomerEditable = payload.CustomerEditable;
      // console.log("CustomerEditable",state.CustomerEditable);
    },
    SET_CHANGE_PASSWORD_LOADING(state, payload) {
      state.changePasswordLoading = payload;
    },
    resetNotificationsPage(state) {
      state.notificationsPage = 1;
    },

    incrementNotificationsPage(state) {
      state.notificationsPage += 1;
    },
    SET_NOTIFICATION_LOADING(state, payload) {
      state.notification_loading = payload;
    },
  },
  actions: {
    getData({ commit }) {
      commit('SET_LOADING', true);
      return axios
        .get(`${ModulesAPIsConstants.API_PATH}`)
        .then(({ data }) => {
          commit('SET_DATA', data.result.modules);
          commit('SET_LOADING', false);
          return data
        })
        .catch((error) => {
          commit('SET_LOADING', false);
          console.log(error);
        });
    },
    getNotifications({ commit, state }, { apiPath }) {
      commit('SET_NOTIFICATION_LOADING', true);
      commit('resetNotificationsPage');
      
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}`)
          .then((response) => {
            state.notifications = response.data.result;
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          }).finally(() => {
           commit('SET_NOTIFICATION_LOADING', false);
          });
      });
    },
    setNotificationsByPage({ commit, state }) {
      commit('SET_NOTIFICATION_LOADING', true);
      commit('incrementNotificationsPage');

      return new Promise((resolve, reject) => {
        axios
            .get(`${AdminNotificationsAPIsConstants.API_PATH}?page=${state.notificationsPage}`)
            .then((response) => {
              state.notifications = response.data.result;
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              commit('SET_NOTIFICATION_LOADING', false);
            });
      });
    },
    getProfile({ commit, state }) {
      // if (!state.editableId) {
      //       //   return;
      //       // }
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
      axios
        .get(`${AdminProfileAPIsConstants.API_PATH}/get-profile`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: res.data.result?.admin,
          });
          // commit('SET_LOADING', false);
          console.log('data',res.data);
          resolve(res);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          console.log(error);
          reject(error);
          // commit('SET_LOADING', false);
        });
      });
    },
    updateProfile({ commit, state }, { credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${AdminProfileAPIsConstants.API_PATH}/update-profile`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },
    getCustomerProfile({ commit, state }, {  id }) {
      // if (!state.editableId) {
      //       //   return;
      //       // }
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
      axios
        .get(`${CustomersAPIsConstants.API_PATH}/${id}`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_CUSTOMER_EDITABLE', {
            CustomerEditable: res.data.result?.item,
          });
          // commit('SET_LOADING', false);
          console.log('dataaa',res.data.result?.item);
          resolve(res);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          console.log(error);
          reject(error);
          // commit('SET_LOADING', false);
        });
      });
    },
    changePassword({ commit, state }, { credentials }) {
      commit('SET_CHANGE_PASSWORD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${AdminProfileAPIsConstants.API_PATH}/change-password`, credentials)
          .then((response) => {
            commit('SET_CHANGE_PASSWORD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CHANGE_PASSWORD_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {
    notificationsIsLastPage(state) {
      return (
          state.notifications?.company_notifications?.current_page ==
          state.notifications?.company_notifications?.last_page
      );
    },
  },
};
export default AdminModulesModule;
