import axios from 'axios';
import DownloadService from '../../../../../../core/services/DownloadService';

const path = 'MyVacations';

export const MyVacationAPIsConstants = {
   API_PATH: '/hr/attendance/report/vacation-movement-report',
};

export const MyVacationsActions = {
   GET_MY_REPORT: `${path}/getMyReport`,
};

const MyVacations = {
   namespaced: true,
   state: {
      itemEditable: {},
      loading: false,
   },
   mutations: {
      SET_ITEM_EDITABLE(state, payload) {
         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      getMyReport({ commit }, params) {
         commit('SET_LOADING', true);

         if (params.excel == 1) {
            return DownloadService.ddd(MyVacationAPIsConstants.API_PATH, params).then(() =>
               commit('SET_LOADING', false),
            );
         }

         return axios
            .get(MyVacationAPIsConstants.API_PATH, { params })
            .then((response) => {
               commit('SET_ITEM_EDITABLE', { itemEditable: response.data.result });
               return response;
            })
            .finally(() => commit('SET_LOADING', false));
      },
   },
};
export default MyVacations;
