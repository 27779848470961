const path = 'modules';
const ModulesActions = {
   // action types
   GET_MODULES: `${path}/getModules`,
   RESET: `${path}/reset`,
   SUBSCRIBE: `${path}/subscribe`,
   SUBSCRIBE_BUNDLE: `${path}/subscribeBundle`,
   SAVE_SELECTED_MODULE: `${path}/saveSelectedModules`,
};

const ModulesMutations = {
   // mutation types
   SET_ERROR: `${path}/setError`,
   RESET_DATA: `${path}/resetData`,
   SET_MODULES: `${path}/setModules`,
   SET_SUBSCRIBABLE_MODULES: `${path}/setSubscribableModules`,
   SET_SUBSCRIBABLE_BUNDLES: `${path}/setSubscribableBundles`,
   SET_LOADING_MODULES: `${path}/setLoadingModules`,
   SET_SELECTED_MODULES: `${path}/SET_SELECTED_MODULES`,
   SET_BUNDLES: `${path}/SET_BUNDLES`,
   SET_SELECTED_DETAILS: `${path}/SET_SELECTED_DETAILS`,
};

export { ModulesActions, ModulesMutations };
