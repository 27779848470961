const path = 'WarehouseDashboardModule';
const apiPath = 'warehouse/dashboard';

const WarehouseDashboardModelActions = {
    //action type
    GET_WAREHOUSE_STATISTIC_DATA : `${path}/getWarehouseStatisticData`,
};

const WarehouseDashboardModelAPIsConstants = {
    API_PATH : `${apiPath}`
}

export {WarehouseDashboardModelActions, WarehouseDashboardModelAPIsConstants};
