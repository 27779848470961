const path = "PrintModelModule";
const apiPath = "/company/print-models";

const PrintModelActions = {
  GET_ITEM: `${path}/getItem`,
};

const PrintModelMutations = {
  SET_LOADING: `${path}/SET_LOADING`,
  SET_ITEM: `${path}/SET_ITEM`,
  RESET: `${path}/RESET`,
};

const PrintModelAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const PrintModelPlaceholderAPIsConstants = {
  API_PATH: `/company/print-model-placeholders`,
};

export {
  PrintModelActions,
  PrintModelMutations,
  PrintModelAPIsConstants,
  PrintModelPlaceholderAPIsConstants,
};
