const path = "EmployeesLeavesInfoModule";
const apiPath = "/hr/report/movements/leaves-info-report";

const EmployeesLeavesInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesLeavesInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesLeavesInfoAPIsConstants, EmployeesLeavesInfoActions };
