import axios from 'axios';
import { UpdateEmployeeJobAPIsConstants } from '../enums/daily-movements/UpdateEmployeeJob';

export default {
   namespaced: true,
   state: {
      crudLoading: false,
      itemLoading: false,
   },
   mutations: {
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_ITEM_LOADING(state, payload) {
         state.itemLoading = payload;
      },
   },
   actions: {
      change({ commit }, { data }) {
         commit('SET_CRUD_LOADING', true);

         return axios.post(UpdateEmployeeJobAPIsConstants.CHANGE, data).finally(() => {
            commit('SET_CRUD_LOADING', false);
         });
      },
      findItemById({ commit }, { id, params }) {
         commit('SET_ITEM_LOADING', true);

         return axios
            .get(`${UpdateEmployeeJobAPIsConstants.API_PATH}/${id}`, { params })
            .finally(() => {
               commit('SET_ITEM_LOADING', false);
            });
      },
   },
};
