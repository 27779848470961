import { App } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/JwtService';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import LanguageService from '@/core/services/LanguageService';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
   /**
    * @description property to share vue instance
    */
   public static vueInstance: App;

   /**
    * @description initialize vue axios
    */
   public static init(app: App<Element>) {
      const logoutRoute = ['/#/user-invitation', '/#/user-complete-profile', '/#/reset-password'];

      if (logoutRoute.some((el) => window.location.href.includes(el))) {
         JwtService.destroyToken();
      }

      ApiService.vueInstance = app;
      ApiService.vueInstance.use(VueAxios, axios);

      ApiService.vueInstance.axios.defaults.baseURL =
         process.env.VUE_APP_URL_HTTP + process.env.VUE_APP_API_URL;
   }

   public static getDomainNameFromUrl() {
      const url = window.location.href;
      return url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
   }

   public static getSubdomainFromUrl() {
      return ApiService.getDomainNameFromUrl().split('.').length > 2
         ? ApiService.getDomainNameFromUrl().split('.')[0]
         : '';
   }

   public static getDomainName(domain) {
      const parts = domain.split('.').reverse();
      const cnt = parts.length;
      if (cnt >= 3) {
         // see if the second level domain is a common SLD.
         if (parts[1].match(/^(com|edu|gov|net|mil|org|nom|co|name|info|biz)$/i)) {
            return parts[2] + '.' + parts[1] + '.' + parts[0];
         }
      }
      return parts[1] + '.' + parts[0];
   }

   public static setHeader(): void {
      ApiService.vueInstance.axios.defaults.headers.common[
         'Authorization'
      ] = `Bearer ${JwtService.getToken()}`;
      ApiService.vueInstance.axios.defaults.headers.common['Lang'] =
         LanguageService.getLang() ?? 'ar';
      // ] = localStorage.getItem('lang') ?? 'ar';
      ApiService.vueInstance.axios.defaults.headers.common['Accept'] = 'application/json';
   }

   /**
    * @description send the GET HTTP request
    * @param resource: string
    * @param params: AxiosRequestConfig
    * @returns Promise<AxiosResponse>
    */
   public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.get(resource, params);
   }

   /**
    * @description send the GET HTTP request
    * @param resource: string
    * @param slug: string
    * @returns Promise<AxiosResponse>
    */
   public static get(resource: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.get(resource, config);
   }

   /**
    * @description set the POST HTTP request
    * @param resource: string
    * @param params: AxiosRequestConfig
    * @returns Promise<AxiosResponse>
    */
   public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.post(`${resource}`, params);
   }

   /**
    * @description send the UPDATE HTTP request
    * @param resource: string
    * @param slug: string
    * @param params: AxiosRequestConfig
    * @returns Promise<AxiosResponse>
    */
   public static update(
      resource: string,
      slug: string,
      params: AxiosRequestConfig,
   ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
   }

   /**
    * @description Send the PUT HTTP request
    * @param resource: string
    * @param params: AxiosRequestConfig
    * @returns Promise<AxiosResponse>
    */
   public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.put(`${resource}`, params);
   }

   /**
    * @description Send the DELETE HTTP request
    * @param resource: string
    * @returns Promise<AxiosResponse>
    */
   public static delete(resource: string): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.delete(resource);
   }
}

export default ApiService;
