import InvoiceModelModule from './modules/InvoiceModelModule';
import SalesInvoiceModule from './modules/SalesInvoiceModule';
import PurchaseInvoiceModule from './modules/PurchaseInvoiceModule';
import InvoiceSettingModule from './modules/InvoiceSettingModule';
import ReportModule from './modules/ReportModule';
import DashboardModule from './modules/DashboardModule';
import InvoicingSalesFinancialTransactionModule from './modules/SalesFinancialTransactionModule';
import PurchaseFinancialTransactionModule from './modules/PurchaseFinancialTransactionModule';
import InvoicingJournalEntryPostingsModule from './modules/InvoicingJournalEntryPostingsModule';
import { CommissionsModule } from './modules/Commissions';

const invoicesStore = {
   InvoiceModelModule,
   SalesInvoiceModule,
   PurchaseInvoiceModule,
   InvoiceSettingModule,
   ReportModule,
   DashboardModule,
   InvoicingSalesFinancialTransactionModule,
   InvoicingJournalEntryPostingsModule,
   PurchaseFinancialTransactionModule,
   CommissionsModule,
};

export default invoicesStore;
