const PATH = '/admin-panel';
import { AdminRoles } from '@/views/admin-panel/views/shared/constants/AdminRoles';

const AdminPanelMenuConfig = [
   {
      heading: 'Settings',
      route: '#',
      pages: [
         {
            heading: 'the coupons',
            route: '/admin-panel/coupons',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'banks',
            route: '/admin-panel/banks',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'the configs',
            route: '/admin-panel/configs',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'Explanation',
            route: '/admin-panel/sections-explanation',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'Send Notifications',
            route: '/admin-panel/send-notifications',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'Privacy Policy',
            route: '/admin-panel/privacy-policy',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'Blogs',
            route: '/admin-panel/blogs',
            role: 'BLOGGER',
         },
      ],
   },
   {
      heading: 'Subscriptions',
      route: '#',
      pages: [
         {
            heading: 'Managers',
            route: '/admin-panel/customers',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Registered Companies',
            route: '/admin-panel/companies',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Subscribed Customers',
            route: '/admin-panel/subscribed-customers',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Payments',
            route: '/admin-panel/subscriptions',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Visitors',
            route: '/admin-panel/visitors',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Gallery Visitors',
            route: '/admin-panel/gallery-visitors',
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
         },
      ],
   },
   {
      pages: [
         {
            heading: 'Departments',
            route: '/admin-panel/departments-management',
            role: 'SUPER_ADMIN',
         },
         {
            heading: 'admins',
            route: '/admin-panel/users',
            role: 'SUPER_ADMIN',
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Companies',
            route: `${PATH}/reports/companies`,
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Modules',
            route: `${PATH}/reports/modules`,
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Managers',
            route: `${PATH}/reports/managers`,
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
         },
         {
            heading: 'Registered from gallery',
            route: `${PATH}/reports/visitors`,
            role: `${AdminRoles.SUPER_ADMIN}`,
         },
         {
            heading: 'payments',
            route: `${PATH}/reports/payments`,
            role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}`,
         },
         {
            heading: 'Visitors',
            route: `${PATH}/reports/visitors-explore`,
            role: `${AdminRoles.SUPER_ADMIN}`,
         },
      ],
   },
];

export default AdminPanelMenuConfig;
