import axios from 'axios';
import { AttendanceDepartureAPIsConstants } from "./../enums/daily-movements/AttendanceDepartureEnums";
import DownloadService from '@/core/services/DownloadService';

const AttendanceDepartureModule = {
    namespaced: true,
    mutations: {},
    actions: {
        import({ commit, state }, { apiPath, credentials }) {
            var formData = new FormData();
            formData.append("file", credentials['file']);

            return new Promise((resolve, reject) => {
                axios
                    .post(`${apiPath}/hr-admin-employee-attendance/import`, formData)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    }
}
export default AttendanceDepartureModule;