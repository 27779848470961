const path = 'WarehouseJournalEntryPostingsModule';
const apiPath = '/warehouse/warehouse-accounting';

const WarehouseJournalEntryPostingsModelActions = {
  // action types
  MAKE_ITEM: `${path}/createPosting`,

}

const WarehouseJournalEntryPostingsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { WarehouseJournalEntryPostingsAPIsConstants, WarehouseJournalEntryPostingsModelActions };
