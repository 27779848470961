export const CookieService = {
   setCookie,
   getCookie,
   eraseCookie,
};

function setCookie(name, data) {
   const now = new Date();
   const time = now.getTime();
   const expireTime = time + 1000 * 3600000;
   now.setTime(expireTime);

   let domain = '';
   domain = '.' + process.env.VUE_APP_DOM + '.';
   if (process.env.NODE_ENV == 'development') domain += 'localhost';
   else domain += 'com';
   document.cookie =
      name +
      '=' +
      (JSON.stringify(data) || '') +
      `; path=/;domain= ${domain};expires= ${now.toUTCString()}`;
}

/**
 *
 * @param name
 * @return any
 */
function getCookie(name): any {
   let result = document.cookie.match(new RegExp(name + '=([^;]+)'));
   result && (result = JSON.parse(result[1]));
   return result;
}

function eraseCookie(name) {
   let domain = '';
   domain = process.env.VUE_APP_DOM + '.';
   if (process.env.NODE_ENV == 'development') domain += 'localhost';
   else domain += 'com';
   document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + domain;
}
