import router from '@/router';
import { App } from 'vue';
import { RouteLocationNamedRaw } from 'vue-router';

export const routerBack = (defaultRoute: RouteLocationNamedRaw) => {
   const backState = router.options.history.state.back;

   if (!backState && defaultRoute) return router.push(defaultRoute);

   return router.back();
};

export const BackPlugin = {
   install: (app: App<Element>) => {
      app.config.globalProperties.$back = routerBack;
   },
};
