import { RouteRecordRaw } from 'vue-router';
import {AdminRoles} from "@/views/admin-panel/views/shared/constants/AdminRoles";

const reportRouter: Array<RouteRecordRaw> = [
   {
      path: 'reports',
      children: [
         {
            path: 'companies',
            name: 'admin-companies-reports',
            meta: {
               title: 'Companies',
               role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/companies/base.vue'),
         },
         {
            path: 'modules',
            name: 'admin-modules-reports',
            meta: {
               title: 'Modules',
               role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/modules/base.vue'),
         },
         {
            path: 'managers',
            name: 'admin-managers-reports',
            meta: {
               title: 'Managers',
               role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/managers/base.vue'),
         },
         {
            path: 'visitors',
            name: 'admin-visitors-reports',
            meta: {
               title: 'Registered from gallery',
               role: `${AdminRoles.SUPER_ADMIN}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/visitors/base.vue'),
         },
         {
            path: 'payments',
            name: 'admin-payments-reports',
            meta: {
               title: 'payments',
               role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/payments/base.vue'),
         },
         {//Visitors from explore doso
            path: 'visitors-explore',
            name: 'admin-visitors-explore-reports',
            meta: {
               title: 'Visitors',
               role: `${AdminRoles.SUPER_ADMIN}`,
            },
            component: () =>
               import('@/views/admin-panel/views/reports/visitors-from-explore/base.vue'),
         },
      ],
   },
];

export default reportRouter;
