import axios from 'axios';
import {SubscriptionsAPIsConstants} from "../enums/SubscriptionsEnums";

const AdminSubscriptionModule = {
  namespaced: true,
  state: {
    data: [],
    loading: false,
    crudLoading: false,
    loadingItem: false,
    itemEditable: {},
    editableId: null,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_DATA(state, payload) {
      state.data = payload.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable?.item?.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item['translations'] = arr;
      }

      // if (payload.itemEditable.item.address && payload.itemEditable.item.address.translations) {
      //   payload.itemEditable.item.address.translations.map((lang) => {
      //     payload.itemEditable.item.address[lang.locale] = lang;
      //   });
      // }

      state.itemEditable = payload.itemEditable;
    },
    SET_EDITABLE_ID(state, id) {
      state.editableId = id;
    },
  },
  actions: {
    // get({ commit }) {
    //   commit('SET_LOADING', true);
    //   axios
    //     .get(`${ModulesAPIsConstants.API_PATH}`)
    //     .then((res) => {
    //       commit('SET_DATA', res.data.result.modules);
    //       commit('SET_LOADING', false);
    //     })
    //     .catch((error) => {
    //       commit('SET_LOADING', false);
    //       console.log(error);
    //     });
    // },
    setEditableId({ commit }, id) {
      commit('SET_EDITABLE_ID', id);
    },
    acceptTransaction({ commit }, { id }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
            .get(SubscriptionsAPIsConstants.ACCEPT_TRANSACTION+'/'+id)
            .then((response) => {
              commit('SET_CRUD_LOADING', false);
              resolve(response);
            })
            .catch((error) => {
              commit('SET_CRUD_LOADING', false);
              reject(error);
            });
      });
    },

    declineTransaction({ commit }, { id , status }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
            .get(SubscriptionsAPIsConstants.DECLINE_TRANSACTION+'/'+id,{params: {status}})
            .then((response) => {
              commit('SET_CRUD_LOADING', false);
              resolve(response);
            })
            .catch((error) => {
              commit('SET_CRUD_LOADING', false);
              reject(error);
            });
      });
    },
    viewSubscription({ commit }, { id }) {
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
        axios
            .get(SubscriptionsAPIsConstants.API_PATH+'/'+id)
            .then((response) => {
              commit('SET_LOADING_ITEM', false);
              resolve(response);
            })
            .catch((error) => {
              commit('SET_LOADING_ITEM', false);
              reject(error);
            });
      });
    },
    findItemById({ commit, state }, id) {
      commit('SET_LOADING_ITEM', true);
      return new Promise((resolve, reject) => {
        axios
            .get(SubscriptionsAPIsConstants.API_PATH+'/'+id)
            .then((res) => {
              commit('SET_LOADING_ITEM', false);
              commit('SET_ITEM_EDITABLE', {
                itemEditable: res.data.result,
              });
              // commit('SET_LOADING', false);
              resolve(res);
            })
            .catch((error) => {
              commit('SET_LOADING_ITEM', false);
              console.log(error);
              reject(error);
              // commit('SET_LOADING', false);
            });
      });
    },
  },
  getters: {

  },
};
export default AdminSubscriptionModule;
