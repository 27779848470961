const path = "EmployeesInfoModule";
const apiPath = "/hr/report/employee/employee-info-report";

const EmployeesInfoActions = {
  RESET_EDITABLE: `${path}/resetEditable`,
  GENERATE_REPORT: `${path}/generateReport`,
};

const EmployeesInfoAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { EmployeesInfoAPIsConstants, EmployeesInfoActions };
