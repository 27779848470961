import { mergeCrudStore } from '@/core/stores/curd';
import axios from 'axios';
import { CustomsProductsAPIsConstants } from '../../enums/customs/CustomsProductsEnums';

const CustomsProductModule = mergeCrudStore({
   namespaced: true,
   actions: {
      getData({ commit, state }, apiPath) {
         commit('SET_LOADING', true);
         let query = state.isPagination
            ? `?pageSize=${state.pagination.rowsPerPage}&page=${state.pagination.page}`
            : '';
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}${query}`)
               .then((res) => {
                  if (state.isPagination)
                     commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
                  commit('SET_DATA_TABLE', {
                     DataTable: res.data.result[state.dataName].data,
                  });
                  commit('SET_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  console.log(error);
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      findItemById({ commit, state }, { apiPath, id }) {
         commit('SET_LOADING_ITEM', true);
         axios
            .get(`${apiPath}/${id}`)
            .then((res) => {
               commit('SET_LOADING_ITEM', false);
               commit('SET_ITEM_EDITABLE', {
                  itemEditable: JSON.parse(JSON.stringify(res.data.result)),
               });
            })
            .catch((error) => {
               commit('SET_LOADING_ITEM', false);
               console.log(error);
            });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getProductUnitWarehouseInfo(
         { commit, state, dispatch },
         { productUnitWarehouseId, expiryDate, lotNumber },
      ) {
         return new Promise((resolve, reject) => {
            axios
               .get(
                  `${CustomsProductsAPIsConstants.GET_PRODUCT_UNIT_WAREHOUSE_INFO_PATH}/${productUnitWarehouseId}?expiry_date=${expiryDate}&lot_number=${lotNumber}`,
               )
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  console.log(error);
                  reject(error);
               });
         });
      },

      serialNumbersValidation({ commit, state }, { newSerialNumber, productId }) {
         return new Promise((resolve, reject) => {
            axios
               .get(`core/common/validation/product-serial-numbers`, {
                  params: {
                     serial_numbers: newSerialNumber,
                     product_id: productId,
                  },
               })
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getGreaterThanUnits({ commit, state }, credentials) {
         return new Promise((resolve, reject) => {
            let filter = '?';
            if (credentials.withSentUnit) filter += 'withSentUnit=' + credentials.withSentUnit;
            axios
               .get(`core/units/get-greater-than-units/${credentials?.unitID}${filter}`)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      generateProductInfo({ commit }) {
         return new Promise((resolve, reject) => {
            axios
               .get(`${CustomsProductsAPIsConstants.API_PATH}/generate-product-info`)
               .then((response) => {
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
});
export default CustomsProductModule;
