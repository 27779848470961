const apiPath = "/customs/customs-deal-updates";
const path = "ProceduresDealsModule";

const ProceduresDealsAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const ProceduresDealsActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  GET_INIT_DATA: `${path}/getInitData`,
};

export { ProceduresDealsAPIsConstants, ProceduresDealsActions };
