const apiPath = '/accounting/journal-entry';
const path = 'JournalEntryDailyModule';


const JournalEntryDailyAPIsConstants = {
  API_PATH : `${apiPath}`,
}
const JournalEntryDailyModelActions = {
  SET_DATA_NAME : `${path}/setDataName`,
  SET_EDITABLE_ID : `${path}/setEditableId`,
  ADD_ITEM : `${path}/addItem`,
  UPDATE_ITEM : `${path}/updateItem`,
  DELETE_ITEM : `${path}/deleteItem`,
  FIND_ITEM_BY_ID : `${path}/findItemById`,
  RESET_EDITABLE : `${path}/resetEditable`,
  RESET : `${path}/reset`,
  SET_IS_CLOSE_MODAL : `${path}/setIsCloseModal`,
  SET_FILTERS : `${path}/setFilters`,

  GET_INIT_DATA : `${path}/getInitData`,
  GET_APPROVED_RECEIPT : `${path}/getApprovedReceipt`,
  GET_APPROVED_RECEIPT_ID: `${path}/getApprovedReceiptId`,
  SET_DETAILS_TABLE_LOADING : `${path}/setDetailsTableLoading`,
  SET_DETAILS_TABLE : `${path}/setDetailsTable`,
  GET_RECEIPT_DETAILS : `${path}/getReceiptDetails`,
  GET_REVERSE_JOURNAL_ENTRY : `${path}/getReverseJournalEntry`,
  GET_JOURNAL_ENTRY_TEMPLATE : `${path}/getJournalEntryTemplate`,
}

export { JournalEntryDailyAPIsConstants, JournalEntryDailyModelActions };