const path = "HrDashboardModule";
const apiPath = "hr/dashboard";
const MONTHS = (t) => [
  t("Jan"),
  t("Feb"),
  t("Mar"),
  t("Apr"),
  t("May"),
  t("Jun"),
  t("Jul"),
  t("Aug"),
  t("Sep"),
  t("Oct"),
  t("Nov"),
  t("Dec"),
];

const DashboardModelActions = {
  //action type
  GET_DATA: `${path}/getStatisticData`,
  GET_DATA_ATTENDANCE: `${path}/getStatisticDataAttendance`,
  GET_DATA_SALARIES: `${path}/getStatisticDataSalaries`,
};

const DashboardModelAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { DashboardModelActions, DashboardModelAPIsConstants, MONTHS };
