const path = 'HrSettingModule';
const apiPath = '/hr/setting';

const HrSettingModelActions = {
  // action types
  GET_SETTINGS : `${path}/getSetting`,
  UPDATE_SETTINGS : `${path}/updateSetting`

}

const HrSettingAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  GET_SETTINGS : `${apiPath}/get-setting`,
  UPDATE_SETTINGS : `${apiPath}/update-setting`,
}

export { HrSettingAPIsConstants, HrSettingModelActions };
