import axios from 'axios';
import { MuqueemAPIsConstants } from '../enums/MuqeemEnums';

const MuqeemModule = {
   namespaced: true,
   state: {
      data: [],
      loadingItem: [],
      cities: [],
      countries: [],
      maritalStatuses: [],
   },
   mutations: {
      SET_DATA(state, payload) {
         state.data = payload;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      SET_CITIES(state, payload) {
         state.cities = payload;
      },
      SET_COUNTRIES(state, payload) {
         state.countries = payload;
      },
      SET_MARITAL_STATUSES(state, payload) {
         state.maritalStatuses = payload;
      },
   },
   actions: {
      getData({ commit }) {
         commit('SET_LOADING_ITEM', true);

         return new Promise((resolve, reject) => {
            axios
               .get(`${MuqueemAPIsConstants.GET_SETTINGS}`)
               .then((res) => {
                  commit('SET_DATA', res.data.result);

                  commit('SET_LOADING_ITEM', false);

                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      updateSetting({ commit }, { values }) {
         commit('SET_LOADING_ITEM', true);

         return new Promise((resolve, reject) => {
            axios
               .post(`${MuqueemAPIsConstants.UPDATE_SETTINGS}`, values)
               .then((res) => {
                  commit('SET_DATA', res.data.result);

                  commit('SET_LOADING_ITEM', false);

                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },
      getCities({ state, commit }) {
         if (state.cities.length) return Promise.resolve();

         return axios.get(MuqueemAPIsConstants.GET_CITIES).then((res) => {
            commit(
               'SET_CITIES',
               res.data.result.data.map((c) => ({ ...c, id: c.code, name: c.nameAr })),
            );
         });
      },
      getCountries({ state, commit }) {
         if (state.countries.length) return Promise.resolve();

         return axios.get(MuqueemAPIsConstants.GET_COUNTRIES).then((res) => {
            commit(
               'SET_COUNTRIES',
               res.data.result.data.map((c) => ({ ...c, id: c.code, name: c.nameAr })),
            );
         });
      },
      getMaritalStatuses({ state, commit }) {
         if (state.maritalStatuses.length) return Promise.resolve();

         return axios.get(MuqueemAPIsConstants.GET_MARITAL_STATUSES).then((res) => {
            commit(
               'SET_MARITAL_STATUSES',
               res.data.result.data.map((c) => ({ ...c, id: c.code, name: c.nameAr })),
            );
         });
      },
   },
};
export default MuqeemModule;
