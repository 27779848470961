import axios from "axios";
import {GuestEnumAPIsConstants} from "@/store/enums/GuestEnum";

const GuestModule = {
    namespaced: true,
    state: {
        loading: false,
        crudLoading: false,
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_CRUD_LOADING(state, payload) {
            state.crudLoading = payload;
        },
        SET_LOADING_ITEM(state, payload) {
            state.loadingItem = payload;
        },
        RESET_ALL(state) {
            state.loading = false;
        },
    },
    actions: {
        SendContactForm({commit, dispatch},params) {
            commit('SET_LOADING',true)
            return new Promise((resolve, reject) => {
                axios.post(`${GuestEnumAPIsConstants.API_PATH}/contact-us`,
                    params)
                    .then((response) => {

                        commit('SET_LOADING',false)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('SET_LOADING',false)
                        reject(error)
                    })
            })
        }
    },
    getters: {}
}

export default GuestModule
