const path = 'TransportReportModule';
const apiPath = '/transport/report';

const TransportReportActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  GENERATE_TRANSPORT_MOVEMENT_REPORT: `${path}/generateTransportMovementReport`,
  GENERATE_DETAIL_TRANSPORT_MOVEMENT_REPORT: `${path}/generateDetailTransportMovementReport`,
  GENERATE_DOWNLOAD_TRANSPORT_MOVEMENT_REPORT: `${path}/generateDownloadTransportMovementReport`,
  GENERATE_RETURN_TRANSPORT_MOVEMENT_REPORT: `${path}/generateReturnTransportMovementReport`,
  GENERATE_TOTAL_TRANSPORTATION_TRAVEL_COST: `${path}/generateTotalTransportationTravelCost`,
  GENERATE_DETAILED_TRANSPORTATION_TRAVEL_COST: `${path}/generateDetailedTransportationTravelCost`,
}


const TransportReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {TransportReportAPIsConstants, TransportReportActions };
