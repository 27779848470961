import { nextTick } from 'vue';
import axios from 'axios';
import { ModalActions } from '@/store/enums/ModalEnum';
import router from '@/router';

export const mergeCrudStore = (store) => {
   return {
      ...store,
      state: { ...crudStore.state(), ...(store.state || {}) },
      getters: { ...crudStore.getters(), ...(store.getters || {}) },
      mutations: { ...crudStore.mutations(), ...(store.mutations || {}) },
      actions: { ...crudStore.actions(), ...(store.actions || {}) },
   };
};

export const crudStore = {
   state() {
      return {
         DataTable: [],
         helper: {},
         itemEditable: {},
         invoiceData: {},
         dataName: '',
         loading: false,
         crudLoading: false,
         loadingItem: false,
         editableId: null,
         isPagination: false,
         isCloseModal: false,
         checkedRows: [],
         checkedRowsObject: [],
         tableFilters: {},
         roleName: '',
         pagination: {
            page: 1,
            total: 0,
            rowsPerPage: 10,
         },
         sort_column: null,
         sort_direction: null,
         getDataResponse: null,
         extraData: null,
         renderPage: true,
      };
   },
   getters() {
      return {
         findItemById: (state) => (id) => {
            return state.DataTable.find((x) => x.id === id);
         },
      };
   },
   mutations() {
      return {
         SET_DATA_TABLE(state, payload) {
            if (payload.DataTable.length > 0 && !payload.DataTable[0]?.translations) {
               state.DataTable = payload.DataTable;
               return;
            }
            state.DataTable = payload.DataTable.map((row) => {
               let arr = [];
               row.translations.map((lang) => {
                  arr[lang.locale] = lang;
                  row[lang.locale] = lang;
               });
               row['translations'] = arr;
               return row;
            });
         },
         SET_HELPER(state, payload) {
            state.helper = payload;
         },
         SET_DATA_NAME(state, payload) {
            state.dataName = payload;
         },
         SET_ITEM_EDITABLE(state, payload) {
            let arr = [];

            if (payload?.itemEditable?.item?.translations) {
               payload.itemEditable.item.translations.map((lang) => {
                  arr[lang.locale] = lang;
               });
               payload.itemEditable.item['translations'] = arr;
            }

            if (
               payload?.itemEditable?.item?.address &&
               payload.itemEditable.item.address.translations
            ) {
               payload.itemEditable.item.address.translations.map((lang) => {
                  payload.itemEditable.item.address[lang.locale] = lang;
               });
            }

            state.itemEditable = payload.itemEditable;
         },
         SET_INVOICE_DATA(state, payload) {
            state.invoiceData = payload;
         },
         SET_LOADING(state, payload) {
            state.loading = payload;
         },
         SET_CRUD_LOADING(state, payload) {
            state.crudLoading = payload;
         },
         SET_LOADING_ITEM(state, payload) {
            state.loadingItem = payload;
         },
         RESET_ALL(state) {
            state.checkedRowsObject = [];
            state.checkedRows = [];
            state.DataTable = [];
            state.helper = {};
            state.dataName = '';
            state.roleName = '';
            state.loading = false;
            state.itemEditable = {};
            state.tableFilters = {};
            state.isPagination = true;
            state.getDataResponse = null;

            const { page, rowsPerPage } = router.currentRoute.value.query || {};
            state.pagination = {
               page: page ? parseInt(page) : 1,
               total: 0,
               rowsPerPage: rowsPerPage ? parseInt(rowsPerPage) : 10,
            };
            state.sort_column = null;
            state.sort_direction = null;
         },
         RESET_EDITABLE(state) {
            state.loading = false;
            state.itemEditable = {};
            state.editableId = null;
         },
         SET_EDITABLE_ID(state, id) {
            state.editableId = id;
         },
         SET_ROWS_PER_PAGE(state, payload) {
            state.pagination['rowsPerPage'] = payload;
            state.pagination['page'] = 1;
         },
         SET_PAGINATION(state, payload) {
            state.pagination = payload;
         },
         SET_TOTAL_NUM(state, payload) {
            state.pagination['total'] = payload;
         },
         SET_CURRENT_PAGE(state, payload) {
            state.pagination['page'] = payload;
         },
         SET_IS_PAGINATION(state, payload) {
            state.isPagination = payload;
         },
         SET_IS_CLOSE_MODAL(state, payload) {
            state.isCloseModal = payload;
         },
         SET_FILTERS(state, payload) {
            state.tableFilters = payload.filters ?? {};
         },
         SET_ROLE_NAME(state, payload) {
            state.roleName = payload;
         },
         SET_EXTRA_DATA(state, payload) {
            state.extraData = payload;
         },
         SET_RENDER_PAGE(state, val) {
            state.renderPage = val;
         },
      };
   },
   actions() {
      return {
         setRoleName({ commit }, payload) {
            commit('SET_ROLE_NAME', payload);
         },
         setRowsPerPage({ commit }, payload) {
            commit('SET_ROWS_PER_PAGE', payload);
         },
         setTotalNum({ commit }, payload) {
            commit('SET_TOTAL_NUM', payload);
         },
         setCurrentPage({ commit }, payload) {
            commit('SET_CURRENT_PAGE', payload);
         },
         setPagination({ commit }, payload) {
            commit('SET_PAGINATION', payload);
         },
         setIsPagination({ commit }, payload) {
            commit('SET_IS_PAGINATION', payload);
         },
         setDataName({ commit }, name) {
            commit('SET_DATA_NAME', name);
         },
         setIsCloseModal({ commit }, payload) {
            commit('SET_IS_CLOSE_MODAL', payload);
         },
         setEditableId({ commit }, id) {
            commit('SET_EDITABLE_ID', id);
         },
         reset({ commit }) {
            commit('RESET_ALL');
         },
         resetEditable({ commit }) {
            commit('RESET_EDITABLE');
         },
         setSort({ state }, sortObj) {
            state.sort_column = sortObj.sort_column;
            state.sort_direction = sortObj.sort_direction;
         },
         setFilters({ commit, dispatch }, { filters, apiPath }) {
            commit('SET_FILTERS', { filters });
            dispatch('getData', apiPath);
         },
         async refreshPage({ commit }) {
            commit('SET_RENDER_PAGE', false);

            await nextTick();

            commit('SET_RENDER_PAGE', true);
         },
         getData({ commit, state, dispatch }, apiPath) {
            commit('SET_LOADING', true);

            const { page, rowsPerPage } = state.pagination || {};
            const query = state.isPagination ? { pageSize: rowsPerPage, page } : {};
            if (state.isPagination) {
               const currentRoute = router.currentRoute.value;
               const currentParams = { page, rowsPerPage };

               if (
                  currentParams.page == currentRoute.query.page &&
                  currentParams.rowsPerPage == currentRoute.query.rowsPerPage
               )
                  return;

               router.replace({
                  query: { ...(currentRoute.query || {}), ...currentParams },
               });
            }

            if (state?.sort_column) state.tableFilters['sort_column'] = state.sort_column;
            if (state?.sort_direction) state.tableFilters['sort_direction'] = state.sort_direction;

            return axios
               .get(apiPath, { params: { ...(state.tableFilters || {}), ...query } })
               .then((res) => {
                  if (state.isPagination)
                     commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
                  commit('SET_DATA_TABLE', {
                     DataTable: state.dataName
                        ? res.data.result[state.dataName].data
                        : res.data.result,
                  });
                  commit('SET_EXTRA_DATA', res.data.result?.extra_data);
                  commit('SET_HELPER', res.data.result?.helper);
                  commit('SET_LOADING', false);

                  state.getDataResponse = res.data;

                  if (state.isCloseModal) {
                     dispatch(ModalActions.CLOSE_MODEL, '', { root: true });
                     commit('SET_IS_CLOSE_MODAL', false);
                  }

                  return res.data;
               })
               .catch((error) => {
                  console.log(error);
                  commit('SET_LOADING', false);
                  if (state.isCloseModal) {
                     dispatch(ModalActions.CLOSE_MODEL, '', { root: true });
                     commit('SET_IS_CLOSE_MODAL', false);
                  }
               });
         },
         findItemById({ commit, state }, data) {
            if (!state.editableId) return;

            commit('SET_LOADING_ITEM', true);
            return axios
               .get(`${data.apiPath}/${state.editableId}`, { params: data.params })
               .then((res) => {
                  commit('SET_ITEM_EDITABLE', { itemEditable: res.data.result });
                  return res;
               })
               .catch((error) => {
                  console.log(error);
                  return Promise.reject(error);
               })
               .finally(() => commit('SET_LOADING_ITEM', false));
         },

         addItem({ commit }, { apiPath, credentials }) {
            commit('SET_CRUD_LOADING', true);
            return axios
               .post(`${apiPath}`, credentials)
               .finally(() => commit('SET_CRUD_LOADING', false));
         },

         updateItem({ commit, state }, { apiPath, credentials }) {
            commit('SET_CRUD_LOADING', true);
            return axios
               .put(`${apiPath}/${state.editableId}`, credentials)
               .finally(() => commit('SET_CRUD_LOADING', false));
         },

         deleteItem({ commit, dispatch }, { apiPath, credentials }) {
            commit('SET_LOADING', true);
            return axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  return response;
               })
               .finally(() => commit('SET_LOADING', false));
         },
      };
   },
};

export function crudActions(path) {
   return {
      GET_DATA: `${path}/getData`,
      SET_DATA_NAME: `${path}/setDataName`,
      SET_EDITABLE_ID: `${path}/setEditableId`,
      ADD_ITEM: `${path}/addItem`,
      UPDATE_ITEM: `${path}/updateItem`,
      DELETE_ITEM: `${path}/deleteItem`,
      FIND_ITEM_BY_ID: `${path}/findItemById`,
      RESET_EDITABLE: `${path}/resetEditable`,
      RESET: `${path}/reset`,
      SET_ROWS_PER_PAGE: `${path}/setRowsPerPage`,
      SET_TOTAL_NUM: `${path}/setTotalNum`,
      SET_PAGINATION: `${path}/setPagination`,
      SET_CURRENT_PAGE: `${path}/setCurrentPage`,
      SET_IS_PAGINATION: `${path}/setIsPagination`,
      SET_IS_CLOSE_MODAL: `${path}/setIsCloseModal`,
      REFRESH_PAGE: `${path}/refreshPage`,
   };
}
