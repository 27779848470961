const path = 'ServicesModule';
const apiPath = '/core/common/services';

const ServicesActions = {
    GET_NUMBER_AS_TEXT: `${path}/getNumberAsText`,
    RESET_NUMBER: `${path}/resetNumber`,
}

const ServicesEnumAPIsConstants = {
    API_PATH: `${apiPath}`,
    GET_NUMBER_AS_TEXT: `${apiPath}/get-number-as-text`,
}

export {ServicesEnumAPIsConstants, ServicesActions};
