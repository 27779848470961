import ApiService from '@/core/services/ApiService';
import { ModulesActions, ModulesMutations } from '@/store/enums/ModulesEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { User } from '@/store/modules/AuthModule';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import JwtService from '@/core/services/JwtService';
import axios from 'axios';
import { overrideUsersCount } from '@/core/services/SubscriptionInfo';

@Module
export default class TestModule extends VuexModule {
   errors = {};
   allModules: any = [];
   allBundles: any = [];
   allSubscribableModules: any = [];
   allSubscribableBundles: any = [];
   loadingModules = false;
   selectedModules = null;
   selectedBundleId = undefined;
   userCount = null;
   modulesUserCount = {};

   /**
    * Get current user object
    * @returns User
    */
   get getModules(): any {
      return this.allModules;
   }

   get getBundles(): any {
      return this.allBundles;
   }

   get getSubscribableModules(): any {
      return this.allSubscribableModules;
   }

   get getSubscribableBundles(): any {
      return this.allSubscribableBundles;
   }

   get getSelectedModules(): any {
      return this.selectedModules;
   }
   get getSelectedBundleId(): any {
      return this.selectedBundleId;
   }
   get getUserCount(): any {
      return this.userCount;
   }
   get getModulesUserCount(): any {
      return this.modulesUserCount;
   }

   get getLoadingModules(): any {
      return this.loadingModules;
   }

   get getModuleById(): any {
      return (id: any) => {
         return this.allModules.find((x) => x.id === id);
      };
   }

   /**
    * Get errors
    * @returns array
    */
   get getModulesErrors() {
      return this.errors;
   }

   @Mutation
   [ModulesMutations.SET_ERROR](error) {
      console.log('error', error);
      this.errors = error;
   }

   @Mutation
   [ModulesMutations.RESET_DATA]() {
      this.errors = '';
   }

   @Mutation
   [ModulesMutations.SET_MODULES](data) {
      this.allModules = data;
   }

   @Mutation
   [ModulesMutations.SET_BUNDLES](data) {
      this.allBundles = data;
   }

   @Mutation
   [ModulesMutations.SET_SUBSCRIBABLE_MODULES](data) {
      this.allSubscribableModules = data;
   }

   @Mutation
   [ModulesMutations.SET_SUBSCRIBABLE_BUNDLES](data) {
      this.allSubscribableBundles = data;
   }

   @Mutation
   [ModulesMutations.SET_LOADING_MODULES](data) {
      this.loadingModules = data;
   }

   @Mutation
   [ModulesMutations.SET_SELECTED_MODULES](data) {
      this.selectedModules = data.map((el) => {
         return { ...el, user_count: el.user_count };
      });
   }

   @Mutation
   [ModulesMutations.SET_SELECTED_DETAILS]({ modules, bundle_id, user_count, modules_user_count }) {
      this.selectedModules = [...(modules || [])];
      this.selectedBundleId = bundle_id;
      this.userCount = user_count;
      this.modulesUserCount = modules_user_count;
   }

   @Action
   [ModulesActions.RESET]() {
      this.context.commit(ModulesMutations.RESET_DATA);
   }

   @Action
   [ModulesActions.GET_MODULES](options: any = {}) {
      this.context.commit(ModulesMutations.SET_LOADING_MODULES, true);
      return axios
         .get('/subscription/modules', {
            params: {
               filters: options?.filters ?? {},
            },
         })
         .then(({ data }) => {
            this.context.commit(ModulesMutations.SET_LOADING_MODULES, false);
            this.context.commit(ModulesMutations.SET_MODULES, data.result.modules);
            this.context.commit(ModulesMutations.SET_BUNDLES, data.result.bundles);
            if (options.filters)
               this.context.commit(ModulesMutations.SET_SUBSCRIBABLE_MODULES, data.result.modules);
            this.context.commit(ModulesMutations.SET_SUBSCRIBABLE_BUNDLES, data.result.bundles);
         })
         .catch(({ response }) => {
            this.context.commit(ModulesMutations.SET_LOADING_MODULES, false);
            this.context.commit(ModulesMutations.SET_ERROR, response.data.message);
         });
   }

   @Action
   [ModulesActions.SAVE_SELECTED_MODULE]({ modules, bundle_id, user_count, modules_user_count }) {
      this.context.commit(ModulesMutations.SET_SELECTED_DETAILS, {
         modules,
         bundle_id,
         user_count,
         modules_user_count,
      });
   }

   @Action
   [ModulesActions.SUBSCRIBE](credentials) {
      overrideUsersCount(credentials);
      return new Promise((resolve, reject) => {
         axios
            .post(`/subscription/subscribeModule`, credentials)
            .then((res) => {
               resolve(res);
            })
            .catch((error) => {
               reject(error);
               // commit('SET_LOADING', false);
            });
      });
   }

   @Action
   [ModulesActions.SUBSCRIBE_BUNDLE](credentials) {
      overrideUsersCount(credentials);
      return axios.post(`/subscription/subscribeBundle`, credentials);
   }
}
