import axios from "axios";

const ExternalCustomsBrokerDealsModule = {
  namespaced: true,
  state: {
    itemEditable: {},
    dataName: "",
    loading: false,
    loadingItem: false,
  },
  mutations: {
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item["translations"] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    RESET_ALL(state) {
      state.dataName = "";
      state.loading = false;
      state.itemEditable = {};
    },
  },
  actions: {
    findItemById({ commit }, { apiPath, id, customs_broker_token }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/${id}`, { params: { customs_broker_token } })
          .then((res) => {
            commit("SET_LOADING_ITEM", false);
            commit("SET_ITEM_EDITABLE", {
              itemEditable: res.data.result,
            });
            resolve(res);
          })
          .catch((error) => {
            commit("SET_LOADING_ITEM", false);
            reject(error);
          });
      });
    },
    reset({ commit }) {
      commit("RESET_ALL");
    },
  },
};
export default ExternalCustomsBrokerDealsModule;
