import axios from 'axios';

const InventoryNoticeModule = {
   namespaced: true,
   state: {
      itemEditable: {},
      invoiceInitData: {},
      dataName: '',
      loading: false,
      crudLoading: false,
      loadingItem: false,
      editableId: null,
      isPagination: true,
      isCloseModal: false,
      bindedInvoice: {},
   },
   mutations: {
      SET_DATA_Name(state, payload) {
         state.dataName = payload;
      },
      SET_ITEM_EDITABLE(state, payload) {
         let arr = [];

         if (payload.itemEditable.item.translations) {
            payload.itemEditable.item.translations.map((lang) => {
               arr[lang.locale] = lang;
            });
            payload.itemEditable.item['translations'] = arr;
         }

         state.itemEditable = payload.itemEditable;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_LOADING_ITEM(state, payload) {
         state.loadingItem = payload;
      },
      RESET_ALL(state) {
         state.dataName = '';
         state.loading = false;
         state.itemEditable = {};
      },
      RESET_EDITABLE(state) {
         state.loading = false;
         state.itemEditable = {};
         state.editableId = null;
      },
      SET_EDITABLE_ID(state, id) {
         state.editableId = id;
      },
      SET_IS_CLOSE_MODAL(state, payload) {
         state.isCloseModal = payload;
      },
      SET_FILTERS(state, payload) {
         state.tableFilters = payload.filters;
      },
      SET_BINDED_INVOICE(state, payload) {
         state.bindedInvoice = payload;
      },
   },
   actions: {
      setDataName({ commit }, name) {
         commit('SET_DATA_Name', name);
      },
      setIsCloseModal({ commit }, payload) {
         commit('SET_IS_CLOSE_MODAL', payload);
      },
      setEditableId({ commit }, id) {
         commit('SET_EDITABLE_ID', id);
      },
      reset({ commit }) {
         commit('RESET_ALL');
      },
      resetEditable({ commit }) {
         commit('RESET_EDITABLE');
      },

      findItemById({ commit, state }, { apiPath, id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/${id}`)
               .then((res) => {
                  commit('SET_LOADING_ITEM', false);
                  commit('SET_ITEM_EDITABLE', {
                     itemEditable: res.data.result,
                  });
                  resolve(res);
               })
               .catch((error) => {
                  commit('SET_LOADING_ITEM', false);
                  reject(error);
               });
         });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getInvoiceInitData({ commit, dispatch, state }, { apiPath, credentials, params }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/get-invoice-init-data`, { params })
               .then((response) => {
                  commit('SET_LOADING', false);

                  state.invoiceInitData = response.data.result;
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getInvoiceToBind({ commit, dispatch, state }, { apiPath, id, ...params }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`warehouse/warehouse-notice-helper/get-invoice-to-bind/${id}`, {
                  params: { ...params },
               })
               .then((response) => {
                  commit('SET_LOADING', false);
                  commit('SET_BINDED_INVOICE', response?.data?.result?.invoice);
                  // state.invoiceInitData = response.data.result;
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getProductStockInfo({ commit, dispatch, state }, values) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`warehouse/warehouse-notice-helper/get-product-stock-info`, values)
               .then((response) => {
                  commit('SET_LOADING', false);
                  // state.invoiceInitData = response.data.result;
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getProductStockInfoGrouping({ commit }, values) {
         commit('SET_LOADING', true);

         return axios
            .post(`warehouse/warehouse-notice-helper/get-product-stock-info-grouping`, values)
            .finally(() => commit('SET_LOADING', false));
      },
   },
   getters: {
      findItemById: (state) => (id) => {
         return state.DataTable.find((x) => x.id === id);
      },
   },
};
export default InventoryNoticeModule;
