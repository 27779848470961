import { RouteRecordRaw } from 'vue-router';
import settingRouter from './settingRouter';
import inventoryRouter from './inventoryRouter';
import invoiceRouter from './invoiceRouter';
import reportRouter from './reportRouter';

const warehouseRouters: Array<RouteRecordRaw> = [
  ...inventoryRouter,
  ...settingRouter,
  ...invoiceRouter,
  ...reportRouter,
];

export default warehouseRouters;
