export const MODULES_NAMES = {
   ACCOUNTING_MANAGEMENT: 'Accounting management',
   INVOICING_MANAGEMENT: 'Invoicing management',
   WAREHOUSE_MANAGEMENT: 'Warehouse management',
   HR_MANAGEMENT: 'HR management',
   SALARIES_MANAGEMENT: 'Salaries management',
   ATTENDANCE_MANAGEMENT: 'Attendance Management',
   CUSTOMS_MANAGEMENT: 'Customs Management',
   TRANSPORT_MANAGEMENT: 'Transport Management',
   RESTAURANT_MANAGEMENT: 'Restaurant Management',
   ELECTRONIC_APPROVAL_MANAGEMENT: 'Electronic Approval Management',
   ASSETS_MANAGEMENT: 'Assets Management',
};

export const MODULES_FIRST_NAMES = {
   ACCOUNTING_MANAGEMENT: 'accounting',
   INVOICING_MANAGEMENT: 'invoicing',
   WAREHOUSE_MANAGEMENT: 'warehouse',
   HR_MANAGEMENT: 'hr',
   ATTENDANCE_MANAGEMENT: 'attendance',
   CUSTOMS_MANAGEMENT: 'customs',
   TRANSPORT_MANAGEMENT: 'transport',
   RESTAURANT_MANAGEMENT: 'restaurant',
   ELECTRONIC_APPROVAL_MANAGEMENT: 'electronic',
   ASSETS_MANAGEMENT: 'assets',
   POS_MANAGEMENT: 'pos',
};

export const MODULES_IDS = {
   ACCOUNTING_MANAGEMENT: 3,
   INVOICING_MANAGEMENT: 1,
   WAREHOUSE_MANAGEMENT: 2,
   HR_MANAGEMENT: 4,
   SETTINGS: 6,
   SALARIES_MANAGEMENT: 20,
   ATTENDANCE_MANAGEMENT: 25,
   CUSTOMS_MANAGEMENT: 30,
   TRANSPORT_MANAGEMENT: 35,
   RESTAURANT_MANAGEMENT: 40,
   ELECTRONIC_APPROVAL_MANAGEMENT: 45,
   ASSETS_MANAGEMENT: 50,
   POS_MANAGEMENT: 51,
};

const idByNumber = {
   1: MODULES_IDS.ACCOUNTING_MANAGEMENT,
   2: MODULES_IDS.INVOICING_MANAGEMENT,
   3: MODULES_IDS.WAREHOUSE_MANAGEMENT,
   4: MODULES_IDS.HR_MANAGEMENT,
   // 5: MODULES_IDS.SALARIES_MANAGEMENT,
   5: MODULES_IDS.ATTENDANCE_MANAGEMENT,
   6: MODULES_IDS.CUSTOMS_MANAGEMENT,
   7: MODULES_IDS.TRANSPORT_MANAGEMENT,
   8: MODULES_IDS.ELECTRONIC_APPROVAL_MANAGEMENT,
   9: MODULES_IDS.ASSETS_MANAGEMENT,
   10: MODULES_IDS.POS_MANAGEMENT,
};

export function getModuleIdByNumber(num: number): number | null {
   return idByNumber[num] || null;
}
export function getModuleNumberById(id: number): number | null {
   let num = null;

   Object.entries(idByNumber).forEach(([moduleNum, moduleId]) => {
      if (id == moduleId) num = moduleNum;
   });

   return num;
}
