const apiPath = '/hr/muqeem/exit-reenter';
const path = 'ExitReenterModule';

const ExitReenterAPIsConstants = {
   API_PATH: `${apiPath}`,
   EXTEND: `${apiPath}/extend`,
   CANCEL: `${apiPath}/cancel`,
   PRINT: `${apiPath}/reprint`,
};

const ExitReenterActions = {
   ADD_ITEM: `${path}/addItem`,
   FIND_ITEM_BY_ID: `${path}/findItemById`,
   EXTEND: `${path}/extend`,
   CANCEL: `${path}/cancel`,
   PRINT: `${path}/print`,
};
export { ExitReenterAPIsConstants, ExitReenterActions };
