const path = 'AdminCustomerModule';
const apiPath = '/admin-panal/users';

const CustomersActions = {
  
  GET_PROFILE : `${path}/getProfile`,
}

const CustomersAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {CustomersAPIsConstants, CustomersActions };
