const path = 'EmployeesSalariesDistributedReportModule';
const apiPath = 'hr/salaries/report';

const EmployeesSalariesDistributedReportModelActions = {
  // action types
  RESET_EDITABLE: `${path}/resetEditable`,
  GET_REPORT: `${path}/getReport`,
  GET_REPORT_DURATION: `${path}/getReportDuration`,
}

const EmployeesSalariesDistributedReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {EmployeesSalariesDistributedReportAPIsConstants, EmployeesSalariesDistributedReportModelActions };
