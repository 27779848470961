export default class ObjectService {
   static merge(obj1, obj2) {
      const result = JSON.parse(JSON.stringify(obj1 || {}));

      Object.entries(obj2 || {}).forEach(([key, val]) => {
         if (typeof val === 'object' && typeof result[key] === 'object') {
            result[key] = { ...result[key], ...val };
         } else {
            result[key] = val;
         }
      });

      return result;
   }

   static clean(obj) {
      const result = {};

      Object.entries(obj).forEach(([key, val]) => {
         if (obj[key]) result[key] = val;
      });

      return result;
   }

   static deepValue(obj, keyStr) {
      if (!obj) return obj;

      const keys = (keyStr || '').split('.');
      let value = obj;

      for (const key of keys) {
         value = value[key];

         if (!value) break;
      }

      return value;
   }
}
