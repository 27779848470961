import moment from "moment";

export function timeFormatter(time)  {
    return moment(time).format('HH:mm');
}

export function dateFormatter (date) {
    return moment(date).format('DD-MM-YYYY');
}

export function dateTimeFormatter(date){
    return moment(date).format('DD-MM-YYYY HH:mm');
}

export const timeFormat = {
    install: (app, time) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$timeFormatter = (time) => {
            return timeFormatter(time);
        }
    }
}

export const dateFormat = {
    install: (app, date) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$dateFormatter = (date) => {
            return dateFormatter(date);
        }
    }
}

export const dateTimeFormat= {
    install: (app, date) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$dateTimeFormatter = (date) => {
            return dateTimeFormatter(date);
        }
    }
}



