const path = "AccountingDashboardModule";
const apiPath = "accounting/dashboard";
const MONTHS = (t) => [
  t("Jan"),
  t("Feb"),
  t("Mar"),
  t("Apr"),
  t("May"),
  t("Jun"),
  t("Jul"),
  t("Aug"),
  t("Sep"),
  t("Oct"),
  t("Nov"),
  t("Dec"),
];

const DashboardModelActions = {
  //action type
  GET_DATA: `${path}/getStatisticData`,
};

const DashboardModelAPIsConstants = {
  API_PATH: `${apiPath}`,
};

export { DashboardModelActions, DashboardModelAPIsConstants, MONTHS };
