type SubscriptionDetails = {
   bundle_id: number;
   plan: 'MONTHLY' | 'YEARLY';
   free: boolean;
   modules: number[];
   isNewCompany: boolean;
};

export class SubscriptionInfo {
   static get(): SubscriptionDetails | null {
      const infoStr = localStorage.getItem('subscription_info');

      if (!infoStr) return null;

      return JSON.parse(infoStr);
   }

   static set(value: string) {
      localStorage.setItem('subscription_info', value);
   }

   static clear() {
      localStorage.removeItem('subscription_info');
   }
}

type UserCountParams = {
   modules: { id: number; user_count: number }[];
   modules_user_count: Record<number, number>;
};

export function overrideUsersCount(params: UserCountParams) {
   if (!params.modules?.length) return;
   if (!params.modules_user_count) return;

   Object.entries(params.modules_user_count).forEach(([id, user_count]) => {
      params.modules = params.modules.map((item) => {
         if (item.id == parseInt(id)) return { ...item, user_count };

         return item;
      });
   });
}
