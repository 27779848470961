import ApiService from '@/core/services/ApiService';
import { CookieService } from './CookieService';
import UrlService from './UrlService';

const ID_TOKEN_KEY = 'id_token' as string;
const USER_KEY = 'user' as string;
const CURRENT_COMPANY_KEY = 'current_company' as string;
const POS_ID = 'pos_id' as string;
export const USER_TYPE = {
   ADMIN: 'admin',
   USER: 'user',
};

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
   return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
   // console.log('saveToken',token);
   window.localStorage.setItem(ID_TOKEN_KEY, token);
};

///////////// Casher ///////////////
/**
 * @description get token form localStorage
 */
export const getPosId = (): string | null => {
   return window.localStorage.getItem(POS_ID);
};

/**
 * @description save token into localStorage
 * @param id: string
 */
export const savePosId = (id: string): void => {
   window.localStorage.setItem(POS_ID, id);
};
///////////// End Casher ///////////////

/**
 * @description remove token form localStorage
 */
export const destroyToken = (params?: { redirectUrl?: string }): void => {
   const { redirectUrl = 'sign-in' } = params || {};

   window.localStorage.removeItem(POS_ID);
   window.localStorage.removeItem(ID_TOKEN_KEY);
   CookieService.eraseCookie('shredDomains');
   CookieService.eraseCookie('X-Tenant-Id');
   CookieService.eraseCookie('userType');
   // const http = process.env.NODE_ENV == 'development' ? 'http://' : 'https://';

   // const domain = ApiService.getDomainNameFromUrl().split('.');

   // const isStage = domain[0] == 'staging' || domain[1] == 'staging';
   // const isDev = domain[0] == 'dev' || domain[1] == 'dev';

   // if (domain.length == 2) return;
   // if ((isStage || isDev) && domain.length == 3) return;
   if (!isAdminDomain()) {
      UrlService.redirectWithSubdomain(`/#/${redirectUrl}`)
   }

};

/**
 * @description set shared cookie between domains
 */
export const setSharedDomainsCookie = (data): void => {
   CookieService.setCookie('shredDomains', data);
};

export const setUserType = (type): void => {
   CookieService.setCookie('userType', type);
};

export const saveUser = (user): void => {
   window.localStorage.setItem(USER_KEY, JSON.stringify(user));
};

export const getUser = () => {
   const user = window.localStorage.getItem(USER_KEY);
   return user != 'undefined' && user != null ? JSON.parse(user) : {};
};
export const saveCurrentCompany = (user): void => {
   window.localStorage.setItem(CURRENT_COMPANY_KEY, JSON.stringify(user));
};

export const getCurrentCompany = () => {
   const item = window.localStorage.getItem(CURRENT_COMPANY_KEY);
   return item != 'undefined' && item != null ? JSON.parse(item) : {};
};

export const removeUser = (): void => {
   window.localStorage.removeItem(USER_KEY);
};

export const isAdminDomain = () => {
   const route = window.location.hash.split('#');
   // console.log("isAdminDomain",(route &&route.length>0 &&route[1] && route[1].split('/')[1] ==='admin-panel'));
   // console.log("route[1].split('/')[1]",route[1].split('/')[1]);
   return route && route.length > 0 && route[1] && route[1].split('/')[1] === 'admin-panel';
};

export const saveRenewSession = (time): void => {
   //Time is the last time that session is renewed
   window.localStorage.setItem('renew_session', time);
};

export const getRenewSession = (): string | null => {
   return window.localStorage.getItem('renew_session');
};

export const removeRenewSession = (): void => {
   window.localStorage.removeItem('renew_session');
};

export default {
   getToken,
   saveToken,
   destroyToken,
   setSharedDomainsCookie,
   saveUser,
   getUser,
   removeUser,
   isAdminDomain,
   setUserType,
   saveRenewSession,
   getRenewSession,
   removeRenewSession,
   getPosId,
   savePosId,
   getCurrentCompany,
   saveCurrentCompany
};
