import axios from 'axios';

const ZatcaIntegrationModule = {
  namespaced: true,
  state: {
    data: [],
    loadingItem: [],
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
  },
  actions: {
    getData({ commit }) {
      commit('SET_LOADING_ITEM', true);

      return new Promise((resolve, reject) => {
        axios
          .get(`/company/integrations/zatca/get-zatca-settings`)
          .then((res) => {
            commit('SET_DATA', res.data.result);

            commit('SET_LOADING_ITEM', false);

            resolve(res);
          })
          .catch((error) => {
            // commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    productionCsids({ commit },{values}) {
      commit('SET_LOADING_ITEM', true);

      return new Promise((resolve, reject) => {
        axios
          .post(`/company/integrations/zatca/production-csids`,values)
          .then((res) => {
            commit('SET_DATA', res.data.result);

            commit('SET_LOADING_ITEM', false);

            resolve(res);
          })
          .catch((error) => {
            // commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {},
};
export default ZatcaIntegrationModule;
