const path = 'EmployeeInstallmentsReportModule';
const apiPath = 'hr/salaries/report';

const EmployeeInstallmentsReportModelActions = {
  // action types
  RESET_EDITABLE : `${path}/resetEditable`,
  EMPLOYEE_INSTALLMENTS: `${path}/employeeInstallments`,
  // GENERATE_DETAILED_EmployeeInstallments_REPORT : `${path}/generateDetailedEmployeeInstallmentsReport`,
}

const EmployeeInstallmentsReportAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export {EmployeeInstallmentsReportAPIsConstants, EmployeeInstallmentsReportModelActions };
