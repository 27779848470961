const apiPath = "/hr/attendance/hr-work-time-schedules";
const path = "WorkTimesSchedulesModule";

const WorkTimesSchedulesAPIsConstants = {
  API_PATH: `${apiPath}`,
};

const WorkTimesSchedulesActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  GET_INIT_DATA : `${path}/getInitData`,
};

export { WorkTimesSchedulesAPIsConstants, WorkTimesSchedulesActions };
