const apiPath = '/hr/salaries/hr-link-with-accounting/migrate-to-accounting';
const path = 'PostParolesModule';

const PostParolesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const PostParolesModelActions = {
  ADD_ITEM: `${path}/addItem`,
}
export { PostParolesAPIsConstants, PostParolesModelActions };
