import axios from 'axios';
import { HREmployeeAttendanceAPIsConstants } from '@/views/modules/attendance/store/enums/daily-movements/HREmployeeAttendanceEnums';
const HREmployeeAttendanceModule = {
   namespaced: true,
   state: {
      attendance: {},
      loading: false,
   },
   mutations: {
      SET_ATTENDANCE(state, payload) {
         state.attendance = payload;
      },
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
   },
   actions: {
      getLastAttendance({ commit }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${HREmployeeAttendanceAPIsConstants.API_PATH}/get-last-attendance`)
               .then((response) => {
                  commit('SET_LOADING', false);
                  console.log('response', response.data.result);
                  commit('SET_ATTENDANCE', response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      checkIfInSite({ commit }, { lat, long }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${HREmployeeAttendanceAPIsConstants.API_PATH}/check-if-in-site`, {
                  lat: lat,
                  long: long,
                  platform: 'WEB',
               })
               .then((response) => {
                  commit('SET_LOADING', false);
                  // commit('SET_ATTENDANCE',response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      clockIn({ commit }, { lat, long }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${HREmployeeAttendanceAPIsConstants.API_PATH}/attendance`, {
                  lat: lat,
                  long: long,
                  platform: 'WEB',
               })
               .then((response) => {
                  commit('SET_LOADING', false);
                  // commit('SET_ATTENDANCE',response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      clockOut({ commit }, { lat, long }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${HREmployeeAttendanceAPIsConstants.API_PATH}/departure`, {
                  lat: lat,
                  long: long,
                  platform: 'WEB',
               })
               .then((response) => {
                  commit('SET_LOADING', false);
                  // commit('SET_ATTENDANCE',response.data.result);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
};
export default HREmployeeAttendanceModule;
