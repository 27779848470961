import axios from "axios";

export default {
  namespaced: true,
  state: {
    itemEditable: {},
    loadingItem: false,
  },
  mutations: {
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    SET_ITEM_EDITABLE(state, { itemEditable }) {
      state.itemEditable = itemEditable;
    },
  },
  actions: {
    findItemById({ commit, state }, { apiPath, id, params }) {
      commit("SET_LOADING_ITEM", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}/${id}`, { params })
          .then((res) => {
            commit("SET_LOADING_ITEM", false);
            commit("SET_ITEM_EDITABLE", {
              itemEditable: res.data.result,
            });
            resolve(res);
          })
          .catch((error) => {
            commit("SET_LOADING_ITEM", false);
            reject(error);
          });
      });
    },
  },
};
