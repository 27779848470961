import axios from 'axios';

const apiPath = 'invoicing/commissions';
const path = 'CommissionsModule';

export const CommissionsAPIsConstant = {
   GET_SETTINGS: `${apiPath}/get-setting`,
   UPDATE_SETTINGS: `${apiPath}/save-setting`,
};

export const CommissionsActions = {
   GET_SETTINGS: `${path}/getSettings`,
   UPDATE_SETTINGS: `${path}/updateSettings`,
};

export const CommissionsModule = {
   namespaced: true,
   state: {
      loading: false,
      settings: null,
      helper: null,
   },
   mutations: {
      SET_LOADING(state, val) {
         state.loading = val;
      },
      SET_SETTINGS(state, val) {
         state.settings = val;
      },
      SET_HELPER(state, val) {
         state.helper = val;
      },
   },
   actions: {
      getSettings({ commit }) {
         commit('SET_LOADING', true);

         return axios
            .get(CommissionsAPIsConstant.GET_SETTINGS)
            .then(({ data }) => {
               const settings = data.result.commission_policy;
               const commission_segments = settings.commission_segments;
               commit('SET_SETTINGS', {
                  commission_type: commission_segments?.[0]?.commission_type,
                  ...settings.settings,
                  ...settings,
               });
               commit('SET_HELPER', data.result.helper);
            })
            .finally(() => commit('SET_LOADING', false));
      },
      updateSettings({ commit }, { values }) {
         commit('SET_LOADING', true);

         return axios
            .post(CommissionsAPIsConstant.UPDATE_SETTINGS, values)
            .finally(() => commit('SET_LOADING', false));
      },
   },
};
