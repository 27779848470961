const path = 'MuqeemEmployeesInfo';
const apiPath = '/hr/report/muqeem/employee-muqeem-report';

const MuqeemEmployeesInfoActions = {
   RESET_EDITABLE: `${path}/resetEditable`,
   GENERATE_REPORT: `${path}/generateReport`,
};

const MuqeemEmployeesInfoAPIsConstants = {
   API_PATH: `${apiPath}`,
};

export { MuqeemEmployeesInfoAPIsConstants, MuqeemEmployeesInfoActions };
