import axios from 'axios';

interface DownloadOptions {
   method?: 'get' | 'post';
   params?: any;
   fileName?: string;
}

class DownloadService {
   public static ddd(url, values) {
      return new Promise((resolve, reject) => {
         axios
            .get(url, {
               params: values,
               responseType: 'blob',
            })
            .then((response) => {
               const blob = new Blob([response.data]);
               const link = document.createElement('a');
               link.href = URL.createObjectURL(blob);

               if (values.pdf) link.download = 'file.pdf';

               if (values.excel) link.download = 'file.xlsx';

               link.click();
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   }
   public static downloadPDF(url, options: DownloadOptions = {}) {
      const { method = 'post', params = {} } = options;

      return axios[method](
         url,
         { responseType: 'blob', params },
         { responseType: 'blob', params },
      ).then((response) => {
         const blob = new Blob([response.data]);
         const link = document.createElement('a');
         link.href = URL.createObjectURL(blob);
         link.download = 'file.pdf';
         link.click();
         return response;
      });
   }

   public static downloadXML(url, options: DownloadOptions = {}) {
      const { method = 'get', params = {} } = options;

      return axios[method](
         url,
         { responseType: 'blob', params },
         { responseType: 'blob', params },
      ).then((response) => {
         const blob = new Blob([response.data]);
         const link = document.createElement('a');
         link.href = URL.createObjectURL(blob);
         link.download = 'file.xml';
         link.click();
         return response;
      });
   }

   public static downloadExcel(url, options: DownloadOptions = {}) {
      const { method = 'get', params = {}, fileName = 'file' } = options;

      return axios[method](
         url,
         { responseType: 'blob', params },
         { responseType: 'blob', params },
      ).then((response) => {
         const blob = new Blob([response.data]);
         const link = document.createElement('a');
         link.href = URL.createObjectURL(blob);
         link.download = `${fileName}.xlsx`;
         link.click();
         return response;
      });
   }

   public static downloadFile(path: string, name: string) {
      const link = document.createElement('a');
      link.href = path;
      link.download = name;
      link.click();
   }
}

export default DownloadService;
