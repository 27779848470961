import { RouteRecordRaw } from 'vue-router';

const dailyMovementsRouter: Array<RouteRecordRaw> = [
   {
      path: 'deprecations',
      children: [
         {
            path: '',
            name: 'assets-deprecations',
            meta: {
               permission: 'asset_deprecations',
               title: 'Deprecation',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/deprecation/List.vue'),
         },
         {
            path: 'add',
            name: 'assets-add-deprecations',
            meta: {
               permission: 'asset_deprecations',
               title: 'Deprecation',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/deprecation/Add.vue'),
         },
         {
            path: ':id',
            name: 'assets-edit-deprecations',
            meta: {
               permission: 'asset_deprecations',
               title: 'Deprecation',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/deprecation/Add.vue'),
         },
         {
            path: ':id/:view',
            name: 'assets-view-deprecations',
            meta: {
               permission: 'asset_deprecations',
               title: 'Deprecation',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/deprecation/Add.vue'),
         },
      ],
   },
   {
      path: 'transferring-asset',
      children: [
         {
            path: '',
            name: 'transferring-asset',
            meta: {
               permission: 'asset_transfers',
               title: 'Transferring Asset Voucher',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/transferring-asset/List.vue'),
         },
         {
            path: 'add',
            name: 'assets-add-transferring-asset',
            meta: {
               permission: 'asset_transfers',
               title: 'Transferring Asset Voucher',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/transferring-asset/Add.vue'),
         },
         {
            path: ':id',
            name: 'assets-edit-transferring-asset',
            meta: {
               permission: 'asset_transfers',
               title: 'Transferring Asset Voucher',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/transferring-asset/Add.vue'),
         },
         {
            path: ':id/:view',
            name: 'assets-view-transferring-asset',
            meta: {
               permission: 'asset_transfers',
               title: 'Transferring Asset Voucher',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/transferring-asset/Add.vue'),
         },
      ],
   },

   {
      path: 'dispose-asset',
      children: [
         {
            path: '',
            name: 'dispose-asset',
            meta: {
               permission: 'asset_disposes',
               title: 'Dispose Asset Document',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/dispose-asset/List.vue'),
         },
         {
            path: 'add',
            name: 'assets-add-dispose-asset',
            meta: {
               permission: 'asset_disposes',
               title: 'Dispose Asset Document',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/dispose-asset/Add.vue'),
         },
         {
            path: ':id',
            name: 'assets-edit-dispose-asset',
            meta: {
               permission: 'asset_disposes',
               title: 'Dispose Asset Document',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/dispose-asset/Add.vue'),
         },
         {
            path: ':id/:view',
            name: 'assets-view-dispose-asset',
            meta: {
               permission: 'asset_disposes',
               title: 'Dispose Asset Document',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/dispose-asset/Add.vue'),
         },
      ],
   },
   {
      path: 'maintenance',
      children: [
         {
            path: '',
            name: 'assets-maintenance',
            meta: {
               permission: 'asset_maintenances',
               title: 'Maintenance',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/maintenance/List.vue'),
         },
         {
            path: 'add',
            name: 'assets-add-maintenance',
            meta: {
               permission: 'asset_maintenances',
               title: 'Maintenance',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/maintenance/Add.vue'),
         },
         {
            path: ':id',
            name: 'assets-edit-maintenance',
            meta: {
               permission: 'asset_maintenances',
               title: 'Maintenance',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/maintenance/Add.vue'),
         },
         {
            path: ':id/:type',
            name: 'assets-view-maintenance',
            meta: {
               permission: 'asset_maintenances',
               title: 'Maintenance',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/maintenance/Add.vue'),
         },
      ],
   },
   {
      path: 'additions',
      children: [
         {
            path: '',
            name: 'assets-additions',
            meta: {
               permission: 'asset_addition_movements',
               title: 'Assets Additions Voucher',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/additions/List.vue'),
         },
         {
            path: 'add',
            name: 'assets-add-addition',
            meta: {
               permission: 'asset_addition_movements',
               title: 'Assets Additions Voucher',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/additions/Add.vue'),
         },
         {
            path: ':id',
            name: 'assets-edit-addition',
            meta: {
               permission: 'asset_addition_movements',
               title: 'Assets Additions Voucher',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/additions/Add.vue'),
         },
         {
            path: ':id/:type',
            name: 'assets-view-addition',
            meta: {
               permission: 'asset_addition_movements',
               title: 'Assets Additions Voucher',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/additions/Add.vue'),
         },
      ],
   },
   {
      path: 'acquire-assets',
      children: [
         {
            path: '',
            name: 'acquire-assets',
            meta: {
               permission: 'asset_acquire_movements',
               title: 'Acquire Assets',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/acquire-assets/List.vue'),
         },
         {
            path: 'add',
            name: 'add-acquire-asset',
            meta: {
               permission: 'asset_acquire_movements',
               title: 'Acquire Assets',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/acquire-assets/Add.vue'),
         },
         {
            path: ':id',
            name: 'update-acquire-asset',
            meta: {
               permission: 'asset_acquire_movements',
               title: 'Acquire Assets',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/acquire-assets/Add.vue'),
         },
         {
            path: ':id/:type',
            name: 'view-acquire-asset',
            meta: {
               permission: 'asset_acquire_movements',
               title: 'Acquire Assets',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/acquire-assets/Add.vue'),
         },
      ],
   },
   {
      path: 'opening-assets',
      children: [
         {
            path: '',
            name: 'opening-assets',
            meta: {
               permission: 'asset_openings',
               title: 'Opening Assets',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/opening-assets/List.vue'),
         },
         {
            path: 'add',
            name: 'add-opening-asset',
            meta: {
               permission: 'asset_openings',
               title: 'Opening Assets',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/opening-assets/Add.vue'),
         },
         {
            path: ':id',
            name: 'update-opening-asset',
            meta: {
               permission: 'asset_openings',
               title: 'Opening Assets',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/opening-assets/Add.vue'),
         },
         {
            path: ':id/:type',
            name: 'view-opening-asset',
            meta: {
               permission: 'asset_openings',
               title: 'Opening Assets',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/opening-assets/Add.vue'),
         },
      ],
   },
   {
      path: 'assets-reevaluations',
      children: [
         {
            path: '',
            name: 'assets-reevaluations',
            meta: {
               permission: 'asset_re_evaluations',
               title: 'Asset Reevaluation Document',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/reevaluation/List.vue'),
         },
         {
            path: 'add',
            name: 'add-asset-reevaluation',
            meta: {
               permission: 'asset_re_evaluations',
               title: 'Asset Reevaluation Document',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/reevaluation/Add.vue'),
         },
         {
            path: ':id',
            name: 'update-asset-reevaluation',
            meta: {
               permission: 'asset_re_evaluations',
               title: 'Asset Reevaluation Document',
               type: 'write',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/reevaluation/Add.vue'),
         },
         {
            path: ':id/:type',
            name: 'view-asset-reevaluation',
            meta: {
               permission: 'asset_re_evaluations',
               title: 'Asset Reevaluation Document',
            },
            component: () =>
               import('@/views/modules/assets/views/daily-movements/reevaluation/Add.vue'),
         },
      ],
   },

   {
      path: 'journal-posting',
      name: 'assets-journal-posting',
      meta: {
         title: 'Post to Accounting',
         permission: 'asset_postings',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/assets/views/daily-movements/journal-posting/base.vue'),
   },
   {
      path: 'transferring-log',
      name: 'assets-transferring-log',
      meta: {
         title: 'transferringLog',
         permission: 'asset_postings',
      },
      component: () =>
         import('@/views/modules/assets/views/daily-movements/journal-posting/List.vue'),
   },
];

export default dailyMovementsRouter;
