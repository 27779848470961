import { RouteRecordRaw } from "vue-router";
import settingRouter from "./settingRouter";
import dailyMovementRouter from "./dailyMovementsRouter";
import receiptRouter from "./receiptRouter";
import reportsRouter from "./reportsRouter";

const accountingRouters: Array<RouteRecordRaw> = [
  ...settingRouter,
  ...receiptRouter,
  ...dailyMovementRouter,
  ...reportsRouter,
];

export default accountingRouters;
