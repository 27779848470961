const path = 'AdminModulesModule';
const apiPath = '/admin-panal/module';

const ModulesActions = {
  // action types
  GET_DATA : `${path}/getData`,
  RESET : `${path}/reset`,
  SUBSCRIBE : `${path}/subscribe`,
  GET_LIST_DATA : `${path}/getListData`,
}

const ModulesAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

export { ModulesActions,ModulesAPIsConstants };
