const apiPath = '/hr/hr-transfer-movements';
const path = 'MovementsModule';

const MovementsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
}

const MovementsModelActions = {
  ADD_ITEM: `${path}/addItem`,
  UPDATE_ITEM: `${path}/updateItem`,
  EDIT_ITEM: `${path}/editItem`,
  FIND_ITEM_BY_ID: `${path}/findItemById`,
  // EXPORT_ITEM_BY_ID: `${path}/exportItemById`,
  // IMPORT_ITEM_BY_ID: `${path}/importItemById`,
}
export { MovementsAPIsConstants, MovementsModelActions };
