import axios from "axios";

import { TransferringLogAPIsConstants } from "@/views/modules/cargo/store/enums/daily-movements/TransferringLogEnums.ts";

const CargoTransferringLogModule = {
  namespaced: true,
  state: {
    DataTable: [],
    dataName: "",
    loading: false,
    isPagination: false,
    isCloseModal: false,
    pagination: {
      page: 1,
      total: 0,
      rowsPerPage: 10,
    },
  },
  mutations: {
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
    },
    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = "";
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
    SET_ROWS_PER_PAGE(state, payload) {
      state.pagination["rowsPerPage"] = payload;
      state.pagination["page"] = 1;
    },
    SET_TOTAL_NUM(state, payload) {
      state.pagination["total"] = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.pagination["page"] = payload;
    },
    SET_IS_PAGINATION(state, payload) {
      state.isPagination = payload;
    },
    SET_IS_CLOSE_MODAL(state, payload) {
      state.isCloseModal = payload;
    },
  },
  actions: {
    setRowsPerPage({ commit }, payload) {
      commit("SET_ROWS_PER_PAGE", payload);
    },
    setTotalNum({ commit }, payload) {
      commit("SET_TOTAL_NUM", payload);
    },
    setCurrentPage({ commit }, payload) {
      commit("SET_CURRENT_PAGE", payload);
    },
    setIsPagination({ commit }, payload) {
      commit("SET_IS_PAGINATION", payload);
    },
    setDataName({ commit }, name) {
      commit("SET_DATA_Name", name);
    },
    setIsCloseModal({ commit }, payload) {
      commit("SET_IS_CLOSE_MODAL", payload);
    },
    reset({ commit }) {
      commit("RESET_ALL");
    },
    getInitData({ commit, state }) {
      // commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .get(`${TransferringLogAPIsConstants.API_PATH}/get-init-data`)
          .then((response) => {
            // commit('SET_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            // commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
    getData({ commit, state, dispatch }, apiPath) {
      commit("SET_LOADING", true);
      let query = state.isPagination
        ? `?pageSize=${state.pagination.rowsPerPage}&page=${state.pagination.page}`
        : "";
      return new Promise((resolve, reject) => {
        axios
          .get(`${apiPath}${query}`)
          .then((res) => {
            if (state.isPagination)
              commit("SET_TOTAL_NUM", res.data.result[state.dataName]?.total);
            commit("SET_DATA_TABLE", {
              DataTable: res.data.result[state.dataName].data,
            });
            commit("SET_LOADING", false);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            commit("SET_LOADING", false);
            reject(error);
          });
      });
    },
  },
};
export default CargoTransferringLogModule;
