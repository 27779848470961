import axios from 'axios';

const PermissionModule = {
  namespaced: true,
  state: {
    DataTable: [],
    itemEditable: {},
    dataName: '',
    loading: false,
    crudLoading: false,
    loadingItem: false,
    editableId: null,
    isPagination:false,
    isCloseModal:false,
    pagination : {
      page: 1,
      total: 0,
      rowsPerPage: 10,
    },
  },
  mutations: {
    SET_DATA_TABLE(state, payload) {
      state.DataTable = payload.DataTable;
      // if (payload.DataTable.length > 0 && !payload.DataTable[0]?.translations) {
      //   state.DataTable = payload.DataTable;
      //   return;
      // }
      // state.DataTable = payload.DataTable.map((row) => {
      //   let arr = [];
      //   row.translations.map((lang) => {
      //     arr[lang.locale] = lang;
      //     row[lang.locale] = lang;
      //   });
      //   row['translations'] = arr;
      //   return row;
      // });
    },
    SET_DATA_Name(state, payload) {
      state.dataName = payload;
    },
    SET_ITEM_EDITABLE(state, payload) {
      let arr = [];

      if (payload.itemEditable.item.translations) {
        payload.itemEditable.item.translations.map((lang) => {
          arr[lang.locale] = lang;
        });
        payload.itemEditable.item['translations'] = arr;
      }

      state.itemEditable = payload.itemEditable;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CRUD_LOADING(state, payload) {
      state.crudLoading = payload;
    },
    SET_LOADING_ITEM(state, payload) {
      state.loadingItem = payload;
    },
    RESET_ALL(state) {
      state.DataTable = [];
      state.dataName = '';
      state.loading = false;
      state.itemEditable = {};
      state.isPagination = false;
      state.pagination = {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      };
    },
    RESET_EDITABLE(state) {
      state.loading = false;
      state.itemEditable = {};
      state.editableId = null;
    },
    SET_EDITABLE_ID(state, id) {
      state.editableId = id;
    },
    SET_ROWS_PER_PAGE(state, payload) {
      state.pagination['rowsPerPage'] = payload;
      state.pagination['page'] = 1;
    },
    SET_TOTAL_NUM(state, payload) {
      state.pagination['total'] = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.pagination['page'] = payload;
    },
    SET_IS_PAGINATION(state, payload) {
      state.isPagination = payload;
    },
    SET_IS_CLOSE_MODAL(state,payload) {
      state.isCloseModal= payload;
    },
  },
  actions: {
    setRowsPerPage({ commit }, payload) {
      commit('SET_ROWS_PER_PAGE', payload);
    },
    setTotalNum({ commit }, payload) {
      commit('SET_TOTAL_NUM', payload);
    },
    setCurrentPage({ commit }, payload) {
      commit('SET_CURRENT_PAGE', payload);
    },
    setIsPagination({ commit }, payload) {
      commit('SET_IS_PAGINATION', payload);
    },
    setDataName({ commit }, name) {
      commit('SET_DATA_Name', name);
    },
    setIsCloseModal({ commit }, payload) {
      commit('SET_IS_CLOSE_MODAL', payload);
    },
    setEditableId({ commit }, id) {
      commit('SET_EDITABLE_ID', id);
    },
    reset({ commit }) {
      commit('RESET_ALL');
    },
    resetEditable({ commit }) {
      commit('RESET_EDITABLE');
    },
    getData({ commit, state, dispatch }, apiPath) {
      commit('SET_LOADING', true);
      let query = state.isPagination
        ? `?pageSize=${state.pagination.rowsPerPage}&page=${state.pagination.page}`
        : '';
      return new Promise((resolve, reject) => {
        axios
            .get(`${apiPath}${query}`)
            .then((res) => {
              if (state.isPagination)
                commit('SET_TOTAL_NUM', res.data.result[state.dataName]?.total);
              commit('SET_DATA_TABLE', {
                DataTable: res.data.result[state.dataName].data,
              });
              commit('SET_LOADING', false);
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              commit('SET_LOADING', false);
              reject(error);
            });
      });
    },

    findItemById({ commit, state }, {apiPath,id}) {
      commit('SET_LOADING_ITEM', true);
      axios
        .get(`${apiPath}/${id}`)
        .then((res) => {
          commit('SET_LOADING_ITEM', false);
          commit('SET_ITEM_EDITABLE', {
            itemEditable: res.data.result,
          });
          // commit('SET_LOADING', false);
        })
        .catch((error) => {
          commit('SET_LOADING_ITEM', false);
          console.log(error);
          // commit('SET_LOADING', false);
        });
    },

    addItem({ commit }, { apiPath, credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiPath}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },

    updateItem({ commit, state }, { apiPath, credentials }) {
      commit('SET_CRUD_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .put(`${apiPath}/${state.editableId}`, credentials)
          .then((response) => {
            commit('SET_CRUD_LOADING', false);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_CRUD_LOADING', false);
            reject(error);
          });
      });
    },

    deleteItem({ commit, dispatch }, { apiPath, credentials }) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        axios
          .delete(`${apiPath}/${credentials}`)
          .then((response) => {
            dispatch('getData', apiPath);
            resolve(response);
          })
          .catch((error) => {
            commit('SET_LOADING', false);
            reject(error);
          });
      });
    },
  },
  getters: {
    findItemById: (state) => (id) => {
      return state.DataTable.find((x) => x.id === id);
    },
  },
};
export default PermissionModule;
