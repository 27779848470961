const path = 'JournalEntryPostingsModule';
const apiPath = '/accounting/journal-entry-postings';

const JournalEntryPostingsModelActions = {
  // action types
  CANCEL_ITEM: `${path}/cancelPosting`,
  MAKE_ITEM: `${path}/createPosting`,

}

const JournalEntryPostingsAPIsConstants = {
  // api's
  API_PATH : `${apiPath}`,
  ACCOUNTING_MAKE_ITEM_API_PATH : `${apiPath}/create-posting`,
  TRANSPORT_MAKE_ITEM_API_PATH : `transport/transport-accounting/migrate-to-accounting`,
  CUSTOMS_MAKE_ITEM_API_PATH : `customs/customs-accounting/migrate-to-accounting`,
  ASSET_MAKE_ITEM_API_PATH : `asset/asset-accounting/migrate-to-accounting`,
}

export { JournalEntryPostingsAPIsConstants, JournalEntryPostingsModelActions };
