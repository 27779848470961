import { RouteRecordRaw } from 'vue-router';

const dailyMovementRouter: Array<RouteRecordRaw> = [
   //begin::employees-earnings-deductions
   {
      path: 'daily-transactions/employees-earnings-deductions',
      name: 'salaries-management-employees-earnings-deductions',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Earnings & Deductions',
      },
      component: () =>
         import(
            '@/views/modules/salaries/views/daily-movements/employees-earnings-deductions/List.vue'
         ),
   },

   {
      path: 'daily-transactions/salaries-items',
      name: 'salaries-management-employees-earnings-deductions-import',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Salary Items',
         type: 'write',
      },
      component: () =>
         import(
            '@/views/modules/salaries/views/daily-movements/employees-earnings-deductions/import.vue'
         ),
   },
   {
      path: 'daily-transactions/employees-earnings-deductions/add/:employee_id?',
      name: 'salaries-management-employees-earnings-deductions-add',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Earnings & Deductions',
         type: 'write',
      },
      component: () =>
         import(
            '@/views/modules/salaries/views/daily-movements/employees-earnings-deductions/Add.vue'
         ),
   },
   {
      path: 'daily-transactions/employees-earnings-deductions/edit/:id',
      name: 'salaries-management-employees-earnings-deductions-edit',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Earnings & Deductions',
         type: 'write',
      },
      component: () =>
         import(
            '@/views/modules/salaries/views/daily-movements/employees-earnings-deductions/Add.vue'
         ),
   },
   {
      path: 'daily-transactions/employees-earnings-deductions/:id/:view',
      name: 'salaries-management-employees-earnings-deductions-view',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Earnings & Deductions',
      },
      component: () =>
         import(
            '@/views/modules/salaries/views/daily-movements/employees-earnings-deductions/Add.vue'
         ),
   },
   {
      path: 'daily-transactions/installments',
      name: 'salaries-management-installments',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Installments',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/installments/List.vue'),
   },

   {
      path: 'daily-transactions/installments/add/:employee_id?',
      name: 'salaries-management-installments-add',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Installments',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/installments/Add.vue'),
   },
   {
      path: 'daily-transactions/installments/edit/:id',
      name: 'salaries-management-installments-edit',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Installments',
         type: 'write',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/installments/Add.vue'),
   },
   {
      path: 'daily-transactions/installments/:id/:view',
      name: 'salaries-management-installments-view',
      meta: {
         permission: 'hr_entitlement_deduction_movements',
         title: 'Installments',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/installments/Add.vue'),
   },
   {
      path: 'daily-transactions/salaries-transfer-salaries',
      name: 'salaries-transfer-salaries',
      meta: {
         permission: 'hr_migrate_salary_effects',
         title: 'Transfer of salary and allowances from HR',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/transfer-salaries/Add.vue'),
   },
   {
      path: 'daily-transactions/calculate-salaries',
      name: 'calculate-salaries',
      meta: {
         permission: 'hr_calculate_salaries',
         title: 'Calculate Salaries',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/calculate-salaries/Add.vue'),
   },
   {
      path: 'daily-transactions/new-month',
      name: 'new-month',
      meta: {
         permission: 'hr_create_new_month',
         title: 'Create New Month',
      },
      component: () => import('@/views/modules/salaries/views/daily-movements/new-month/Add.vue'),
   },
   {
      path: 'daily-transactions/post-paroles',
      name: 'post-paroles',
      meta: {
         permission: 'hr_migrate_to_accounting',
         title: 'Post Paroles',
      },
      component: () =>
         import('@/views/modules/salaries/views/daily-movements/post-paroles/Add.vue'),
   },
   {
      path: 'daily-transactions/salaries-transfer',
      name: 'salaries-transfer',
      meta: {
         permission: 'hr_migrate_salaries',
         title: 'Transfer salaries',
      },
      component: () => import('@/views/modules/salaries/views/daily-movements/transfer/Add.vue'),
   },
   {
      path: 'daily-transactions/transfer-to-accounting',
      name: 'salaries-transfer-to-accounting',
      meta: {
         permission: 'hr_migrate_to_accounting',
         title: 'Post to Accounting',
      },
      component: () =>
         import('@/views/modules/salaries/views/settings/migrate-to-accounting/Index.vue'),
   },
   {
      path: 'daily-transactions/transfer-log',
      name: 'salaries-transfer-log',
      meta: {
         permission: 'hr_migrate_to_accounting',
         title: 'transferringLog',
      },
      component: () =>
         import('@/views/modules/salaries/views/settings/migrations-log/List.vue'),
   },
];

export default dailyMovementRouter;
