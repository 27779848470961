import axios from 'axios';
import {BankAPIsConstants} from "../enums/BankEnums";

const BankModule = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload.map((row) => {
        let arr = [];
        row.translations.map((lang) => {
          arr[lang.locale] = lang;
          row[lang.locale] = lang;
        });
        row['translations'] = arr;
        return row;
      });
    },
  },
  actions: {
    getData({ commit }) {
      axios
        .get(`${BankAPIsConstants.API_PATH}`)
        .then((res) => {
          commit('SET_DATA', res.data.result.banks);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  getters: {

  },
};
export default BankModule;
