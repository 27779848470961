import axios from 'axios';
import {crudActions, mergeCrudStore} from "@/core/stores/curd";
import {TransferringLogAPIsConstants} from "@/views/modules/shared/store/enums/TransferringLogEnums";

const path = 'DeprecationAssets';
const apiPath = '/asset/asset-deprecation-movements';

export const DeprecationActions = {
   ...crudActions(path),
   GET_INIT_DATA: `${path}/getInitData`,
   GET_ASSETS_TO_DEPRECATION: `${path}/getAssetsToDeprecation`,
};

export const DeprecationAPIsConstants = {
   API_PATH: apiPath,
};

const DeprecationAssets = mergeCrudStore({
   namespaced: true,
   actions: {
      findItemById({ commit, state }, { apiPath, id }) {
         commit('SET_LOADING_ITEM', true);
         return new Promise((resolve, reject) => {
         axios
            .get(`${apiPath}/${id}`)
            .then((res) => {
               commit('SET_LOADING_ITEM', false);
               commit('SET_ITEM_EDITABLE', {
                  itemEditable: JSON.parse(JSON.stringify(res.data.result)),
               });
               resolve(res);
            })
            .catch((error) => {
               commit('SET_LOADING_ITEM', false);
               reject(error);
            });
         });
      },

      addItem({ commit }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      updateItem({ commit, state }, { apiPath, credentials }) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .put(`${apiPath}/${credentials.id}`, credentials)
               .then((response) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },

      deleteItem({ commit, dispatch }, { apiPath, credentials }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .delete(`${apiPath}/${credentials}`)
               .then((response) => {
                  dispatch('getData', apiPath);
                  resolve(response);
               })
               .catch((error) => {
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getInitData({ commit, state }) {
         // commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`${apiPath}/init-data`)
               .then((response) => {
                  // commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  // commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },

      getAssetsToDeprecation({ commit, state }, credentials) {
         // commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`${apiPath}/get-assets-to-deprecation`, credentials)
               .then((response) => {
                  // commit('SET_LOADING', false);
                  resolve(response);
               })
               .catch((error) => {
                  // commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
   },
});
export default DeprecationAssets;
