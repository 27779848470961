import { RouteRecordRaw } from 'vue-router';

const settingRouter: Array<RouteRecordRaw> = [
  {
    path: 'accounting-dashboard',
    name: 'accounting-dashboard',
    meta: {
      title: 'Accounting',
    },
    component: () =>
      import('@/views/modules/accounting/views/dashboard/AccountingDashboard.vue'),
  },
  {
    path: 'settings-accounting-mangment',
    name: 'settings-accounting',
    meta: {
      title: 'Accounting settings',
    },
    component: () =>
      import('@/views/modules/accounting/views/settings/Setting.vue'),
  },
  {
    path: 'project-groups',
    name: 'project-groups',
    meta: {
      permission: 'project_groups',
      title: 'Project Groups',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/project-group/List.vue'
      ),
  },
  {

    path: "recycle-the-fiscal-year",
    name: "recycle-the-fiscal-year",
    meta: {
      // permission: 'journal_posting',
      title: "Recycle Fiscal Year"
    },
    component: () =>
      import(
        "@/views/modules/accounting/views/settings/recycle-the-fiscal-year/base.vue"
      ),
  },
  {
    path: "close-fiscal-year",
    name: "accounting-close-fiscal-year",
    meta: {
      title: "Close Fiscal Year",
      permission: 'close_financial_years',
      type: 'write',
    },
    component: () =>
      import(
        "@/views/modules/accounting/views/settings/close-fiscal-year/base.vue"
      ),
  },
  {
    path: 'projects',
    name: 'projects',
    meta: {
      permission: 'projects',
      title: 'Projects',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/projects/List.vue'
      ),
  },
  {
    path: 'journal-entries-list',
    name: 'journal-entries-list',
    meta: {
      permission: 'account',
      title: 'Chart Of Account',
    },
    component: () =>
      import(
        '@/views/modules/accounting/views/journal-entries/List.vue'
      ),
  },
  {
    path: 'journal-entries-view/:id',
    name: 'journal-entries-view',
    meta: {
      permission: 'account',
      title: 'Account',
    },
    component: () =>
      import(
        '@/views/modules/accounting/views/journal-entries/View.vue'
      ),
  },
  {
    path: 'account-groups',
    name: 'account-groups',
    meta: {
      permission: 'account_groups',
      title: 'Account Groups',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/account-groups/List.vue'
      ),
  },
  {
    path: 'cost-center-groups',
    name: 'cost-center-groups',
    meta: {
      permission: 'cost_center_groups',
      title: 'Cost Center Groups',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/cost-center-group/List.vue'
      ),
  },
  {
    path: 'cost-center',
    name: 'cost-center',
    meta: {
      permission: 'cost_centers',
      title: 'Cost Centers',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/cost-center/List.vue'
      ),
  },
  {
    path: 'asset-groups',
    name: 'asset-groups',
    meta: {
      permission: 'asset_groups',
      title: 'Asset Groups',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/asset-groups/List.vue'
      ),
  },
  {
    path: 'assets',
    name: 'assets',
    meta: {
      permission: 'assets',
      title: 'Assets',
    },
    component: () =>
      import(
        '@/views/modules/settings/views/settings/account-settings/assets/List.vue'
      ),
  },
];

export default settingRouter;
